'use strict';

const { TaxYears } = require('@taxfix/de-itc-types');

const calculateMobilityBonus2021 = require('../2021/mobility_bonus_2021');

function calculateMobilityBonus(fields, totalTaxableIncome, personsEarnings, taxYear) {
  switch (taxYear) {
    case TaxYears['2k21']:
      return calculateMobilityBonus2021(fields, totalTaxableIncome, personsEarnings);
    default:
      return undefined;
  }
}

module.exports = calculateMobilityBonus;