'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _State = require('./State');

var _State2 = _interopRequireDefault(_State);

var _renderProps = require('../utils/renderProps');

var _renderProps2 = _interopRequireDefault(_renderProps);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var complement = function complement(fn) {
  return function () {
    return !fn.apply(undefined, arguments);
  };
};

var List = function List(_ref) {
  var _ref$initial = _ref.initial,
      initial = _ref$initial === undefined ? [] : _ref$initial,
      onChange = _ref.onChange,
      props = _objectWithoutProperties(_ref, ['initial', 'onChange']);

  return _react2.default.createElement(
    _State2.default,
    { initial: { list: initial }, onChange: onChange },
    function (_ref2) {
      var state = _ref2.state,
          setState = _ref2.setState;
      return (0, _renderProps2.default)(props, {
        list: state.list,
        setList: function setList(list) {
          return setState({ list: list });
        },
        push: function push(value) {
          return setState(function (_ref3) {
            var list = _ref3.list;
            return { list: [].concat(_toConsumableArray(list), [value]) };
          });
        },
        pull: function pull(predicate) {
          return setState(function (_ref4) {
            var list = _ref4.list;
            return { list: list.filter(complement(predicate)) };
          });
        },
        sort: function sort(compareFn) {
          return setState(function (_ref5) {
            var list = _ref5.list;
            return { list: [].concat(_toConsumableArray(list)).sort(compareFn) };
          });
        }
      });
    }
  );
};

exports.default = List;