'use strict';

const Decimal = require('decimal.js');

function getFieldsByNr(fields = [], nr = '') {
  if (!Array.isArray(fields) || typeof nr !== 'string') {
    return undefined;
  }

  return fields.filter(field => {
    return field.nr === nr;
  });
}

function getValuesByLfdNr(fields = [], nr = '') {
  const matchedFields = getFieldsByNr(fields, nr);

  const personA = matchedFields.find((field) => field.lfdNr === 1) || { value: 0 };
  const personB = matchedFields.find((field) => field.lfdNr === 2) || { value: 0 };

  return {
    personA: personA.value,
    personB: personB.value,
  };
}

const getValue = (fields, nr) => {
  const field = fields.find(f => f.nr === nr);
  return typeof field === 'undefined' ? null : field.value;
};

const getStringifiedValue = (fields, nr) => {
  const field = fields.find(f => f.nr === nr);
  if (typeof field === 'undefined' || field.value == null ) { 
    return null;
  }
  return field.value.toString();
};

const getBool = (fields, nr, trueValue) => {
  const value = getValue(fields, nr);
  return value === trueValue;
};

const getDecimal = (fields, nr) => {
  const value = getValue(fields, nr);
  return value === null ? new Decimal(0) : new Decimal(value);
};

const getFieldWithLfdNrAndIndex = (fields, nr, lfdNr, index) => {
  const field = fields.find(f => f.nr === nr && f.lfdNr === lfdNr && f.index === index);
  return field != null ? field : null;
};

function recurse(fields, nrs, func, lfdNr, index, maxLfdNr, maxIndex) {
  const indexedFields = fields.filter(f => f.lfdNr === lfdNr && f.index === index);

  // more fields to iterate over
  if (indexedFields.length > 0) {
    func(indexedFields, lfdNr, index);
  }

  ++index;

  if (index > maxIndex) {
    ++lfdNr;

    if (lfdNr > maxLfdNr) {
      console.log('stop looking', lfdNr, index);
      return;
    }

    index = 1;
  }

  recurse(fields, nrs, func, lfdNr, index, maxLfdNr, maxIndex);
}

const iterateLfdNrAndIndex = (fields, nrs, func) => {
  const allNrsFields = fields.filter(f => nrs.includes(f.nr));

  if (allNrsFields.length === 0) {
    console.log('fields do not include these nrs: ', nrs);
    return;
  }

  let maxLfdNr = 1;
  let maxIndex = 1;

  allNrsFields.forEach(f => {
    maxLfdNr = f.lfdNr > maxLfdNr ? f.lfdNr : maxLfdNr;
    maxIndex = f.index > maxIndex ? f.index : maxIndex;
  });

  recurse(allNrsFields, nrs, func, 1, 1, maxLfdNr, maxIndex);
};

const moveField = (fields, nrSrc, nrDest, lfdNr, index) => {
  const src = getFieldWithLfdNrAndIndex(fields, nrSrc, lfdNr, index);  

  if (src !== null) {
    const dest = getFieldWithLfdNrAndIndex(fields, nrDest, lfdNr, index);

    if (dest === null) {
      src.nr = nrDest;

    } else {
      console.warn(`moveField: could not overwrite ${nrDest} [${lfdNr}][${index}]`);
    }
  } else {
    console.warn(`moveField: could not find ${nrSrc} [${lfdNr}][${index}]`);
  }
};

const updateField = (fields, nr, lfdNr, index, value) => {
  const field = fields.find(f => f.nr === nr && f.lfdNr === lfdNr && f.index === index);

  if (typeof field === 'undefined') {
    fields.push({
      nr,
      lfdNr,
      index,
      value
    });
  } else {
    field.value = value;
  }
};

/**
 * Parse a date in a DD.MM.YYYY by default
 * Supports different separators
 *
 * @param {*} dateString
 * @param {*} separator
 */
const parseDateString = (dateString, separator = '.') => {
  var parts = dateString.split(separator);
  return new Date(parts[2], parts[1] - 1, parts[0]);
};

const deleteField = (fields, fieldNr) => {
  const index = fields.findIndex(x => x.nr === fieldNr);
  if (index > -1) {
    fields.splice(index, 1);
  }
};

module.exports = {
  getBool,
  getDecimal,
  getValue,
  getStringifiedValue,
  getFieldsByNr,
  getValuesByLfdNr,
  iterateLfdNrAndIndex,
  moveField,
  updateField,
  parseDateString,
  deleteField,
};
