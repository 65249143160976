// @flow

import type {
  TreeNode,
} from '../types';

const tree2list = (tree: TreeNode): TreeNode[] => {
  let out = [
    tree,
  ];

  if (tree.children) {
    out = tree.children.reduce(
      (flat, child) => flat.concat(tree2list(child)),
      out,
    );
  }

  return Object.freeze(out);
};

export default tree2list;
