'use strict';

const Decimal = require('decimal.js');
const { getDecimal } = require('../utils/utils_fields');

function homeOffice2016(fields) {
  let expenses = new Decimal(0);

  let costs = getDecimal(fields, '0204504');
  expenses = expenses.add(costs);

  // TODO there is a maximum of 1250 in some cases - try and respect that

  return expenses;
}

module.exports = homeOffice2016;
