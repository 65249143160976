// @flow

import BaseAnswer from './Base';
import type { ValidationFunction, ValidationSchema } from '../flowtypes';

export class ESInKindIncome extends BaseAnswer {
  constructor() {
    const formats = [
      { name: 'INCOME', type: 'number' },
      { name: 'RETENTION_ONE', type: 'number' },
      { name: 'RETENTION_TWO', type: 'number' },
    ];

    super('ES_in_kind_income', formats);
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/ES_in_kind_income.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/ES_in_kind_income');
    /* eslint-enable global-require */
  }

  format(value: any, formatKey: string): ?(string | boolean) {
    super.format(value);

    const requestedFormat = this.getFormat(formatKey).name;

    switch (requestedFormat) {
      case this.formats.INCOME:
        return this.valueOf(value, 'income');

      case this.formats.RETENTION_ONE:
        return this.valueOf(value, 'retention1');

      case this.formats.RETENTION_TWO:
        return this.valueOf(value, 'retention2');
      default:
        break;
    }

    return '';
  }
}

export default new ESInKindIncome();
