// @flow
import memoizeOne from 'memoize-one';

import type {
  Cache,
  Refs,
  Responses,
  TreeNode,
} from '../types';

import {
  isAnswered,
  isSkipped,
} from './answersHelper';

import preconditionsMet from './preconditionHelper';

import isQuestion from '../isQuestion';
import createCache from '../utils/createCache';

const questions = memoizeOne((
  list: TreeNode[],
  responses: Responses,
  refs: Refs,
  cache: Cache = createCache(),
  year: number,
) => (
  list
    .filter(node => isQuestion(node))
    .filter(question => preconditionsMet(question, responses, refs, cache, year))
));

const unansweredQuestions = (
  list: TreeNode[],
  responses: Responses,
  refs: Refs,
  cache: Cache,
  year: number,
) => (
  questions(list, responses, refs, cache, year)
    .filter(question => !isAnswered(question, responses))
);

const unrespondedQuestions = (
  list: TreeNode[],
  responses: Responses,
  refs: Refs,
  cache: Cache,
  year: number,
) => (
  questions(list, responses, refs, cache, year)
    .filter(question => !isSkipped(question, responses))
    .filter(question => !isAnswered(question, responses))
);

const respondedQuestions = (
  list: TreeNode[],
  responses: Responses,
  refs: Refs,
  cache: Cache,
  year: number,
) => (
  questions(list, responses, refs, cache, year)
    .filter(question => isSkipped(question, responses) || isAnswered(question, responses))
);

const hasAnswersForAllQuestions = (
  list: TreeNode[],
  responses: Responses,
  refs: Refs,
  cache: Cache,
  year: number,
): boolean => (
  unansweredQuestions(list, responses, refs, cache, year).length === 0
);

const hasNoResponsesForQuestions = (
  list: TreeNode[],
  responses: Responses,
  refs: Refs,
  cache: Cache,
  year: number,
): boolean => (
  unrespondedQuestions(
    list,
    responses,
    refs,
    cache,
    year,
  ).length === questions(list, responses, refs, cache, year).length
);

const hasResponsesForAllQuestions = (
  list: TreeNode[],
  responses: Responses,
  refs: Refs,
  cache: Cache,
  year: number,
): boolean => (
  unrespondedQuestions(list, responses, refs, cache, year).length === 0
);

const nextUnrespondedIndex = (
  list: TreeNode[],
  responses: Responses,
  refs: Refs,
  cache: Cache,
  year: number,
): number => {
  const unresponded = unrespondedQuestions(list, responses, refs, cache, year);

  if (unresponded.length) {
    return list.indexOf(unresponded[0]);
  }

  return -1;
};

const currentProgress = (
  list: TreeNode[],
  responses: Responses,
  refs: Refs,
  cache: Cache,
  year: number,
) => {
  const respondedQuestionsLength = respondedQuestions(list, responses, refs, cache, year).length;

  const questionLength = questions(list, responses, refs, cache, year).length;
  if (questionLength <= 0) {
    return 1;
  }
  const percentageLeft = respondedQuestionsLength / questionLength;
  return Math.min(Math.max(0, percentageLeft), 1);
};

export {
  hasAnswersForAllQuestions,
  hasNoResponsesForQuestions,
  hasResponsesForAllQuestions,
  nextUnrespondedIndex,
  currentProgress,
};
