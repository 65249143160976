// @flow

import get from 'lodash/get';
import set from 'lodash/fp/set';
import flatMap from 'lodash/flatMap';

import { format } from '@taxfix/answer-types';

import { daysAndNightsForDuration } from '@taxfix/dates';

import meetsPrecondition from '../meetsPrecondition';

import preconditionsMet from '../q-and-a/preconditionHelper';

import { idForIndexChain, indexFromId, referenceFor, unindexed, appendIndex } from '../reference';

import type {
  Cache,
  Id,
  Input,
  InputProperties,
  Inputs,
  Responses,
  TreeNode,
  WrappedError,
} from '../types';

const valueOf = (answer: any) =>
  (answer != null && {}.hasOwnProperty.call(answer, 'value') ? answer.value : answer);

const withIndex = (input: Input, idx?: number[] | null): Input => {
  if (!idx) {
    return input;
  }
  return set(['properties', 'idx'], idx, input);
};

const addResolver = (properties: InputProperties, responses: Responses) =>
  properties.values
    .map(key =>
      (typeof key === 'number'
        ? key
        : valueOf(responses[key] != null ? responses[key].answer : null)))
    .reduce((acc, value) => acc + value, 0);

// Helper for boolean operations
const booleanResolver = (operation: 'AND' | 'OR', values, responses: Responses = {}) => {
  let initialValue = true;

  // TODO: Extract to helper and use across all resolvers
  const resolvedValues = values.map(key =>
    (typeof key === 'boolean'
      ? key
      : valueOf(responses[key] != null ? responses[key].answer : false)));

  if (operation === 'OR') {
    initialValue = false;
  }

  return resolvedValues.reduce((result, value) => {
    switch (operation) {
      case 'AND':
        return result && value;
      case 'OR':
        // XXX: strictly typing bools might hide logic errors, but is maybe also expected behaviour?
        // return !!result || value;
        return result || value;
      default:
        return false;
    }
  }, initialValue);
};

const concatResolver = (properties: InputProperties, responses: Responses) =>
  properties.values
    .map((key) => {
      const response = responses[key];
      if (response != null) {
        const val = valueOf(responses[key].answer);
        return val == null ? '' : val;
      }
      return key;
    })
    .join('');

const andResolver = (properties: InputProperties, responses: Responses = {}) =>
  booleanResolver('AND', properties.values, responses);

const orResolver = (properties: InputProperties, responses: Responses = {}) =>
  booleanResolver('OR', properties.values, responses);

const logicResolver = (
  properties: InputProperties,
  responses: Responses = {},
  inputs: Inputs,
  refs: { [key: Id]: TreeNode },
  id: Id,
  cache: Cache,
  year: number,
) => {
  const { condition, value } = properties;
  const resolvedValue = valueOf(responses[value] != null ? responses[value].answer : value);

  // we might mutate it, so keep eslint happy
  let conditionCopy = condition;

  // before/after, check for dynamic dates, then substitute the value:
  if (Array.isArray(conditionCopy) && ['before', 'after'].includes(conditionCopy[0])) {
    // make a copy before mutating
    conditionCopy = [...condition];

    const datePart = condition[1];

    // dynamic dates are inputs that are prefixed with `generated~`, so let's take advantage:
    const isDynamicDate = typeof datePart === 'string' && datePart.includes('generated~');

    if (isDynamicDate) {
      const input = inputs[datePart];

      // eslint-disable-next-line no-use-before-define
      const resolved = inputResolver(input, responses, inputs, refs, id, cache, year);
      conditionCopy[1] = resolved;
    }
  }

  return meetsPrecondition(resolvedValue, conditionCopy);
};

const taxYearResolver = (
  input: Input,
  responses: Responses,
  inputs: Inputs,
  refs: { [key: Id]: TreeNode },
  id: Id,
  cache: Cache,
  year: number,
) => year;

const findIndexedAncestor = (node: TreeNode, id: Id, refs: { [key: Id]: TreeNode }) => {
  if (!node || !node.parentId) {
    return undefined;
  } else if (unindexed(node.parentId) === id) {
    return node.parentId;
  }
  return findIndexedAncestor(refs[node.parentId || ''], id, refs);
};

const indexResolver = (
  input: InputProperties,
  responses: Responses,
  inputs: Inputs,
  refs: { [key: Id]: TreeNode },
  id: Id,
) => {
  const ref = input.reference;
  if (ref) {
    const loopIndex = get(refs[id], ['loopContext', 'indexes', ref]);
    if (loopIndex != null) {
      return loopIndex;
    }
    return undefined;
  }
  const indexes = indexFromId(id);
  return indexes && indexes.length > 0 ? indexes.pop() : undefined;
};

const durationResolver = (properties: InputProperties, responses: Responses = {}) => {
  const { values } = properties;

  const possibleFromDate = get(responses, [values[0], 'answer'], null);
  const possibleUntilDate = get(responses, [values[1], 'answer'], null);

  const from = possibleFromDate != null ? valueOf(possibleFromDate) : values[0];
  const until = possibleUntilDate != null ? valueOf(possibleUntilDate) : values[1];

  let days = 0;

  try {
    // eslint-disable-next-line prefer-destructuring
    days = daysAndNightsForDuration({
      from,
      until,
    }).days;
  } catch (err) {
    // If the values are references to inputs and the inputs are
    // not available (because a question has not been answered yet)
    // then this will throw. This is usually fine as we
    // return days = 0 and the precondition will not be met.
  }

  return days > 0 ? days : 0;
};

const divideResolver = (properties: InputProperties, responses: Responses) => {
  const resolvedValues = properties.values.map(key =>
    (typeof key === 'number'
      ? key
      : valueOf(responses[key] != null ? responses[key].answer : null)));

  const head = resolvedValues[0];
  const tail = resolvedValues.slice(1);

  const result = tail.reduce((acc, value) => acc / value, head);

  return result === Infinity || Number.isNaN(result) ? 0 : result;
};

const multiplyResolver = (properties: InputProperties, responses: Responses) =>
  properties.values
    .map(key =>
      (typeof key === 'number'
        ? key
        : valueOf(responses[key] != null ? responses[key].answer : null)))
    .reduce((acc, value) => acc * value, 1);

const internalRoundResolver = (
  operation: 'ROUND' | 'ROUND_UP' | 'ROUND_DOWN',
  properties: InputProperties,
  responses: Responses = {},
) => {
  const key = get(properties, 'value', null);
  if (key == null) {
    throw new Error('roundResolver: no value set');
  }

  const answer = valueOf(responses[key] != null ? responses[key].answer : null);
  if (answer == null) {
    return 0;
  }

  switch (operation) {
    case 'ROUND':
      return Math.round(answer);
    case 'ROUND_DOWN':
      return Math.floor(answer);
    case 'ROUND_UP':
      return Math.ceil(answer);
    default:
      throw new Error(`unknown round operation: ${operation}`);
  }
};

const roundResolver = (properties: InputProperties, responses: Responses) =>
  internalRoundResolver('ROUND', properties, responses);

const roundDownResolver = (properties: InputProperties, responses: Responses) =>
  internalRoundResolver('ROUND_DOWN', properties, responses);

const roundUpResolver = (properties: InputProperties, responses: Responses) =>
  internalRoundResolver('ROUND_UP', properties, responses);

// ugh, referring to refs here is such an almighty hack. I'm sorry.
// it'll bail early if and resolve to "0" if there's a problem.
const sumResolver = (
  properties: InputProperties,
  responses: Responses,
  inputs: Inputs,
  refs: { [key: Id]: TreeNode },
  id: Id,
  cache: Cache,
  year: number,
) => {
  const node = refs[id];

  if (node.loop == null) {
    return 0;
  }

  const { basedOn, count } = node.loop;
  const loopCount = count || (responses[basedOn] || {}).answer;

  if (loopCount == null || typeof loopCount !== 'number') {
    return 0;
  }

  let sum = 0;

  for (let i = 0; i < loopCount; i += 1) {
    const referenceId = appendIndex(properties.reference, i);

    const resolveIfOutputMaybe = (output: ?Id): number => {
      const targetNode = refs[referenceId];
      if (!targetNode) {
        return 0;
      }

      try {
        if (!preconditionsMet(targetNode, responses, refs, cache, year)) {
          return 0;
        }
      } catch (originalError) {
        const error: WrappedError = new Error(`sumResolver: error with question id: "${id}" on output: "${
          output != null ? output : 'null'
        }"`);
        error.originalError = originalError;
        throw error;
      }

      if (output == null) {
        if (typeof responses[referenceId].answer !== 'number') {
          return 0;
        }

        return responses[referenceId].answer;
      }

      if (
        targetNode.outputs == null ||
        targetNode.inputs == null ||
        targetNode.inputs[output] == null
      ) {
        return 0;
      }

      const targetInput = targetNode.inputs[output];

      // recursively call the input resolver for the referenced node
      // eslint-disable-next-line no-use-before-define
      const value = inputResolver(
        targetInput,
        responses,
        targetNode.inputs,
        refs,
        referenceId,
        cache,
        year,
      );

      return value == null ? 0 : value;
    };

    const value = resolveIfOutputMaybe(properties.value);

    sum += value;
  }

  return sum;
};

const staticResolver = (properties: InputProperties) => properties.value;

const subtractResolver = (properties: InputProperties, responses: Responses) => {
  const resolvedValues = properties.values.map(key =>
    (typeof key === 'number'
      ? key
      : valueOf(responses[key] != null ? responses[key].answer : null)));
  const head = resolvedValues[0];
  const tail = resolvedValues.slice(1);

  return tail.reduce((acc, value) => acc - value, head);
};

const formatResolver = (properties: InputProperties, responses: Responses) => {
  const response = responses[referenceFor(properties)];

  if (response == null) {
    return null;
  }

  const { answer } = response;

  if (answer == null) {
    return null;
  }

  if (properties.type == null && properties.format === 'default') {
    return answer;
  }

  return format(properties.type, properties.format, answer);
};

export const getIndex = (
  idx: string | number[] | void,
  responses: Responses,
  inputs: Inputs,
  refs: { [key: Id]: TreeNode },
  id: Id,
  defaultValue: ?number[] = undefined,
): ?(number[]
) => {
  if (Array.isArray(idx)) {
    return flatMap(idx, i => getIndex(i, responses, inputs, refs, id, [i]));
  } else if (idx === '$current') {
    return indexFromId(id);
  } else if (typeof idx === 'string' && /^\$index/.test(idx)) {
    const indexInput = inputs[`generated~${idx}`];
    if (indexInput) {
      const index = indexResolver(indexInput.properties, responses, inputs, refs, id);
      if (index != null) {
        return [index];
      }
    }
  }
  return defaultValue;
};

const valueResolver = (
  properties: InputProperties,
  responses: Responses,
  inputs: Inputs,
  refs: { [key: Id]: TreeNode },
  id: Id,
  cache: Cache,
  year: number,
) => {
  if (properties.reference != null) {
    const idx = getIndex(properties.idx, responses, inputs, refs, id);
    const reference = idx == null ? properties.reference : unindexed(properties.reference);
    const ref = refs[idForIndexChain(reference, idx)];

    if (ref != null) {
      if (preconditionsMet(ref, responses, refs, cache, year)) {
        const refInputs = ref.inputs;
        if (refInputs != null && ref.outputs != null) {
          const input = ref.outputs.includes(properties.value) ? refInputs[properties.value] : null;

          if (input != null) {
            // recursively call the input resolver for the referenced node
            // eslint-disable-next-line no-use-before-define
            return inputResolver(
              withIndex(input, idx),
              responses,
              refInputs,
              refs,
              ref.id,
              cache,
              year,
            );
          }
        }
      }
    }
  }

  if (typeof properties.key === 'string' && responses[properties.value] != null) {
    return get(responses[properties.value].answer, properties.key, null);
  }

  const response = responses[referenceFor(properties)];
  return valueOf(response != null ? response.answer : null);
};

const findDependentInputKeys = (properties: InputProperties, possibleInputs: Inputs) => {
  const inputs = possibleInputs == null ? {} : possibleInputs;

  if (properties.value != null && properties.reference != null) {
    return [];
  }

  const values = properties.value || properties.values || null;
  const potentialKeys = values !== null ? [].concat(values) : [];

  return potentialKeys.filter(key => inputs[key] != null);
};

type Resolver<T> = (
  InputProperties,
  Responses,
  Inputs,
  { [key: Id]: TreeNode },
  Id,
  Cache,
  number,
) => T;

const resolvers: { [string]: Resolver<any> } = {
  add: addResolver,
  and: andResolver,
  concat: concatResolver,
  format: formatResolver,
  logic: logicResolver,
  duration: durationResolver,
  divide: divideResolver,
  multiply: multiplyResolver,
  or: orResolver,
  round: roundResolver,
  roundDown: roundDownResolver,
  roundUp: roundUpResolver,
  static: staticResolver,
  subtract: subtractResolver,
  sum: sumResolver,
  taxYear: taxYearResolver,
  value: valueResolver,
  index: indexResolver,
};

// XXX: it's a pretty large smell to have `refs` passed in here, but we need it for recursively
//      resolving `value` input types that reference other nodes
const inputResolver = (
  input: Input,
  responses: Responses,
  inputs: Inputs,
  refs: { [key: Id]: TreeNode },
  id: Id,
  cache: Cache,
  year: number,
) => {
  // idToInputMap is a map of Ids to cached responses
  // The same Id will return a previous response
  let idToInputMap;

  if (cache != null) {
    idToInputMap = cache.get(id);

    if (idToInputMap === undefined) {
      idToInputMap = new Map();
      cache.set(id, idToInputMap);
    }

    const cachedResolved = idToInputMap.get(input);

    if (cachedResolved !== undefined) {
      return cachedResolved;
    }
  } else if (__DEV__) { // eslint-disable-line no-undef
    throw new Error('inputResolver requires a `cache` but none was given');
  }

  const resolver = resolvers[input.type];

  if (resolver == null) {
    throw new Error(`Unknown input resolver type ${input.type}`);
  }

  const resolvedValues = Object.assign({}, responses);
  const dependentInputKeys = findDependentInputKeys(input.properties, inputs);

  for (let i = 0, len = dependentInputKeys.length; i < len; i += 1) {
    const key = dependentInputKeys[i];
    const dependent = inputs[key];
    if (dependent === input) {
      throw new Error(`Infinite loop in input ${key} of node ${id}`);
    }
    resolvedValues[key] = {
      answer: inputResolver(dependent, responses, inputs, refs, id, cache, year),
    };
  }

  const resolved = resolver(input.properties, resolvedValues, inputs, refs, id, cache, year);

  if (cache != null && idToInputMap != null) {
    idToInputMap.set(input, resolved);
  }

  return resolved;
};

export default inputResolver;

export {
  addResolver,
  andResolver,
  concatResolver,
  formatResolver,
  logicResolver,
  durationResolver,
  divideResolver,
  multiplyResolver,
  orResolver,
  roundResolver,
  roundDownResolver,
  roundUpResolver,
  staticResolver,
  subtractResolver,
  sumResolver,
  taxYearResolver,
  valueResolver,
  valueOf,
  indexResolver,
};
