'use strict';
module.exports = validate21;
module.exports.default = validate21;
var schema6 = {
  $id: 'http://taxfix.de/schemas/answers/countrylumpsums.json',
  $schema: 'http://json-schema.org/draft-06/schema#',
  type: 'object',
  properties: {
    isAbroad: { type: 'boolean' },
    country: { $ref: '#/definitions/country' },
    city: { type: 'string' },
    lumpSums: { $ref: '#/definitions/lumpsum' },
  },
  additionalProperties: false,
  required: ['isAbroad'],
  definitions: {
    country: {
      type: 'object',
      properties: { code: { type: 'string' }, name: { type: 'string' } },
      additionalProperties: false,
      required: ['code', 'name'],
    },
    lumpsum: {
      type: 'object',
      properties: {
        hours24: { type: 'number' },
        hours8: { type: 'number' },
        accommodation: { type: 'number' },
      },
      additionalProperties: false,
      required: ['hours24', 'hours8', 'accommodation'],
    },
  },
};
var schema7 = {
  type: 'object',
  properties: { code: { type: 'string' }, name: { type: 'string' } },
  additionalProperties: false,
  required: ['code', 'name'],
};

function validate22(data, valCxt) {
  'use strict';
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.code === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'code' },
        message: "should have required property '" + 'code' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.name === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'name' },
        message: "should have required property '" + 'name' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    for (var key0 in data) {
      if (!(key0 === 'code' || key0 === 'name')) {
        var err2 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.code !== undefined) {
      if (typeof data.code !== 'string') {
        var err3 = {
          keyword: 'type',
          dataPath: dataPath + '/code',
          schemaPath: '#/properties/code/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.name !== undefined) {
      if (typeof data.name !== 'string') {
        var err4 = {
          keyword: 'type',
          dataPath: dataPath + '/name',
          schemaPath: '#/properties/name/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    var err5 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate22.errors = vErrors;
  return errors === 0;
}

var schema8 = {
  type: 'object',
  properties: {
    hours24: { type: 'number' },
    hours8: { type: 'number' },
    accommodation: { type: 'number' },
  },
  additionalProperties: false,
  required: ['hours24', 'hours8', 'accommodation'],
};

function validate24(data, valCxt) {
  'use strict';
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.hours24 === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'hours24' },
        message: "should have required property '" + 'hours24' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.hours8 === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'hours8' },
        message: "should have required property '" + 'hours8' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.accommodation === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'accommodation' },
        message: "should have required property '" + 'accommodation' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    for (var key0 in data) {
      if (!(key0 === 'hours24' || key0 === 'hours8' || key0 === 'accommodation')) {
        var err3 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.hours24 !== undefined) {
      if (!(typeof data.hours24 == 'number')) {
        var err4 = {
          keyword: 'type',
          dataPath: dataPath + '/hours24',
          schemaPath: '#/properties/hours24/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
    if (data.hours8 !== undefined) {
      if (!(typeof data.hours8 == 'number')) {
        var err5 = {
          keyword: 'type',
          dataPath: dataPath + '/hours8',
          schemaPath: '#/properties/hours8/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
    }
    if (data.accommodation !== undefined) {
      if (!(typeof data.accommodation == 'number')) {
        var err6 = {
          keyword: 'type',
          dataPath: dataPath + '/accommodation',
          schemaPath: '#/properties/accommodation/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
  } else {
    var err7 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err7];
    } else {
      vErrors.push(err7);
    }
    errors++;
  }
  validate24.errors = vErrors;
  return errors === 0;
}

function validate21(data, valCxt) {
  'use strict' /*# sourceURL="http://taxfix.de/schemas/answers/countrylumpsums.json" */;
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.isAbroad === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'isAbroad' },
        message: "should have required property '" + 'isAbroad' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    for (var key0 in data) {
      if (!(key0 === 'isAbroad' || key0 === 'country' || key0 === 'city' || key0 === 'lumpSums')) {
        var err1 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
    if (data.isAbroad !== undefined) {
      if (typeof data.isAbroad !== 'boolean') {
        var err2 = {
          keyword: 'type',
          dataPath: dataPath + '/isAbroad',
          schemaPath: '#/properties/isAbroad/type',
          params: { type: 'boolean' },
          message: 'should be boolean',
        };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.country !== undefined) {
      if (
        !validate22(data.country, {
          dataPath: dataPath + '/country',
          parentData: data,
          parentDataProperty: 'country',
          rootData: rootData,
        })
      ) {
        vErrors = vErrors === null ? validate22.errors : vErrors.concat(validate22.errors);
        errors = vErrors.length;
      }
    }
    if (data.city !== undefined) {
      if (typeof data.city !== 'string') {
        var err3 = {
          keyword: 'type',
          dataPath: dataPath + '/city',
          schemaPath: '#/properties/city/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.lumpSums !== undefined) {
      if (
        !validate24(data.lumpSums, {
          dataPath: dataPath + '/lumpSums',
          parentData: data,
          parentDataProperty: 'lumpSums',
          rootData: rootData,
        })
      ) {
        vErrors = vErrors === null ? validate24.errors : vErrors.concat(validate24.errors);
        errors = vErrors.length;
      }
    }
  } else {
    var err4 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate21.errors = vErrors;
  return errors === 0;
}
