// @flow

import Address from './Address';
import BankConfirmation from './BankConfirmation';
import CountryLumpSums from './CountryLumpSums';
import Date from './Date';
import Distance from './Distance';
import Duration from './Duration';
import MultipleChoice from './MultipleChoice';
import MultipleSelect from './MultipleSelect';
import PayrollData from './PayrollData';
import Number from './Number';
import String from './String';
import TaxAuthority from './TaxAuthority';
import YesNo from './YesNo';
import BankAccount from './BankAccount';
import TaxNumber from './TaxNumber';
import ITAddress from './ITAddress';
import ITTaxNumber from './ITTaxNumber';
import Iban from './Iban';
import ForeignAddress from './ForeignAddress';
import GlobalAddress from './GlobalAddress';
import ESPersonalId from './ESPersonalId';
import ESAddress from './ESAddress';
import ESInKindIncome from './ESInKindIncome';
import ESMainIncome from './ESMainIncome';
import ESNameAmountNif from './ESNameAmountNif';

export {
  GlobalAddress,
  ForeignAddress,
  Address,
  BankConfirmation,
  CountryLumpSums,
  Date,
  Distance,
  Duration,
  MultipleChoice,
  MultipleSelect,
  PayrollData,
  Number,
  String,
  TaxAuthority,
  YesNo,
  BankAccount,
  TaxNumber,
  ITAddress,
  ITTaxNumber,
  Iban,
  ESAddress,
  ESPersonalId,
  ESMainIncome,
  ESInKindIncome,
  ESNameAmountNif,
};
