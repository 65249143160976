// @flow

export default (value: any, validationFunction: (any) => boolean, name: string) => {
  const result = validationFunction(value);

  const DEBUG = false;

  if (DEBUG === true && result === false) {
    // eslint-disable-next-line no-console
    console.warn({
      name,
      value,
      errors: validationFunction.errors,
    });
  }

  return result;
};
