'use strict';

const {
  getBool,
  getDecimal,
  getValue,
  iterateLfdNrAndIndex,
  moveField,
  updateField,
} = require('../utils');

module.exports = fields => {

  // based on disability, there are changes to 1) commuting and 
  // 2) double housekeeping

  const gradeOfDisability = getDecimal(fields, '0109708');
  const hampered = getBool(fields, '0109707', 1);

  // Changes to commuting must happen, if the user’s degree of disability is at
  // least 50 and he/she is hampered or 70 degree
  if (gradeOfDisability.gte(70) || (gradeOfDisability.gte(50) && hampered)) {
    
    // 1 commuting

    // because the fields we have to adjust could be lfdNr/index looped, we have
    // to group them together before changing them
    iterateLfdNrAndIndex(fields, [
      '0203507', '0203517', '0203504', '0203512', '0203611', '0203516', '0203503',
      'txf-commuting-additional-cost-employers-office',
      'txf-commuting-additional-cost-meeting-point',
      'txf-commuting-additional-drives-employers-office',
      'txf-commuting-additional-drives-meeting-point',
    ], (indexedFields, lfdNr, index) => {
      
      // if 0203504 !== null => 0203507, 0203611
      // if 0203512 !== null => 0203517, 0203516
      const f0203504 = getValue(indexedFields, '0203504');
      const f0203512 = getValue(indexedFields, '0203512');

      // employer's office
      if (f0203504 !== null) {
        // The number 1 must be entered into 0203507 for employer’s office
        updateField(fields, '0203507', lfdNr, index, 1);

        // Additional costs must be added to 0203611 for employer’s office
        const additionalCost = getDecimal(indexedFields,
          'txf-commuting-additional-cost-employers-office');
        let cost = getDecimal(indexedFields, '0203611');
        cost = cost.add(additionalCost);
        if (!cost.isZero()) {
          cost = cost.toDP(2).toNumber();
          updateField(fields, '0203611', lfdNr, index, cost);
        }

        // Additional drives must be added to 0203503 for employer’s office
        const additionalDrives = getDecimal(indexedFields,
          'txf-commuting-additional-drives-employers-office');
        let drives = getDecimal(indexedFields, '0203503');
        drives = drives.add(additionalDrives);
        if (!drives.isZero()) {
          drives = drives.toNumber();
          updateField(fields, '0203503', lfdNr, index, drives);
        }

      // meeting point
      } else if (f0203512 !== null) {
        // The number 1 must be entered into 0203517 for meeting point
        updateField(fields, '0203517', lfdNr, index, 1);

        // Additional costs must be added to 0203516 for meeting point
        const additionalCost = getDecimal(indexedFields,
          'txf-commuting-additional-cost-meeting-point');
        let cost = getDecimal(indexedFields, '0203516');
        cost = cost.add(additionalCost);
        cost = cost.toDP(2).toNumber();
        updateField(fields, '0203516', lfdNr, index, cost);

        // Additional drives must be added to 0203511 for meeting point
        const additionalDrives = getDecimal(indexedFields,
          'txf-commuting-additional-drives-meeting-point');
        let drives = getDecimal(indexedFields, '0203511');
        drives = drives.add(additionalDrives);
        if (!drives.isZero()) {
          drives = drives.toNumber();
          updateField(fields, '0203511', lfdNr, index, drives);
        }
      
      } else {
        console.warn('could not find anything to change');
      }
    });

    // 2 double housekeeping

    // because the fields we have to adjust could be lfdNr/index looped, we have
    // to group them together before changing them
    iterateLfdNrAndIndex(fields, [
      '0207116', '0207302',
      'txf-double-housekeeping-km-with-car',
      '0207304', '0207117',
      'txf-double-housekeeping-cost-per-km',
      '0207211', '0207411',
    ], (indexedFields, lfdNr, index) => {
      // The distance between the two homes must be entered into 0207302 and deleted from 0207116
      moveField(fields, '0207116', '0207302', lfdNr, index);
    
      // The distance, which was travelled by a private car must be entered
      // into 0207303. Info is requested in the regular double housekeeping
      // flow. (txf-double-housekeeping-km-with-car)
      let kmWithCar = getDecimal(indexedFields, 'txf-double-housekeeping-km-with-car');
      kmWithCar = kmWithCar.toDP(2).toNumber();
      updateField(fields, '0207303', lfdNr, index, kmWithCar);
      
      // The number of drives must be entered into 0207304 and deleted from 0207117
      moveField(fields, '0207117', '0207304', lfdNr, index);
      
      // The costs per km requested in the double housekeeping flow must be
      // entered into 0207305 (here is a taxfix-ID required). 
      // (txf-double-housekeeping-cost-per-km)
      let costPerKM = getDecimal(indexedFields, 'txf-double-housekeeping-cost-per-km');
      costPerKM = costPerKM.toDP(2).toNumber();
      updateField(fields, '0207305', lfdNr, index, costPerKM);
      
      // Costs for public transport must be entered into 0207411 and deleted from 0207211.
      moveField(fields, '0207211', '0207411', lfdNr, index);
    });
  }

  return fields;
};
