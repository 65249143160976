'use strict';

const Decimal = require('decimal.js');
const { getDecimal } = require('../utils/utils_fields');

function notPayrollTax2016(fields) {
  let sum = new Decimal(0);

  // Steuerpflichtiger Arbeitslohn - keine Lohnsteuer einbehalten
  sum = sum.add(getDecimal(fields, '0201401'));
  
  // CHECK Werbungskosten Arbeitslohn - kein Steuerabzug - Betrag
  // sum = sum.sub(getDecimal(fields, '0209502'));

  return sum;
}

module.exports = notPayrollTax2016;
