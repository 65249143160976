'use strict';

const {
  getValue,
  updateField,
  deleteField,
} = require('../utils');

const GERMAN_IBAN_REGEX = /(^DE).*/ig;

/**
 * Input: 0102102
 * Output: 0102102 if the IBAN is german
 *         0102603 if the IBAN is foreign
 * Logic: The IBAN should be written into one of the above 
 * depending on wether it's a german or foreign one
 */
module.exports = fields => {
  try {
    const IBAN = getValue(fields, '0102102');
    const FOREIGN_IBAN = getValue(fields, '0102603');
    
    if (!IBAN && !FOREIGN_IBAN) {
      console.warn('Missing IBAN in taxml-postprocessing');
    }
    if (FOREIGN_IBAN) {
      // The postprocessing was already done
      // No need for other actions
      return fields;
    }
    if (IBAN && IBAN.match(GERMAN_IBAN_REGEX)) {
      // Do nothing, the IBAN should remain in field 0102102
    } else {
      // Foreign IBAN: delete field 0102102 and write the IBAN to 0102603
      deleteField(fields, '0102102');
      updateField(fields, '0102603', 1, 1, IBAN);
    }
  } catch (error) {
    throw error;
  }

  return fields;
};