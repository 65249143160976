'use strict';

const logger = require('../logger');
const Decimal = require('decimal.js');
const { getBool, getDecimal } = require('../utils/utils_fields');
const { percent: getPercent } = require('../utils/utils_decimal');
const { toString } = require('../utils/utils_logs');

let MAX_AMOUNT_PER_PERSON = null;

//
// Rentenversicherungsbeiträge
//
function pensionInsurance2016(fields, incomeWithoutProfessionalExpensesPerPerson, options, lumpsums) {
  logger.debug('\n-------------------------\nPENSION INSURANCE');
  MAX_AMOUNT_PER_PERSON = new Decimal(lumpsums.pension.max_contribution);
  logger.debug('MAX_AMOUNT_PER_PERSON', toString(MAX_AMOUNT_PER_PERSON));
  
  let joint = getBool(fields, '0101201', 'X');
  let sum = new Decimal(0);

  let paidByEmployee = new Decimal(0);
  let paidByEmployer = new Decimal(0);

  let paidByEmployeeA = new Decimal(0);
  let paidByEmployerA = new Decimal(0);

  let paidByEmployeeB = new Decimal(0);
  let paidByEmployerB = new Decimal(0);

  // Person: Altersvorsorge - lt. Lohnsteuerbescheinigung
  paidByEmployeeA = paidByEmployeeA.add(getDecimal(fields, '2000401'));
  // Person: Altersvorsorge - sonstige zur gesetzl. RV
  paidByEmployeeA = paidByEmployeeA.add(getDecimal(fields, '2000601'));
  // Person: Altersvorsorge - Zertifizierte Basisrentenverträge nach 31.12.04
  let zertifizierteBasisrente = getDecimal(fields, '2000701');
  if (options && options.case104a === true) {
    paidByEmployeeA = paidByEmployeeA.add(zertifizierteBasisrente);
  }

  sum = sum.add(paidByEmployeeA);
  paidByEmployee = paidByEmployee.add(paidByEmployeeA);

  // Person: Altersvorsorge - Erstattete Beiträge / steuerfreie Zuschüsse 
  sum = sum.sub(getDecimal(fields, '2000703'));
  
  // Person: Altersvorsorge - AG-Anteil RV  
  paidByEmployerA = paidByEmployerA.add(getDecimal(fields, '2000801'));
  // Person: Altersvorsorge - AG-Anteil RV bei pauschal besteuertem Mini-Job  
  paidByEmployerA = paidByEmployerA.add(getDecimal(fields, '2000901'));

  sum = sum.add(paidByEmployerA);
  paidByEmployer = paidByEmployer.add(paidByEmployerA);

  let checkIndex = 0;

  // (Ankreuzfeld) keine gesetzliche Rentenversicherungspfl. - Beamter  
  let check1 = getBool(fields, '2002201', 1);
  // (Ankreuzfeld) keine gesetzliche Rentenversicherungspfl. - Vorstand / GF
  let check2 = getBool(fields, '2002202', 1);
  // (Ankreuzfeld) keine gesetzliche Rentenversicherungspfl. - andere Gründe
  let check3 = getBool(fields, '2005303', 1);
  // (Ankreuzfeld) keine gesetzliche Rentenversicherungspfl. - Anwartschaft auf Altersversorgung
  let check4 = getBool(fields, '2002401', 1);
  // (Ankreuzfeld) keine gesetzliche Rentenversicherungspfl. - Anwartschaft ganz / teilw. ohne eigene Beiträge
  let check5 = getBool(fields, '2002501', 1);

  if (check1 || check2 || check3 || check4 || check5) {
    ++checkIndex;
  }

  // Person B
  // (Ankreuzfeld) B: keine gesetzliche Rentenversicherungspfl. - Beamter  
  let check6 = getBool(fields, '2003101', 1);
  // (Ankreuzfeld) B: keine gesetzliche Rentenversicherungspfl. - Vorstand / GF  
  let check7 = getBool(fields, '2003102', 1);
  // (Ankreuzfeld) B: keine gesetzliche Rentenversicherungspfl. - andere Gründe  
  let check8 = getBool(fields, '2005304', 1);
  // (Ankreuzfeld) B: keine gesetzliche Rentenversicherungspfl. - Anwartschaft auf Altersversorgung  
  let check9 = getBool(fields, '2003301', 1);
  // (Ankreuzfeld) B: keine gesetzliche Rentenversicherungspfl. - Anwartschaft ganz / teilw. ohne eigene Beiträge  
  let check10 = getBool(fields, '2003401', 1);

  if (joint) {
    // Person B: Altersvorsorge - lt. Lohnsteuerbescheinigung
    paidByEmployeeB = paidByEmployeeB.add(getDecimal(fields, '2000402'));
    // Person B: Altersvorsorge - sonstige zur gesetzl. RV
    paidByEmployeeB = paidByEmployeeB.add(getDecimal(fields, '2000602'));
    // Person B: Altersvorsorge - Zertifizierte Basisrentenverträge nach 31.12.04
    let zertifizierteBasisrente = getDecimal(fields, '2000702');
    if (options && options.case104a === true) {
      paidByEmployeeB = paidByEmployeeB.add(zertifizierteBasisrente);
    }
    sum = sum.add(paidByEmployeeB);
    paidByEmployee = paidByEmployee.add(paidByEmployeeB);
    // Person B: Altersvorsorge - Erstattete Beiträge / steuerfreie Zuschüsse 
    sum = sum.sub(getDecimal(fields, '2000704'));
    // Person B: Altersvorsorge - AG-Anteil RV  
    paidByEmployerB = paidByEmployerB.add(getDecimal(fields, '2000802'));
    // Person B: Altersvorsorge - AG-Anteil RV bei pauschal besteuertem Mini-Job  
    paidByEmployerB = paidByEmployerB.add(getDecimal(fields, '2000902'));

    sum = sum.add(paidByEmployerB);
    paidByEmployer = paidByEmployer.add(paidByEmployerB);

    if (check6 || check7 || check8 || check9 || check10) {
      ++checkIndex;
    }
  }
  
  let sumBeforeMax = new Decimal(sum);
  let max = MAX_AMOUNT_PER_PERSON;

  if (joint) {
    max = max.times(2);
  }

  let percentage = 100;
  let deductionsFromMax = new Decimal(0);
  const lumpSumpMaxIncomeForContribution = new Decimal(lumpsums.pension.max_income_for_contribution);

  logger.debug('Check list 1-10', check1, check2, check3, check4, check5, check6, check7, check8, check9, check10);

  if (check4 || check5) {
    const maxIncomeForContribution = Decimal.min(incomeWithoutProfessionalExpensesPerPerson[0], lumpSumpMaxIncomeForContribution);
    deductionsFromMax = getPercent(maxIncomeForContribution, lumpsums.pension.contribution_rate);
  } else {
    if (checkIndex === 1) {
      percentage = joint ? 90.65 : 81.3;
    } else if (checkIndex === 2) {
      percentage = 81.3;
    }
  }

  // debugger
  if (joint) {
    if ((check4 || check5) && (check9 || check10)) {
      const maxIncomeForContribution = Decimal.min(incomeWithoutProfessionalExpensesPerPerson[1], lumpSumpMaxIncomeForContribution);
      deductionsFromMax = deductionsFromMax.add(maxIncomeForContribution, lumpsums.pension.contribution_rate);
    } else {
      if (checkIndex === 1) {
        percentage = joint ? 90.65 : 81.3;
      } else if (checkIndex === 2) {
        percentage = 81.3;
      }
    }
  }

  max = getPercent(max, percentage);

  logger.debug('max', toString(max));
  logger.debug('deductionsFromMax', toString(deductionsFromMax));
  max = max.sub(deductionsFromMax);
  logger.debug('max after deductions', toString(max));

  let sumAfterMax = Decimal.min(sum, max);
  logger.debug('sumAfterMax', toString(sumAfterMax));
  let proportionalMaxValue = getPercent(sumAfterMax, lumpsums.pension.deductible_share_pension_insurance).ceil();
  sum = proportionalMaxValue.sub(paidByEmployerA).ceil();

  if (options && options.case104a === true) {
    logger.debug('paidByEmployeeA', toString(paidByEmployeeA));
    logger.debug('paidByEmployerA', toString(paidByEmployerA));
    paidByEmployeeA = paidByEmployeeA.sub(zertifizierteBasisrente);
    const gekurzterHochsbetrag = MAX_AMOUNT_PER_PERSON.sub(paidByEmployeeA).sub(paidByEmployerA).ceil();
    logger.debug('gekurzterHochsbetrag', toString(gekurzterHochsbetrag));
    logger.debug('zertifizierteBasisrente', toString(zertifizierteBasisrente));
    
    if (gekurzterHochsbetrag.lt(zertifizierteBasisrente)) {
      zertifizierteBasisrente = getPercent(gekurzterHochsbetrag, lumpsums.pension.deductible_share_pension_insurance);
    } else {
      zertifizierteBasisrente = getPercent(zertifizierteBasisrente, lumpsums.pension.deductible_share_pension_insurance);
    }
  } else {
    zertifizierteBasisrente = getPercent(zertifizierteBasisrente, lumpsums.pension.deductible_share_pension_insurance);
  }
  logger.debug('zertifizierteBasisrente', toString(zertifizierteBasisrente));

  if (joint) {
    sum = sum.sub(paidByEmployerB);
  }

  sum = Decimal.max(sum, 0);

  let subContributionPensionInsurance = new Decimal(0);
  subContributionPensionInsurance = subContributionPensionInsurance.add(getDecimal(fields, '2000401'));
  subContributionPensionInsurance = subContributionPensionInsurance.add(getDecimal(fields, '2000501'));
  subContributionPensionInsurance = subContributionPensionInsurance.add(getDecimal(fields, '2000601'));
  subContributionPensionInsurance = subContributionPensionInsurance.sub(getDecimal(fields, '2000703'));
  subContributionPensionInsurance = subContributionPensionInsurance.add(getDecimal(fields, '2000701'));
  subContributionPensionInsurance = subContributionPensionInsurance.sub(getDecimal(fields, '2000801'));
  subContributionPensionInsurance = subContributionPensionInsurance.add(getDecimal(fields, '2000901'));

  logger.debug('Erstattungsüberhänge subContributionPensionInsurance',toString(subContributionPensionInsurance));

  return {
    paidByEmployee,
    paidByEmployer,
    sumBeforeMax,
    max,
    sumAfterMax,
    proportionalMaxValue,
    zertifizierteBasisrente,
    pensionInsurance: sum,
    subContributionPensionInsurance,
    percentage,
  };
}

module.exports = pensionInsurance2016;
