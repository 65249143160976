'use strict';

const Decimal = require('decimal.js');
const { getDecimal } = require('../utils/utils_fields');

const MAX_AMOUNT_PER_PERSON = 6000;

function educationCost2016(fields) {
  let expenses = new Decimal(0);

  // Steuerpflichtige Person: Aufwendungen eigene Berufsausbildung - Summe
  let jobEducationPersonA = getDecimal(fields, '0108202');
  jobEducationPersonA = Decimal.min(jobEducationPersonA, MAX_AMOUNT_PER_PERSON);
  expenses = expenses.add(jobEducationPersonA);

  // Steuerpflichtige Person B: Aufwendungen eigene Berufsausbildung - Summe
  let jobEducationPersonB = getDecimal(fields, '0108110');
  jobEducationPersonB = Decimal.min(jobEducationPersonB, MAX_AMOUNT_PER_PERSON);
  expenses = expenses.add(jobEducationPersonB);

  return expenses;
}

module.exports = educationCost2016;


