// @flow

import * as _ from 'lodash';
import set from 'lodash/fp/set';

import type {
  Answer,
  Cache,
  Id,
  Refs,
  Responses,
  TreeNode,
} from './types';

import {
  answerFor,
  isAnsweredFor,
} from './q-and-a/answersHelper';

import {
  hasAnswersForAllQuestions,
  hasNoResponsesForQuestions,
  hasResponsesForAllQuestions,
  nextUnrespondedIndex,
  currentProgress,
} from './q-and-a/answerQueue';

import isQuestion from './isQuestion';
import propagatePreconditions from './processor/propagatePreconditions';
import tree2list from './transformer/tree2list';
import createCache from './utils/createCache';
import preconditionsMet from './q-and-a/preconditionHelper';
import { translationKeys } from './q-and-a/translationsHelper';
import inputsForQuestion from './calculator/inputsForQuestion';

import { indexFromId } from './reference';

const addTranslations = (responses: Responses, refs: Refs, cache: Cache, year: number) =>
  (node: TreeNode) => {
    const nodeInputsForQuestion = inputsForQuestion(node, refs, responses, cache, year);
    const translations = translationKeys(node, nodeInputsForQuestion);

    return set(
      'translationKeys',
      translations,
      node,
    );
  };

class Quizmaster {
  constructor(
    tree: TreeNode,
    responses: Responses,
    refs: Refs,
    editing: boolean = false,
    cache: Cache = createCache(),
    year: number,
    shouldAddTranslations: boolean = true,
  ) {
    this.tree = propagatePreconditions(tree);
    this.responses = responses;
    this.editing = editing;
    this.inputResolverCache = cache;
    this.year = year;

    const mapper = shouldAddTranslations ? addTranslations(responses, refs, cache, year) : x => x;
    this.questions = tree2list(this.tree)
      .filter(isQuestion)
      .map(mapper);
    this.refs = refs;
  }

  inputResolverCache: Cache;
  editing: boolean;
  responses: Responses;
  year: number;

  tree: TreeNode;
  questions: TreeNode[] = [];
  refs: Refs;

  answerAt(index: number): ?Answer {
    if (this.questions[index]) {
      return this.answerFor(this.questions[index].id);
    }

    return undefined;
  }

  answerFor(id: Id) {
    return answerFor(id, this.responses);
  }

  hasAllAnswers() {
    if (this.editing) {
      return false;
    }

    return hasAnswersForAllQuestions(
      this.questions,
      this.responses,
      this.refs,
      this.inputResolverCache,
      this.year,
    );
  }

  hasAllResponses() {
    if (this.editing) {
      return false;
    }

    return hasResponsesForAllQuestions(
      this.questions,
      this.responses,
      this.refs,
      this.inputResolverCache,
      this.year,
    );
  }

  isAnsweredAt(index: number) {
    return this.isAnsweredFor(this.questions[index].id);
  }

  isAnsweredFor(id: string) {
    return isAnsweredFor(id, this.responses);
  }

  nextQuestionIndex() {
    if (this.editing) {
      // ugh
      return 0;
    }

    return nextUnrespondedIndex(
      this.questions,
      this.responses,
      this.refs,
      this.inputResolverCache,
      this.year,
    );
  }

  progress() {
    if (this.questions.length === 0) {
      return 1;
    }

    if (this.hasAllAnswers()) {
      return 1;
    }

    return currentProgress(
      this.questions,
      this.responses,
      this.refs,
      this.inputResolverCache,
      this.year,
    );
  }

  isNotStarted() {
    return hasNoResponsesForQuestions(
      this.questions,
      this.responses,
      this.refs,
      this.inputResolverCache,
      this.year,
    );
  }

  activeResponses(): Responses {
    return _.pickBy(this.responses, response =>
      // question exits and it is still active by preconditions
      this.refs[response.answerID] && preconditionsMet(
        this.refs[response.answerID],
        this.responses,
        this.refs,
        this.inputResolverCache,
        this.year,
      ) && this.isValidLoop(response.answerID));
  }

  responsesWithActive(): Responses {
    const activeResponses = Object.keys(this.activeResponses());

    return Object.entries(this.responses).reduce(
      (inActives, [key, response]) => ({
        ...inActives,
        [key]: {
          ...response,
          active: activeResponses.includes(key),
        },
      }),
      {},
    );
  }

  isValidLoop(answerID: Id): boolean {
    const answer = this.refs[answerID];
    const loopId = answer.loopContext && answer.loopContext.loopId;
    const loop = this.refs[loopId];

    if (!answer) {
      return false;
    }

    const answerIndex = indexFromId(answer.id);

    if (!loop || !answerIndex) {
      // no loop
      return true;
    }

    const loopCount = loop.loop && loop.loop.count;
    const loopIndex = answerIndex.slice(-1);

    return loopIndex < loopCount;
  }
}

export default Quizmaster;
