'use strict';

const Decimal = require('decimal.js');
const { getDecimal } = require('../utils/utils_fields');

function workEquipment2016(fields, grossSalary) {
  const minCost = new Decimal(110);
  let costs = getDecimal(fields, '0204403');
  costs = Decimal.max(costs, minCost);

  const shouldBeCappedAtSalary = costs.eq(minCost) && grossSalary.lt(minCost);

  if (shouldBeCappedAtSalary) {
    costs = grossSalary;
  }

  return costs;
}

module.exports = workEquipment2016;
