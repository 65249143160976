'use strict';

const { TaxYears } = require('@taxfix/de-itc-types');

const lumpSum2021 = require('../2021/lumpsums_2021.json');
const lumpSum2020 = require('../2020/lumpsums_2020.json');
const lumpSum2019 = require('../2019/lumpsums_2019.json');
const lumpSum2018 = require('../2018/lumpsums_2018.json');
const lumpSum2017 = require('../2017/lumpsums_2017.json');
const lumpSum2016 = require('../2016/lumpsums_2016.json');
const lumpSum2015 = require('../2015/lumpsums_2015.json');

function lumpSum(taxYear) {
  switch (taxYear) {
    case TaxYears['2k21']:
      return lumpSum2021;
    case TaxYears['2k20']:
      return lumpSum2020;
    case TaxYears['2k19']:
      return lumpSum2019;
    case TaxYears['2k18']:
      return lumpSum2018;
    case TaxYears['2k17']:
      return lumpSum2017;
    case TaxYears['2k16']:
      return lumpSum2016;
    default:
      return lumpSum2015;
  }
}

module.exports = lumpSum;