// @flow

import * as steuernummer from '@taxfix/steuernummer';

export const tax_number = (data: string) => steuernummer.validate(data);

export const customFormats = {
  tax_number,
};

export default customFormats;
