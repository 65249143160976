'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const {
  getBool,
  getBoolWithLfdNr,
  getDecimal,
  getFieldsByIndex,
  getLfdNrGroupsByNrs,
  getMonths,
  getValue,
  iterateLfNr
} = require('../utils/utils_fields');
const { percent: getPercent } = require('../utils/utils_decimal');
const { toString } = require('../utils/utils_logs');
const dateUtils = require('../utils/utils_date');

function getKidsPercentage(fields, nr, joint) {
  let percent = 50;

  if (joint) {
    percent = 100;
  } else {
    let p = getValue(fields, nr); // 0506202
    if (typeof p !== 'undefined') {
      try {
        percent = parseInt(p, 10);
      } catch (error) {
        throw new Error('kids percentage is not a number (' + nr + '): ' + p);
      }
    }
  }

  return percent;
}

function disabilityCost(fields, nrA, nrB, nrC, lfdNr = 1) {
  let lumpsum = new Decimal(0);

  // (Ankreuzfeld) Steuerpflichtige Person: hinterblieben  
  let b1 = getBoolWithLfdNr({ fields, nr: nrA, lookFor: 1, lfdNr });
  if (b1) {
    lumpsum = lumpsum.add(370);
  }
  // (Ankreuzfeld) Steuerpflichtige Person: blind / ständig hilflos
  let b2 = getBoolWithLfdNr({ fields, nr: nrB, lookFor: 1, lfdNr });
  if (b2) {
    lumpsum = lumpsum.add(7400);
  } else {
    // Steuerpflichtige Person: Grad der Behinderung 
    let b3 = getDecimal(fields, nrC, 1, lfdNr).toNumber();

    let l = new Decimal(0);

    if (b3 >= 20) {
      if (b3 <= 29) {
        l = new Decimal(384);
      } else if (b3 <= 39) {
        l = new Decimal(620);
      } else if (b3 <= 49) {
        l = new Decimal(860);
      } else if (b3 <= 59) {
        l = new Decimal(1140);
      } else if (b3 <= 69) {
        l = new Decimal(1440);
      } else if (b3 <= 79) {
        l = new Decimal(1780);
      } else if (b3 <= 89) {
        l = new Decimal(2120);
      } else if (b3 <= 99) {
        l = new Decimal(2460);
      } else {
        l = new Decimal(2840);
      }
    }

    lumpsum = lumpsum.add(l);
  }

  return lumpsum;
}

//
//
function exceptionalExpenses2021(fields, totalAmountOfEarnings, lumpsums) {
    logger.debug('\n\t-----------------\n\tEXCEPTIONAL EXPENSES');

  let expenses = new Decimal(0);

  // Andere außergewöhnliche Belastungen
  let sumExtraordinary = new Decimal(0);

  // 2020: new costs
  const _0161304 = getDecimal(fields, '0161304');
  const _0161404 = getDecimal(fields, '0161404');
  const _0161504 = getDecimal(fields, '0161504');
  const _0161704 = getDecimal(fields, '0161704');
  const _0161804 = getDecimal(fields, '0161804');

  // 2020: new reimbursements
  const _0161305 = getDecimal(fields, '0161305');
  const _0161405 = getDecimal(fields, '0161405');
  const _0161505 = getDecimal(fields, '0161505');
  const _0161705 = getDecimal(fields, '0161705');
  const _0161805 = getDecimal(fields, '0161805');

  sumExtraordinary = sumExtraordinary
    .add(_0161304)
    .add(_0161404)
    .add(_0161504)
    .add(_0161704)
    .add(_0161804)
    .sub(_0161305)
    .sub(_0161405)
    .sub(_0161505)
    .sub(_0161705)
    .sub(_0161805);

  logger.debug('expenses', toString(sumExtraordinary));

  // Berufsausbildung: auswärtige Unterbringung
  let educationCost = new Decimal(0);
  let joint = getBool(fields, '0101201', 'X');
  let numberOfChildren = 0;

  iterateLfNr(getLfdNrGroupsByNrs(fields, [
    '0500701',  // add birthday as a mandatory field
    '0504806',
    '0506202',
    '0501610'
  ]), lfdNrFields => {
    ++numberOfChildren;

    // Volljähriges Kind: Berufsausbildung - auswärtige Unterbringung - von-bis
    let months = getMonths(lumpsums.year, lfdNrFields, '0504806');
    let isMonthsInTrainingSet = !!getValue(lfdNrFields, '0501610');
    if (isMonthsInTrainingSet) {
      const birthdateStr = getValue(lfdNrFields, '0500701');
      let monthsInTraining = getMonths(lumpsums.year, lfdNrFields, '0501610');
      logger.debug('isMonthsInTrainingSet monthsInTraining', toString(monthsInTraining));
      monthsInTraining = dateUtils.correctMonthsArrayAgainstBirtdate(monthsInTraining, birthdateStr, lumpsums.year);
      logger.debug('isMonthsInTrainingSet corrected monthsInTraining', toString(monthsInTraining));

      months = months.map((monthValue, index) => {
        return (monthValue ===  1 && monthsInTraining[index] === 1) ? 1 : 0;
      });
    }
    let numberOfMonths = months.reduce((a, b) => a + b);

    if (numberOfMonths === 0) {
      return;
    }

    let totalCost = new Decimal(924).times(numberOfMonths).div(12);

    const percent = getKidsPercentage(lfdNrFields, '0506202', joint);

    educationCost = educationCost.add(getPercent(totalCost, percent));
  });
  logger.debug('educationCost',  toString(educationCost));
  expenses = expenses.add(educationCost);

  // Pauschbetrag Behinderung
  let disabilityA = disabilityCost(fields, '0109704', '0109706', '0109708');
  let disabilityB = disabilityCost(fields, '0109704', '0109706', '0109708', 2);

  logger.debug('disabilityA', toString(disabilityA));
  logger.debug('disabilityB', toString(disabilityB));

  expenses = expenses.add(disabilityA);
  expenses = expenses.add(disabilityB);

  // pauschbetrag für behinderte kinder
  iterateLfNr(getLfdNrGroupsByNrs(fields, [
    '0505805',
    '0505807',
    '0505809',
    '0506007'
  ]), (lfdNrFields, index) => {
    const lfdNr = index + 1;
    let disability = disabilityCost(lfdNrFields, '0505805', '0505807', '0505809', lfdNr);
   
    logger.debug('\t disability', toString(disability));

    const percent = getKidsPercentage(lfdNrFields, '0506007', joint);

    logger.debug('\t percent', percent);
    expenses = expenses.add(getPercent(disability, percent));
  });

  logger.debug('1 expenses', toString(expenses));

  // Pauschbetrag unbezahlte Pflege
  const careFields = ['0110601', '0106603','0110602', '0110603', '0110604', '0110605', '0110606', '0106607', '0106507'];
  let groups = getLfdNrGroupsByNrs(fields, careFields);
  getFieldsByIndex(groups[0], careFields).forEach(indexFields => {
    // Unentgeltliche persönliche Pflege: Name, Anschrift, 
    // Verwandschaftsverhältnis der hilflosen Person 
    let person = getValue(indexFields, '0110601');
    logger.debug('person', person);
    if (typeof person !== 'undefined') {
      // Anzahl weiterer Pflegepersonen
      let numberOfPersonal = getDecimal(indexFields, '0106603');
      
      // For 2015 we need to change the logic to count the numberOfPersonal from
      // fields 0110602-0110607
      if (numberOfPersonal.toNumber() === 0) {
        numberOfPersonal = numberOfPersonal.add((!!getValue(indexFields, '0110602') ? 1 : 0));
        numberOfPersonal = numberOfPersonal.add((!!getValue(indexFields, '0110603') ? 1 : 0));
        numberOfPersonal = numberOfPersonal.add((!!getValue(indexFields, '0110604') ? 1 : 0));
        numberOfPersonal = numberOfPersonal.add((!!getValue(indexFields, '0110605') ? 1 : 0));
        numberOfPersonal = numberOfPersonal.add((!!getValue(indexFields, '0110606') ? 1 : 0));
        numberOfPersonal = numberOfPersonal.add((!!getValue(indexFields, '0110607') ? 1 : 0));
      }


      logger.debug('numberOfPersonal', toString(numberOfPersonal));

      const pflegegrad = getValue(fields, '0161606');
      let pflegegradPauschbetrag = 0;
      if (pflegegrad === 2) pflegegradPauschbetrag = 600;
      if (pflegegrad === 3) pflegegradPauschbetrag = 1100;
      if (pflegegrad === 4) pflegegradPauschbetrag = 1800;

      logger.debug('pflegegradPauschbetrag: ', pflegegradPauschbetrag);

      let lumpsum = new Decimal(0);
      if (pflegegradPauschbetrag > 0) {
        // cared by person a = 1
        // cared by person b = 2
        // cared by person a and b = 3
        let caredBy = getValue(indexFields, '0106507');
        if (caredBy === 3) {
          lumpsum = new Decimal(pflegegradPauschbetrag).div(numberOfPersonal.add(2)).mul(2);
        } else {
          lumpsum = new Decimal(pflegegradPauschbetrag).div(numberOfPersonal.add(1));
        }
      }
      logger.debug('lumpsum', toString(lumpsum));
      expenses = expenses.add(lumpsum);
    }
  });

  logger.debug('2 expenses', toString(expenses));
  logger.debug('\t number of children', numberOfChildren);

  // Behinderten bedingte fahrtkostenpauschale
  function travelExpensesLumpsum(nrs, lfdNr) {
    const [nr80, nrSpecial] = nrs;
    const min80Disability = getBoolWithLfdNr({ fields, nr: nr80, lookFor: 1, lfdNr });
    const specialDisability = getBoolWithLfdNr({ fields, nr: nrSpecial, lookFor: 1, lfdNr });
  
    if(specialDisability) return new Decimal(4500);
    if(min80Disability) return new Decimal(900);

    return new Decimal(0);
  }

  sumExtraordinary = sumExtraordinary.add(travelExpensesLumpsum(['0161706', '0161806'], 1)); // add travel expensed for personA
  sumExtraordinary = sumExtraordinary.add(travelExpensesLumpsum(['0161706', '0161806'], 2)); // add travel expensed for personB

  // Behinderten bedingte fahrtkostenpauschale - Kinder
  let travelExpensesKids = Array.from(Array(numberOfChildren).keys(), kid => kid + 1);

  const travelExpensesKidsAmount = travelExpensesKids.reduce((amount, kid) => {
    // lfdNr is based on 1
    const travelExpenseKid = travelExpensesLumpsum(['0507302', '0507403'], kid);

    if (travelExpenseKid.eq(new Decimal(0))) return amount;

    const percent = getKidsPercentage(fields, '0507507', joint);
    amount = amount.add(getPercent(travelExpenseKid, percent));
    return amount;
  }, new Decimal(0));
  logger.debug('travelExpensesKidsAmount: ', toString(travelExpensesKidsAmount));
  sumExtraordinary = sumExtraordinary.add(travelExpensesKidsAmount);

  // zumutbare Belastung
  // let reasonableCostPercent;

  // Taxation tiers
  const firstTier = { max: new Decimal(15340), single: 5, joint: 4, lessThanOrTwoKids: 2, moreThanTwoKids: 1 };
  const secondTier =  { max: new Decimal(51130), single: 6, joint: 5, lessThanOrTwoKids: 3, moreThanTwoKids: 1 };
  const thirdTier =  { single: 7, joint: 6, lessThanOrTwoKids: 4, moreThanTwoKids: 2 };

  const getTaxationPercent = (tierData, joint, numberOfChildren) => {
    let percent = null;
    if (numberOfChildren === 0) {
      percent = joint ? tierData.joint : tierData.single;
    } else if (numberOfChildren <= 2) {
      percent = tierData.lessThanOrTwoKids;
    } else {
      percent = tierData.moreThanTwoKids;
    }
    return percent;
  };

  let reasonableCalculatedCost = new Decimal(0);

  logger.debug('\t totalAmountOfEarnings', toString(totalAmountOfEarnings));
  if (totalAmountOfEarnings.gt(0)) {
    if (totalAmountOfEarnings.lte(firstTier.max)) {
      const percent = getTaxationPercent(firstTier, joint, numberOfChildren);
      reasonableCalculatedCost = getPercent(totalAmountOfEarnings, percent);
    } else if (totalAmountOfEarnings.lte(secondTier.max)) {
      //we calculate for two tiers, with different percentages
      const firstTierPercent = getTaxationPercent(firstTier, joint, numberOfChildren);
      reasonableCalculatedCost = getPercent(firstTier.max, firstTierPercent);      
      logger.debug('\t firstTierPercent', firstTierPercent);
      logger.debug('\t after first tier reasonableCalculatedCost', toString(reasonableCalculatedCost));
      const secondTierPercent = getTaxationPercent(secondTier, joint, numberOfChildren);
      logger.debug('\t secondTierPercent', secondTierPercent);
      // calculate the rest included in the second tier
      // by subtracting the first fier max value out of totalAmountOfEarnings
      const secondTierRest = totalAmountOfEarnings.sub(new Decimal(firstTier.max));
      reasonableCalculatedCost = 
        reasonableCalculatedCost.add(getPercent(secondTierRest , secondTierPercent));  
      logger.debug('\t after second tier reasonableCalculatedCost', toString(reasonableCalculatedCost));
    } else {
      //we are in the third tier > 51130
      //we calculate for three tiers, with different percentages
      const firstTierPercent = getTaxationPercent(firstTier, joint, numberOfChildren);
      reasonableCalculatedCost = getPercent(firstTier.max, firstTierPercent);
      logger.debug('\t firstTierPercent', firstTierPercent);
      logger.debug('\t after first tier reasonableCalculatedCost', toString(reasonableCalculatedCost));
      const secondTierPercent = getTaxationPercent(secondTier, joint, numberOfChildren);
      logger.debug('\t secondTierPercent', secondTierPercent);
      reasonableCalculatedCost = 
        reasonableCalculatedCost.add(getPercent((secondTier.max.sub(firstTier.max)) , secondTierPercent));
      logger.debug('\t after second tier reasonableCalculatedCost', toString(reasonableCalculatedCost));
  
      const thirdTierPercent = getTaxationPercent(thirdTier, joint, numberOfChildren);
      const thirdTierRest = totalAmountOfEarnings.sub(new Decimal(secondTier.max));
      reasonableCalculatedCost = 
        reasonableCalculatedCost.add(getPercent(thirdTierRest , thirdTierPercent));
      logger.debug('\t after third tier reasonableCalculatedCost', toString(reasonableCalculatedCost));
    }
  }

  logger.debug('\t reasonableCalcuatedCost', toString(reasonableCalculatedCost));

  logger.debug('\t sumExtraordinary', toString(sumExtraordinary));

  const disabilityLumpsum = disabilityA.add(disabilityB);

  let deductibleExtraordinary = sumExtraordinary.sub(reasonableCalculatedCost);
  deductibleExtraordinary = Decimal.max(deductibleExtraordinary, 0);

  expenses = expenses.add(deductibleExtraordinary);
  logger.debug('\t total exceptional expenses', toString(expenses));

  return {
    exceptionalExpenses: expenses,
    sumExtraordinary,
    deductibleExtraordinary,
    disabilityLumpsum,
    adultChildTrainingLumpsum: educationCost,
  };
}

module.exports = exceptionalExpenses2021;
