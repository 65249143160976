// @flow

import * as ibantools from 'ibantools';
import BaseAnswer from './Base';
import { iban, bic } from './custom-formats/bank_account';
import { FormatterError } from '../error';
import type { ValidationSchema, ValidationFunction } from '../flowtypes';

export type BankAccountType = {
  iban: string,
  bic: ?string,
  bank_name: ?string,
};

class BankAccount extends BaseAnswer {
  constructor() {
    const formats = [
      { name: 'IBAN', type: 'string' },
      { name: 'BIC', type: 'string' },
      { name: 'BANK_NAME', type: 'string' },
    ];

    super('bank_account', formats);
    this.hasCustomFormat = true;
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/bank_account.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/bank_account');
    /* eslint-enable global-require */
  }

  validateIBAN(data: string) {
    return iban(data);
  }

  validateBIC(data: string) {
    return bic(data);
  }

  format(value: any, formatKey: string): string {
    super.format(value); // make sure default validations running
    const bankAccountValue: BankAccountType = value;

    switch (formatKey) {
      case this.formats.IBAN:
        return ibantools.electronicFormatIBAN(bankAccountValue.iban);

      case this.formats.BIC:
        return bankAccountValue.bic ? bankAccountValue.bic : '';

      case this.formats.BANK_NAME:
        return bankAccountValue.bank_name ? bankAccountValue.bank_name : '';

      default:
        break;
    }

    throw new FormatterError(`Unknown format: ${formatKey}`);
  }
}

export default new BankAccount();
