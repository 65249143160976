// @flow

import BaseAnswer from './Base';
import type { ValidationFunction, ValidationSchema } from '../flowtypes';

export class ESPersonalId extends BaseAnswer {
  constructor() {
    const formats = [
      { name: 'NIF', type: 'string' },
      { name: 'SUPPORT_NUMBER', type: 'string' },
      { name: 'VALIDITY_DATE', type: 'string' },
      { name: 'BIRTH_DATE', type: 'string' },
      { name: 'HAS_EXPIRATION_DATE', type: 'boolean' },
      { name: 'HAS_NIE', type: 'boolean' },
    ];

    super('ES_personal_id', formats);
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/ES_personal_id.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/ES_personal_id');
    /* eslint-enable global-require */
  }

  format(value: any, formatKey: string): ?(string | boolean) {
    super.format(value);

    const requestedFormat = this.getFormat(formatKey).name;

    switch (requestedFormat) {
      case this.formats.NIF:
        return this.valueOf(value, 'nif');

      case this.formats.SUPPORT_NUMBER:
        return this.valueOf(value, 'supportNumber');

      case this.formats.VALIDITY_DATE:
        return this.valueOf(value, 'validityDate');

      case this.formats.BIRTH_DATE:
        return this.valueOf(value, 'birthDate');

      case this.formats.HAS_EXPIRATION_DATE:
        return this.valueOf(value, 'hasExpirationDate');

      case this.formats.HAS_NIE:
        return this.valueOf(value, 'hasNie');

      default:
        break;
    }

    return '';
  }
}

export default new ESPersonalId();
