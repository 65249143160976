// @flow

import type {
  Cache,
  Id,
  Responses,
  TreeNode,
  WrappedError,
} from '../types';

import {
  answerFor,
} from './answersHelper';

import inputResolver from '../calculator/inputResolver';

import meetsPrecondition from '../meetsPrecondition';

const preconditionsMetInternal = (
  question: TreeNode,
  responses: Responses,
  refs: { [key: Id]: TreeNode },
  cache: Cache,
  year: number,
): boolean => {
  let pass = true;

  if (question.parentId != null) {
    const parent = refs[question.parentId];

    if (parent) {
      const parentPass = preconditionsMetInternal(parent, responses, refs, cache, year);

      if (!parentPass) {
        return false;
      }
    }
  }

  if (question.precondition && typeof question.precondition === 'string') {
    const key = question.precondition;
    if (question.inputs && key in question.inputs) {
      const inputResult = inputResolver(
        question.inputs[key],
        responses,
        question.inputs,
        refs,
        question.id,
        cache,
        year,
      );

      if (typeof inputResult === 'boolean') {
        pass = inputResult;
      }
    }
  } else if (question.preconditions) {
    const { preconditions } = question;

    Object.keys(preconditions).forEach((key) => {
      const answer = (() => {
        if (question.inputs && key in question.inputs) {
          // resolve key to input
          return inputResolver(
            question.inputs[key],
            responses,
            question.inputs,
            refs,
            question.id,
            cache,
            year,
          );
        }
        // resolve key to answer
        return answerFor(key, responses);
      })();

      // if we haven't answered the precondition question, we can't evaluate anything about it
      if (answer == null) {
        pass = false;
        return;
      }

      const precondition = preconditions[key];
      pass = pass && meetsPrecondition(answer, precondition);
    });
  }

  // there are no unsatisfied preconditions, so let's go ahead and ask this question
  return pass;
};

const preconditionsMetWrappingErrors = (
  question: TreeNode,
  responses: Responses,
  refs: { [key: Id]: TreeNode },
  cache: Cache,
  year: number,
): boolean => {
  try {
    return preconditionsMetInternal(
      question,
      responses,
      refs,
      cache,
      year,
    );
  } catch (originalError) {
    let str;
    if (question == null) {
      str = `Error resolving inputs for unknown question (probably a bad value reference?). ${originalError.message}`;
    } else {
      str = `Error resolving inputs for question: ${question.id}. ${originalError.message}`;
    }

    const error: WrappedError = new Error(str);
    error.originalError = originalError;
    throw error;
  }
};

export default preconditionsMetWrappingErrors;
