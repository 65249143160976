// @flow

import BaseAnswer from './Base';
import { FormatterError } from '../error';
import type { ValidationFunction, ValidationSchema } from '../flowtypes';

export type TaxAuthorityAnswer = {
  id: string,
  name: string,
  street: string,
  city: string,
  state: string,
  postalCode: string,
};

class TaxAuthority extends BaseAnswer {
  constructor() {
    const formats = [
      { name: 'ID', type: 'string' },
      { name: 'NAME', type: 'string' },
      { name: 'STREET', type: 'string' },
      { name: 'CITY', type: 'string' },
      { name: 'STATE', type: 'string' },
      { name: 'POSTAL_CODE', type: 'string' },
    ];

    super('tax_authority', formats);
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/tax_authority.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/tax_authority');
    /* eslint-enable global-require */
  }

  format(value: any, formatKey: string): string {
    super.format(value);

    const taxAuthorityValue: TaxAuthorityAnswer = value;

    switch (formatKey) {
      case this.formats.ID:
        return taxAuthorityValue.id;
      case this.formats.NAME:
        return taxAuthorityValue.name;
      case this.formats.STREET:
        return taxAuthorityValue.street;
      case this.formats.CITY:
        return taxAuthorityValue.city;
      case this.formats.STATE:
        return taxAuthorityValue.state;
      case this.formats.POSTAL_CODE:
        return taxAuthorityValue.postalCode;

      default:
        break;
    }

    throw new FormatterError(`Unknown format: ${formatKey}`);
  }
}

export default new TaxAuthority();
