'use strict';

const Decimal = require('decimal.js');

const { isJointAssessment } = require('../utils/utils_field');

/**
 * @param fields
 * @param income
 * @param reducedTaxRate
 * @param incomeSubjectToProgression
 * @param calculateIncomeTax
 * @param options
 * @returns {{taxRate, incomeTax}}
 */
function incomeTaxCalculator(
  fields,
  income,
  reducedTaxRate,
  incomeSubjectToProgression,
  calculateIncomeTax,
  options = {
    roundCalculatedIncomeTax: false
  },
) {
  let incomeTax;
  let taxRate;
  const isJA = isJointAssessment(fields);
  const TWO = new Decimal(2);
  const ZERO = new Decimal(0);

  if (isJA) {
    income = income.div(TWO);
    reducedTaxRate = reducedTaxRate.div(TWO);
    incomeSubjectToProgression = incomeSubjectToProgression.div(TWO);
  }

  if (reducedTaxRate.gt(ZERO)) {
    const x = income.sub(reducedTaxRate);
    const x2 = x.add(incomeSubjectToProgression);

    let taxes = calculateIncomeTax(x2);
    taxRate = taxes.div(x2);

    // 1/5-Regel
    const tax1 = x.times(taxRate);
    const x3 = x.add(reducedTaxRate.times(0.2));
    const x4 = x3.add(incomeSubjectToProgression);
    taxes = calculateIncomeTax(x4);

    const taxRate2 = taxes.div(x4);
    const tax2 = x3.times(taxRate2);
    // Steuer nach § 34 Abs. 1 EStG
    const tax3 = tax2.sub(tax1).times(new Decimal(5));
    incomeTax = tax1.add(tax3);
  } else if (incomeSubjectToProgression.gt(ZERO)) {
    const incomeWithIncomeSubjectToProgression = income.add(incomeSubjectToProgression);
    let calculatedIncomeTax = calculateIncomeTax(incomeWithIncomeSubjectToProgression);

    if (options.roundCalculatedIncomeTax) {
      calculatedIncomeTax = calculatedIncomeTax.toDecimalPlaces(0, Decimal.ROUND_DOWN);
    }

    taxRate = calculatedIncomeTax.div(incomeWithIncomeSubjectToProgression);

    // zvE wird hier einfach mit dem erhöhten Steuersatz von oben multipliziert
    // Beispiel: (30.000, 0, 2.500) => steuersatz von 32.500 auf 30.000 angewendet
    incomeTax = income.times(taxRate);
  } else {
    const taxes = calculateIncomeTax(income);
    taxRate = taxes.div(income);
    incomeTax = taxes;
  }

  if (isJA) {
    incomeTax = incomeTax.times(TWO);
  }
  
  incomeTax = incomeTax.floor();

  return {
    taxRate,
    incomeTax
  };
}

module.exports = incomeTaxCalculator;
