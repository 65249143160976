// @flow

import BaseAnswer from './Base';
import type { ESAddressAnswer } from './shared/Address';
import type { ValidationFunction, ValidationSchema } from '../flowtypes';

export class ESAddress extends BaseAnswer {
  constructor() {
    const formats = [
      { name: 'COUNTRY', type: 'string' }, // Spain 1 or Foreign 0
      { name: 'REGION', type: 'string' },
      { name: 'PROVINCE', type: 'string' },
      { name: 'TOWN', type: 'string' },
      { name: 'LOCATION', type: 'string' },
      { name: 'POSTAL_CODE', type: 'string' },
      { name: 'STREET_TYPE', type: 'string' },
      { name: 'STREET_NAME', type: 'string' },
      { name: 'NUMBER_TYPE', type: 'string' }, // NUM, K/M, S/N, OTR
      { name: 'NUMBER', type: 'string' },
      { name: 'BLOCK', type: 'string' },
      { name: 'STAIR', type: 'string' },
      { name: 'FLOOR', type: 'string' },
      { name: 'DOOR', type: 'string' },
      { name: 'CADASTRAL_REFERENCE', type: 'string' },
      { name: 'IN_SMALL_TOWN', type: 'boolean' }, // invisible
    ];

    super('ES_address', formats);
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/ES_address.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/ES_address');
    /* eslint-enable global-require */
  }

  format(value: any, formatKey: string): ?(string | boolean) {
    super.format(value);

    const addressValue: ESAddressAnswer = value;
    const requestedFormat = this.getFormat(formatKey).name;

    switch (requestedFormat) {
      case this.formats.COUNTRY:
        return this.valueOf(addressValue, 'country');

      case this.formats.REGION:
        return this.valueOf(addressValue, 'region');

      case this.formats.PROVINCE:
        return this.valueOf(addressValue, 'province');

      case this.formats.TOWN:
        return this.valueOf(addressValue, 'town');

      case this.formats.LOCATION:
        return this.valueOf(addressValue, 'location');

      case this.formats.POSTAL_CODE:
        return this.valueOf(addressValue, 'postalCode');

      case this.formats.STREET_TYPE:
        return this.valueOf(addressValue, 'streetType');

      case this.formats.STREET_NAME:
        return this.valueOf(addressValue, 'streetName');

      case this.formats.NUMBER_TYPE:
        return this.valueOf(addressValue, 'numberType');

      case this.formats.NUMBER:
        return this.valueOf(addressValue, 'number');

      case this.formats.BLOCK:
        return this.valueOf(addressValue, 'block');

      case this.formats.STAIR:
        return this.valueOf(addressValue, 'stair');

      case this.formats.FLOOR:
        return this.valueOf(addressValue, 'floor');

      case this.formats.DOOR:
        return this.valueOf(addressValue, 'door');

      case this.formats.CADASTRAL_REFERENCE:
        return this.valueOf(addressValue, 'cadastralReference');

      case this.formats.IN_SMALL_TOWN:
        return this.valueOf(addressValue, 'inSmallTown');

      default:
        break;
    }

    return '';
  }
}

export default new ESAddress();
