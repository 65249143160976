'use strict';

const Decimal = require('decimal.js');
const moment = require('moment');
const leftpad = require('left-pad');
const logger = require('../logger');
const dateUtils = require('../utils/utils_date');
const LivingAbroadCountryTaxRate = require('./living-abroad-country-tax-rate.json');
const { toString } = require('../utils/utils_logs');
const {
  getBool,
  getDecimal,
  getFieldsByIndex,
  getLfdNrGroupsByNrs,
  getMonths,
  getValue,
  iterateLfNr,
  find
} = require('../utils/utils_fields');

/**
 * Will return the taxation value if found in our country-tax map
 * @param {*} ISOCountryCode 
 */
const getCountryTaxRate = (ISOCountryCode) => {
  if (LivingAbroadCountryTaxRate && 
    typeof ISOCountryCode === 'string' && 
    LivingAbroadCountryTaxRate[ISOCountryCode]) {
    return LivingAbroadCountryTaxRate[ISOCountryCode];
  } else {
    return 0.5; //Default if not found 
  }
};

function allowancesPerChild(fields, lfdNrFields, lfdNr, lumpsums) {
  logger.debug('\n\t-----------------\n\tALLOWANCES PER CHILD');
  const grandparentTakingCare = getBool(lfdNrFields, '0503904', 1);
  const birthdateStr = getValue(lfdNrFields, '0500701');

  const childBenefits = getDecimal(lfdNrFields, '0500702');
  logger.debug('kindergeld', toString(childBenefits));

  // grandparent taking care OR no age -> skip complete child
  if (grandparentTakingCare || typeof birthdateStr === 'undefined' || childBenefits.equals(new Decimal(0))) {
    return {
      exemptAmount: new Decimal(0),
      exemptAmountForTaxes: new Decimal(0),
      childBenefits: new Decimal(0),
    };
  }

  let monthParentage = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  // Person A Kindschaftsverhältnis
  // Person B Kindschaftsverhältnis
  // Dritte Person Kindschaftsverhältnis
  monthParentage = getMonths(lumpsums.year, lfdNrFields, [
    '0500601', '0500805', '0501903'
  ], monthParentage, 'add');

  let monthWithAllowances = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const disabled = getMonths(lumpsums.year, lfdNrFields, '0501905');
  const lookingForAJob = getMonths(lumpsums.year, lfdNrFields, '0501802');
  const stillInTraining = getMonths(lumpsums.year, lfdNrFields, [
    '0501610', '0502009'
  ]);
  logger.debug('stillInTraining', stillInTraining);
  
  let monthsLivingInCountry = getMonths(lumpsums.year, lfdNrFields, '0500703');
  logger.debug('monthsLivingInCountry', monthsLivingInCountry);
  monthsLivingInCountry = dateUtils.correctMonthsArrayAgainstDate(monthsLivingInCountry, birthdateStr, lumpsums.year, true);
  logger.debug('corrected monthsLivingInCountry yaaass', birthdateStr, lumpsums.year, monthsLivingInCountry);

  const monthsLivingAbroad = getMonths(lumpsums.year, lfdNrFields, '0500704');
  logger.debug('monthsLivingAbroad', monthsLivingAbroad);

  const monthsLivingWithGrandparents = getMonths(lumpsums.year, lfdNrFields, '0504302');

  const minijobs = getFieldsByIndex(lfdNrFields, ['0502502', '0502618']);
  const otherJobs = getFieldsByIndex(lfdNrFields, ['0502617', '0502713']);

  let monthlyWorkingHours = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  minijobs.forEach(dateHourCombo => {
    const date = getValue(dateHourCombo, '0502502');
    const hours = getValue(dateHourCombo, '0502618');

    dateUtils.addMonthsIfMatchDDMM(monthlyWorkingHours, date, lumpsums.year, hours);
  });

  otherJobs.forEach(dateHourCombo => {
    const date = getValue(dateHourCombo, '0502617');
    const hours = getValue(dateHourCombo, '0502713');

    dateUtils.addMonthsIfMatchDDMM(monthlyWorkingHours, date, lumpsums.year, hours);
  });

  // check for how many months has the other parent lived abroad
  // 0503903

  let monthsOtherParentLivingAbroad = getMonths(lumpsums.year, lfdNrFields, '0503903');
  logger.debug('monthsOtherParentLivingAbroad', monthsOtherParentLivingAbroad);
  monthsOtherParentLivingAbroad = dateUtils.correctMonthsArrayAgainstDate(monthsOtherParentLivingAbroad, birthdateStr, lumpsums.year);
  logger.debug('corrected monthsOtherParentLivingAbroad', monthsOtherParentLivingAbroad);
  logger.debug('monthWithAllowances', monthWithAllowances);
  logger.debug('monthsLivingInCountry', monthsLivingInCountry);
  logger.debug('monthsLivingAbroad', monthsLivingAbroad);
  logger.debug('monthsLivingWithGrandparents', monthsLivingWithGrandparents);

  const deathdateStr = getValue(lfdNrFields, '0501102');
  let monthsWithDeadParent = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  monthsWithDeadParent = dateUtils.correctMonthsArrayAgainstDeathDate(monthsWithDeadParent, deathdateStr, lumpsums.year);
  logger.debug('monthsWithDeadParent', monthsWithDeadParent);
  //
  const transferAllowanceToStepparent = getBool(lfdNrFields, '0503905', 1);

  let monthAge = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const months = monthWithAllowances.map((val, i) => {
    const birthdate = moment(birthdateStr, 'DD.MM.YYYY');

    const mm = leftpad(i + 1, 2);
    const age = moment(`01.${mm}.${lumpsums.year}`, 'DD.MM.YYYY')
                .diff(birthdate, 'years', true);
    monthAge[i] = age;

    if (age < 18 ||
        (age < 21 && lookingForAJob[i]) ||
        (age < 25 && stillInTraining[i]) ||
        disabled[i]
      ) {

      // child can't work more than 20 hours per month
      if (monthlyWorkingHours[i] > 20) {
        return val;
      }

      // check if child was living with grandparents
      if (monthsLivingWithGrandparents[i]) {
        return val;
      }

      if (monthsLivingInCountry[i]) {
        val = 1;
      } else if (monthsLivingAbroad[i]) {
        const abroadCountry = getValue(fields, 'txf-child-abroad-country');
        logger.debug('\t abroadCountry', abroadCountry, getCountryTaxRate(abroadCountry));
        val = getCountryTaxRate(abroadCountry);
      } else {
        val = 0;
      }
    }
    return val;
  });

  logger.debug('monate', months);


  // Beantragung voller Kinderfreibetrag & Freibetrag für Betreuung / Erziehung /
  // Ausbildung - keine Meldung anderer Elternteil
  const allowanceBecauseNoOther = getBool(lfdNrFields, '0503906', 1);
  const noOther = getMonths(lumpsums.year, lfdNrFields, '0504106');

  // Beantragung voller Kinderfreibetrag & Freibetrag für Betreuung / Erziehung /
  // Ausbildung - keine / nicht ausreichende Unterhaltszahlung
  const allowanceBecauseAlimony = getBool(lfdNrFields, '0503907', 1);
  let alimony = getMonths(lumpsums.year, lfdNrFields, '0503911');
  // invert alimony months, because it should not be used for the given months
  alimony = alimony.map(month => month === 0 ? 1 : 0);

  // Residence of the other parent is unknown or the father of
  // the child is not known. Then claim the full amount of child allowance.
  const unknownParentDetails = getValue(lfdNrFields, '0501513');
  if (unknownParentDetails) {
    monthParentage = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
    // if user get alimony, cannot claim the full amount of child allowance
    // for the alimony given months.
    if (allowanceBecauseAlimony && alimony) {
      monthParentage = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }
  }

  logger.debug('unknownParentDetails', unknownParentDetails);
  logger.debug('noOther', noOther);
  logger.debug('alimony', alimony);

  let isAllowanceTransferedToOtherParent = false;
  const allowancesPerMonth = months.map((factor, i) => {
    let baseAllowancePerParent = lumpsums.allowances_children.baseAllowancePerParent;
    let allowanceChildcarePerParent = lumpsums.allowances_children.allowanceChildcarePerParent;

    // if there is only one parent during this month, this parent gets all
    // if the other parent lioves abroad in this months, this parent gets all
    if (monthParentage[i] === 1 || monthsOtherParentLivingAbroad[i] === 1 || monthsWithDeadParent[i] || transferAllowanceToStepparent) {
      baseAllowancePerParent = lumpsums.allowances_children.baseAllowancePerParent * 2;
      allowanceChildcarePerParent = lumpsums.allowances_children.allowanceChildcarePerParent * 2;
    } else {
      if (allowanceBecauseNoOther && noOther[i] === 1) {
        allowanceChildcarePerParent = lumpsums.allowances_children.allowanceChildcarePerParent * 2;
      }

      if (allowanceBecauseAlimony && alimony[i] === 1) {
        baseAllowancePerParent = lumpsums.allowances_children.baseAllowancePerParent * 2;
        allowanceChildcarePerParent = lumpsums.allowances_children.allowanceChildcarePerParent * 2;
      }

      if (monthAge[i] > 18) {
        allowanceChildcarePerParent = lumpsums.allowances_children.allowanceChildcarePerParent;
      }
    }

    logger.debug('allowance', factor, baseAllowancePerParent / 12, allowanceChildcarePerParent / 12);

    isAllowanceTransferedToOtherParent = getBool(lfdNrFields, 'txf-transfer-allowance', 1);
    logger.debug('isAllowanceTransferedToOtherParent', isAllowanceTransferedToOtherParent);
    if (isAllowanceTransferedToOtherParent === true && monthAge[i] < 18) {
      allowanceChildcarePerParent = 0;
    }

    return new Decimal(baseAllowancePerParent)
                .add(allowanceChildcarePerParent)
                .div(12)
                .times(factor);
  });

  const highestAllowance = allowancesPerMonth.reduce((result, month) => month.gt(result) ? month : result, new Decimal(0));

  let exemptAmountForTaxes = highestAllowance.times(12);
  let exemptAmount  = new Decimal(0);
  logger.debug('allowancesPerMonth', allowancesPerMonth.map(toString));
  let joint = getBool(fields, '0101201', 'X');
  if (joint) {
    let exemptAmountPersonB = new Decimal(0);
    const monthsPersonB = getMonths(lumpsums.year, lfdNrFields, ['0500805']);
    const personBRelation = (find(fields, '0500808', 1, lfdNr + 1) || {}).value;

    const monthsPersonA = getMonths(lumpsums.year, lfdNrFields, ['0500601']);
    const personARelation = (find(fields, '0500807', 1, lfdNr + 1) || {}).value;
    let exemptAmountPersonA = new Decimal(0);

    if (personARelation === 1 || personARelation === 2) {
      exemptAmountPersonA = monthsPersonA.reduce((result, isMonthParented, index) => {
        if (!isMonthParented) return result;

        const allowencePerMonth = allowancesPerMonth[index];
        return allowencePerMonth.gt(0) ? result.add(allowencePerMonth) : result;
      }, new Decimal(0));
    }
    if (personBRelation === 1 || personBRelation === 2) {
      exemptAmountPersonB = monthsPersonB.reduce((result, isMonthParented, index) => {
        if (!isMonthParented) return result;

        const allowencePerMonth = allowancesPerMonth[index];
        return allowencePerMonth.gt(0) ? result.add(allowencePerMonth) : result;
      }, new Decimal(0));
    }

    if ((personARelation === 1 || personARelation === 2) && (personBRelation === 1 || personBRelation === 2)) {
      exemptAmountForTaxes = exemptAmountForTaxes.times(2);
    }

    exemptAmount = exemptAmount.add(exemptAmountPersonA);
    exemptAmount = exemptAmount.add(exemptAmountPersonB);
  } else {
    exemptAmount = allowancesPerMonth.reduce((a, b) => {
      // look for higher value in all months and if it occurs once, use 7248 otherwise 3624 or 0
      if (b.gte(lumpsums.allowances_children.monthlyAllowanceTier1)) {
        exemptAmountForTaxes = new Decimal(lumpsums.allowances_children.exemptAmountForTaxesTier1);
      } else if (b.gte(lumpsums.allowances_children.monthlyAllowanceTier2) && exemptAmountForTaxes.lt(lumpsums.allowances_children.exemptAmountForTaxesTier1)) {
        exemptAmountForTaxes = new Decimal(lumpsums.allowances_children.exemptAmountForTaxesTier2);
      }
      return a.add(b);
    });
  }

  logger.debug('exemptAmountForTaxes', toString(exemptAmountForTaxes));
  logger.debug('freibetrag monate', allowancesPerMonth.map(toString));
  logger.debug('freibetrag', toString(exemptAmount));

  return {
    exemptAmount,
    exemptAmountForTaxes,
    childBenefits
  };
}

function allowancesChildren2017(fields, lumpsums) {
  let exemptAmountSum = new Decimal(0);
  let childBenefitsSum = new Decimal(0);
  let exemptAmountForTaxesSum = new Decimal(0);

  logger.debug('\n-------------------------\nALLOWANCES CHILDREN \n');

  iterateLfNr(getLfdNrGroupsByNrs(fields,
    [
      '0500701',
      '0500703',
      '0500704',
      '0500104',
      '0500601',
      '0500805',
      '0501903',
      '0501802',
      '0501905',
      '0502502',
      '0502617',
      '0502618',
      '0502713',
      '0503907',
      '0503911',
      '0503906',
      '0504106',
      '0504302',
      '0503904',
      '0500702',
      '0503903',
      '0501610',
      '0501513',
      '0501102',
      'txf-transfer-allowance'
    ]
  ), (lfdNrFields, lfdNr) => {
    const {
      exemptAmount,
      exemptAmountForTaxes,
      childBenefits
    } = allowancesPerChild(fields, lfdNrFields, lfdNr, lumpsums);

    exemptAmountSum = exemptAmountSum.add(exemptAmount).ceil();
    childBenefitsSum = childBenefitsSum.add(childBenefits);
    exemptAmountForTaxesSum = exemptAmountForTaxesSum.add(exemptAmountForTaxes);
  });

  logger.debug('\n=', toString(exemptAmountSum));
  logger.debug('\n= exemptAmountForTaxesSum', toString(exemptAmountForTaxesSum));
  logger.debug('\n=', toString(childBenefitsSum));
  logger.debug('-------------------------\n');

  return {
    exemptAmountSum,
    exemptAmountForTaxesSum,
    childBenefitsSum
  };
}

module.exports = allowancesChildren2017;
