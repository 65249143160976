'use strict';

const Decimal = require('decimal.js');
const { getDecimal } = require('../utils/utils_fields');

function revenue2016(fields) {
  let revenue = new Decimal(0);

  // Bruttoarbeitslohn (Stkl. 1-5)
  revenue = revenue.add(getDecimal(fields, '0200201'));
  
  // Bruttoarbeitslohn (Stkl. 6 / Urlaubskasse)
  revenue = revenue.add(getDecimal(fields, '0200203'));

  revenue = revenue.sub(getDecimal(fields, '0201806'));

  return revenue;
}

module.exports = revenue2016;
