'use strict';

const { TaxYears } = require('@taxfix/de-itc-types');

const earningsFromEmployment2021 = require('../2021/earnings_from_employment_2021');
const earningsFromEmployment2020 = require('../2020/earnings_from_employment_2020');
const earningsFromEmployment2016 = require('../2016/earnings_from_employment_2016');

function earningsFromEmployment(fields, taxYear) {
  switch (taxYear) {
    case TaxYears['2k21']:
      return earningsFromEmployment2021(fields, taxYear);
    case TaxYears['2k20']:
      return earningsFromEmployment2020(fields, taxYear);
    default:
      return earningsFromEmployment2016(fields, taxYear);
  }
}

module.exports = earningsFromEmployment;