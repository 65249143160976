'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const pensionInsurance2016 = require('./pension_insurance_2016');
const healthInsurance2016 = require('./health_insurance_2016');
const { percent: getPercent } = require('../utils/utils_decimal');
const { getDecimal } = require('../utils/utils_fields');
const { toString } = require('../utils/utils_logs');

function provisionCost2016(fields, incomeWithoutProfessionalExpensesPerPerson, lumpsums) {
  let expenses = new Decimal(0);

  logger.debug('\n-------------------------\PROVISION COSTS\n');

  // pension insurance
  let {
    paidByEmployee,
    refunds,
    paidByEmployer,
    sumBeforeMax,
    max,
    sumAfterMax,
    proportionalMaxValue,
    zertifizierteBasisrente,
    pensionInsurance,
    subContributionPensionInsurance,
    percentage,
  } = pensionInsurance2016(fields, incomeWithoutProfessionalExpensesPerPerson, { case104a: true }, lumpsums);

  expenses = expenses.add(pensionInsurance);
  logger.debug('pensionInsurance', toString(pensionInsurance));

  // health insurance
  let {
    healthInsurancePaidEmployeeA,
    healthInsurancePaidEmployeeB,
    healthInsurancePaidEmployee,
    
    healthInsuranceNoSickpayA,
    healthInsuranceNoSickpayB,
    healthInsuranceNoSickpay,
    healthInsuranceWithSickpayA,
    healthInsuranceWithSickpayB,
    healthInsuranceWithSickpay,
    
    healthInsuranceRefunds,
    healthInsuranceReimbursements,
    healthInsuranceTaxfreeEmployerBenefits,
    healthInsuranceTaxfreeEmployerBenefitsA,
    healthInsuranceTaxfreeEmployerBenefitsB,

    additionalHealthInsuranceA,
    additionalHealthInsuranceB,
    foreignKVA,
    foreignKVB,
    foreignNoClaimSickPayA,
    foreignNoClaimSickPayB,

    healthInsuranceCutSum,
    healthInsuranceCut4Percent,
    healthInsuranceAfterCut,

    socialCareInsurance,
    socialCareInsuranceA,
    socialCareInsuranceB,
    socialCareInsuranceTaxfreeEmployerBenefitsA,
    socialCareInsuranceTaxfreeEmployerBenefitsB,
    socialCareInsuranceTaxfreeEmployerBenefits,

    taxfreeEmployerBenefitsA,
    taxfreeEmployerBenefitsB,
    taxfreeEmployerBenefits,
    steuerfreieAGZuschuesse,

    healthInsuranceSumFeesP10Abs1Nr3,
    healthInsuranceSumP10Abs1Nr3,

    additionalHealthInsurance,
    unemploymentInsurance,
    incapacityToWorkInsurance,
    accidentEtcInsurance,
    childKV,

    healthInsuranceSum,
    healthInsuranceSumForAnzuHoch,
    healthInsuranceSumAfterMax,
    healthInsuranceMax,
    additionalInsurances,
    erstattungsuberhang10Abs4b,
    subContributionHealthInsurance,
    subContributionOtherInsurance
  } = healthInsurance2016(fields);

  // check which is higher
  let healthInsurance = additionalInsurances;
  if (healthInsurance.lt(healthInsuranceSumP10Abs1Nr3)) {
    healthInsurance = healthInsuranceSumP10Abs1Nr3;
  }

  expenses = expenses.add(healthInsurance);
  logger.debug('healthInsurance', toString(healthInsurance));
  logger.debug('healthInsuranceSumP10Abs1Nr3', toString(healthInsuranceSumP10Abs1Nr3));
  logger.debug('healthInsurance erstattungsuberhang10Abs4b', toString(erstattungsuberhang10Abs4b));
  logger.debug('healthInsurance healthInsuranceSumForAnzuHoch',toString(healthInsuranceSumForAnzuHoch));

  const provisionCostP10Abs3And4 = new Decimal(expenses);

  // Günstigerprüfung

  // Höchstbetragsberechnung nach § 10 Abs. 4a S. 1 EStG
  let anzusetzendeVorsorgeaufwendungen = new Decimal(healthInsuranceSumForAnzuHoch);
  logger.debug('\t     anzusetzendeVorsorgeaufwendungen', toString(anzusetzendeVorsorgeaufwendungen));
  const xxx = getDecimal(fields, '2000401');
  logger.debug('\t  xxx   anzusetzendeVorsorgeaufwendungen', toString(xxx));
  anzusetzendeVorsorgeaufwendungen = anzusetzendeVorsorgeaufwendungen.add(xxx);
  logger.debug('\t     anzusetzendeVorsorgeaufwendungen adter xxx',toString(anzusetzendeVorsorgeaufwendungen));

  const kuerzungNach10Abs3Nr2 = getPercent(incomeWithoutProfessionalExpensesPerPerson[0], 16);
  logger.debug('kuerzungNach10Abs3Nr2', toString(kuerzungNach10Abs3Nr2));
  const vorwegabzug = new Decimal(lumpsums.special_expenses.vorwegabzug);
  
  let vorwegabzugNachKuerzung = vorwegabzug.sub(kuerzungNach10Abs3Nr2);
  vorwegabzugNachKuerzung = Decimal.max(vorwegabzugNachKuerzung, 0);
  logger.debug('vorwegabzugNachKuerzung', toString(vorwegabzugNachKuerzung));

  const verbleiben = anzusetzendeVorsorgeaufwendungen.sub(vorwegabzugNachKuerzung);
  logger.debug('\t    verbleiben', toString(verbleiben));

  let anzusetzenderHoechstbetrag = new Decimal(vorwegabzugNachKuerzung);
  logger.debug('\t 0) Anzusetzender Höchstbetrag', toString(anzusetzenderHoechstbetrag));

  const HOECHSTBETRAG_VOLL = new Decimal(1334);
  const HOECHSTBETRAG_50 = new Decimal(667);

  let uebersteigenderBetrag = new Decimal(0);

  if (verbleiben.gt(HOECHSTBETRAG_VOLL)) {
    anzusetzenderHoechstbetrag = anzusetzenderHoechstbetrag.add(HOECHSTBETRAG_VOLL);
    uebersteigenderBetrag = verbleiben.sub(HOECHSTBETRAG_VOLL);
  } else {
    anzusetzenderHoechstbetrag = anzusetzenderHoechstbetrag.add(verbleiben);
  }

  logger.debug('\t  uebersteigenderBetrag', toString(uebersteigenderBetrag));

  logger.debug('\t 1) Anzusetzender Höchstbetrag', toString(anzusetzenderHoechstbetrag));

  if (uebersteigenderBetrag.gt(0)) {
    if (uebersteigenderBetrag.gt(HOECHSTBETRAG_VOLL)) {
      logger.debug('\t + ', toString(HOECHSTBETRAG_50));
      anzusetzenderHoechstbetrag = anzusetzenderHoechstbetrag.add(HOECHSTBETRAG_50);
    } else {
      logger.debug('\t + ', toString(uebersteigenderBetrag));
      const haelfteDesUebersteigendenBetrags = getPercent(uebersteigenderBetrag, 50);
      anzusetzenderHoechstbetrag = anzusetzenderHoechstbetrag.add(haelfteDesUebersteigendenBetrags);
    }
  }
  logger.debug('\t 2) Anzusetzender Höchstbetrag', toString(anzusetzenderHoechstbetrag));
  logger.debug('\t -----------------------------');
  logger.debug('Anzusetzender Höchstbetrag', toString(anzusetzenderHoechstbetrag));
  logger.debug('zertifizierteBasisrente', toString(zertifizierteBasisrente));
  logger.debug('max', toString(max));
  if (zertifizierteBasisrente.gt(max)) {
    anzusetzenderHoechstbetrag = anzusetzenderHoechstbetrag.add(max);
  } else {
    anzusetzenderHoechstbetrag = anzusetzenderHoechstbetrag.add(zertifizierteBasisrente);
  }
  logger.debug('Anzusetzender Höchstbetrag', toString(anzusetzenderHoechstbetrag));
  

  if (anzusetzenderHoechstbetrag.gt(expenses)) {
    expenses = anzusetzenderHoechstbetrag;
  }

  // End Günstigerprüfung

  return {
    pensionInsurancePaidByEmployee: paidByEmployee,
    pensionInsuranceRefunds: refunds,
    pensionInsurancePaidByEmployer: paidByEmployer,
    pensionInsuranceBeforeMax: sumBeforeMax,
    pensionInsuranceMax: max,
    pensionInsuranceAfterMax: sumAfterMax,
    pensionInsuranceProportionalMaxValue: proportionalMaxValue,
    pensionInsurance,
    pensionInsurancePercentage: percentage,

    healthInsurancePaidEmployeeA,
    healthInsurancePaidEmployeeB,
    healthInsurancePaidEmployee,
    healthInsuranceNoSickpayA,
    healthInsuranceNoSickpayB,
    healthInsuranceNoSickpay,
    healthInsuranceWithSickpayA,
    healthInsuranceWithSickpayB,
    healthInsuranceWithSickpay,
    healthInsuranceRefunds,
    healthInsuranceReimbursements,
    healthInsuranceTaxfreeEmployerBenefits,
    healthInsuranceTaxfreeEmployerBenefitsA,
    healthInsuranceTaxfreeEmployerBenefitsB,
    healthInsuranceCutSum,
    healthInsuranceCut4Percent,
    healthInsuranceAfterCut,
    healthInsuranceSumForAnzuHoch,
    additionalInsurances,
    socialCareInsurance,
    socialCareInsuranceA,
    socialCareInsuranceB,
    socialCareInsuranceTaxfreeEmployerBenefitsA,
    socialCareInsuranceTaxfreeEmployerBenefitsB,
    socialCareInsuranceTaxfreeEmployerBenefits,
    healthInsuranceSumFeesP10Abs1Nr3,
    healthInsuranceSumP10Abs1Nr3,
    taxfreeEmployerBenefitsA,
    taxfreeEmployerBenefitsB,
    taxfreeEmployerBenefits,
    additionalHealthInsurance,
    unemploymentInsurance,
    incapacityToWorkInsurance,
    accidentEtcInsurance,
    healthInsuranceMax,
    healthInsuranceSum,
    healthInsuranceSumAfterMax,
    healthInsurance,
    erstattungsuberhang10Abs4b,
    steuerfreieAGZuschuesse,

    provisionCostP10Abs3And4,
    provisionCost: expenses,

    subContributionPensionInsurance,
    subContributionHealthInsurance,

    additionalHealthInsuranceA,
    additionalHealthInsuranceB,

    foreignKVA,
    foreignKVB,

    foreignNoClaimSickPayA,
    foreignNoClaimSickPayB,
    childKV,
    
    subContributionOtherInsurance
  };
}

module.exports = provisionCost2016;
