'use strict';

const {
  getStringifiedValue,
  updateField,
  parseDateString
} = require('../utils');

const OTHER_CONFESSIONS = ['04', '05', '20', '21', '07', '16', '15', '17', '13', '14', 
    '25', '12', '28', '18', '24', '19', '27', '26', '29'];
const OTHER_CONFESSION_KEY = '10';

const persons = [
  {
    originalConfessionField: 'txf-church-confession-change-from',
    newConfessionField: 'txf-church-confession-change-to',
    confessionChangeDateField: 'txf-church-confession-change-date',
    confessionFields: ['0100410', '0100411', '0100412', '0100413', '0100414',
      '0100415', '0100416', '0100417', '0100418', '0100419', '0100420', '0100421'],
    otherConfessionFields: ['0100430', '0100431', '0100432', '0100433', '0100434',
      '0100435', '0100436', '0100437', '0100438', '0100439', '0100440', '0100441'],
    lfdNr: 1,
  },
  {
    originalConfessionField: 'txf-church-confession-change-from-B',
    newConfessionField: 'txf-church-confession-change-to-B',
    confessionChangeDateField: 'txf-church-confession-change-date-B',
    confessionFields: ['0100410', '0100411', '0100412', '0100413', '0100414',
      '0100415', '0100416', '0100417', '0100418', '0100419', '0100420', '0100421'],
    otherConfessionFields: ['0100430', '0100431', '0100432', '0100433', '0100434',
      '0100435', '0100436', '0100437', '0100438', '0100439', '0100440', '0100441'],
    lfdNr: 2,
  },
];

/**
 * Input: Custom Taxfix fields txf-church-confession-change-**from/to/date**
 * Output: Elster IDs enumerated above in [confessionFields] and [otherConfessionFields]
 * Logic: If there was a change in confession, then we will write the confession for each month
 * into its own elster id (Jan-Dec) and if the confession was special(code 10), we will write 10 
 * to the confession field and the actual confession code into a new ID (0100430, 0100431...)
 */
module.exports = fields => {
  persons.forEach(({
    originalConfessionField,
    newConfessionField,
    confessionChangeDateField,
    confessionFields,
    otherConfessionFields,
    lfdNr,
  }) => {
    // if there was a confession change during the year
    // we will gather the information from taxfix custom fields
    const originalConfession = getStringifiedValue(fields, originalConfessionField);
    const newConfession = getStringifiedValue(fields, newConfessionField);
    const confessionChangeDate = getStringifiedValue(fields, confessionChangeDateField);

    //for compliance and confidence we will now translate the above time/confession data
    //to a confession/month basis
    if (originalConfession && typeof originalConfession === 'string' &&
      newConfession && typeof newConfession === 'string' &&
      confessionChangeDate && typeof confessionChangeDate === 'string') {
      try {
        const changeMonthIndex = parseDateString(confessionChangeDate).getMonth();
        //if the change month is December (index=11) then do nothing
        if (changeMonthIndex === 11) {
          return;
        }
        //process each month at a time
        for(let i = 0; i <= 11; i ++) {
          let currentMonthConfession = originalConfession;
          if (i > changeMonthIndex) {
            currentMonthConfession = newConfession;
          }
          // if the confession for the currently processed month is Sonstige (10)
          // we have to update the special confession monthly field (0100430, 0100431...) with the
          // corresponding confession id (normally a value found in OTHER_CONFESSIONS)
          // otherwise we add just the current mainstream confession in one of the monthly
          // confession fields e.g. (0100410, 0110411...)
          if (OTHER_CONFESSIONS.includes(currentMonthConfession)) {
            updateField(fields, confessionFields[i], lfdNr, 1, OTHER_CONFESSION_KEY);
            updateField(fields, otherConfessionFields[i], lfdNr, 1, currentMonthConfession);
          } else {
            updateField(fields, confessionFields[i], lfdNr, 1, currentMonthConfession);
          }
        }
      } catch (error) {
        console.error('Error building the fields ::', error);
        throw error;
      }
    }
  });
  return fields;
};
