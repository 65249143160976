'use strict';

const churchTax2016 = require('../2016/church_tax_2016');
const calculateITC = require('./calculateIncomeTax');

function calculateChurchTax(
  fields,
  churchInfo,
  taxableIncome,
  allowancesForChildrenExemptAmountForTaxes,
  capitalGainsTax,
  totalIncomePerPerson,
  guenstigerpruefungKind,
  taxDeductions,
  riesterAllowances,
  incomeReducedTaxRate,
  incomeSubjectToProgression,
  taxYear
) {
  const calculateIncomeTax = (income) => calculateITC(income, taxYear);

  return churchTax2016(
    fields,
    churchInfo,
    taxableIncome,
    allowancesForChildrenExemptAmountForTaxes,
    capitalGainsTax,
    totalIncomePerPerson,
    guenstigerpruefungKind,
    taxDeductions,
    riesterAllowances,
    incomeReducedTaxRate,
    incomeSubjectToProgression,
    calculateIncomeTax,
  );
}

module.exports = calculateChurchTax;