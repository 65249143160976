'use strict';

const Decimal = require('decimal.js');
const { getDecimal } = require('../utils/utils_fields');

//
// Spenden / Mitgliedsbeiträge
// Politische Parteien + unabhängige Wählervereinigungen
//
function donationsPolitical2019(fields) {
  let donations = new Decimal(0);

  // Politische Parteien - Summe lt. Bestätigung
  donations = donations.add(getDecimal(fields, '0108701'));

  // Politische Parteien - Summe lt. Nachweis Betriebs-FA
  donations = donations.add(getDecimal(fields, '0108702'));

  // Politische Partein (elektron. Übermittlung) Person A
  donations = donations.add(getDecimal(fields, 'txf-electric-donation'));

  // Politische Partein (elektron. Übermittlung) Person B
  donations = donations.add(getDecimal(fields, 'txf-electric-donation-B'));

  return donations;
}

module.exports = donationsPolitical2019;
