'use strict';

const Decimal = require('decimal.js');
const { refundReasons } = require('@taxfix/de-itc-types');

const isPartner = (index) => index === 1;

const getReasonsForPerson = (calculations) => (personIndex = 0) => {
  const reasons = [];

  const personEarning = calculations.personsEarnings && calculations.personsEarnings[personIndex] ?
    calculations.personsEarnings[personIndex] :
    {};
  const professionalExpenses = personEarning.professionalExpenses ?
    new Decimal(personEarning.professionalExpenses) :
    undefined;
  const commutingPerPerson = personEarning.commutingPerPerson ?
    new Decimal(personEarning.commutingPerPerson)
    : undefined;
  const doubleHouseholdPerPerson = personEarning.doubleHouseholdPerPerson ?
    new Decimal(personEarning.doubleHouseholdPerPerson) :
    undefined;
  const homeOfficePerPerson = personEarning.homeOfficePerPerson ?
    new Decimal(personEarning.homeOfficePerPerson) :
    undefined;
  const workEquipmentPerPerson = personEarning.workEquipmentPerPerson ?
    new Decimal(personEarning.workEquipmentPerPerson) :
    undefined;
  const otherWorkRelatedExpensesPerPerson = personEarning.otherWorkRelatedExpensesPerPerson ?
    new Decimal(personEarning.otherWorkRelatedExpensesPerPerson) :
    undefined;
  const educationPerPerson = personEarning.educationPerPerson ?
    new Decimal(personEarning.educationPerPerson) :
    undefined;
  const professionalAssociationsPerPerson = personEarning.professionalAssociationsPerPerson ?
    new Decimal(personEarning.professionalAssociationsPerPerson) :
    undefined;
  const travellingPerPerson = personEarning.travellingPerPerson ?
    new Decimal(personEarning.travellingPerPerson) :
    undefined;
  const allowancesSingleParent = calculations.allowancesSingleParent ?
    new Decimal(calculations.allowancesSingleParent) :
    undefined;
  const exceptionalExpenses = calculations.exceptionalExpenses ?
    new Decimal(calculations.exceptionalExpenses) :
    undefined;
  const allowancesForChildrenExemptAmount = calculations.allowancesForChildrenExemptAmount ?
    new Decimal(calculations.allowancesForChildrenExemptAmount) :
    undefined;
  const deductions34g = calculations.deductions34g ?
    new Decimal(calculations.deductions34g) :
    undefined;
  const deductions35a = calculations.deductions35a ?
    new Decimal(calculations.deductions35a) : undefined;
  const deductions35c = calculations.deductions35c ?
    new Decimal(calculations.deductions35c) :
    undefined;
  const riesterAllowances = calculations.riesterAllowances ?
    new Decimal(calculations.riesterAllowances) :
    undefined;

  // Work-related expenses
  if (professionalExpenses && professionalExpenses.lte(new Decimal(1000))) {
    reasons.push({
      personIndex,
      reason: refundReasons.workRelatedExpenses,
      amount: professionalExpenses.valueOf(),
    });
  } else {
    // commuting
    if (commutingPerPerson && commutingPerPerson.gt(new Decimal(0))) {
      reasons.push({
        personIndex,
        reason: refundReasons.highCommutingExpenses,
        amount: commutingPerPerson.valueOf(),
      });
    }

    // double household
    if (doubleHouseholdPerPerson && doubleHouseholdPerPerson.gt(new Decimal(0))) {
      reasons.push({
        personIndex,
        reason: refundReasons.doubleHousehold,
        amount: doubleHouseholdPerPerson.valueOf(),
      });
    }

    // home office
    if (homeOfficePerPerson && homeOfficePerPerson.gt(new Decimal(0))) {
      reasons.push({
        personIndex,
        reason: refundReasons.homeOffice,
        amount: homeOfficePerPerson.valueOf(),
      });
    }

    // work equipment
    if (workEquipmentPerPerson && workEquipmentPerPerson.gt(new Decimal(0))) {
      if (workEquipmentPerPerson.lte(new Decimal(110))) {
        reasons.push({
          personIndex,
          reason: refundReasons.workEquipmentLumpsum,
          amount: workEquipmentPerPerson.valueOf(),
        });
      } else {
        reasons.push({
          personIndex,
          reason: refundReasons.workEquipment,
          amount: workEquipmentPerPerson.valueOf(),
        });
      }
    }

    // other work related expenses
    if (otherWorkRelatedExpensesPerPerson.gt(new Decimal(0))) {
      if (otherWorkRelatedExpensesPerPerson.lte(new Decimal(16))) {
        reasons.push({
          personIndex,
          reason: refundReasons.otherWorkRelatedExpensesLumpsum,
          amount: otherWorkRelatedExpensesPerPerson.valueOf(),
        });
      } else {
        reasons.push({
          personIndex,
          reason: refundReasons.otherWorkRelatedExpenses,
          amount: otherWorkRelatedExpensesPerPerson.valueOf(),
        });
      }
    }

    // education
    if (educationPerPerson && educationPerPerson.gt(new Decimal(0))) {
      reasons.push({
        personIndex,
        reason: refundReasons.educationCost,
        amount: educationPerPerson.valueOf(),
      });
    }

    // professional associations
    if (professionalAssociationsPerPerson && professionalAssociationsPerPerson.gt(new Decimal(0))) {
      reasons.push({
        personIndex,
        reason: refundReasons.professionalAssociations,
        amount: professionalAssociationsPerPerson.valueOf(),
      });
    }

    // business travels
    if (travellingPerPerson && travellingPerPerson.gt(new Decimal(0))) {
      reasons.push({
        personIndex,
        reason: refundReasons.businessTravel,
        amount: travellingPerPerson.valueOf(),
      });
    }
  }

  // The following calculations only exist for the main person
  if (!isPartner(personIndex)) {
    // single parent allowance
    if (allowancesSingleParent.gt(new Decimal(0))) {
      reasons.push({
        personIndex,
        reason: refundReasons.singleParentAllowance,
        amount: allowancesSingleParent.valueOf(),
      });
    }

    // exceptional expenses
    if (exceptionalExpenses.gt(new Decimal(0))) {
      reasons.push({
        personIndex,
        reason: refundReasons.exceptionalExpenses,
        amount: exceptionalExpenses.valueOf(),
      });
    }

    // child benefits
    if (allowancesForChildrenExemptAmount.gt(new Decimal(0))) {
      reasons.push({
        personIndex,
        reason: refundReasons.childAllowance,
        amount: allowancesForChildrenExemptAmount.valueOf(),
      });
    }

    // political donations
    if (deductions34g.gt(new Decimal(0))) {
      reasons.push({
        personIndex,
        reason: refundReasons.politicalDonations,
        amount: deductions34g.valueOf(),
      });
    }

    // household services
    if (deductions35a.gt(new Decimal(0))) {
      reasons.push({
        personIndex,
        reason: refundReasons.householdServices,
        amount: deductions35a.valueOf(),
      });
    }

    //
    if (deductions35c && deductions35c.gt(new Decimal(0))) {
      reasons.push({
        personIndex,
        reason: refundReasons.householdServices,
        amount: deductions35c.valueOf(),
      });
    }

    // riester allowances (pension)
    if (riesterAllowances.gt(new Decimal(0))) {
      reasons.push({
        personIndex,
        reason: refundReasons.riesterPension,
        amount: riesterAllowances.valueOf(),
      });
    }
  }

  return reasons;
};

module.exports = { getReasonsForPerson };
