'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _warn = require('./warn');

var _warn2 = _interopRequireDefault(_warn);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var isFn = function isFn(prop) {
  return typeof prop === 'function';
};

/**
 * renderProps
 * is a render/children props interop.
 * will pick up the prop that was used,
 * or children if both are used
 */

var renderProps = function renderProps(_ref, props) {
  var children = _ref.children,
      render = _ref.render;

  if (process.env.NODE_ENV !== 'production') {
    (0, _warn2.default)(isFn(children) && isFn(render), 'You are using the children and render props together.\n' + 'This is impossible, therefore, only the children will be used.');
  }

  var fn = isFn(children) ? children : render;

  return fn ? fn(props) : null;
};

exports.default = renderProps;