'use strict';

const { extendMoment } = require('moment-range');
const Moment = require('moment');
const moment = extendMoment(Moment);

/**
 * Unit of time string values.
 * @enum {string}
 */
const UnitOfTime = {
  YEAR: 'year',
  YEARS: 'years',
  MONTHS: 'months',
};

const DAY_FORMAT = 'DD';
const MONTH_FORMAT = 'MM';
const YEAR_FORMAT = 'YYYY';
const DAY_MONTH_FORMAT = `${DAY_FORMAT}.${MONTH_FORMAT}`;
const DATE_FORMAT = `${DAY_MONTH_FORMAT}.${YEAR_FORMAT}`;

// DD.MM.-DD.MM.
const REGEX_DDMM_DDMM = /(\d{1,2})\.(\d{1,2})\.?-(\d{1,2})\.(\d{1,2})\.?/;

// DD.MM.YYYY-DD.MM.YYYY
const REGEX_DDMMYYYY_DDMMYYYY = /((\d{1,2})\.(\d{1,2})\.(\d{4}))-((\d{1,2})\.(\d{1,2})\.(\d{4}))/;

function extractOnlyCurrentYearFromRange(year, range) {
  const x = REGEX_DDMMYYYY_DDMMYYYY.exec(range);

  if (x !== null) {
    const range = moment.range(
        moment(x[1], DATE_FORMAT),
        moment(x[5], DATE_FORMAT)
    );

    const rangeYear = moment.range(
        moment(`${year}`, YEAR_FORMAT).startOf('year'),
        moment(`${year}`, YEAR_FORMAT).endOf('year')
    );

    const intersect = range.intersect(rangeYear);
    if (intersect == null) {
      return null;
    } else {
      return `${intersect.start.format(DAY_MONTH_FORMAT)}-${intersect.end.format(DAY_MONTH_FORMAT)}`;
    }

  } else {
    return range;
  }
}

function updateMonthsIfMatchDDMM(arr, value, year, updateTo = 1) {
  value = extractOnlyCurrentYearFromRange(year, value);
  let x = REGEX_DDMM_DDMM.exec(value);

  let from;
  let to;

  if (x !== null) {
    from = parseInt(x[2], 10) - 1;
    to = parseInt(x[4], 10) - 1;

    for (let i = from; i <= to; i++) {
      arr[i] = updateTo;
    }
  }
}

function addMonthsIfMatchDDMM(arr, value, year, addTo = 1) {
  value = extractOnlyCurrentYearFromRange(year, value);
  let x = REGEX_DDMM_DDMM.exec(value);

  let from;
  let to;
  
  if (x !== null) {
    from = parseInt(x[2], 10) - 1;
    to = parseInt(x[4], 10) - 1;

    for (let i = from; i <= to; i++) {
      arr[i] += addTo;
    }
  }
}

function checkForMonth(arr, monthIndex) {
  arr.find(field => {
    let x = REGEX_DDMM_DDMM.exec(field.value);
    let from;
    let to;
    
    if (x !== null) {
      from = parseInt(x[2], 10) - 1;
      to = parseInt(x[4], 10) - 1;

      return from >= monthIndex && monthIndex <= to;
    }    
  });
}

// for a months binary array, compares it to a date and corrects the array if the date is 
// within the time interval determined by the array
function correctMonthsArrayAgainstDate(months, date, year, correctFollowingMonths = false) {
  let dateMonth = moment(date, DATE_FORMAT).format('M');
  let dateYear = moment(date, DATE_FORMAT).format('Y');

  if (year.toString() !== dateYear.toString()) {
    return months;
  }
  for (let i = 0; i < dateMonth - 1; i++ ) {
    months[i] = 0;
  }
  if (correctFollowingMonths) {
    for (let i = dateMonth - 1; i <= 11; i++ ) {
      months[i] = 1;
    }
  }
  return months;
}

function correctMonthsArrayAgainstDeathDate(months, date, year) {
  let dateYear = moment(date, DATE_FORMAT).format('Y');

  if (year > dateYear) {
    return months.map(() => 1);
  }

  return months;
}

function correctMonthsArrayAgainstBirtdate(months, birthdateStr, givenYear, lowerAgeLimit = 18, upperAgeLimit = 25) {
  const [, month, year] = birthdateStr.split('.').map(entry => Number(entry));

  return months.map((isMonthActive, index) => {
    if (!isMonthActive) return 0;

    const oldInYears = givenYear - year;
     // if older than lowerAgeLimit and younger than upperAgeLimit, nothing todo
    if (oldInYears > lowerAgeLimit && oldInYears < upperAgeLimit) return 1;

    if (oldInYears <= lowerAgeLimit) {
      return index + 1 < month ? 0 : 1;
    }

    if (oldInYears >= upperAgeLimit) {
      return index + 1 > month ? 0 : 1;
    }
  });
}

/**
 * @param dateStr
 * @returns {*}
 */
function getDateYear(dateStr) {
  const dateYear = moment(dateStr, DATE_FORMAT).format(YEAR_FORMAT);

  return parseInt(dateYear, 10);
}

module.exports = {
  DATE_FORMAT,
  REGEX_DDMM_DDMM,
  REGEX_DDMMYYYY_DDMMYYYY,
  UnitOfTime,
  getDateYear,
  checkForMonth,
  addMonthsIfMatchDDMM,
  updateMonthsIfMatchDDMM,
  extractOnlyCurrentYearFromRange,
  correctMonthsArrayAgainstDate,
  correctMonthsArrayAgainstBirtdate,
  correctMonthsArrayAgainstDeathDate,
};
