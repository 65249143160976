'use strict';

const Decimal = require('decimal.js');
const {
  getBool,
  getDecimal,
  getLfdNrGroupsByNrs,
  getValue,
  iterateLfNr
} = require('../utils/utils_fields');
const { percent: getPercent } = require('../utils/utils_decimal');

const MAX_AMOUNT_PER_CHILD = 5000;

function schoolFees2016(fields) {
  let expenses = new Decimal(0);
  let joint = getBool(fields, '0101201', 'X');

  iterateLfNr(getLfdNrGroupsByNrs(fields, [
    '0505607', '0504505', '0504603'
  ]), lfdNrGroups => {
    let expensesPerChild = new Decimal(0);
    // Schulgeld - Privatschule
    let privateSchool;

    let max = new Decimal(MAX_AMOUNT_PER_CHILD);

    // zusammen veranlagt
    if (joint) {
      // Gesamtsumme
      privateSchool = getDecimal(lfdNrGroups, '0505607');

    // nicht zusammen veranlagt
    } else {
      // von mir übernommenes Schulgeld 
      privateSchool = getDecimal(lfdNrGroups, '0504505');

      // Antrag Aufteilung Aufwendungen nicht hälftig - Anteil Stpfl.
      let percent = getValue(lfdNrGroups, '0504603');
      
      if (typeof percent === 'undefined') {
        percent = new Decimal(50);
      } else {
        percent = new Decimal(percent);
      }

      max = getPercent(max, percent);
    }

    expensesPerChild = expensesPerChild.add(privateSchool);
    // only 30% deductable
    expensesPerChild = getPercent(expensesPerChild, 30);
    // max
    expensesPerChild = Decimal.min(expensesPerChild, max);
    expenses = expenses.add(expensesPerChild);
  });

  return expenses;
}

module.exports = schoolFees2016;
