// @flow
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import differenceInCalendarMonths from 'date-fns/difference_in_calendar_months';
import format from 'date-fns/format';
import isBefore from 'date-fns/is_before';

import {
  parseValidDate,
} from './index';

import type {
  CalendarDate,
  Duration,
  DaysAndNights,
} from './index';

const durationFromUntil = (from: CalendarDate, until: CalendarDate): Duration => (
  {
    from,
    until,
  }
);

const calendarDateToDate = (possibleDate: CalendarDate): Date => {
  const date = parseValidDate(possibleDate);
  if (date == null) {
    throw new Error(`calendarDateToDate: date is not valid '${possibleDate}'`);
  }
  return date;
};

const dateToCalendarDate = (date: Date): CalendarDate => (
  format(date, 'YYYY-MM-DD')
);

const isSameCalendarDate = (a: CalendarDate, b: CalendarDate): boolean => (
  a === b
);

const daysAndNightsForDuration = ({ from, until }: Duration): DaysAndNights => {
  let fromDate = calendarDateToDate(from);
  let untilDate = calendarDateToDate(until);

  if (isBefore(untilDate, fromDate)) {
    [fromDate, untilDate] = [untilDate, fromDate];
  }
  const daysExcludingEnd = differenceInCalendarDays(
    untilDate,
    fromDate,
  );
  return {
    days: daysExcludingEnd + 1,
    nights: daysExcludingEnd,
  };
};

const numMonthsForDuration = ({ from, until }: Duration): number => (
  differenceInCalendarMonths(
    calendarDateToDate(until),
    calendarDateToDate(from),
  ) + 1
);

const durationAsDates = ({ from, until }: Duration): { from: Date, until: Date } => (
  {
    from: calendarDateToDate(from),
    until: calendarDateToDate(until),
  }
);

export {
  durationAsDates,
  durationFromUntil,
  daysAndNightsForDuration,
  isSameCalendarDate,
  numMonthsForDuration,
  calendarDateToDate,
  dateToCalendarDate,
};
