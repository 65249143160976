'use strict';

const {
  getBool,
  getLfdNrGroupsByNrs,
  iterateLfNr
} = require('../utils/utils_fields');
const constants = require('./../2016/constants-2016.json');
const Decimal = require('decimal.js');
const logger = require('../logger');
const { toString } = require('../utils/utils_logs');

const { calculateExpenses } = require('../utils/utils_calculate_expenses');
const revenue2016 = require('./../2016/revenue_2016');
const professionalExpenses2020 = require('./professional_expenses_2020');
const reducedTaxRate2016 = require('./../2016/reduced_tax_rate_2016');
const notPayrollTax2016 = require('./../2016/not_payroll_tax_2016');

function earningsFromEmploymentPerPerson(currentTaxYear, fields) {
  logger.debug('\n-------------------------\nEARNINGS FROM EMPLOYMENT \n');

  logger.debug('currentTaxYear', currentTaxYear);
  // 1.1.1 Revenue
  const revenue = revenue2016(fields);
  logger.debug('revenue', toString(revenue));

  // 1.1.3 Revenue subject to reduced tax rate
  // 1.1.4 Professional expenses - subject to reduced tax rate
  const reducedTaxRate = reducedTaxRate2016(fields);
  logger.debug('reducedTaxRate', toString(reducedTaxRate));

  // 1.1.5 Revenue not subject to payroll tax
  // 1.1.6 Professional expenses - related to revenues not subject to payroll tax
  const notPayrollTax = notPayrollTax2016(fields);
  logger.debug('notPayrollTax', toString(notPayrollTax));

  const incomePerPerson = reducedTaxRate.add(revenue).add(notPayrollTax);

  // 1.1.2 Professional Expenses
  const grossSalary = revenue.add(reducedTaxRate).add(notPayrollTax);
  const professionalExpenses = professionalExpenses2020(fields, incomePerPerson, grossSalary, currentTaxYear);
  professionalExpenses.expensesPerPerson = calculateExpenses(
    professionalExpenses.expensesPerPerson,
    revenue,
    constants.OVERALL_LUMP_SUM
  );
  const actualProfessionalExpenses = professionalExpenses.expensesPerPerson;
  const revenueAfterProfessionalExpenses = revenue.sub(actualProfessionalExpenses);

  return {
    revenue,
    grossSalary,
    notPayrollTax,
    reducedTaxRate,
    ...professionalExpenses,
    revenueAfterProfessionalExpenses,
    professionalExpenses: actualProfessionalExpenses
  };
}

function earningsFromEmployment(fields, currentTaxYear) {
  let perPerson = [];

  const groups = getLfdNrGroupsByNrs(fields,
    [
      // revenue
      '0200201', '0200203', '0201201',

      // professional expenses
      // commuting
      '0203503', '0203505', '0203506', '0203510', '0203611', '0203511',
      '0203513', '0203514', '0203515', '0203516', '0204103', '0203901',
      '0204004',

      // double household
      '0206704', '0206103', '0206304', '0206903', '0206904', '0206906',
      '0206907', '0207003', '0207116', '0207117', '0207211', '0207511',
      '0207302', '0207304', '0207305', '0207411', '0207611', '0207901',
      '0208011', '0208301', '0208107', '0208202', '0206303',

      // home office
      '0204504',

      // work equipment
      '0204403',

      // other work related expenses
      '0204803',

      // education
      '0204812',

      // berufsverbaende
      '0204002',

      // travelling
      // 2020: added "0206501"
      '0205518', '0205608', '0205201', '0205302', '0205409', '0205508',
      '0205630', '0205629', '0205108', '0206501',

      // reduced tax rate
      '0201002', '0206502', '0201806',

      // not subject to payroll tax
      '0201401', '0209502',

      //disability (needed for commuting)
      '0109708', '0109707'
    ]
  );

  const joint = getBool(fields, '0101201', 'X');
  const maxIterations = joint ? 2 : 1;
  let grossSalary = new Decimal(0);
  let workEquipment = new Decimal(0);

  iterateLfNr(groups, (lfdNrGroups, lfdNr) => {
    const person = earningsFromEmploymentPerPerson(currentTaxYear, lfdNrGroups, lfdNr);
    grossSalary = grossSalary.add(person.grossSalary);
    workEquipment = workEquipment.add(person.workEquipmentPerPerson);
    perPerson.push(person);
  }, maxIterations);

  let grossSalaryPersonA = perPerson[0].grossSalary;
  let grossSalaryPersonB = joint ? perPerson[1].grossSalary : new Decimal(0);
  let workEquipmentPersonA = perPerson[0].workEquipmentPerPerson;
  let workEquipmentPersonB = joint ? perPerson[1].workEquipmentPerPerson : new Decimal(0);

  return {
    personsEarnings: perPerson,
    grossSalary,
    grossSalaryPersonA,
    grossSalaryPersonB,
    workEquipment,
    workEquipmentPersonA,
    workEquipmentPersonB,
    homeOfficeLumpSumPersonA: undefined,
    homeOfficeLumpSumPersonB: undefined
  };
}

module.exports = earningsFromEmployment;
