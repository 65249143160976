'use strict';

const Decimal = require('decimal.js');
const { getFieldsByNr } = require('../utils');

const MARK_LOSS_CARRY_FORWARD_ID = '0100003';

function markLossCarryForward(fields, calculationResult = {}) {
  const taxableIncome = new Decimal(calculationResult.taxableIncome || 0);
  const existingFields = getFieldsByNr(fields, MARK_LOSS_CARRY_FORWARD_ID);

  if (existingFields.length === 0 && taxableIncome.isNegative()) {
    fields.push({
      nr: MARK_LOSS_CARRY_FORWARD_ID,
      lfdNr: 1,
      index: 1,
      value: 'X',
    });
  }

  return fields;
}

module.exports = {
  markLossCarryForward,
};
