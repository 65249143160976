// @flow

import type {
  Id,
  TreeNode,
} from './types';

const findSubtree = (root: TreeNode, needle: Id): ?TreeNode => {
  const visited = new Set();
  const queue = [root];

  while (queue.length > 0) {
    const node = queue.shift();
    if (node.id === needle) {
      return node;
    }
    if (node.children) {
      for (let i = 0; i < node.children.length; i += 1) {
        const child = node.children[i];
        if (!visited.has(child)) {
          visited.add(child);
          queue.push(child);
        }
      }
    }
  }

  return null;
};

export default findSubtree;
