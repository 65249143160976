'use strict';

const Decimal = require('decimal.js');
const moment = require('moment');
const logger = require('../logger');
const {
  getBool,
  getDecimal,
  getLfdNrGroupsByNrs,
  getValue,
  iterateLfNr
} = require('../utils/utils_fields');
const { percent: getPercent } = require('../utils/utils_decimal');
const { toString } = require('../utils/utils_logs');

const MAX_AMOUNT = 4000;

function childcarePerChild2016(fields, groupFields) {
  let expenses = new Decimal(0);

  let birthdayStr = getValue(groupFields, '0500701');
  let birthday = moment(birthdayStr, 'DD.MM.YYYY');
  let age = moment().diff(birthday, 'years');

  let disabled = getBool(groupFields, '0505806', 1);
  let blind = getBool(groupFields, '0505807', 1);
  let disabled2 = getBool(groupFields, '0505808', 1);

  let impaired = disabled || blind || disabled2;

  // not deductable if child >= 14 or child >= 25 and impaired
  if ((age >= 14 && !impaired) || (age >= 25 && impaired)) {
    return new Decimal(0);
  }

  // Kinderbetreuungkosten: Gemeinsamer Haushalt der Elternteile
  // Kind gehörte zu unserem Haushalt - von - bis
  let childInOurHouseholdFromTo = getValue(groupFields, '0504808');

  // Kinderbetreuungkosten: Kein gemeinsamer Haushalt der Elternteile
  // Kind gehörte zu Haushalt Stpfl. - von - bis
  let childInMyHouseholdFromTo = getValue(groupFields, '0505202');


  // if neither of those two question above has values, cost can't be deducted
  if (typeof childInOurHouseholdFromTo === 'undefined' &&
      typeof childInMyHouseholdFromTo === 'undefined') {
    return new Decimal(0);
  }

  // Steuerpflichtige Person B: Kind - Kindschaftsverhältnis bestand von - bis
  let isWifeAlsoMother = getValue(groupFields, '0500805');
  isWifeAlsoMother = typeof isWifeAlsoMother !== 'undefined';

  let joint = getBool(fields, '0101201', 'X');
  let separate = getBool(fields, '0102602', 'X');

  // wife is also mother and we are doing a joint or separate tax assessment
  if (isWifeAlsoMother && (joint || separate)) {
    if (joint) {
      // Kinderbetreuungkosten: Summe
      let childcare = getDecimal(groupFields, '0506105');
      // TODO zwölfteln
      // 0506103 von - bis
      // 0506104 teilbetrag

      // Kinderbetreuungkosten: Steuerfreier Ersatz - Gesamtsumme
      let reimbursed = getDecimal(groupFields, '0506504');
      // TODO do they need to be subtracted from childcare or expenses?
      expenses = expenses.sub(reimbursed);

      childcare = childcare.times(2).div(3);
      childcare = Decimal.min(childcare, MAX_AMOUNT);

      expenses = expenses.add(childcare);
    
    } else {
      // Keine Zusammenveranlagung: Kinderbetreuungkosten vom Stpfl. getragen - Gesamtsumme
      let childcareOwn = getDecimal(groupFields, '0506604');
      // TODO zwölfteln
      // 0506606 von - bis
      // 0506605 teilbetrag

      childcareOwn = childcareOwn.times(2).div(3);

      let max = new Decimal(MAX_AMOUNT);
      max = getPercent(max, 50);
      childcareOwn = Decimal.min(childcareOwn, max);

      expenses = expenses.add(childcareOwn);
    } 
  
  // parents are not married
  } else {
    // Keine Zusammenveranlagung: Kinderbetreuungkosten vom Stpfl. getragen - Gesamtsumme
    let childcareOwn = getDecimal(groupFields, '0506604');
    logger.debug('Kinderbetreuungkosten vom Stpfl. getragen', toString(childcareOwn));
    
    // Kinderbetreuungkosten: Steuerfreier Ersatz - Gesamtsumme
    let reimbursed = getDecimal(groupFields, '0506504');
    logger.debug('Erstattung AG', toString(reimbursed));
    childcareOwn = childcareOwn.sub(reimbursed);

    // TODO zwölfteln
    // 0506606 von - bis
    // 0506605 teilbetrag
    childcareOwn = childcareOwn.times(2).div(3);
    logger.debug('2/3', toString(childcareOwn));

    // Keine Zusammenveranlagung: Kinderbetreuungskosten - keine hälftige
    // Aufteilung - Anteil des Stpfl.
    let percent = getValue(groupFields, '0508601');
    logger.debug('keine hälftige Aufteilung - Anteil des Stpfl.', percent);
    
    if (typeof percent === 'undefined') {
      percent = new Decimal(50);
    } else {
      percent = new Decimal(percent);
    }

    let max = new Decimal(MAX_AMOUNT);
    max = getPercent(max, percent);
    logger.debug('max?', toString(max));
    
    childcareOwn = Decimal.min(childcareOwn, max);
    logger.debug(' minimum ', toString(childcareOwn));
    if (childcareOwn.lte(0)) {
      childcareOwn = new Decimal(0);
    }
    logger.debug(' => ', toString(childcareOwn));
    expenses = expenses.add(childcareOwn);
  }

  return expenses;
}

//
//
function childcareCost2016(fields) {
  let expenses = new Decimal(0);

  logger.debug('\n\t-----------------\n\tCHILDCARE\n');

  iterateLfNr(getLfdNrGroupsByNrs(fields,
    [
      '0500701', '0505806', '0505807', '0505808', '0504808', '0505202',
      '0500805', '0506105', '0506504', '0506604', '0508601', '0506103'
    ]
  ), (lfdNrGroups) => {
    expenses = expenses.add(childcarePerChild2016(fields, lfdNrGroups));
  });

  logger.debug('\n\t= ', toString(expenses));
  logger.debug('\t-----------------\n');
  return expenses;
}

module.exports = childcareCost2016;
