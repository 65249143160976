// @flow

import BaseAnswer from './Base';
import type { ValidationFunction, ValidationSchema } from '../flowtypes';

export class ESMainIncome extends BaseAnswer {
  constructor() {
    const formats = [
      { name: 'NAME', type: 'string' },
      { name: 'NIF', type: 'string' },
      { name: 'INCOME', type: 'number' },
      { name: 'TAX_RETENTION', type: 'number' },
    ];

    super('ES_main_income', formats);
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/ES_main_income.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/ES_main_income');
    /* eslint-enable global-require */
  }

  format(value: any, formatKey: string): ?(string | boolean) {
    super.format(value);

    const requestedFormat = this.getFormat(formatKey).name;

    switch (requestedFormat) {
      case this.formats.NAME:
        return this.valueOf(value, 'name');

      case this.formats.NIF:
        return this.valueOf(value, 'nif');

      case this.formats.INCOME:
        return this.valueOf(value, 'income');

      case this.formats.TAX_RETENTION:
        return this.valueOf(value, 'taxRetention');

      default:
        break;
    }

    return '';
  }
}

export default new ESMainIncome();
