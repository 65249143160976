// @flow

import type { Id, InputProperties } from './types';

export const unindexed = (id: Id): Id => {
  if (!id) {
    return id;
  }
  return id.replace(/(.+)\[(\d+:?)+\]$/, '$1');
};

export const referenceFor = (properties: InputProperties): Id => {
  const index = properties.idx instanceof Array ? `[${properties.idx.join(':')}]` : '';
  const id = index !== '' ? unindexed(properties.value) : properties.value;
  return `${id}${index}`;
};

export const indexFromId = (id: Id): ?number[] => {
  const match = /.+(\[(\d+:?)+\])$/.exec(id);
  if (match) {
    const idx = match[1];
    if (idx) {
      return JSON.parse(idx.replace(/:/g, ','));
    }
  }
  return undefined;
};

export const isIndexed = (id: Id): boolean =>
  indexFromId(id) != null;

// generates an ID as a child of a looped node (i.e. foo[0], or foo[1:2:3])
export const idForIndexChain = (id: Id, indexChain: ?number[]): string => {
  const indexChainString = indexChain instanceof Array && indexChain.length > 0 ? `[${indexChain.join(':')}]` : '';
  return `${id}${indexChainString}`;
};

// turns target "foo" to "foo[0]"
//              "foo[0]" to "foo[0:0]"
//              "foo[0:0]" to "foo[0:0:0]"
export const appendIndex = (target: Id, index: number): string => {
  const indexes = target.match(/^([^[]+)\[((:?\d+:?)+)\]$/);

  if (indexes == null) {
    return `${target}[${index}]`;
  }

  return `${indexes[1]}[${indexes[2]}:${index}]`;
};
