'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const {
  getBool,
  getDecimal,
  getBoolWithLfdNr,
  getLfdNrGroupsByNrs,
  iterateLfNr
} = require('../utils/utils_fields');
const { percent: getPercent } = require('../utils/utils_decimal');
const { toString } = require('../utils/utils_logs');

function healthInsurance2016(fields) {
  
  logger.debug('\n\t-----------------\n\tINSURANCES KV / PV / ...');

  //
  // 3.2.2.1 Costs for basic insurance

  let healthInsurancePaidEmployee = new Decimal(0);
  // Steuerpflichtige Person: Beitrag KV - lt. Lohnsteuerbescheinigung
  let healthInsurancePaidEmployeeA = getDecimal(fields, '2001203', 1, 1);
  healthInsurancePaidEmployee = healthInsurancePaidEmployee.add(healthInsurancePaidEmployeeA);
  
  // Steuerpflichtige Person B: Beitrag KV - lt. Lohnsteuerbescheinigung 
  let healthInsurancePaidEmployeeB = getDecimal(fields, '2001203', 1, 2);
  healthInsurancePaidEmployee = healthInsurancePaidEmployee.add(healthInsurancePaidEmployeeB);

  let ANBeitraegeGesetzlicheKV = new Decimal(healthInsurancePaidEmployee);

  // private health insurance
  let pKV = new Decimal(0);
  // Steuerpflichtige Person: pKV - Beitrag Basisabsicherung 
  let plus6a = getDecimal(fields, '2003104', 1, 1);
  pKV = pKV.add(plus6a);

  // Steuerpflichtige Person B: pKV - Beitrag Basisabsicherung 
  let plus6b = getDecimal(fields, '2003104', 1, 2);
  pKV = pKV.add(plus6b);

  logger.debug('pKV', toString(pKV));

  healthInsurancePaidEmployee = healthInsurancePaidEmployee.add(pKV);

  // ausländische KV
  let foreignKV = new Decimal(0);
  // Steuerpflichtige Person: ausländ. KV - Beitrag Basisabsicherung
  let foreignKVA = getDecimal(fields, '2002502', 1, 1);
  foreignKV = foreignKV.add(foreignKVA);
  // Steuerpflichtige Person B: ausländ. KV - Beitrag Basisabsicherung
  let foreignKVB = getDecimal(fields, '2002502', 1, 2);
  foreignKV = foreignKV.add(foreignKVB);

  logger.debug('Person B foreign KV ', toString(foreignKV));

  healthInsurancePaidEmployee = healthInsurancePaidEmployee.add(foreignKV);
  logger.debug('healthInsurancePaidEmployee 0', toString(healthInsurancePaidEmployee));

  let foreignKVNoSickpay = new Decimal(0);
  // Steuerpflichtige Person: ausländ. KV - Beitrag Basisabsicherung - kein Anspruch Krankengeld
  const foreignNoClaimSickPayA = getDecimal(fields, '2002602', 1, 1);
  foreignKVNoSickpay = foreignKVNoSickpay.add(foreignNoClaimSickPayA);
  // Steuerpflichtige Person B: ausländ. KV - Beitrag Basisabsicherung - kein Anspruch Krankengeld
  const foreignNoClaimSickPayB = getDecimal(fields, '2002602', 1, 2);
  foreignKVNoSickpay = foreignKVNoSickpay.add(foreignNoClaimSickPayB);


  let childReimbursements = new Decimal(0);
  let totalChildPV = new Decimal(0);
  let totalChildKV = new Decimal(0);
  
  // children
  iterateLfNr(getLfdNrGroupsByNrs(fields,
    [
      '0503110',
      '0503111',
      '0503209',
      '0503310',
      '0503311',
      '0503409',
      '0503410',
      '0503509',
      '0503610',
      '0503822',
      '0503823'
    ]
  ), lfdNrFields => {
    logger.debug('one two');
    let childKV = new Decimal(0);
    let childPV = new Decimal(0);
    // Kind: getragene Beiträge KV / PV - Basis-KV - Aufwand Stpfl (Versicherungsnehmer) 
    let childBasicHealthInsuranceMe = getDecimal(lfdNrFields, '0503110');
    childKV = childKV.add(childBasicHealthInsuranceMe);

    // Kind: getragene Beiträge KV / PV - Basis-KV - Aufwand Kind (Versicherungsnehmer)  
    let childBasicHealthInsuranceChild = getDecimal(lfdNrFields, '0503111');
    childKV = childKV.add(childBasicHealthInsuranceChild);
    
    // Kind: getragene Beiträge KV / PV - Basis-KV - Aufwand Kind (Versicherungsnehmer)
    // davon Anspruch auf Krankengeld 
    let childBasicHealthInsuranceMeSickpay = getDecimal(lfdNrFields, '0503209');
    // let cutChild1 = getPercent(childBasicHealthInsuranceMeSickpay, 4);
    // childrenCuts = childrenCuts.add(cutChild1);
    // basicInsuranceWithSickpay = basicInsuranceWithSickpay.add(childBasicHealthInsuranceMeSickpay);
    
    // childBasicHealthInsuranceMeSickpay = getPercent(childBasicHealthInsuranceMeSickpay, 96);
    childKV = childKV.add(childBasicHealthInsuranceMeSickpay);

    // Kind: getragene Beiträge auslandische KV / PV - Basis-KV/PV
    let childForeignKVEntitlementToSickPay = getDecimal(lfdNrFields, '0503822');
    childKV = childKV.add(childForeignKVEntitlementToSickPay);

    // Kind: getragene Beiträge auslandische KV / PV - Basis-KV/PV
    // davon Anspruch auf Krankengeld 
    let childForeignKVBasicContribution = getDecimal(lfdNrFields, '0503823');
    foreignKV = foreignKV.add(childForeignKVBasicContribution);
    
    // Kind: getragene Beiträge KV / PV - soziale PV / pPV - Aufwand Stpfl (Versicherungsnehmer) 
    let childCareInsuranceMe = getDecimal(lfdNrFields, '0503310');
    childPV = childPV.add(childCareInsuranceMe);
    
    // Kind: getragene Beiträge KV / PV - soziale PV / pPV - Aufwand Kind (Versicherungsnehmer) 
    let childCareInsuranceChild = getDecimal(lfdNrFields, '0503311');
    childPV = childPV.add(childCareInsuranceChild);
    
    // Kind: getragene Beiträge KV / PV - erstattete Beiträge - Aufwand Stpfl (Versicherungsnehmer)  
    let reimbursedFeesMe = getDecimal(lfdNrFields, '0503409');
    childReimbursements = childReimbursements.add(reimbursedFeesMe);
    
    // Kind: getragene Beiträge KV / PV - erstattete Beiträge - Aufwand Kind (Versicherungsnehmer) 
    let reimbursedFeesChild = getDecimal(lfdNrFields, '0503410');
    childReimbursements = childReimbursements.add(reimbursedFeesChild);
    
    // Kind: getragene Beiträge KV / PV - erstattete Beiträge - Aufwand Kind (Versicherungsnehmer)
    // davon Anspruch auf Krankengeld  
    let reimbursedFeesChildSickpay = getDecimal(lfdNrFields, '0503509');
    // let cutChild2 = getPercent(reimbursedFeesChildSickpay, 4);
    // childrenCuts = childrenCuts.add(cutChild2);
    // basicInsuranceWithSickpay = basicInsuranceWithSickpay.add(reimbursedFeesChildSickpay);

    // reimbursedFeesChildSickpay = getPercent(reimbursedFeesChildSickpay, 96);
    childReimbursements = childReimbursements.add(reimbursedFeesChildSickpay);
    
    // Kind: getragene Beiträge KV / PV - Aufwand Kind (Versicherungsnehmer)
    // Zuschuss von Dritter Seite  
    let benefitFrom3rd = getDecimal(lfdNrFields, '0503610');
    childReimbursements = childReimbursements.add(benefitFrom3rd);

    totalChildKV = totalChildKV.add(childKV);

    logger.debug('childKV', toString(childKV));
    logger.debug('childPV', toString(childPV));
    logger.debug('childReimbursements', toString(childReimbursements));
    logger.debug('healthInsurancePaidEmployee', toString(healthInsurancePaidEmployee));

    // costPerChild = Decimal.max(costPerChild, 0);
    // basicInsurance = basicInsurance.add(costPerChild);

    healthInsurancePaidEmployee = healthInsurancePaidEmployee.add(childKV);
    totalChildPV = totalChildPV.add(childPV);
  });

  logger.debug('healthInsurancePaidEmployee >', toString(healthInsurancePaidEmployee));


  let cut = new Decimal(ANBeitraegeGesetzlicheKV);

  // plus ausländische KV
  cut = cut.add(foreignKV);
  cut = cut.sub(foreignKVNoSickpay);

  let healthInsuranceNoSickpay = new Decimal(0);
  // Steuerpflichtige Person: Beitrag KV - lt. Lohnsteuerbescheinigung
  // davon kein Anspruch auf Krankengeld
  let healthInsuranceNoSickpayA = getDecimal(fields, '2001405', 1, 1);
  healthInsuranceNoSickpay = healthInsuranceNoSickpay.add(healthInsuranceNoSickpayA);
  // Steuerpflichtige Person B: Beitrag KV - lt. Lohnsteuerbescheinigung
  // davon kein Anspruch auf Krankengeld
  let healthInsuranceNoSickpayB = getDecimal(fields, '2001405', 1, 2);
  healthInsuranceNoSickpay = healthInsuranceNoSickpay.add(healthInsuranceNoSickpayB);

  logger.debug('healthInsuranceNoSickpay', toString(healthInsuranceNoSickpay));

  const healthInsuranceWithSickpayA = healthInsurancePaidEmployeeA.sub(healthInsuranceNoSickpayA);
  const healthInsuranceWithSickpayB = healthInsurancePaidEmployeeB.sub(healthInsuranceNoSickpayB);
  const healthInsuranceWithSickpay = healthInsuranceWithSickpayA.add(healthInsuranceWithSickpayB);

  logger.debug('healthInsuranceNoSickpay aftewards', toString(healthInsuranceNoSickpay));
  // CHECK cut = cut.add(healthInsuranceNoSickpay);

  let refunds = new Decimal(0);
  // debugger
  // Steuerpflichtige Person: von der KV / PV erstattete Beiträge
  let healthOrCareInsuranceRefundsA = getDecimal(fields, '2001605', 1, 1);
  refunds = refunds.add(healthOrCareInsuranceRefundsA);
  // Steuerpflichtige Person B: von der KV / PV erstattete Beiträge
  let healthOrCareInsuranceRefundsB = getDecimal(fields, '2001605', 1, 2);
  refunds = refunds.add(healthOrCareInsuranceRefundsB);
  // Add foreign health insurance refund
  const foreignHealthInsuranceRefund = getDecimal(fields, '2002801');
  refunds = refunds.add(foreignHealthInsuranceRefund);

  logger.debug('refunds', toString(refunds));
  cut = cut.sub(refunds);

  // add additional health insurance
  let additionalHealthInsurance = new Decimal(0);
  // // Steuerpflichtige Person: Beitrag KV - weitere Beiträge  
  let additionalHealthInsuranceA = getDecimal(fields, '2001805', 1, 1);
  // additionalHealthInsurance = additionalHealthInsurance.add(additionalHealthInsuranceA);
  // // Steuerpflichtige Person B: Beitrag KV - weitere Beiträge  
  let additionalHealthInsuranceB = getDecimal(fields, '2001805', 1, 2);
  // additionalHealthInsurance = additionalHealthInsurance.add(additionalHealthInsuranceB);

  let healthInsuranceTaxfreeEmployerBenefits = new Decimal(0);
  // Steuerpflichtige Person: KV - steuerfreie AG-Zuschüsse  
  let healthInsuranceTaxfreeEmployerBenefitsA = getDecimal(fields, '2003705', 1, 1);
  healthInsuranceTaxfreeEmployerBenefits = healthInsuranceTaxfreeEmployerBenefits
                                            .add(healthInsuranceTaxfreeEmployerBenefitsA);
  // Steuerpflichtige Person B: KV - steuerfreie AG-Zuschüsse  
  let healthInsuranceTaxfreeEmployerBenefitsB = getDecimal(fields, '2003705', 1, 2);
  healthInsuranceTaxfreeEmployerBenefits = healthInsuranceTaxfreeEmployerBenefits
                                            .add(healthInsuranceTaxfreeEmployerBenefitsB);

  logger.debug('healthInsuranceTaxfreeEmployerBenefits', toString(healthInsuranceTaxfreeEmployerBenefits));
  let healthInsuranceCutSum = cut.sub(healthInsuranceTaxfreeEmployerBenefits);

    // Steuerpflichtige Person: Beitrag KV - weitere Beiträge
  // davon Anspruch auf Krankengeld
  let additionalHealthInsuranceWithSickpayA = getDecimal(fields, '2002005', 1, 1);
  logger.debug('additionalHealthInsuranceWithSickpayA', toString(additionalHealthInsuranceWithSickpayA));
  healthInsuranceCutSum = healthInsuranceCutSum.add(additionalHealthInsuranceWithSickpayA);

  // Steuerpflichtige Person B: Beitrag KV - weitere Beiträge
  // davon Anspruch auf Krankengeld
  let additionalHealthInsuranceWithSickpayB = getDecimal(fields, '2002005', 1, 2);
  healthInsuranceCutSum = healthInsuranceCutSum.add(additionalHealthInsuranceWithSickpayB);

  let healthInsuranceCut4Percent = getPercent(healthInsuranceCutSum, 4).floor();
  logger.debug('\t4% Kürzungsbetrag', toString(healthInsuranceCut4Percent));

  let healthInsuranceAfterCut = healthInsurancePaidEmployee.add(additionalHealthInsuranceA).add(additionalHealthInsuranceB);
  if (healthInsuranceCut4Percent.gt(0)) {
    healthInsuranceAfterCut = healthInsuranceAfterCut.sub(healthInsuranceCut4Percent);
  }
  logger.debug('\tverbleiben', toString(healthInsuranceAfterCut));

  // PV
  let socialCareInsurance = new Decimal(0);
  // Steuerpflichtige Person: Beitrag soziale PV - lt. Lohnsteuerbescheinigung
  let socialCareInsuranceA = getDecimal(fields, '2001505', 1, 1);
  socialCareInsurance = socialCareInsurance.add(socialCareInsuranceA);
  // Steuerpflichtige Person B: Beitrag soziale PV - lt. Lohnsteuerbescheinigung
  let socialCareInsuranceB = getDecimal(fields, '2001505', 1, 2);
  socialCareInsurance = socialCareInsurance.add(socialCareInsuranceB);

  let pensionPVA = getDecimal(fields, '2002105', 1, 1);
  socialCareInsurance = socialCareInsurance.add(pensionPVA);
  let pensionPVB = getDecimal(fields, '2002105', 1, 2);
  socialCareInsurance = socialCareInsurance.add(pensionPVB);

  // private PV
  // Steuerpflichtige Person: pPV - Beitrag Basisabsicherung 
  let plus7a = getDecimal(fields, '2003202', 1, 1);
  socialCareInsurance = socialCareInsurance.add(plus7a);

  // Steuerpflichtige Person B: pPV - Beitrag Basisabsicherung 
  let plus7b = getDecimal(fields, '2003202', 1, 2);
  socialCareInsurance = socialCareInsurance.add(plus7b);


  // ausländische PV
  let plus8a = getDecimal(fields, '2002703', 1, 1);
  socialCareInsurance = socialCareInsurance.add(plus8a);
  let plus8b = getDecimal(fields, '2002703', 1, 2);
  socialCareInsurance = socialCareInsurance.add(plus8b);

  logger.debug('\t totalChildPV', toString(totalChildPV));
  socialCareInsurance = socialCareInsurance.add(totalChildPV);


  logger.debug('\tPflegeversicherungsbeiträge', toString(socialCareInsurance));

  let healthInsuranceSumFeesP10Abs1Nr3 = healthInsuranceAfterCut.add(socialCareInsurance);
  logger.debug('\tSumme der Beiträge', toString(healthInsuranceSumFeesP10Abs1Nr3));

  // subtract the refunds
  let rueckerstattungen = new Decimal(0);
  rueckerstattungen = rueckerstattungen.add(refunds);

  // we also need to subtract the private refunds
  // Steuerpflichtige Person: pKV / pPV - erstattete Beiträge  
  let plus9a = getDecimal(fields, '2003302', 1, 1);
  rueckerstattungen = rueckerstattungen.add(plus9a);

  // Steuerpflichtige Person B: pKV / pPV - erstattete Beiträge  
  let plus9b = getDecimal(fields, '2003302', 1 ,2);
  rueckerstattungen = rueckerstattungen.add(plus9b);

  // Steuerpflichtige Person: pKV / pPV - Zuschüsse Dritter  
  let plus10a = getDecimal(fields, '2003402', 1, 1);
  rueckerstattungen = rueckerstattungen.add(plus10a);

  // Steuerpflichtige Person B: pKV / pPV - Zuschüsse Dritter  
  let plus10b = getDecimal(fields, '2003402', 1, 2);
  rueckerstattungen = rueckerstattungen.add(plus10b);

  logger.debug('childReimbursements', toString(childReimbursements));
  rueckerstattungen = rueckerstattungen.add(childReimbursements);

  logger.debug('Beitragsrückerstattungen', toString(rueckerstattungen));

  // Steuerpflichtige Person: soziale PV - steuerfreie AG-Zuschüsse  
  let socialCareInsuranceTaxfreeEmployerBenefitsA = getDecimal(fields, '2003907', 1, 1);


  // healthInsurancePaidEmployee = healthInsurancePaidEmployee.add(socialCareInsuranceA);

  // TODO: new calc of rueckerstattungen
  let erstattungsuberhang10Abs4b = new Decimal(0);
  if (pKV.gt(0)) {
    let plus11a = getDecimal(fields, '2003807', 1, 1);
    erstattungsuberhang10Abs4b = erstattungsuberhang10Abs4b
      .add(plus6a)
      .add(plus7a)
      .sub(plus9a)
      .sub(plus11a);
  } else {
    erstattungsuberhang10Abs4b = healthInsuranceAfterCut
      .add(socialCareInsuranceA)
      .add(socialCareInsuranceB)
      .sub(socialCareInsuranceTaxfreeEmployerBenefitsA)
      .sub(rueckerstattungen)
      .sub(healthInsuranceTaxfreeEmployerBenefits);
  }
  logger.debug('Erstattungsuberhang10Abs4b', toString(erstattungsuberhang10Abs4b));



  
  let afterRefunds = healthInsuranceSumFeesP10Abs1Nr3.sub(rueckerstattungen);

  let socialCareInsuranceTaxfreeEmployerBenefits = new Decimal(0);
  
  socialCareInsuranceTaxfreeEmployerBenefits = socialCareInsuranceTaxfreeEmployerBenefits
                                                .add(socialCareInsuranceTaxfreeEmployerBenefitsA);
  // Steuerpflichtige Person B: soziale PV - steuerfreie AG-Zuschüsse  
  let socialCareInsuranceTaxfreeEmployerBenefitsB = getDecimal(fields, '2003907', 1, 2);
  socialCareInsuranceTaxfreeEmployerBenefits = socialCareInsuranceTaxfreeEmployerBenefits
                                                .add(socialCareInsuranceTaxfreeEmployerBenefitsB);

  // steuerfreieAGZuschuesse = steuerfreieAGZuschuesse.add(socialCareInsuranceTaxfreeEmployerBenefits);

  let steuerfreieAGZuschuessepKV = new Decimal(0);
  // Steuerpflichtige Person: pKV - steuerfreie AG-Zuschüsse 
  let plus11a = getDecimal(fields, '2003807', 1, 1);
  steuerfreieAGZuschuessepKV = steuerfreieAGZuschuessepKV.add(plus11a);

  // Steuerpflichtige Person B: pKV - steuerfreie AG-Zuschüsse 
  let plus11b = getDecimal(fields, '2003807', 1, 2);
  steuerfreieAGZuschuessepKV = steuerfreieAGZuschuessepKV.add(plus11b);

  // TODO Never used maybe remove
  logger.debug('steuerfreieAGZuschuessepKV', toString(steuerfreieAGZuschuessepKV));

  // benefits
  let taxfreeEmployerBenefitsA = healthInsuranceTaxfreeEmployerBenefitsA
                                  .add(socialCareInsuranceTaxfreeEmployerBenefitsA)
                                  .add(plus11a);
  let taxfreeEmployerBenefitsB = healthInsuranceTaxfreeEmployerBenefitsB
                                  .add(socialCareInsuranceTaxfreeEmployerBenefitsB)
                                  .add(plus11b);
  
  let steuerfreieAGZuschuesse = taxfreeEmployerBenefitsA.add(taxfreeEmployerBenefitsB);



  logger.debug('\tsteuerfreie Arbeitgebererstattungen', toString(steuerfreieAGZuschuesse));
  
  // sum basic insurance
  let healthInsuranceSumP10Abs1Nr3 = afterRefunds.sub(steuerfreieAGZuschuesse);
  logger.debug('\tSumme Basisabsicherung', toString(healthInsuranceSumP10Abs1Nr3));
  

  // Steuerpflichtige Person: weitere Beiträge - Zuschüsse zu den Beiträgen  
  let additional6a = getDecimal(fields, '2002402', 1, 1);
  healthInsuranceSumP10Abs1Nr3 = healthInsuranceSumP10Abs1Nr3.sub(additional6a);

  // Steuerpflichtige Person: weitere Beiträge von der KV / PV erstattete Beiträge 
  let additional4a = getDecimal(fields, '2002207', 1, 1);
  healthInsuranceSumP10Abs1Nr3 = healthInsuranceSumP10Abs1Nr3.sub(additional4a);
  //
  // 3.2.2.2 Cost for additional insurances
  let additionalInsurances = new Decimal(0);
  if (healthInsuranceSumP10Abs1Nr3.gte(0)) {
    additionalInsurances = additionalInsurances.add(healthInsuranceSumP10Abs1Nr3);
  }
  
  // if (healthInsuranceSumP10Abs1Nr3.lt(0)) {
  //   additionalInsurances = additionalInsurances.sub(healthInsuranceSumP10Abs1Nr3)
  // }

  // add 4 % Kürzungsbetrag
  additionalInsurances = additionalInsurances.add(healthInsuranceCut4Percent);
  
  // Steuerpflichtige Person: Beitrag soziale PV - weitere Beiträge 
  // let additional3a = getDecimal(fields, '2002105');
  // additionalInsurances = additionalInsurances.add(additional3a);

  healthInsurancePaidEmployee = healthInsurancePaidEmployee.add(additionalHealthInsuranceA).add(additionalHealthInsuranceB);
  logger.debug('healthInsurancePaidEmployee after adding additionalHealthInsuranceA/B', toString(healthInsurancePaidEmployee));

  healthInsuranceAfterCut = healthInsuranceAfterCut.add(additionalHealthInsuranceA).add(additionalHealthInsuranceB);

  // Steuerpflichtige Person: über die Basisabsicherung hinausgehende Beiträge 
  const additional7a = getDecimal(fields, '2002206', 1, 1);
  additionalHealthInsurance = additionalHealthInsurance.add(additional7a);

  // Steuerpflichtige Person B: über die Basisabsicherung hinausgehende Beiträge 
  const additional7b = getDecimal(fields, '2002206', 1, 2);
  additionalHealthInsurance = additionalHealthInsurance.add(additional7b);

  // Steuerpflichtige Person: pKV - Beitrag über Basisabsicherung hinausgehend 
  const additional8a = getDecimal(fields, '2003502', 1, 1);
  additionalHealthInsurance = additionalHealthInsurance.add(additional8a);

  // Steuerpflichtige Person B: pKV - Beitrag über Basisabsicherung hinausgehend 
  const additional8b = getDecimal(fields, '2003502', 1, 2);
  additionalHealthInsurance = additionalHealthInsurance.add(additional8b);

  additionalInsurances = additionalInsurances.add(additionalHealthInsurance);

  // TODO add additional careInsurance?

  // add unemployment insurance
  let unemploymentInsurance = new Decimal(0);
  // Steuerpflichtige Person: AN-Beiträge zur Arbeitslosenversicherung 
  let unemploymentInsuranceA = getDecimal(fields, '2004403', 1, 1);
  unemploymentInsurance = unemploymentInsurance.add(unemploymentInsuranceA);
  // Steuerpflichtige Person B: AN-Beiträge zur Arbeitslosenversicherung 
  let unemploymentInsuranceB = getDecimal(fields, '2004403', 1, 2);
  unemploymentInsurance = unemploymentInsurance.add(unemploymentInsuranceB);
  // Versicherung gegen Arbeitslosigkeit - Summe 
  let additionalUnemploymentInsurance = getDecimal(fields, '2001403');
  unemploymentInsurance = unemploymentInsurance.add(additionalUnemploymentInsurance);
  
  logger.debug('additionalInsurances', toString(additionalInsurances));
  logger.debug('unemploymentInsurance', toString(unemploymentInsurance));
  additionalInsurances = additionalInsurances.add(unemploymentInsurance);

  // add "incapacity to work" insurance
  // Freiw. eigenständige Erwerbs- / Berufsunfähigkeitsvers. - Summe  
  let incapacityToWorkInsurance = getDecimal(fields, '2001503');
  additionalInsurances = additionalInsurances.add(incapacityToWorkInsurance);
  
  let healthInsuranceSum = new Decimal(additionalInsurances);
  logger.debug('healthInsuranceSum', toString(healthInsuranceSum));
  let healthInsuranceSumForAnzuHoch = new Decimal(additionalInsurances);
  logger.debug('healthInsuranceSumP10Abs1Nr3', toString(healthInsuranceSumP10Abs1Nr3));

  logger.debug('healthInsuranceSumForAnzuHoch',toString(healthInsuranceSumForAnzuHoch));
  // TODO Unfall- / Haftpflichtvers. / Risikolebensvers. - Summe ?

  // Unfall- / Haftpflichtvers. / Risikolebensvers. - Summe
  let accidentEtcInsurance = getDecimal(fields, '2001803');
  additionalInsurances = additionalInsurances.add(accidentEtcInsurance);
  logger.debug('accidentEtcInsurance',toString(accidentEtcInsurance));

  healthInsuranceSum = healthInsuranceSum.add(accidentEtcInsurance);

  // add additional nursing the health insurance sum
  const additionNursing = getDecimal(fields, '2001101', 1, 1);
  healthInsuranceSum = healthInsuranceSum.add(additionNursing);
  // TODO Rentevers. mit Kapitalwahlrecht / Kapitallebensversicherung
  // (mind. 12 Jahre / Beginn vor 1.1.05) - Summe ?

  logger.debug('\tSumme der sonstigen Vorsorgeaufwendungen', toString(additionalInsurances));

  // (Ankreuzfeld) Steuerpflichtige Person: steuerfreie Zuschüsse / AG-Beiträge /
  // Beihilfen zu Krankenversicherung / Krankheitskosten
  let benefitsReceivedPersonA = getBoolWithLfdNr({ fields, nr: '2001001', lookFor: 2, lfdNr: 1 });
  // (Ankreuzfeld) Steuerpflichtige Person B: steuerfreie Zuschüsse / AG-Beiträge /
  // Beihilfen zu Krankenversicherung / Krankheitskosten
  let benefitsReceivedPersonB = getBoolWithLfdNr({ fields, nr: '2001001', lookFor: 2, lfdNr: 2 });

  logger.debug('benefitsReceivedPersonA is 2', benefitsReceivedPersonA);
  logger.debug('benefitsReceivedPersonB is 2', benefitsReceivedPersonB);

  // calculate max deductable amount
  let joint = getBool(fields, '0101201', 'X');
  let max = benefitsReceivedPersonA ? new Decimal(2800) : new Decimal(1900);

  if (joint) {
    max = benefitsReceivedPersonB ? max.add(2800) : max.add(1900);
  }

  logger.debug('MAX is ', toString(max));
  logger.debug('additionalInsurances ', toString(additionalInsurances));

  let healthInsuranceSumAfterMax = Decimal.min(additionalInsurances, max);
  additionalInsurances = new Decimal(healthInsuranceSumAfterMax);

  // take the bigger value of these two
  if (healthInsuranceSumP10Abs1Nr3.gt(additionalInsurances)) {
    additionalInsurances = new Decimal(healthInsuranceSumP10Abs1Nr3);
  }

  if (erstattungsuberhang10Abs4b.lt(0)) {
    // additionalInsurances = additionalInsurances.sub(erstattungsuberhang10Abs4b);
  }


  let subContributionHealthInsurance = new Decimal(0);
  subContributionHealthInsurance = subContributionHealthInsurance.add(getDecimal(fields, '2001203', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.add(getDecimal(fields, '2001505', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.sub(getDecimal(fields, '2001605', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.add(getDecimal(fields, '2001805', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.add(getDecimal(fields, '2002105', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.add(getDecimal(fields, '2002207', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.sub(getDecimal(fields, '2002402', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.add(getDecimal(fields, '2003104', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.add(getDecimal(fields, '2003202', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.sub(getDecimal(fields, '2003302', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.sub(getDecimal(fields, '2003402', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.add(getDecimal(fields, '2002502', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.add(getDecimal(fields, '2002703', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.sub(getDecimal(fields, '2002801', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.sub(getDecimal(fields, '2003705', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.sub(getDecimal(fields, '2003807', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.sub(getDecimal(fields, '2003907', 1, 1));
  subContributionHealthInsurance = subContributionHealthInsurance.add(getDecimal(fields, '2004003'));
  subContributionHealthInsurance = subContributionHealthInsurance.add(getDecimal(fields, '2004103'));
  subContributionHealthInsurance = subContributionHealthInsurance.sub(getDecimal(fields, '2004203'));

  logger.debug('Erstattungsüberhänge subContributionHealthInsurance',toString(subContributionHealthInsurance));

  let subContributionOtherInsurance = new Decimal(0);
  subContributionOtherInsurance = subContributionOtherInsurance.add(getDecimal(fields, '2002206', 1, 1));
  subContributionOtherInsurance = subContributionOtherInsurance.add(getDecimal(fields, '2003502', 1, 1));
  subContributionOtherInsurance = subContributionOtherInsurance.add(getDecimal(fields, '2001101'));
  subContributionOtherInsurance = subContributionOtherInsurance.add(getDecimal(fields, '2003606'));
  subContributionOtherInsurance = subContributionOtherInsurance.add(getDecimal(fields, '2004303'));
  subContributionOtherInsurance = subContributionOtherInsurance.add(getDecimal(fields, '2004403', 1, 1));
  subContributionOtherInsurance = subContributionOtherInsurance.add(getDecimal(fields, '2004503'));
  subContributionOtherInsurance = subContributionOtherInsurance.add(getDecimal(fields, '2001403'));
  subContributionOtherInsurance = subContributionOtherInsurance.add(getDecimal(fields, '2001503'));
  subContributionOtherInsurance = subContributionOtherInsurance.add(getDecimal(fields, '2001803'));
  subContributionOtherInsurance = subContributionOtherInsurance.add(getDecimal(fields, '2001903'));
  subContributionOtherInsurance = subContributionOtherInsurance.add(getDecimal(fields, '2002003'));

  logger.debug('Erstattungsüberhänge subContributionOtherInsurance',toString(subContributionOtherInsurance));



  logger.debug('\n\t=', toString(additionalInsurances));
  logger.debug('\t-----------------');

  return {
    childKV: totalChildKV,
    
    healthInsurancePaidEmployeeA,
    healthInsurancePaidEmployeeB,
    healthInsurancePaidEmployee,

    ANBeitraegeGesetzlicheKV,
    pKV,
    foreignKV,

    healthInsuranceNoSickpayA,
    healthInsuranceNoSickpayB,
    healthInsuranceNoSickpay,
    healthInsuranceWithSickpayA,
    healthInsuranceWithSickpayB,
    healthInsuranceWithSickpay,
    healthInsuranceRefunds: refunds,
    healthInsuranceTaxfreeEmployerBenefitsA,
    healthInsuranceTaxfreeEmployerBenefitsB,
    healthInsuranceTaxfreeEmployerBenefits,

    additionalHealthInsuranceA,
    additionalHealthInsuranceB,
    foreignKVA,
    foreignKVB,
    foreignNoClaimSickPayA,
    foreignNoClaimSickPayB,

    healthInsuranceCutSum,
    healthInsuranceCut4Percent,
    healthInsuranceAfterCut,

    healthInsuranceReimbursements: rueckerstattungen,
    erstattungsuberhang10Abs4b,

    socialCareInsurance,
    socialCareInsuranceA,
    socialCareInsuranceB,
    socialCareInsuranceTaxfreeEmployerBenefitsA,
    socialCareInsuranceTaxfreeEmployerBenefitsB,
    socialCareInsuranceTaxfreeEmployerBenefits,

    healthInsuranceSumFeesP10Abs1Nr3,
    healthInsuranceSumP10Abs1Nr3,

    taxfreeEmployerBenefitsA,
    taxfreeEmployerBenefitsB,
    steuerfreieAGZuschuesse,

    additionalHealthInsurance,
    unemploymentInsurance,
    incapacityToWorkInsurance,
    accidentEtcInsurance,

    healthInsuranceMax: max,
    healthInsuranceSum,
    healthInsuranceSumForAnzuHoch,
    healthInsuranceSumAfterMax,
    additionalInsurances,
    subContributionHealthInsurance,
    
    subContributionOtherInsurance
  };
}

module.exports = healthInsurance2016;
