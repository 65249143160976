// @flow
import type { TreeNode } from './types';

const extractRefRules = (rules) => {
  let rulesRef = [];
  rules.args.forEach((arg) => {
    if (arg.type === 'ref') {
      rulesRef.push(arg.args[0]);
    } else if (Array.isArray(arg.args)) {
      rulesRef = [...rulesRef, ...extractRefRules(arg)];
    }
  });
  return rulesRef;
};

const searchDeps = (inputs, deps) => {
  let depsToResolve = [...deps];
  const resolvedDeps = [];
  const references = [];

  while (depsToResolve.length) {
    const dep = depsToResolve.shift();
    if (resolvedDeps.indexOf(dep) === -1) {
      resolvedDeps.push(dep);
      const entry = inputs[dep];
      if (entry) {
        const { properties } = entry;
        if (properties.reference) {
          references.push(properties.reference);
        } else {
          const values =
            (properties.value ? [properties.value] : properties.values) || [];
          depsToResolve = [...depsToResolve, ...values];
        }
      }
    } else {
      console.warn('circular dependecy resolving', deps);
    }
  }
  return references;
};

export function getQuestionsByPlausibilityRule(ruleId: string, node: TreeNode) {
  const rules = (node.rules || []).find(item => item.id === ruleId);

  if (!rules) {
    return [];
  }

  const rulesRefs = extractRefRules(rules.rule);
  const dependencyRefs = searchDeps(node.inputs, rulesRefs);
  return dependencyRefs;
}
