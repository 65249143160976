'use strict';

const { getBool, getValue } = require('../utils/utils_fields');
const { BAYERN, BADEN_WURTTEMBERG } = require('../utils/utils_states');

const PERCENT_CHURCH_TAX = 9;
const PERCENT_CHURCH_TAX_BAVARIA = 8;
const NO_CHURCH_TAX = '11';

function getPersonInfo(fields, religionKeyNrs) {
  const stateNr = 'txf-state';

  // Steuerpflichtige Person: Religionsschlüssel
  let religionKey = getValue(fields, religionKeyNrs[0]);

  if (typeof religionKey === 'undefined') {
    religionKey = 'VD';
  }

  // religionKey may come as a number
  religionKey = religionKey + '';

  // sonstige?
  if (religionKey === '10') {
    religionKey = getValue(fields, religionKeyNrs[1]);
    religionKey = religionKey + '';
  }

  // überprüfe monatliche religionsschlüssel
  let payReligionTaxMonths = 0;

  // Going through all the monthly church tax fields and counting
  // the months when you have to pay because your religion was a taxable one (not 11)
  for (let i = 2; i < religionKeyNrs.length ; i++) {
    const val = getValue(fields, religionKeyNrs[i]);
    if (typeof val !== 'undefined' && val !== NO_CHURCH_TAX) {
      payReligionTaxMonths++;
    }
  }

  // If we can't find any monthly religion keys but the base religion key
  // is set to something else than NO_CHURCH (11) then church tax has to
  // be paid for the entire year
  if (payReligionTaxMonths === 0 && religionKey !== NO_CHURCH_TAX) {
    payReligionTaxMonths = 12;
  }

  let hasToPayChurchTax = payReligionTaxMonths > 0;

  const state = getValue(fields, stateNr);

  let churchTaxRate = 0;

  if (hasToPayChurchTax) {
    churchTaxRate = PERCENT_CHURCH_TAX;
    if (state === BADEN_WURTTEMBERG || state === BAYERN) {
      churchTaxRate = PERCENT_CHURCH_TAX_BAVARIA;
    }
  }

  return {
    religionKey,
    monthsToPay: payReligionTaxMonths,
    hasToPayChurchTax,
    churchTaxRate
  };
}

function getChurchInfo(fields) {
  let persons = [];

  const joint = getBool(fields, '0101201', 'X');

  // Person A
  persons.push(getPersonInfo(fields, [
    '0100402', '0100422',

    // nach Monaten
    '0100410', '0100411', '0100412', '0100413', '0100414', '0100415', '0100416',
    '0100417', '0100418', '0100419', '0100420', '0100421',

    // sonstige nach Monaten
    // '0100430', '0100431', '0100432', '0100433', '0100434', '0100435', '0100436',
    // '0100437', '0100438', '0100439', '0100440', '0100441'
  ]));

  if (joint) {
    // Person B
    // TODO check what about 2 persons living in different states?
    persons.push(getPersonInfo(fields, [
      '0101002', '0101022',

      '0101010', '0101011', '0101012', '0101013', '0101014', '0101015',
      '0101016', '0101017', '0101018', '0101019', '0101020', '0101021',

      // '0101030', '0101031', '0101032', '0101033', '0101034', '0101035',
      // '0101036', '0101037', '0101038', '0101039', '0101040', '0101041'
    ]));
  } else {
    persons.push({hasToPayChurchTax: false});
  }

  return persons;
}

module.exports = getChurchInfo;