/* eslint-disable function-paren-newline */
// @flow

import _ from 'lodash';
import indexGroups from '@taxfix/elster-index-groups';
import { elsterFieldsPerYear } from '@taxfix/elster-fields';

import list2refs from './list2refs';
import inputResolver from '../calculator/inputResolver';
import { cartesianProduct } from '../utils/cartesianProduct';

import {
  isAnswered,
} from '../q-and-a/answersHelper';

import preconditionsMet from '../q-and-a/preconditionHelper';

import type {
  Cache,
  Id,
  IncomeTaxValue,
  Responses,
  TaxReference,
  TreeNode,
  LoopContext,
} from '../types';

const elsterField = (
  nodeId: Id,
  taxReference: TaxReference,
  loopContext: LoopContext | null | void,
  year: number,
) => {
  const data = {
    nr: taxReference.elsterFieldNumber,
    lfdNr: taxReference.runningNumber || 1,
    index: taxReference.index || 1,
  };
  if (taxReference.partnerB) {
    const field = elsterFieldsPerYear(year).find(f => f.nr === taxReference.elsterFieldNumber);
    if (!field) {
      // eslint-disable-next-line max-len
      throw new Error(`The elster field with number ${taxReference.elsterFieldNumber} is not valid for the year ${year}`);
    }
    const { personBField } = field;
    if (!personBField) {
      throw new Error(
        `The elster field with number ${taxReference.elsterFieldNumber} does not have a corresponding person B field. \
        However, it is is used in question ${nodeId} which is between a person loop`,
      );
    }
    switch (personBField.variation) {
      case 'index':
        return ({
          ...data,
          index: 2,
        });
      case 'lfdnr':
        return ({
          ...data,
          lfdNr: 2,
        });
      case 'field':
        return ({
          ...data,
          nr: personBField.nr,
        });
      default:
        throw new Error(`Unexpected variation (${personBField.variation}) for field ${taxReference.elsterFieldNumber}`);
    }
  }
  return data;
};

// TODO: ignore skipped values
const taxValueTransformer = (
  list: TreeNode[],
  responses: Responses,
  cache: Cache,
  year: number,
): IncomeTaxValue[] => {
  const refs = list2refs(list);

  const taxReferencesAsValues = (node: TreeNode) => {
    const {
      inputs,
      outputs,
      taxReferences,
      loopContext,
    } = node;

    if (inputs == null || outputs == null || taxReferences == null) {
      return [];
    }

    return taxReferences
      .filter((taxReference: TaxReference) => {
        const { condition } = taxReference;
        if (condition) {
          const input = inputs[condition];
          const value = inputResolver(input, responses, inputs, refs, node.id, cache, year);
          return value;
        }
        return true;
      })
      .map((taxReference: TaxReference) => {
        const outputKey = taxReference.value;
        const input = inputs[outputKey];
        const value = inputResolver(input, responses, inputs, refs, node.id, cache, year);

        return {
          context: {
            id: taxReference.id,
            group: taxReference.groupId || node.parentId,
            loop: taxReference.loopId,
          },
          ...elsterField(node.id, taxReference, loopContext, year),
          value: value == null ? null : JSON.parse(JSON.stringify(value)),
        };
      });
  };

  let candidates = list.filter(node => preconditionsMet(node, responses, refs, cache, year));

  // Only show candidates that have been answered
  candidates = candidates
    .filter((node) => {
      if (node.response == null) {
        return true;
      }
      return isAnswered(node, responses);
    });

  // Get tax references that have values
  const taxReferences = _.chain(candidates)
    .flatMap(taxReferencesAsValues)
    .filter(taxReference => taxReference.value != null)
    .value();

  const elsterIndexGroups = indexGroups(year);

  /* The following lines will group elster fields into groups to automatically increase their
   * indices in parallel given that: 1) they appear multuple times and, 2) groups share the same parent.
   * We make a best effort to deal with loops in the mix and trying to given them priority.
   *
   * NOTE: I wish this could be easier to understand, but ot be honest the concept behind
   * is a bit hacky. If at some point you need to change this, and there's a guy name Alex in
   * working at the company, ask him for help.
   */
  elsterIndexGroups.forEach((elsterIndexGroup) => {
    // Find all taxReferences matching items in elsterIndexGroup
    const relevantReferences = taxReferences.filter((reference: IncomeTaxValue) => (
      elsterIndexGroup.includes(reference.nr)
    ));

    // Group taxReferences by person i.e. lfdnr
    const lfdNrGroups = _.groupBy(relevantReferences, 'lfdNr');

    // For each person group i.e. lfdNrGroups
    _.forEach(lfdNrGroups, (lfdNrGroup) => {
      /**
       * For each of the values in lfdNrGroup take the
       * unique index. Return array of index.
       *
       * @type {unknown[]}
       */
      const indices = _.uniq(_.map(lfdNrGroup, _.property('index')));
      /**
       *
       * For each of the values in lfdNrGroup take the
       * unique context.group. Return array of context.group
       *
       * @type {unknown[]}
       */
      const groups = _.uniq(_.map(lfdNrGroup, _.property('context.group')));
      /**
       * Take the cartesian product of indexes and group
       * so we can get all cartesian combination of
       * indexes and groups. Returns array of array
       *
       * https://en.wikipedia.org/wiki/Cartesian_product
       * @type {[]}
       */
      const cartesianProductIndicesGroups = cartesianProduct(indices, groups);

      /**
       * Sort cartesianProductIndicesGroups by the first value
       * in each array which is the index.
       *
       * @type {unknown[]}
       */
      const buckets = _.sortBy(cartesianProductIndicesGroups, ([index]) => index);

      const filledBuckets = _.map(
        buckets,
        ([index, group]) => {
          const byNr = _.chain(relevantReferences)
            .filter(ref => ref.index === index && ref.context.group === group)
            .groupBy('nr')
            .values()
            .value();

          return _.zip.apply(null, byNr);
        },
      );

      _.flatten(filledBuckets).forEach((group, index) =>
        // here where we rewrite the indexes
        _.forEach(group, elem => (elem || {}).index = index + 1),  // eslint-disable-line
      );
    });
  });

  // Filter only required keys
  const leanTaxReferences = taxReferences.map(x => _.pick(x, ['nr', 'lfdNr', 'index', 'value']));

  const DEBUG_DUPLICATES = false;

  if (DEBUG_DUPLICATES) {
    const yyy = leanTaxReferences.map(x => _.pick(x, ['nr', 'lfdNr', 'index']));

    const duplicates = _.flatMap(yyy, (o, i) => {
      const eq = _.find(yyy, (e, ind) => {
        if (i > ind) {
          return _.isEqual(e, o);
        }
        return false;
      });
      if (eq) {
        return o;
      }
      return [];
    });

    // eslint-disable-next-line no-console
    console.log(duplicates.length, duplicates);

    process.exit(1);
  }

  return leanTaxReferences;
};

export default taxValueTransformer;
