'use strict';

const Decimal = require('decimal.js');
const parseDecimalNumber = require('parse-decimal-number');

const logger = require('../logger');
const dateUtils = require('./utils_date');

function match(fields, { into = [], lfdNr, index }) {
  let affectedFields = [...fields];

  if (into && into.length) {
    affectedFields = affectedFields.filter((f) => into.includes(f.nr));
  }

  if (typeof lfdNr !== 'undefined') {
    affectedFields = affectedFields.filter((f) => f.lfdNr === lfdNr);
  }

  if (typeof index !== 'undefined') {
    affectedFields = affectedFields.filter((f) => f.index === index);
  }

  return affectedFields;
}

function getFieldByNr(fields = [], nr = '') {
  if (!Array.isArray(fields) || typeof nr !== 'string') {
    return undefined;
  }

  return fields.find(field => {
    return field.nr === nr;
  });
}

function getFieldsByNr(fields = [], nr = '') {
  if (!Array.isArray(fields) || typeof nr !== 'string') {
    return undefined;
  }

  return fields.filter(field => {
    return field.nr === nr;
  });
}

//
//
function getGroupsByNrs(fields = [], nrs = []) {
  if (!Array.isArray(fields) || !Array.isArray(nrs)) {
    return undefined;
  }

  let groups = [];

  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];

    if (nrs.includes(field.nr)) {
      // elster index/lfdNr is 1-based, array is 0-based
      let lfdNr = parseInt(field.lfdNr, 10) - 1;
      let index = parseInt(field.index, 10) - 1;

      if (typeof groups[lfdNr] === 'undefined') {
        groups[lfdNr] = [];
      }

      if (typeof groups[lfdNr][index] === 'undefined') {
        groups[lfdNr][index] = [];
      }

      groups[lfdNr][index].push(field);
    }
  }

  return groups;
}

//
//
function getFieldsByIndex(fields = {}, nrs = []) {
  let groups = [];

  nrs.forEach(nr => {
    let field = fields[nr];

    if (Array.isArray(field)) {
      field.forEach(f => {
        let index = parseInt(f.index, 10) - 1;

        if (typeof groups[index] === 'undefined') {
          groups[index] = [];
        }

        groups[index].push(f);
      });
    }
  });

  return groups;
}

/**
 * This break the field into separate group by LFDNR(Person).
 * Each person object then contains each field keyed by `nr`.
 * Each field is an array that contains fields with same `nr`
 * with different indexes. See sample response below
 *
 * [
 *    // LFDNR 1 or Person A. There can be multiple of this.
 *    {
 *       "0204802":[
 *          {
 *             "nr":"0204802",
 *             "lfdNr":1,
 *             "index":1,
 *             "value":"3755"
 *          },
 *          {
 *             "nr":"0204802",
 *             "lfdNr":1,
 *             "index":2,
 *             "value":"16"
 *          }
 *       ],
 *       "0109708":[
 *          {
 *             "nr":"0109708",
 *             "lfdNr":1,
 *             "index":1,
 *             "value":"80"
 *          }
 *       ]
 *    }
 * ]
 * @param fields array of all fields
 * @param nrs elster ID
 * @returns {*[]|undefined}
 */
function getLfdNrGroupsByNrs(fields = [], nrs = []) {
  if (!Array.isArray(fields) || !Array.isArray(nrs)) {
    return undefined;
  }

  let groups = [];

  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];

    if (nrs.includes(field.nr)) {
      // elster index/lfdNr is 1-based, array is 0-based
      let lfdNr = parseInt(field.lfdNr, 10) - 1;
      let index = parseInt(field.index, 10) - 1;

      if (typeof groups[lfdNr] === 'undefined') {
        groups[lfdNr] = {};
      }

      if (typeof groups[lfdNr][field.nr] === 'undefined') {
        groups[lfdNr][field.nr] = [];
      }
      groups[lfdNr][field.nr][index] = field;
    }
  }

  return groups;
}

/**
 * @param fields
 * @param nr
 * @param index
 * @returns {*|undefined}
 */
function getValue(fields = [], nr = '', index = 0) {
  let field;

  if (Array.isArray(fields)) {
    field = getFieldByNr(fields, nr);
  } else {
    field = fields[nr];
  }

  if (typeof field === 'undefined') {
    return undefined;
  }

  if (Array.isArray(field)) {
    return field[index] ? field[index].value : undefined;
  } else {
    return field.value;
  }
}

/**
 * Find a value by specifying the nr (elster ID), index, lfdNr (running number).
 * Returns the entire field object if found or undefined if not found
 *
 * @param {*} fields array of all fields
 * @param {*} nr elster id
 * @param {*} index elster index (default is 1)
 * @param {*} lfdNr elster running numnber (default is 1)
 */
function find(fields, nr, index = 1, lfdNr = 1) {
  const matches = fields.filter(function(f) {
    return f.nr === nr && f.index === index && f.lfdNr === lfdNr;
  });
  if(matches && matches.length) {
    return matches[0];
  }
}

function getValues(fields = [], nr = '') {
  let f;

  if (Array.isArray(fields)) {
    f = getFieldsByNr(fields, nr);
  } else {
    f = fields[nr];
  }

  if (!Array.isArray(f)) {
    return [];
  }

  return f.map(field => field.value);
}

function getBool(fields = [], nr = '', lookFor = 'X', index = 0) {
  let value = getValue(fields, nr, index);

  if (typeof value === 'undefined') {
    return false;
  }

  return value === lookFor;
}

function getBoolWithLfdNr({ fields = [], nr = '', lookFor = 'X', index = 1, lfdNr = 0 }) {
  let field;
  if (Array.isArray(fields)) {
    field = find(fields, nr, index, lfdNr);
  } else {
    try {
      field = fields[nr];
      if (Array.isArray(field)) {
        field = find(field, nr, index, lfdNr);
      }
    } catch (error) {
      logger.warn(`Error while executing the field. Nr: ${nr}. Type: ${typeof field}. Field: ${JSON.stringify(field)}`);
      return false;
    }
  }

  if (!field || typeof field.value === 'undefined') {
    return false;
  }

  return field.value === lookFor;
}

function getDecimal(fields = [], nr = '', index = 0, lfdNr = undefined) {
  let value;
  let field = {};
  if (typeof lfdNr !== 'undefined') {
    if (Array.isArray(fields)) {
      field = find(fields, nr, index, lfdNr) || {};
    } else {
      try {
        field = fields[nr] || {};
        if (Array.isArray(field)) {
          field = find(field, nr, index, lfdNr) || {};
        }
      } catch (error) {
        logger.warn(`Error while executing the field. Nr: ${nr}. Type: ${typeof field}. Field: ${JSON.stringify(field)}`);
        return new Decimal(0);
      }
    }
    value = field.value;
  } else {
    value = getValue(fields, nr, index);
  }

  if (typeof value === 'undefined') {
    return new Decimal(0);
  }

  if (typeof value === 'string') {
    try {
      value = parseDecimalNumber(value, '.,');
      if (Number.isNaN(value)) {
        throw new Error('Not a number');
      }
    } catch (error) {
      logger.warn(`Unable to parse field (fallback to 0). Number: ${nr}, value: ${value}`);
      return new Decimal(0);
    }
  }

  return new Decimal(value);
}

function getDecimalOrSpecial(fields = [], nr = '', nrOr = '', index = 0, flagValue = 1) {
  let value = getValue(fields, nrOr, index);
  if (typeof value !== 'undefined' && value.toString() === flagValue.toString()) {
    value = new Decimal(0);
  } else if (typeof value !== 'undefined' && !(new Decimal(value)).isZero()) {
    value = getDecimal(fields, nrOr, index);
  } else {
    value = getDecimal(fields, nr, index);
  }

  return value;
}

function getDecimalOr(fields = [], nr = '', nrOr = '', index = 0) {
  let value = getDecimal(fields, nrOr, index);

  if (value.isZero()) {
    value = getDecimal(fields, nr, index);
  }

  return value;
}

function getDecimalOrByLfdNr(fields = [], nr = '', nrOr = '', lfdNr = 1, index = 1) {
  const firstField = find(fields, nr, index, lfdNr);
  const secondField = find(fields, nr, index, lfdNr);
  if (firstField && firstField.value) {
    return parseDecimalNumber(firstField.value);
  } else if (secondField && secondField.value) {
    return parseDecimalNumber(secondField.value);
  }
  return new Decimal(0);
}

//
//
function getMonths(year,
  fields = [],
  nr = [],
  arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  method = 'update'
) {
  if (typeof nr === 'string') {
    nr = [nr];
  }

  let months = arr;

  nr.forEach(currentNr => {
    const values = getValues(fields, currentNr);

    if (values.length > 0) {
      values.forEach(val => {

        if (method === 'update') {
          dateUtils.updateMonthsIfMatchDDMM(months, val, year);
        } else if (method === 'add') {
          dateUtils.addMonthsIfMatchDDMM(months, val, year);
        }
      });
    }
  });

  return months;
}

//
// TODO only iterate lfdNr + create functions for iterating index + both!?
function iterateLfNr(groups = [], iteratee = undefined, max = 0) {
  let maxLength = groups.length;
  if (max > 0) {
    maxLength = max;
  }

  for (let lfdNr = 0; lfdNr < maxLength; lfdNr++) {
    let lfdNrGroups = groups[lfdNr];

    if (typeof iteratee !== 'undefined') {
      iteratee(lfdNrGroups, lfdNr);
    }
  }
}

module.exports = {
  getBool,
  getFieldByNr,
  getFieldsByNr,
  getFieldsByIndex,
  getGroupsByNrs,
  getLfdNrGroupsByNrs,
  getDecimal,
  getDecimalOr,
  getDecimalOrByLfdNr,
  getDecimalOrSpecial,
  getMonths,
  getValue,
  getValues,
  iterateLfNr,
  find,
  getBoolWithLfdNr,
  match,
};
