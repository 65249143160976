// @flow

import * as ibantools from 'ibantools';
import { iban } from './shared';

export { iban };

export const bic = (data: string) => ibantools.isValidBIC(data);

export const customFormats = {
  iban,
  bic,
};

export default customFormats;
