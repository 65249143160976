'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const { getDecimal } = require('../utils/utils_fields');
const { toString } = require('../utils/utils_logs');

function travelling2020(fields) {
  logger.debug('\n-------------------------\nTRAVELLING');
  let expenses = new Decimal(0);

  let sum1 = new Decimal(0);

  // 
  let travellingCost = getDecimal(fields, '0205518');
  logger.debug('travellingCost', toString(travellingCost));
  sum1 = sum1.add(travellingCost);

  // 2020: Truck travelling (number of days)
  let truckTravelling = getDecimal(fields, '0206501');
  logger.debug('Truck travelling', toString(truckTravelling));
  truckTravelling = truckTravelling.times(8);
  sum1 = sum1.add(truckTravelling);

  // 
  let reimbursement1 = getDecimal(fields, '0205608');
  logger.debug('reimbursement1', toString(reimbursement1));
  sum1 = sum1.sub(reimbursement1);
  
  logger.debug('sum1', toString(sum1));
  expenses = expenses.add(sum1);

  let sum2 = new Decimal(0);

  // 
  // 2020: 12 > 14
  let awayMoreThan14 = getDecimal(fields, '0205201');
  awayMoreThan14 = awayMoreThan14.times(14);
  logger.debug('        awayMoreThan14', toString(awayMoreThan14));
  sum2 = sum2.add(awayMoreThan14);

  //
  // 2020: 12 > 14
  let arrivalDepature = getDecimal(fields, '0205302');
  arrivalDepature = arrivalDepature.times(14);
  logger.debug('        arrivalDepature', toString(arrivalDepature));
  sum2 = sum2.add(arrivalDepature);

  //
  // 2020: 24 > 28
  let awayMoreThan28 = getDecimal(fields, '0205409');
  awayMoreThan28 = awayMoreThan28.times(28);
  logger.debug('        awayMoreThan28', toString(awayMoreThan28));
  sum2 = sum2.add(awayMoreThan28);

  //
  let freeMeals = getDecimal(fields, '0205508');
  logger.debug('        freeMeals', toString(freeMeals));
  sum2 = sum2.sub(freeMeals);
  
  logger.debug('sum2', toString(sum2));
  expenses = expenses.add(sum2);

  let sum3 = new Decimal(0);

  // TODO check how these fields need to be treated
  // Auswärtstätigkeit Ausland - Aufwendungen Summe
  let abroadCost = getDecimal(fields, '0205630');
  logger.debug('abroadCost', toString(abroadCost));
  sum3 = sum3.add(abroadCost);

  // this is already subtracted in 0205630, no need to subtract it again
  // let abroadFreeMeals = getDecimal(fields, '0205629');
  // logger.debug('abroadFreeMeals',toNumber(abroadFreeMeals));
  // sum3 = sum3.sub(abroadFreeMeals);

  let reimbursement2 = getDecimal(fields, '0205108');
  logger.debug('reimbursement2', toString(reimbursement2));
  sum3 = sum3.sub(reimbursement2);
  
  logger.debug('sum3', toString(sum3));
  expenses = expenses.add(sum3);

  // 0206501

  return expenses;
}

module.exports = travelling2020;
