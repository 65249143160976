'use strict';

const disability = require('../2016/disability');
const confessionChange = require('../2016/confession-change');
const treasureSales = require('../2016/treasure-sales');
const foreignIBAN = require('./foreign-iban');
const gunstigerprufungPartner = require('../2016/gunstigerprufung-partner');
const { markLossCarryForward } = require('../2016/mark-loss-carry-forward');

module.exports = (fields, calculationResult = {}) => {
  fields = disability(fields);
  fields = confessionChange(fields);
  fields = treasureSales(fields);
  fields = foreignIBAN(fields);
  fields = gunstigerprufungPartner(fields);
  fields = markLossCarryForward(fields, calculationResult);

  return fields;
};
