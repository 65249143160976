'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const { getBool, getDecimal, getDecimalOr, getDecimalOrByLfdNr } = require('../utils/utils_fields');
const constants = require('./../2016/constants-2016.json');
const { toString } = require('../utils/utils_logs');

/**
 * Specified by Mira in 
 * https://docs.google.com/spreadsheets/d/1Q1WxRiJ9voAfeoqwZdNfVvN8PkZ7e4lWq0AE1E9vKG8/edit#gid=399614256
 * 
 * @param {*} fields 
 * @param {*} incomePerPerson 
 * @param {*} saleOfPropertyForTaxLoss 
 */

function taxLoss2020(fields, incomePerPerson, saleOfPropertyForTaxLoss) {
  logger.debug('\n-------------------------\nTAX LOSS');
  
  let taxLoss = {};
  try {
    let taxLossOverall = incomePerPerson[0] || incomePerPerson;
    logger.debug('taxLossBasis x', toString(taxLossOverall), incomePerPerson);

    // // Update on 01.09.2017 w/ Werner
    // if (taxLossOverall.gt(0)) {
    //   return {
    //     overall: 0
    //   };
    // }

    /**
     * Capital gains 
     */
    let capitalGainsExcludingSaleOfShares = new Decimal(0);
    const capitalGains = getDecimalOr(fields, '1900701', '1900702');
    logger.debug('taxLoss capitalGains', toString(capitalGains));
    const capitalGainsGainShares = getDecimalOr(fields, '1900901', '1900902');
    logger.debug('taxLoss capitalGainsShares', toString(capitalGainsGainShares));
    const notClearedLossesCapitalGains = getDecimalOr(fields, '1901201', '1901202');
    logger.debug('taxLoss notClearedLossesCapitalGains', toString(notClearedLossesCapitalGains));
    capitalGainsExcludingSaleOfShares = capitalGainsExcludingSaleOfShares.add(capitalGains).sub(capitalGainsGainShares).sub(notClearedLossesCapitalGains);
    logger.debug('taxLoss capitalGainsExcludingSaleOfShares', toString(capitalGainsExcludingSaleOfShares));

    let capitalGainsFromSaleOfShares = new Decimal(0);
    const notClearedLossesCapitalGainsFromSalesOfShares = getDecimalOr(fields, '1901301', '1901302');
    logger.debug('taxLoss notClearedLossesCapitalGainsFromSalesOfShares', toString(notClearedLossesCapitalGainsFromSalesOfShares));
    capitalGainsFromSaleOfShares = capitalGainsFromSaleOfShares.add(capitalGainsGainShares).sub(notClearedLossesCapitalGainsFromSalesOfShares);
    logger.debug('taxLoss capitalGainsFromSaleOfShares', toString(capitalGainsFromSaleOfShares));


    /**
     * Sale of assets
     */
    let saleOfAssets = new Decimal(0);
    const saleOfProperty = getDecimal(fields, '0306801', 1, 1);
    let privateSales = (getDecimal(fields, '0307701', 1, 1)).add(getDecimal(fields, '0307801'));
    logger.debug('taxLoss capitalGainsFromSaleOfShares', toString(capitalGainsFromSaleOfShares));
    logger.debug('taxLoss saleOfOtherTreasures', toString(saleOfPropertyForTaxLoss));
    logger.debug('taxLoss privateSales', toString(privateSales));
    saleOfAssets = saleOfAssets.add(saleOfProperty).add(saleOfPropertyForTaxLoss).add(privateSales);
    logger.debug('taxLoss saleOfAssets', toString(saleOfAssets));
    if (saleOfAssets.lt(constants.TAXFREE_LUMPSUM)) {
      saleOfAssets = new Decimal(0);
    }

    /**
     * Other Income
     */
    const otherIncome = getDecimal(fields, '0305301', 1, 1);
    logger.debug('taxLoss otherIncome', toString(otherIncome));
    let capitalGainsTotal = new Decimal(0);
    if (capitalGainsFromSaleOfShares.greaterThan(0)) {
      capitalGainsTotal = capitalGainsTotal.add(capitalGainsFromSaleOfShares);
    }
    if (capitalGainsExcludingSaleOfShares.greaterThan(0)) {
      capitalGainsTotal = capitalGainsTotal.add(capitalGainsExcludingSaleOfShares);
    }
    if (capitalGainsTotal.gt(constants.CAPITAL_GAINS_LUMPSUM)) {
      taxLossOverall = taxLossOverall.add(capitalGainsTotal);
    }
    if (saleOfAssets.greaterThan(0)) {
      taxLossOverall = taxLossOverall.add(saleOfAssets);
    }

    if (otherIncome.greaterThan(constants.OTHER_INCOME_LUMPSUM)) {
      taxLossOverall = taxLossOverall.add(otherIncome);
    }


    logger.debug('taxLoss after adding custom tax losses', toString(taxLossOverall));

    logger.debug('taxLoss taxLossOverall before adding alimony things', toString(taxLossOverall));
    let alimonyValue = new Decimal(0);
    alimonyValue = alimonyValue.add(getDecimal(fields, '0304601', 1, 1));
    alimonyValue = alimonyValue.add(getDecimal(fields, '0300515', 1, 1));
    alimonyValue = alimonyValue.add(getDecimal(fields, '0304502', 1, 1));
    alimonyValue = alimonyValue.sub(getDecimal(fields, '0304701', 1, 1));
    
    logger.debug('taxLoss alimonyValue', toString(alimonyValue));
    taxLossOverall = taxLossOverall.add(alimonyValue);
    logger.debug('taxLoss taxLossOverall before adding the previous years tax loss', toString(taxLossOverall));

    logger.debug('check if joint');
    let taxLossOverallB = new Decimal(0);

    let joint = getBool(fields, '0101201', 'X');
    if (joint && incomePerPerson[1]) {
      logger.debug('===== TaxLoss calc for person B');
      // calculate for person B too.
      taxLossOverallB = incomePerPerson[1];
      logger.debug('taxLossBasis B x', toString(taxLossOverallB));
      /**
       * Capital gains 
       */
      let capitalGainsExcludingSaleOfSharesB = new Decimal(0);
      const capitalGainsB = getDecimalOrByLfdNr(fields, '1900701', '1900702', 2);
      logger.debug('taxLoss capitalGains', toString(capitalGainsB));
      const capitalGainsGainSharesB = getDecimalOrByLfdNr(fields, '1900901', '1900902', 2);
      logger.debug('taxLoss capitalGainsShares', toString(capitalGainsGainSharesB));
      const notClearedLossesCapitalGainsB = getDecimalOrByLfdNr(fields, '1901201', '1901202', 2);
      logger.debug('taxLoss notClearedLossesCapitalGains', toString(notClearedLossesCapitalGainsB));
      capitalGainsExcludingSaleOfSharesB = capitalGainsExcludingSaleOfSharesB.add(capitalGainsB).sub(capitalGainsGainSharesB).sub(notClearedLossesCapitalGainsB);
      logger.debug('taxLoss capitalGainsExcludingSaleOfSharesB', toString(capitalGainsExcludingSaleOfSharesB));

      let capitalGainsFromSaleOfSharesB = new Decimal(0);
      const notClearedLossesCapitalGainsFromSalesOfSharesB = getDecimalOrByLfdNr(fields, '1901301', '1901302', 2);
      logger.debug('taxLoss notClearedLossesCapitalGainsFromSalesOfShares', toString(notClearedLossesCapitalGainsFromSalesOfSharesB));
      capitalGainsFromSaleOfSharesB = capitalGainsFromSaleOfSharesB.add(capitalGainsGainSharesB).sub(notClearedLossesCapitalGainsFromSalesOfSharesB);
      logger.debug('taxLoss capitalGainsFromSaleOfSharesB', toString(capitalGainsFromSaleOfSharesB));


      /**
       * Sale of assets person B
       */
      let saleOfAssetsB = new Decimal(0);
      const saleOfPropertyB = getDecimal(fields, '0306801', 1, 2);
      let privateSalesB = (getDecimal(fields, '0307701', 1, 2)).add(getDecimal(fields, '0307802'));
      logger.debug('taxLoss capitalGainsFromSaleOfSharesB', toString(capitalGainsFromSaleOfSharesB));
      logger.debug('taxLoss saleOfOtherTreasuresB', toString(saleOfPropertyForTaxLoss));
      logger.debug('taxLoss privateSalesB', toString(privateSalesB));
      saleOfAssetsB = saleOfAssetsB.add(saleOfPropertyB).add(saleOfPropertyForTaxLoss).add(privateSalesB);
      logger.debug('taxLoss saleOfAssetsB', toString(saleOfAssetsB));
      if (saleOfAssetsB.lt(constants.TAXFREE_LUMPSUM)) {
        saleOfAssetsB = new Decimal(0);
      }

      /**
       * Other Income
       */
      const otherIncomeB = getDecimal(fields, '0305301', 1, 2);
      logger.debug('taxLoss otherIncome', toString(otherIncomeB));
      let capitalGainsTotalB = new Decimal(0);
      if (capitalGainsFromSaleOfSharesB.greaterThan(0)) {
        capitalGainsTotalB = capitalGainsTotalB.add(capitalGainsFromSaleOfSharesB);
      }
      if (capitalGainsExcludingSaleOfShares.greaterThan(0)) {
        capitalGainsTotalB = capitalGainsTotalB.add(capitalGainsExcludingSaleOfSharesB);
      }
      if (capitalGainsTotalB.gt(constants.CAPITAL_GAINS_LUMPSUM)) {
        taxLossOverallB = taxLossOverallB.add(capitalGainsTotalB);
      }
      if (saleOfAssets.greaterThan(0)) {
        taxLossOverallB = taxLossOverallB.add(saleOfAssetsB);
      }

      if (otherIncomeB.greaterThan(constants.OTHER_INCOME_LUMPSUM)) {
        taxLossOverallB = taxLossOverallB.add(otherIncomeB);
      }

      logger.debug('taxLoss after adding custom tax losses B', toString(taxLossOverallB));

      logger.debug('taxLoss taxLossOverallB before adding alimony things', toString(taxLossOverallB));
      let alimonyValueB = new Decimal(0);
      alimonyValueB = alimonyValueB.add(getDecimal(fields, '0304601', 1, 2));
      alimonyValueB = alimonyValueB.add(getDecimal(fields, '0300515', 1, 2));
      alimonyValueB = alimonyValueB.add(getDecimal(fields, '0304502', 1, 2));
      alimonyValueB = alimonyValueB.sub(getDecimal(fields, '0304701', 1, 2));
      
      logger.debug('taxLoss alimonyValueB', toString(alimonyValueB));
      taxLossOverallB = taxLossOverallB.add(alimonyValueB);

      logger.debug('taxLoss overall B after adding alimony', toString(taxLossOverallB));
    }

    if (taxLossOverall.gt(0)) {
      taxLossOverall = new Decimal(0);
    }
    if (taxLossOverallB.gt(0)) {
      taxLossOverallB = new Decimal(0);
    }

    taxLossOverall = taxLossOverall.add(taxLossOverallB);

    const taxLossDeduction2020 = require('./tax_loss_deduction_2020');
    const taxLossDeduction = taxLossDeduction2020(fields);
    taxLossOverall = taxLossOverall.sub(taxLossDeduction);
    logger.debug('taxLoss taxLossOverall', toString(taxLossOverall));

    if (taxLossOverall.gt(0)) {
      taxLossOverall = new Decimal(0);
    }

    // compile the detaild tax loss result
    taxLoss = {
      capitalGainsExcludingSaleOfShares: 0,//capitalGainsExcludingSaleOfShares.toNumber(),
      capitalGainsFromSaleOfShares: 0,//capitalGainsFromSaleOfShares.toNumber(),
      saleOfAssets: 0,//saleOfAssets.toNumber(),
      otherIncome: 0,//otherIncome.toNumber(),
      overall: taxLossOverall,
    };

  } catch (error) {
    logger.debug('ERROR', error);
    throw error;
  }
  return taxLoss;
}

module.exports = taxLoss2020;