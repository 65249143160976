'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const {
  getBool,
  getDecimal,
  getDecimalOrSpecial,
  getLfdNrGroupsByNrs,
  iterateLfNr
} = require('../utils/utils_fields');
const { percent: getPercent} = require('../utils/utils_decimal');
const { toString } = require('../utils/utils_logs');

function capitalGains2017(fields, chruchInfo) {
  logger.debug('\n-------------------------\nCAPITAL GAINS ');

  let perPerson = [];
  let openExemptAmount = new Decimal(0);

  iterateLfNr(getLfdNrGroupsByNrs(fields, [
    '1901702',
    '1900701',
    '1900702',
    '1900901',
    '1900902',
    '1901201',
    '1901202',
    '1901802',
    '1901701',
    '1901501',
    '1902001',
    '1901301',
    '1901302',
    '1901903',
    '1901402',
    '1904701',
    '1904702',
    '1905502',
    '1905301',
    '1902302'
  ]), lfdNrFields => {
    let withoutStocks = new Decimal(0);
    
  
    // gesamt
    withoutStocks = withoutStocks.add(getDecimalOrSpecial(lfdNrFields, '1900701', '1900702'));
    logger.debug('0 withoutStocks', toString(withoutStocks));

    // XXX remove for now because we don't have any values from prior years
    // gewinne aktienverkäufe
    // withoutStocks = withoutStocks.sub(getDecimalOr(lfdNrFields, '1900901', '1900902'));

    // logger.debug('1 withoutStocks',toNumber(withoutStocks));
    
    // nicht ausgeglichene verluste ohne aktien
    withoutStocks = withoutStocks.sub(getDecimalOrSpecial(lfdNrFields, '1901201', '1901202'));
    
    // Inländische Kapitalerträge - davon Verluste
    withoutStocks = withoutStocks.sub(getDecimal(lfdNrFields, '1901802'));
    
    // Inländische Kapitalerträge - davon Gewinne Aktienveräußerungen
    withoutStocks = withoutStocks.sub(getDecimal(lfdNrFields, '1901701'));
    
    // Inländische Kapitalerträge
    withoutStocks = withoutStocks.sub(getDecimal(lfdNrFields, '1901501'));
    
    // Zinsen auf Steuererstattungen von FA
    withoutStocks = withoutStocks.add(getDecimal(lfdNrFields, '1902001'));

    // Foreign investment income
    const foreignInvestmentIncome = getDecimal(lfdNrFields, '1901702');
    logger.debug('Foreign investment income', toString(foreignInvestmentIncome));

    withoutStocks = withoutStocks.add(foreignInvestmentIncome);

    logger.debug('2 withoutStocks', toString(withoutStocks));

    // XXX remove for now because we don't have any values from prior years
    // let stocks = new Decimal(0);
    // // gewinne aktienverkäufe
    // stocks = stocks.add(getDecimalOr(lfdNrFields, '1900901', '1900902'));
    // // nicht ausgeglichene verluste aktien (plus, da die werte negativ sind)
    // stocks = stocks.add(getDecimalOr(lfdNrFields, '1901301', '1901302'));
    // // Inländische Kapitalerträge - davon Aktienverluste (plus, da die werte negativ sind)
    // stocks = stocks.add(getDecimal(lfdNrFields, '1901903'));

    // stocks = Decimal.max(stocks, 0);
    // logger.debug('stocks',toNumber(stocks));

    // let capitalGainsAbs1 = withoutStocks.add(stocks);
    let capitalGainsAbs1 = new Decimal(withoutStocks);
    
    let exemptAmount = new Decimal(801);
    // In Anspruch genommener Sparer-Pauschbetrag - nicht angegebene Kapitalerträge
    exemptAmount = exemptAmount.sub(getDecimal(lfdNrFields, '1901402'));
    // capitalGains = capitalGains.sub(exemptAmount);
    logger.debug('0) exemptAmount', toString(getDecimal(lfdNrFields, '1901402')));
    logger.debug('1) exemptAmount', toString(exemptAmount));

    let exemptAmountLeft = exemptAmount.sub(capitalGainsAbs1);
    exemptAmountLeft = Decimal.max(exemptAmountLeft, 0);
    openExemptAmount = openExemptAmount.add(exemptAmountLeft);

    logger.debug('offen nach Freibetrag', toString(openExemptAmount));

    let capitalGainsPaid = new Decimal(0);

    // Steuerabzugsbeträge: Kapitalertragsteuer - lt. Bescheinigung
    capitalGainsPaid = capitalGainsPaid.add(getDecimal(lfdNrFields, '1904701'));
    // Steuerabzugsbeträge: Kapitalertragsteuer - Beteiligungen
    capitalGainsPaid = capitalGainsPaid.add(getDecimal(lfdNrFields, '1904702'));
    // Anzurechnende Steuern: Kapitalertragsteuer - lt. Bescheinigung
    capitalGainsPaid = capitalGainsPaid.add(getDecimal(lfdNrFields, '1905502'));
    // Anzurechnende Steuern: Kapitalertragsteuer - Beteiligungen
    capitalGainsPaid = capitalGainsPaid.add(getDecimal(lfdNrFields, '1905301'));
    
    const newthing = getDecimal(lfdNrFields, '1902302');
    capitalGainsAbs1 = capitalGainsAbs1.add(getPercent(newthing, 50));

    logger.debug('capitalGainsPaid', toString(capitalGainsPaid));

    perPerson.push({
      capitalGainsAbs1,
      capitalGainsAbs2And6: capitalGainsAbs1,
      exemptAmount,
      capitalGainsPaid
    });
  });

  let joint = getBool(fields, '0101201', 'X');

  // found data for a second person but we're not doing a joint declaration
  // -> reset second person + the open exemptamount
  if (!joint && perPerson[1] != null) {
    perPerson[1] = {
      capitalGainsAbs1: new Decimal(0),
      capitalGainsAbs2And6: new Decimal(0),
      exemptAmount: new Decimal(0),
      capitalGainsPaid: new Decimal(0)
    };
    openExemptAmount = new Decimal(0);
  }

  // no capital gains found
  if (perPerson.length === 0) {
    perPerson[0] = {
      capitalGainsAbs1: new Decimal(0),
      capitalGainsAbs2And6: new Decimal(0),
      exemptAmount: new Decimal(0),
      capitalGainsPaid: new Decimal(0)
    };

    if (joint) {
      perPerson[1] = {
        capitalGainsAbs1: new Decimal(0),
        capitalGainsAbs2And6: new Decimal(0),
        exemptAmount: new Decimal(0),
        capitalGainsPaid: new Decimal(0)
      };  
    }
  }

  perPerson.forEach((person, index) => {
    let total = new Decimal(0);
    total = total.add(person.capitalGainsAbs1);

    logger.debug('person.capitalGains', toString(total));

    total = total.sub(person.exemptAmount);
    logger.debug('person.capitalGains - freibetrag', toString(total));

    if (total.gt(0) && openExemptAmount.gt(0)) {
      total = total.sub(openExemptAmount);
    }

    if (total.lt(0)) {
      person.exemptAmount = person.exemptAmount.sub(total);
      // openExemptAmount = openExemptAmount.sub(total);
      total = new Decimal(0);
    }

    person.total = total;

    person.tax = new Decimal(0);
    person.churchTax = new Decimal(0);
    
    // TODO baseRate could be higher if incomeTaxRate is lower than 25%
    let baseRate = new Decimal(4);
    logger.debug('total', toString(total));
    let church = chruchInfo[index];
    logger.debug('church', church);
    if (typeof church !== 'undefined') {
      let churchRate = new Decimal(church.churchTaxRate)
                        .div(100)
                        .times(new Decimal(church.monthsToPay).div(12));
      logger.debug('churchRate', toString(churchRate));
      person.tax = total.div(baseRate.add(churchRate)); 
      
      if (church.hasToPayChurchTax) {
        person.churchTax = getPercent(person.tax, church.churchTaxRate);
      }
    // person not in church
    } else {
      person.tax = total.div(baseRate); 
    }

    logger.debug('person.tax', toString(person.tax));
  });


  return perPerson;
}

module.exports = capitalGains2017;
