// @flow

import BaseAnswer from './Base';
import { FormatterError } from '../error';
import type { DurationRange } from './Duration';
import type { ValidationFunction, ValidationSchema } from '../flowtypes';

export type PayrollDataAnswer = {
  eTin?: ?string,
  taxClass: string,
  duration: ?DurationRange,
  '3': number,
  '4': number,
  '5': number,
  '6': number,
  '7': number,
  '10': number,
  '11': number,
  '12': number,
  '13': number,
  '14': number,
  '15': number,
  '17': number,
  '18': number,
  '19': number,
  '20': number,
  '21': number,
  '22a': number,
  '22b': number,
  '23a': number,
  '23b': number,
  '24a': number,
  '24b': number,
  '24c': number,
  '25': number,
  '26': number,
  '27': number,
  '28': number,
  '33': number,
};

class PayrollData extends BaseAnswer {
  constructor() {
    const formats = [
      { name: 'ETIN', type: 'string' },
      { name: 'TAX_CLASS', type: 'string' },
      { name: 'FIELD_3', type: 'number' },
      { name: 'FIELD_4', type: 'number' },
      { name: 'FIELD_5', type: 'number' },
      { name: 'FIELD_6', type: 'number' },
      { name: 'FIELD_7', type: 'number' },
      { name: 'FIELD_10', type: 'number' },
      { name: 'FIELD_11', type: 'number' },
      { name: 'FIELD_12', type: 'number' },
      { name: 'FIELD_13', type: 'number' },
      { name: 'FIELD_14', type: 'number' },
      { name: 'FIELD_15', type: 'number' },
      { name: 'FIELD_17', type: 'number' },
      { name: 'FIELD_18', type: 'number' },
      { name: 'FIELD_19', type: 'number' },
      { name: 'FIELD_20', type: 'number' },
      { name: 'FIELD_21', type: 'number' },
      { name: 'FIELD_22A', type: 'number' },
      { name: 'FIELD_22B', type: 'number' },
      { name: 'FIELD_23A', type: 'number' },
      { name: 'FIELD_23B', type: 'number' },
      { name: 'FIELD_24A', type: 'number' },
      { name: 'FIELD_24B', type: 'number' },
      { name: 'FIELD_24C', type: 'number' },
      { name: 'FIELD_25', type: 'number' },
      { name: 'FIELD_26', type: 'number' },
      { name: 'FIELD_27', type: 'number' },
      { name: 'FIELD_28', type: 'number' },
      { name: 'FIELD_33', type: 'number' },
    ];

    super('payroll_data', formats);
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/payroll_data.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/payroll_data');
    /* eslint-enable global-require */
  }

  format(value: any, formatKey: string): string | number {
    super.format(value);
    const payrollData: PayrollDataAnswer = value;

    switch (formatKey) {
      case this.formats.ETIN:
        return payrollData.eTin == null ? '' : payrollData.eTin;
      case this.formats.TAX_CLASS:
        return payrollData.taxClass;
      case this.formats.FIELD_3:
        return payrollData['3'];
      case this.formats.FIELD_4:
        return payrollData['4'];
      case this.formats.FIELD_5:
        return payrollData['5'];
      case this.formats.FIELD_6:
        return payrollData['6'];
      case this.formats.FIELD_7:
        return payrollData['7'];
      case this.formats.FIELD_10:
        return payrollData['10'];
      case this.formats.FIELD_11:
        return payrollData['11'];
      case this.formats.FIELD_12:
        return payrollData['12'];
      case this.formats.FIELD_13:
        return payrollData['13'];
      case this.formats.FIELD_14:
        return payrollData['14'];
      case this.formats.FIELD_15:
        return payrollData['15'];
      case this.formats.FIELD_17:
        return payrollData['17'];
      case this.formats.FIELD_18:
        return payrollData['18'];
      case this.formats.FIELD_19:
        return payrollData['19'];
      case this.formats.FIELD_20:
        return payrollData['20'];
      case this.formats.FIELD_21:
        return payrollData['21'];
      case this.formats.FIELD_22A:
        return payrollData['22a'];
      case this.formats.FIELD_22B:
        return payrollData['22b'];
      case this.formats.FIELD_23A:
        return payrollData['23a'];
      case this.formats.FIELD_23B:
        return payrollData['23b'];
      case this.formats.FIELD_24A:
        return payrollData['24a'];
      case this.formats.FIELD_24B:
        return payrollData['24b'];
      case this.formats.FIELD_24C:
        return payrollData['24c'];
      case this.formats.FIELD_25:
        return payrollData['25'];
      case this.formats.FIELD_26:
        return payrollData['26'];
      case this.formats.FIELD_27:
        return payrollData['27'];
      case this.formats.FIELD_28:
        return payrollData['28'];
      case this.formats.FIELD_33:
        return payrollData['33'];

      default:
        break;
    }

    throw new FormatterError(`Unknown format: ${formatKey}`);
  }
}

export default new PayrollData();
