'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _State = require('./State');

var _State2 = _interopRequireDefault(_State);

var _renderProps = require('../utils/renderProps');

var _renderProps2 = _interopRequireDefault(_renderProps);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var Active = function Active(_ref) {
  var onChange = _ref.onChange,
      props = _objectWithoutProperties(_ref, ['onChange']);

  return _react2.default.createElement(
    _State2.default,
    { initial: { isActive: false }, onChange: onChange },
    function (_ref2) {
      var state = _ref2.state,
          setState = _ref2.setState;
      return (0, _renderProps2.default)(props, {
        isActive: state.isActive,
        bindActive: {
          onMouseDown: function onMouseDown() {
            return setState({ isActive: true });
          },
          onMouseUp: function onMouseUp() {
            return setState({ isActive: false });
          }
        }
      });
    }
  );
};

exports.default = Active;