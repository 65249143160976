'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _compose = require('../utils/compose');

var _compose2 = _interopRequireDefault(_compose);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var Compose = function Compose(_ref) {
  var states = _ref.states,
      props = _objectWithoutProperties(_ref, ['states']);

  return _compose2.default.apply(undefined, _toConsumableArray(states))(props);
};

exports.default = Compose;