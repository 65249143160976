'use strict';

const Decimal = require('decimal.js');
const { TaxYears } = require('@taxfix/de-itc-types');
const { default: elsterFormatter } = require('@taxfix/elster-formatter'); // jshint ignore:line
const postProcessing = require('@taxfix/taxml-post-processing');

const { getDecimal } = require('./utils/utils_fields');
const { percent: getPercent, toNumber } = require('./utils/utils_decimal');
const { calculateOtherIncome } = require('./base/otherIncome');

const taxDeductions = require('./base/taxDeductions');
const solidaritySurcharge = require('./base/solidaritySurcharge');
const incomeTaxCalculator = require('./base/incomeTaxCalculator');
const earningsFromEmployment = require('./base/earningsFromEmployment');
const getChurchInfo = require('./base/churchInfo');
const calculateCapitalGains = require('./base/capitalGains');
const getSingleParentAllowances = require('./base/allowancesSingleParent');
const getChildrenAllowances = require('./base/allowancesChildren');
const getSpecialExpenses = require('./base/specialExpenses');
const calculateIncomeSubjectToProgressionTax = require('./base/incomeSubjectToProgressionTax');
const getExceptionalExpenses = require('./base/exceptionalExpenses');
const getPayrollTaxPrepayments = require('./base/payrollTaxPrepayments');
const getIncomeTaxPrepayments = require('./base/incomeTaxPrepayments');
const getChurchTaxPrepayments = require('./base/churchTaxPrepayments');
const calculateChurchTax = require('./base/churchTax');
const calculateTaxLossDeduction = require('./base/taxLossDeduction');
const calculateTaxLoss = require('./base/taxLoss');
const calculateITC = require('./base/calculateIncomeTax');
const calculateSpecialExpenses = require('./base/calculateSpecialExpenses');
const calculateMobilityBonus = require('./base/calculateMobilityBonus');
const getLumSum = require('./base/getLumSum');
const determineTaxType = require('./tax_type');

function calculatorForIncomeTax(fields, taxYear, detailedResponse = false) {
  const ZERO = new Decimal(0);
  const lumpsums = getLumSum(taxYear);
  const calculateIncomeTax = (income) => calculateITC(income, taxYear);

  if (!Array.isArray(fields) || fields.length === 0) {
    throw new Error('we need fields to calculate income tax');
  }

  // Fetch this value for the taxLoss later
  const saleOfPropertyForTaxLoss = getDecimal(fields, '0307801');

  fields = postProcessing(lumpsums.year, fields);
  fields = elsterFormatter(lumpsums.year, fields);

  let tax = ZERO;

  /**
   * 1.1 Earnings from Employment per person
   * - Gross salary per person
   * - Work related expenses
   */
  let {
    personsEarnings,
    grossSalary,
    grossSalaryPersonA,
    grossSalaryPersonB,
    workEquipment,
    workEquipmentPersonA,
    workEquipmentPersonB,
    homeOfficeLumpSumPersonA,
    homeOfficeLumpSumPersonB
  } = earningsFromEmployment(fields, lumpsums.year);

  let incomeWithoutProfessionalExpensesPerPerson = [];
  let incomePerPerson = [];
  let income = ZERO;

  let incomeReducedTaxRate = ZERO;
  let incomeNotPayrollTax = ZERO;

  personsEarnings.forEach(person => {
    let i = ZERO;

    i = i.add(person.revenue);
    i = i.add(person.reducedTaxRate);
    i = i.add(person.notPayrollTax);
    i = i.add(person.extraIncomePerPerson);

    incomeWithoutProfessionalExpensesPerPerson.push(new Decimal(i));

    i = i.sub(person.professionalExpenses);

    incomeReducedTaxRate = incomeReducedTaxRate.add(person.reducedTaxRate);
    incomeNotPayrollTax = incomeNotPayrollTax.add(person.notPayrollTax);

    incomePerPerson.push(i);
    income = income.add(i);
  });

  let churchInfoPerPerson = getChurchInfo(fields);

  // 1.2 Earnings from capital gains / savings
  let capitalGainsPerPerson = calculateCapitalGains(fields, churchInfoPerPerson, lumpsums.year);

  let capitalGainsAbs2And6 = ZERO;
  let capitalGainsTax = ZERO;
  let capitalGainsChurchTax = ZERO;
  let capitalGainsPaid = ZERO;
  let capitalGainsTotal = ZERO;

  capitalGainsPerPerson.forEach(person => {
    capitalGainsAbs2And6 = capitalGainsAbs2And6.add(person.capitalGainsAbs2And6);
    capitalGainsTax = capitalGainsTax.add(person.tax);
    capitalGainsChurchTax = capitalGainsChurchTax.add(person.churchTax);
    capitalGainsPaid = capitalGainsPaid.add(person.capitalGainsPaid).floor();
    capitalGainsTotal = capitalGainsTotal.add(person.total);
  });

  /**
   * Capital gain tax needs to be rounded down before being used
   */
  capitalGainsTax = capitalGainsTax.toDecimalPlaces(0, Decimal.ROUND_DOWN);

  /**
   * 1.4 Earnings from other income
   */
  let {
    pensionIncome,
    otherIncomePerPerson,
    incomePrivateSalesPerPerson,
    incomePerAlimonyPerPerson,
    incomeFromBenefitsPerPerson,
    incomePrivateSales,
    incomePerAlimony,
    incomeFromBenefits,
  } = calculateOtherIncome(lumpsums.year, fields);
  let otherIncome = otherIncomePerPerson.reduce((a, b) => a.add(b));

  let sumOfAllIncomePerPerson = [];
  let sumOfAllIncome = ZERO;

  incomePerPerson.forEach((ipp, index) => {
    let sum = new Decimal(ipp);
    if (otherIncomePerPerson[index] != null) {
      sum = sum.add(otherIncomePerPerson[index]);
    }

    sumOfAllIncomePerPerson.push(sum);
    sumOfAllIncome = sumOfAllIncome.add(sum);
  });

  /**
   * 2.2 Allowance for single parents
   */
  let allowancesSingleParent = getSingleParentAllowances(fields, lumpsums);

  /**
   * Total amount of income
   */
  let totalIncome = ZERO;
  if (allowancesSingleParent && sumOfAllIncome.lt(0)) {
    // allowancesSingleParent cannot be deducted from negagiv income
    totalIncome = sumOfAllIncome;
    allowancesSingleParent = ZERO;
  } else {
    totalIncome = sumOfAllIncome.sub(allowancesSingleParent);
  }
  
  let taxableIncome = new Decimal(totalIncome);
  /**
   * Loss deductions(loss from previous years)
   * Subtract the tax loss deduction from totalIncome = taxableIncome,
   * not to change the value of totalIncome for exeptional expenses lalter. 
   * totalIncome and taxableIncome can go below zero.
   */
  let taxLossDeduction = calculateTaxLossDeduction(fields, lumpsums.year);
  if (taxLossDeduction.lt(taxableIncome)) {
    taxableIncome = taxableIncome.sub(taxLossDeduction);
  } else {
    taxableIncome = new Decimal(totalIncome);
  }

  // Tax Loss Calculation
  let taxLoss = calculateTaxLoss(fields, incomePerPerson, saleOfPropertyForTaxLoss, lumpsums.year);
  if (taxLossDeduction.gt(totalIncome)) {
    // taxLossDeduction is deductable until totalIncome becomes 0
    // the remaining will be carried forward to the next year
    taxLoss.overall = totalIncome.sub(taxLossDeduction);
    taxLossDeduction = totalIncome;

    if (totalIncome.lt(new Decimal(0))) {
      // taxLossDeduction is not deductable in that year.
      taxLossDeduction = ZERO;
    }
  } else {
    taxLoss.overall = ZERO;
  }

  /**
   * Convert to number. I don't know why,
   * but this is the current behaviour
   */
  taxLoss.overall = toNumber(taxLoss.overall);

  /**
   * Provision cost(insurances) and special expenses
   */
  let {
    churchTaxSpecialExpense,
    riester,
    riesterAllowances,
    otherSpecialExpenses,
    donations,
    donationsPolitical,
    specialExpenses,
    provisionCost,
    churchTaxAsIncome,
    healthInsuranceSumP10Abs1Nr3,
    healthInsuranceReimbursements,
    erstattungsuberhang10Abs4b,
    erstattungsuberhang,
    additionalHealthInsuranceA,
    additionalHealthInsuranceB,
    foreignKVA,
    foreignKVB,
    foreignNoClaimSickPayA,
    foreignNoClaimSickPayB,
    childKV,
    pensionInsurancePaidByEmployee,
    pensionInsurancePaidByEmployer,
    pensionInsurance,
    pensionInsurancePercentage,
    pensionInsuranceBeforeMax,
    pensionInsuranceProportionalMaxValue,
    healthInsurancePaidEmployee,
    healthInsuranceCut4Percent,
    healthInsuranceAfterCut,
    socialCareInsurance,
    steuerfreieAGZuschuesse: steuerfreieAgZuschuesse,
    healthInsuranceSum,
    healthInsurance,
    childcareCost: specialExpensesChildcare,
    specialExpensesAlimony,
    churchTaxSpecialExpense: specialExpensesChurchTaxRefund,
    specialExpensesInitialEducation,
    specialExpensesDonations,
    specialExpensesSchoolFees,
    healthInsuranceSumForAnzuHoch,
    additionalInsurances,
  } = getSpecialExpenses(fields, totalIncome, incomeWithoutProfessionalExpensesPerPerson, lumpsums);

  taxableIncome = taxableIncome.sub(specialExpenses);

  /**
   * Add Erstattungsüberhänge nach §10 Abs. 4b S. 3 EStG to taxableIncome
   */
  taxableIncome = taxableIncome.add(erstattungsuberhang);

  /*totalIncome = totalIncome.sub(capitalGainsTotal);
  taxableIncome = taxableIncome.sub(capitalGainsTotal);*/

  // TODO 5 incomeSubjectToProgression
  let {
    total: incomeSubjectToProgression,
    foreignIncome,
    incomeReplacementBenefits,
  } = calculateIncomeSubjectToProgressionTax(
    fields,
    personsEarnings,
    lumpsums.incomeProgression.werbungskostenpauschbetrag,
    lumpsums.year
  );

  // moved calculation of deductions here b/c we need them for "allowances kids"
  // deductions35c was added in 2020
  let { deductions35a, deductions34g, deductions35c, deductions} = taxDeductions({
    fields,
    taxYear: lumpsums.year,
    donationsPolitical
  });

  /**
   * Capital Gains Günstigerprüfung
   */
  let { incomeTax: capitalGainsAndTaxableIncomeTax } = incomeTaxCalculator(
    fields,
    taxableIncome.add(capitalGainsTotal),
    incomeReducedTaxRate,
    incomeSubjectToProgression,
    calculateIncomeTax
  );

  let { incomeTax: taxableIncomeTax } = incomeTaxCalculator(
    fields,
    taxableIncome,
    incomeReducedTaxRate,
    incomeSubjectToProgression,
    calculateIncomeTax
  );

  // 2020: deductions35a -> deductions
  const amountToDeduct = taxYear > TaxYears['2k19'] ? deductions : deductions35a;
  // Abgeltungssteuer
  const capitalGainsTaxCalculated = getPercent(capitalGainsTotal, 25);
  const taxCalculatedSeparatedly = taxableIncomeTax.add(capitalGainsTaxCalculated).sub(amountToDeduct);
  capitalGainsAndTaxableIncomeTax = capitalGainsAndTaxableIncomeTax.sub(amountToDeduct);

  let incomeForExceptionalExpense = totalIncome;
  let isCapitalGainsIncomeIncludedInTotalIncome = false;
  if (taxCalculatedSeparatedly.gt(capitalGainsAndTaxableIncomeTax)) {
    isCapitalGainsIncomeIncludedInTotalIncome = true;
    taxableIncome = taxableIncome.add(capitalGainsTotal);
    incomeForExceptionalExpense = incomeForExceptionalExpense.add(capitalGainsTotal);
    capitalGainsTax = ZERO;
    capitalGainsPerPerson.forEach((person, index) => {
      let sum = ZERO;
      sum = sum.add(person.capitalGainsAbs2And6);
      sumOfAllIncomePerPerson[index] = sumOfAllIncomePerPerson[index].add(sum);
      sumOfAllIncome = sumOfAllIncome.add(sum);
    });
  }
  else{
    capitalGainsPerPerson.forEach(person => person.capitalGainsAbs2And6 = ZERO);
    capitalGainsAbs2And6 = ZERO;
  }
  totalIncome = totalIncome.add(capitalGainsAbs2And6);

  /**
   * 3.3 Exceptional expenses
   */
  let {
    exceptionalExpenses,
    sumExtraordinary,
    deductibleExtraordinary,
    disabilityLumpsum,
    adultChildTrainingLumpsum,
  } = getExceptionalExpenses(fields, incomeForExceptionalExpense, lumpsums);
  taxableIncome = taxableIncome.sub(exceptionalExpenses);

  if (erstattungsuberhang10Abs4b.lt(0)) {
    taxableIncome = taxableIncome.sub(erstattungsuberhang10Abs4b);
  }

  // 4 allowances for children
  let {
    exemptAmountSum: allowancesForChildrenExemptAmount,
    exemptAmountForTaxesSum: allowancesForChildrenExemptAmountForTaxes,
    childBenefitsSum: allowancesForChildrenChildBenefits
  } = getChildrenAllowances(fields, lumpsums);

  // income tax (tarifliche Einkommensteuer lt. Grund-/Splittingtarif)
  let { incomeTax: tariffIncomeTax } = incomeTaxCalculator(
    fields,
    taxableIncome,
    incomeReducedTaxRate,
    incomeSubjectToProgression,
    calculateIncomeTax
  );

  // Riester Günstigerprüfung
  let incomeMinusRiester = taxableIncome.add(riester);
  let { incomeTax: riesterIncomeTax2 } = incomeTaxCalculator(
    fields,
    incomeMinusRiester,
    incomeReducedTaxRate,
    incomeSubjectToProgression,
    calculateIncomeTax
  );
  // we have to take the deductions into account b/c they could lead to
  // income tax being 0
  let riesterIncomeTax2MinusDeductions = riesterIncomeTax2.sub(deductions);
  riesterIncomeTax2MinusDeductions = Decimal.max(riesterIncomeTax2MinusDeductions, 0);

  let tariffIncomeTaxMinusDeductions = tariffIncomeTax.sub(deductions);
  tariffIncomeTaxMinusDeductions = Decimal.max(tariffIncomeTaxMinusDeductions, 0);

  let riesterIncomeTaxDiff = riesterIncomeTax2MinusDeductions.sub(tariffIncomeTaxMinusDeductions);

  if (riesterIncomeTaxDiff.lt(riesterAllowances)) {
    // they were subtracted before, so re-add them
    taxableIncome = taxableIncome.add(riester);
    // reset everything afterwards
    riester = ZERO;
    riesterAllowances = ZERO;
    tariffIncomeTax = riesterIncomeTax2;
  }

  let taxableIncomeAfterAllowances = new Decimal(taxableIncome);

  // Kinderfreibetrag Günstigerprüfung
  let incomeMinusAllowances = taxableIncome.sub(allowancesForChildrenExemptAmount);
  let { incomeTax: allowancesIncomeTax } = incomeTaxCalculator(
    fields,
    incomeMinusAllowances,
    incomeReducedTaxRate,
    incomeSubjectToProgression,
    calculateIncomeTax
  );

  let savingsAllowances = tariffIncomeTax.sub(allowancesIncomeTax);

  let guenstigerpruefungKind;

  if (savingsAllowances.gt(allowancesForChildrenChildBenefits)) {
    guenstigerpruefungKind = 'freibetrag';
    taxableIncomeAfterAllowances = taxableIncome.sub(allowancesForChildrenExemptAmount);
    tariffIncomeTax = allowancesIncomeTax;
  } else {
    if (allowancesForChildrenExemptAmount.gt(0)) {
      guenstigerpruefungKind = 'kindergeld';
    }
    savingsAllowances = ZERO;
    allowancesForChildrenExemptAmount = ZERO;
    allowancesForChildrenChildBenefits = ZERO;
  }

  // 6.1 tax deductions
  let assessedIncomeTax = tariffIncomeTax.sub(deductions);

  // tax deductions can't reduce tax below 0€
  assessedIncomeTax = Decimal.max(assessedIncomeTax, 0);

  if (taxCalculatedSeparatedly.lt(capitalGainsAndTaxableIncomeTax)) {
    assessedIncomeTax = assessedIncomeTax.add(capitalGainsTax);
  }

  assessedIncomeTax = assessedIncomeTax.add(riesterAllowances);

  assessedIncomeTax = assessedIncomeTax.add(allowancesForChildrenChildBenefits).floor();

  // calculate difference in assessed and prepaid values
  let difference = ZERO;

  // 7.1 payroll tax prepayments
  let payrollTaxPrepayments = getPayrollTaxPrepayments(fields);

  let incomeTaxDifference = assessedIncomeTax.sub(payrollTaxPrepayments);
  incomeTaxDifference = incomeTaxDifference.sub(capitalGainsPaid);
  
  // 7.2 income tax prepayments
  let incomeTaxPrepayment = getIncomeTaxPrepayments(fields);
  incomeTaxDifference = incomeTaxDifference.sub(incomeTaxPrepayment);
  
  difference = difference.add(incomeTaxDifference);

  // 8.1 solidarity surcharge from payroll
  let {
    soliAssessed,
    soliDifference,
    soliPaidPayroll,
    soliPaidCapitalGains,
    solidaritySurchargePrepayment
  } = solidaritySurcharge({
    fields,
    lumpsums,
    taxableIncome,
    capitalGainsTax,
    riesterAllowances,
    calculateIncomeTax,
    incomeReducedTaxRate,
    incomeSubjectToProgression,
    taxDeductions: deductions,
    taxYear: lumpsums.year,
    isCapitalGainsIncomeIncludedInTotalIncome,
    allowancesForChildrenExemptAmountForTaxes,
  });
  
  difference = difference.add(soliDifference);

  // 8.2 church tax
  let {
    churchTaxPayroll,
    churchTaxCapitalGains,
    churchTaxPaidPayroll,
    churchTaxPaidCapitalGains
  } = calculateChurchTax(
    fields,
    churchInfoPerPerson,
    taxableIncome,
    allowancesForChildrenExemptAmountForTaxes,
    capitalGainsTax,
    sumOfAllIncomePerPerson,
    guenstigerpruefungKind,
    deductions,
    riesterAllowances,
    incomeReducedTaxRate,
    incomeSubjectToProgression,
    lumpsums.year
  );

  let churchTax = churchTaxPayroll.add(churchTaxCapitalGains);
  let churchTaxDifference = churchTax.sub(churchTaxPaidPayroll);
  churchTaxDifference = churchTaxDifference.sub(churchTaxPaidCapitalGains);
  
  // 8.3 church tax prepayment
  let churchTaxPrepayment = getChurchTaxPrepayments(fields);
  churchTaxDifference = churchTaxDifference.sub(churchTaxPrepayment);

  tax = tax.add(difference);
  tax = tax.add(churchTaxDifference);

  const isTaxLoss = taxLoss.overall < 0;

  let taxableIncomeIncludingReimbursement = taxableIncome;

  if (erstattungsuberhang10Abs4b.lt(0)) {
    taxableIncomeIncludingReimbursement = taxableIncome.sub(erstattungsuberhang10Abs4b);
  }

  taxableIncome = taxableIncome.floor();

  // churchTaxAsIncome = churchTaxAsIncome.add(healthInsuranceSumP10Abs1Nr3);
  // provisionCost = provisionCost.sub(healthInsuranceSumP10Abs1Nr3);

  const result = {
    churchInfoPerPerson,

    personsEarnings,
    incomeWithoutProfessionalExpensesPerPerson,
    incomePerPerson,
    income,
    incomePrivateSalesPerPerson,
    incomePerAlimonyPerPerson,
    incomeFromBenefitsPerPerson,
    incomePrivateSales,
    incomePerAlimony,
    incomeFromBenefits,
    grossSalary,
    grossSalaryPersonA,
    grossSalaryPersonB,
    workEquipment,
    workEquipmentPersonA,
    workEquipmentPersonB,
    homeOfficeLumpSumPersonA,
    homeOfficeLumpSumPersonB,

    capitalGainsPerPerson,
    capitalGainsAbs2And6,
    capitalGainsTax,
    capitalGainsChurchTax,
    capitalGainsPaid,

    pensionIncome,
    otherIncomePerPerson,
    otherIncome,
    sumOfAllIncomePerPerson,
    sumOfAllIncome,
    allowancesSingleParent,
    allowancesForChildrenExemptAmount,
    allowancesForChildrenChildBenefits,
    totalIncome,
    taxableIncomeIncludingReimbursement,

    exceptionalExpenses,
    taxableIncome,
    taxableIncomeAfterAllowances,

    provisionCost,
    churchTaxSpecialExpense,
    donations,
    riester,
    riesterAllowances,
    otherSpecialExpenses,
    churchTaxAsIncome,
    healthInsuranceReimbursements,
    erstattungsuberhang10Abs4b,

    deductions35a,
    deductions34g,
    deductions35c,
    deductions,

    payrollTaxPrepayments,
    incomeTaxPrepayment,
    solidaritySurchargePrepayment,
    churchTaxPrepayment,

    soliAssessed,
    soliPaidPayroll,
    soliPaidCapitalGains,
    soliDifference,

    difference,

    churchTax,
    churchTaxPayroll,
    churchTaxCapitalGains,
    churchTaxPaidPayroll,
    churchTaxPaidCapitalGains,
    churchTaxDifference,

    tariffIncomeTax,
    assessedIncomeTax,
    incomeTaxDifference,
    tax,
    taxLoss,
    taxLossDeduction,

    isTaxLoss,

    capitalGainsTotal,
    erstattungsuberhang,

    additionalHealthInsuranceA,
    additionalHealthInsuranceB,

    foreignKVA,
    foreignKVB,

    childKV,

    foreignNoClaimSickPayA,
    foreignNoClaimSickPayB,

    incomeSubjectToProgression,
    foreignIncome,
    incomeReplacementBenefits,

    healthInsurancePaidEmployee,
    healthInsuranceCut4Percent,
    healthInsuranceAfterCut,
    socialCareInsurance,
    steuerfreieAgZuschuesse,
    healthInsuranceSum,
    healthInsurance,
    healthInsuranceSumP10Abs1Nr3,
    healthInsuranceSumForAnzuHoch,
    additionalInsurances,

    pensionInsurancePaidByEmployee,
    pensionInsurancePaidByEmployer,
    pensionInsurance,
    pensionInsuranceBeforeMax,
    pensionInsuranceProportionalMaxValue,
    pensionInsurancePercentage,

    specialExpensesChildcare,
    specialExpensesAlimony,
    specialExpensesChurchTaxRefund,
    specialExpensesInitialEducation,
    specialExpensesDonations,
    specialExpensesSchoolFees,

    sumExtraordinary,
    deductibleExtraordinary,
    disabilityLumpsum,
    adultChildTrainingLumpsum,
  };

  if (typeof detailedResponse === 'undefined' || detailedResponse === false) {
    return result.tax.toDP(2).toNumber();
  } else {
    result.specialExpenses = calculateSpecialExpenses(fields, result, lumpsums);

    /**
     * Calculate mobility bonus
     */
    result.mobilityBonus = calculateMobilityBonus(
      fields,
      result.taxableIncome,
      result.personsEarnings,
      lumpsums.year
    );

    /**
     * Add mobility bonus to tax result if
     * mobilityBonus exist and is greater than ZERO
     */
    if (result.mobilityBonus && result.mobilityBonus.totalMobilityBonus.gt(new Decimal(0))) {
      /**
       * Subtraction here is acting more like add.
       * The reason why we use sub is that a negative
       * value for tax is a refund while a positive value
       * for tax is a shortfall.
       */
      result.tax = result.tax.sub(result.mobilityBonus.totalMobilityBonus);
    }

    // Determine the tax type from the tax result
    result.taxType = determineTaxType(result.tax);

    // Make the tax a number
    result.tax = result.tax.toDP(2).toNumber();

    return result;
  }
}

module.exports = calculatorForIncomeTax;
