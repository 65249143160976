// @flow

import type {
  TreeNode,
} from './types';

// If it has a response, it's probably a question. Maybe one day we'll add types in properly.
const isQuestion = (node: TreeNode) => (
  node !== null &&
  typeof node === 'object' &&
  node.response != null
);

export default isQuestion;
