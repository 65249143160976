'use strict';

const Decimal = require('decimal.js');

const logger = require('../logger');
const incomeTaxCalculator = require('../base/incomeTaxCalculator');
const { percent: getPercent } = require('../utils/utils_decimal');
const { getBool } = require('../utils/utils_fields');
const { toString } = require('../utils/utils_logs');
const { getDecimal, getLfdNrGroupsByNrs, iterateLfNr } = require('../utils/utils_fields');

function churchTax2016(
  fields,
  churchInfo,
  taxableIncome,
  allowancesForChildrenExemptAmountForTaxes,
  capitalGainsTax,
  totalIncomePerPerson,
  guenstigerpruefungKind,
  taxDeductions,
  riesterAllowances,
  incomeReducedTaxRate,
  incomeSubjectToProgression,
  calculateIncomeTax
) {
  let tax = new Decimal(0);
  logger.debug('\n\t-----------------\n\tCHURCH TAX');

  logger.debug('churchInfo', churchInfo);

  if (!churchInfo[0].hasToPayChurchTax && !churchInfo[1].hasToPayChurchTax) {
    return {
      assessmentBaseLumpsumChild: new Decimal(0),
      assessmentBaseTaxableIncome: new Decimal(0),
      assessmentBaseIncomeTax: new Decimal(0),
      assessmentBaseRemainingIncomeTax: new Decimal(0),

      churchTaxRate: new Decimal(0),
      churchTaxPayroll: new Decimal(0),
      churchTaxCapitalGains: new Decimal(0),
      churchTaxPaidPayroll: new Decimal(0),
      churchTaxPaidCapitalGains: new Decimal(0)
    };
  }

  let income = new Decimal(taxableIncome);
  logger.debug('Einkommen', toString(income));
  logger.debug('allowancesForChildrenExemptAmountForTaxes', toString(allowancesForChildrenExemptAmountForTaxes));
  const lumpsumChild = new Decimal(allowancesForChildrenExemptAmountForTaxes);
  income = income.sub(lumpsumChild);
  logger.debug('Zu versteuerndes Einkommen nach § 51a Abs. 2 EStG', toString(income), toString(lumpsumChild));

  let { incomeTax } = incomeTaxCalculator(
    fields,
    income,
    incomeReducedTaxRate,
    incomeSubjectToProgression,
    calculateIncomeTax
  );

  incomeTax = incomeTax.sub(taxDeductions);
  logger.debug('2 incomeTax', toString(incomeTax));
  incomeTax = incomeTax.add(capitalGainsTax);
  logger.debug('3 incomeTax', toString(incomeTax));
  incomeTax = incomeTax.add(riesterAllowances);
  logger.debug('4 incomeTax', toString(incomeTax));

  // just to be overly correct we're adding capitalGainsTax (above) and now
  // subtracting it again
  let remainingIncomeTax = incomeTax.sub(capitalGainsTax);
  logger.debug('remainingIncomeTax', toString(remainingIncomeTax));

  remainingIncomeTax = Decimal.max(remainingIncomeTax, 0);

  // do you have to pay all months?
  logger.debug('months => remainingIncomeTax', toString(remainingIncomeTax));

  let taxCapitalGains;
  const joint = getBool(fields, '0101201', 'X');

  if (joint) {
    let incomeTaxBoth = calculateIncomeTax(totalIncomePerPerson[0].add(totalIncomePerPerson[1]));
    let incomeTaxPersonA = calculateIncomeTax(totalIncomePerPerson[0]);
    let incomeTaxPersonB = calculateIncomeTax(totalIncomePerPerson[1]);

    logger.debug(
      'incomeTax A',
      toString(totalIncomePerPerson[0].add(totalIncomePerPerson[1])),
      toString(incomeTaxPersonA),
      toString(incomeTaxPersonB),
      toString(incomeTaxBoth),
    );

    // personA needs to pay, but not personB
    if (totalIncomePerPerson.length > 1 && churchInfo[0].hasToPayChurchTax && !churchInfo[1].hasToPayChurchTax) {
      logger.debug('A');
      remainingIncomeTax = remainingIncomeTax.times(churchInfo[0].monthsToPay).div(12);
      const portionOfA = incomeTaxPersonA.div(incomeTaxPersonA.add(incomeTaxPersonB)).times(100);
      let remainingIncomeTaxA = getPercent(remainingIncomeTax, portionOfA);
      tax = getPercent(remainingIncomeTaxA, churchInfo[0].churchTaxRate);

      let taxCapitalGainsA = getPercent(capitalGainsTax, portionOfA);
      taxCapitalGains = taxCapitalGainsA.times(churchInfo[0].monthsToPay).div(12);
    }
    // personB needs to pay, but not personA
    else if (totalIncomePerPerson.length > 1 && !churchInfo[0].hasToPayChurchTax && churchInfo[1].hasToPayChurchTax) {
      logger.debug('B');
      remainingIncomeTax = remainingIncomeTax.times(churchInfo[1].monthsToPay).div(12);
      const portionOfB = incomeTaxPersonB.div(incomeTaxPersonB.add(incomeTaxPersonA)).times(100);
      let remainingIncomeTaxB = getPercent(remainingIncomeTax, portionOfB);
      tax = getPercent(remainingIncomeTaxB, churchInfo[1].churchTaxRate);

      let taxCapitalGainsB = getPercent(capitalGainsTax, portionOfB);
      taxCapitalGains = taxCapitalGainsB.times(churchInfo[1].monthsToPay).div(12);
    }
    // both need to pay
    else {
      logger.debug('AB');
      remainingIncomeTax = remainingIncomeTax.times(churchInfo[0].monthsToPay).div(12);
      tax = getPercent(remainingIncomeTax, churchInfo[0].churchTaxRate);
      taxCapitalGains = getPercent(capitalGainsTax, churchInfo[0].churchTaxRate);
      taxCapitalGains = taxCapitalGains.times(churchInfo[0].monthsToPay).div(12);
    }
  } else {
    logger.debug('WTF');
    remainingIncomeTax = remainingIncomeTax.times(churchInfo[0].monthsToPay).div(12);
    tax = getPercent(remainingIncomeTax, churchInfo[0].churchTaxRate);
    taxCapitalGains = getPercent(capitalGainsTax, churchInfo[0].churchTaxRate);
    taxCapitalGains = taxCapitalGains.times(churchInfo[0].monthsToPay).div(12);
  }

  logger.debug('church tax', toString(tax));
  logger.debug('church taxCapitalGains', toString(taxCapitalGains));

  let taxPaidPayroll = new Decimal(0);

  // bereits bezahlte beträge I
  iterateLfNr(getLfdNrGroupsByNrs(fields,
    [
      '0200501',
      '0200503',
      '0200601',
      '0200603',
      '0201301',
      '0201302'
    ]
  ), (lfdNrGroups) => {
    // Kirchensteuer AN (Stkl. 1-5)
    taxPaidPayroll = taxPaidPayroll.add(getDecimal(lfdNrGroups, '0200501'));
    // Kirchensteuer AN (Stkl. 6 / Urlaubskasse)
    taxPaidPayroll = taxPaidPayroll.add(getDecimal(lfdNrGroups, '0200503'));
    // Kirchensteuer für Ehegatte (Stkl. 1-5)
    taxPaidPayroll = taxPaidPayroll.add(getDecimal(lfdNrGroups, '0200601'));
    // Kirchensteuer für Ehegatte (Stkl. 6 / Urlaubskasse)
    taxPaidPayroll = taxPaidPayroll.add(getDecimal(lfdNrGroups, '0200603'));
    // Entschädigungen / Arbeitslohn für mehrere Jahre: Kirchensteuer - Summe
    taxPaidPayroll = taxPaidPayroll.add(getDecimal(lfdNrGroups, '0201301'));
    // Entschädigungen / Arbeitslohn für mehrere Jahre: Kirchensteuer für Ehegatte - Summe
    taxPaidPayroll = taxPaidPayroll.add(getDecimal(lfdNrGroups, '0201302'));
  });

  let taxPaidCapitalGains = new Decimal(0);

  // bereits bezahlte beträge II
  iterateLfNr(getLfdNrGroupsByNrs(fields,
    [
      '1900601',
      '1904801',
      '1904802',
      '1905702',
      '1905401',
    ]
  ), (lfdNrGroups) => {
    // TODO (Ankreuzfeld) Stpfl. ist Mitglied der Kirche, aber (für Teile der 
    // Kapitalerträge) Kirchensteuer wurde nicht einbehalten 
    // 1900601

    // Steuerabzugsbeträge: Kirchensteuer - lt. Bescheinigung  
    taxPaidCapitalGains = taxPaidCapitalGains.add(getDecimal(lfdNrGroups, '1904801'));
    // Steuerabzugsbeträge: Kirchensteuer - Beteiligungen  
    taxPaidCapitalGains = taxPaidCapitalGains.add(getDecimal(lfdNrGroups, '1904802'));
    // Anzurechnende Steuern: Kirchensteuer - lt. Bescheinigung  
    taxPaidCapitalGains = taxPaidCapitalGains.add(getDecimal(lfdNrGroups, '1905702'));
    // Anzurechnende Steuern: Kirchensteuer - Beteiligungen  
    taxPaidCapitalGains = taxPaidCapitalGains.add(getDecimal(lfdNrGroups, '1905401'));
  });

  return {
    assessmentBaseLumpsumChild: lumpsumChild,
    assessmentBaseTaxableIncome: income,
    assessmentBaseIncomeTax: incomeTax,
    assessmentBaseRemainingIncomeTax: remainingIncomeTax,

    churchTaxRate: churchInfo[0].churchTaxRate,
    churchTaxPayroll: tax,
    churchTaxCapitalGains: taxCapitalGains,
    churchTaxPaidPayroll: taxPaidPayroll,
    churchTaxPaidCapitalGains: taxPaidCapitalGains
  };
}

module.exports = churchTax2016;
