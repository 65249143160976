// @flow
import inputResolver from './inputResolver';

import type {
  Cache,
  Id,
  Refs,
  Responses,
  TreeNode,
} from '../types';

type QuestionInputs = {
  [Id]: any,
};

const inputsForQuestion = (
  question: TreeNode,
  refs: Refs,
  responses: Responses,
  cache: Cache,
  year: number,
): QuestionInputs => {
  const map = question.inputs;

  if (map == null) {
    return {};
  }

  const out = {};

  Object.keys(map).forEach((key) => {
    if (map == null) {
      return;
    }

    const input = map[key];

    if (input != null) {
      out[key] = inputResolver(input, responses, map, refs, question.id, cache, year);
    }
  });

  return out;
};

export default inputsForQuestion;
