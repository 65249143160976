'use strict';


const { getReasonsForPerson } = require('./utils/refund-reasons');
const { taxMapping, refundReasons, taxTypes } = require('@taxfix/de-itc-types');

const version = require('./version');
const calc2015 = require('./calculator_2015');
const calc2016 = require('./calculator_2016');
const calc2017 = require('./calculator_2017');
const calc2018 = require('./calculator_2018');
const calc2019 = require('./calculator_2019');
const calc2020 = require('./calculator_2020');
const calc2021 = require('./calculator_2021');

function getCalculatorForYear(year) {
  if (typeof year === 'undefined') {
    throw new Error('year missing');
  }

  switch (year) {
    case 2015: return calc2015;
    case 2016: return calc2016;
    case 2017: return calc2017;
    case 2018: return calc2018;
    case 2019: return calc2019;
    case 2020: return calc2020;
    case 2021: return calc2021;
    default: throw new Error('unsupported year: ' + year);
  }
}

module.exports = {
  version,
  taxTypes,
  taxMapping,
  refundReasons,
  getReasonsForPerson,
  getCalculatorForYear,
};
