'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const { getDecimal } = require('../utils/utils_fields');
const { toString } = require('../utils/utils_logs');

function travelling2016(fields) {
  logger.debug('\n-------------------------\nTRAVELLING');
  let expenses = new Decimal(0);

  let sum1 = new Decimal(0);

  // 
  let travellingCost = getDecimal(fields, '0205518');
  logger.debug('travellingCost', toString(travellingCost));
  sum1 = sum1.add(travellingCost);

  // 
  let reimbursement1 = getDecimal(fields, '0205608');
  logger.debug('reimbursement1', toString(reimbursement1));
  sum1 = sum1.sub(reimbursement1);
  
  logger.debug('sum1', toString(sum1));
  expenses = expenses.add(sum1);

  let sum2 = new Decimal(0);

  // 
  let awayMoreThan8 = getDecimal(fields, '0205201');
  awayMoreThan8 = awayMoreThan8.times(12);
  logger.debug('        awayMoreThan8', toString(awayMoreThan8));
  sum2 = sum2.add(awayMoreThan8);

  // 
  let arrivalDepature = getDecimal(fields, '0205302');
  arrivalDepature = arrivalDepature.times(12);
  logger.debug('        arrivalDepature', toString(arrivalDepature));
  sum2 = sum2.add(arrivalDepature);

  // 
  let awayMoreThan24 = getDecimal(fields, '0205409');
  awayMoreThan24 = awayMoreThan24.times(24);
  logger.debug('        awayMoreThan24', toString(awayMoreThan24));
  sum2 = sum2.add(awayMoreThan24);

  // 
  let freeMeals = getDecimal(fields, '0205508');
  logger.debug('        freeMeals', toString(freeMeals));
  sum2 = sum2.sub(freeMeals);
  
  logger.debug('sum2', toString(sum2));
  expenses = expenses.add(sum2);

  let sum3 = new Decimal(0);

  // TODO check how these fields need to be treated
  // Auswärtstätigkeit Ausland - Aufwendungen Summe
  let abroadCost = getDecimal(fields, '0205630');
  logger.debug('abroadCost', toString(abroadCost));
  sum3 = sum3.add(abroadCost);

  // this is already subtracted in 0205630, no need to subtract it again
  // let abroadFreeMeals = getDecimal(fields, '0205629');
  // logger.debug('abroadFreeMeals',toNumber(abroadFreeMeals));
  // sum3 = sum3.sub(abroadFreeMeals);

  let reimbursement2 = getDecimal(fields, '0205108');
  logger.debug('reimbursement2', toString(reimbursement2));
  sum3 = sum3.sub(reimbursement2);
  
  logger.debug('sum3', toString(sum3));
  expenses = expenses.add(sum3);

  return expenses;
}

module.exports = travelling2016;
