'use strict';

const Decimal = require('decimal.js');

const { iterateLfNr, getLfdNrGroupsByNrs, getDecimal } = require('../utils/utils_fields');

function getPayrollTaxPrepayments(fields) {
  let prepayments = new Decimal(0);

  iterateLfNr(getLfdNrGroupsByNrs(fields, [
    '0200301', '0200303', '0201201'
  ]), lfdNrFields => {
    // Lohnsteuer (Stkl. 1-5)
    prepayments = prepayments.add(getDecimal(lfdNrFields, '0200301'));
    // Lohnsteuer (Stkl. 6 / Urlaubskasse)
    prepayments = prepayments.add(getDecimal(lfdNrFields, '0200303'));
    // Entschädigungen / Arbeitslohn für mehrere Jahre: Lohnsteuer - Summe
    prepayments = prepayments.add(getDecimal(lfdNrFields, '0201201'));
  });

  prepayments = prepayments.ceil();

  return prepayments;
}

module.exports = getPayrollTaxPrepayments;