'use strict';

const { TaxYears } = require('@taxfix/de-itc-types');

const taxLossDeduction2020 = require('../2020/tax_loss_deduction_2020');
const taxLossDeduction2019 = require('../2019/tax_loss_deduction_2019');
const taxLossDeduction2016 = require('../2016/tax_loss_deduction_2016');

function calculateTaxLossDeduction(fields, taxYear) {
  switch (taxYear) {
    case TaxYears['2k21']:
    case TaxYears['2k20']:
      return taxLossDeduction2020(fields);
    case TaxYears['2k19']:
      return taxLossDeduction2019(fields);
    default:
      return taxLossDeduction2016(fields);
  }
}

module.exports = calculateTaxLossDeduction;