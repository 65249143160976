'use strict';
module.exports = validate21;
module.exports.default = validate21;
var schema6 = {
  $id: 'http://taxfix.de/schemas/answers/ES_main_income.json',
  $schema: 'http://json-schema.org/draft-06/schema#',
  type: 'object',
  properties: {
    name: { type: 'string', maxLength: 100 },
    nif: { type: 'string' },
    income: { type: 'number' },
    taxRetention: { type: 'number' },
  },
  required: ['name', 'nif', 'income', 'taxRetention'],
  additionalProperties: false,
};
var func3 = require('ajv/dist/compile/ucs2length').default;

function validate21(data, valCxt) {
  'use strict' /*# sourceURL="http://taxfix.de/schemas/answers/ES_main_income.json" */;
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.name === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'name' },
        message: "should have required property '" + 'name' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.nif === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'nif' },
        message: "should have required property '" + 'nif' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.income === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'income' },
        message: "should have required property '" + 'income' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.taxRetention === undefined) {
      var err3 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'taxRetention' },
        message: "should have required property '" + 'taxRetention' + "'",
      };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    for (var key0 in data) {
      if (!(key0 === 'name' || key0 === 'nif' || key0 === 'income' || key0 === 'taxRetention')) {
        var err4 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
    if (data.name !== undefined) {
      var data0 = data.name;
      if (typeof data0 === 'string') {
        if (func3(data0) > 100) {
          var err5 = {
            keyword: 'maxLength',
            dataPath: dataPath + '/name',
            schemaPath: '#/properties/name/maxLength',
            params: { limit: 100 },
            message: 'should NOT have more than 100 characters',
          };
          if (vErrors === null) {
            vErrors = [err5];
          } else {
            vErrors.push(err5);
          }
          errors++;
        }
      } else {
        var err6 = {
          keyword: 'type',
          dataPath: dataPath + '/name',
          schemaPath: '#/properties/name/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
    if (data.nif !== undefined) {
      if (typeof data.nif !== 'string') {
        var err7 = {
          keyword: 'type',
          dataPath: dataPath + '/nif',
          schemaPath: '#/properties/nif/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err7];
        } else {
          vErrors.push(err7);
        }
        errors++;
      }
    }
    if (data.income !== undefined) {
      if (!(typeof data.income == 'number')) {
        var err8 = {
          keyword: 'type',
          dataPath: dataPath + '/income',
          schemaPath: '#/properties/income/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
    if (data.taxRetention !== undefined) {
      if (!(typeof data.taxRetention == 'number')) {
        var err9 = {
          keyword: 'type',
          dataPath: dataPath + '/taxRetention',
          schemaPath: '#/properties/taxRetention/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
    }
  } else {
    var err10 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err10];
    } else {
      vErrors.push(err10);
    }
    errors++;
  }
  validate21.errors = vErrors;
  return errors === 0;
}
