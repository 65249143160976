'use strict';

const getSpecialExpenses = require('./specialExpenses');

function calculateSpecialExpenses(fields, result, lumpsums) {
  try {
    const specialExpensesCalculations = getSpecialExpenses(
      fields,
      result.totalIncome,
      result.incomeWithoutProfessionalExpensesPerPerson,
      lumpsums
    );

    specialExpensesCalculations.healthInsurancePaidEmployeePerPerson = [
      specialExpensesCalculations.healthInsurancePaidEmployeeA,
      specialExpensesCalculations.healthInsurancePaidEmployeeB
    ];

    specialExpensesCalculations.healthInsuranceTaxfreeEmployerBenefitsPerPerson = [
      specialExpensesCalculations.healthInsuranceTaxfreeEmployerBenefitsA,
      specialExpensesCalculations.healthInsuranceTaxfreeEmployerBenefitsB
    ];

    specialExpensesCalculations.socialCareInsurancePerPerson = [
      specialExpensesCalculations.socialCareInsuranceA,
      specialExpensesCalculations.socialCareInsuranceB
    ];

    specialExpensesCalculations.socialCareInsuranceTaxfreeEmployerBenefitsPerPerson = [
      specialExpensesCalculations.socialCareInsuranceTaxfreeEmployerBenefitsA,
      specialExpensesCalculations.socialCareInsuranceTaxfreeEmployerBenefitsB
    ];

    specialExpensesCalculations.taxfreeEmployerBenefitsAPerPerson = [
      specialExpensesCalculations.taxfreeEmployerBenefitsA,
      specialExpensesCalculations.taxfreeEmployerBenefitsB
    ];

    return specialExpensesCalculations;
  } catch (error) {
    return {};
  }
}

module.exports = calculateSpecialExpenses;
