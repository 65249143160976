'use strict';

const logger = require('loglevel');
const { env } = require('./utils/utils_env');

logger.setLevel('warn');

if (env === 'debug') {
  logger.setLevel('debug');
}

module.exports = logger;
