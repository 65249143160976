'use strict';

const Decimal = require('decimal.js');
const moment = require('moment');

const besteuerungsanteilBreakdown = require('./data/besteuerungsanteil-breakdown.json');
const { isJointAssessment } = require('../utils/utils_field');
const { getDateYear, YEAR_FORMAT, DATE_FORMAT } = require('../utils/utils_date');
const {
  getDecimal,
  iterateLfNr,
  getLfdNrGroupsByNrs,
  getFieldsByIndex,
  getValue,
} = require('../utils/utils_fields');

const MIN_BESTEUERUNGSANTEIL_YEAR = 2005;
const MAX_BESTEUERUNGSANTEIL_YEAR = 2040;

const calculationNrs = [
  // (Renteneinnahmen) Pension Revenue - Format `N`
  '1800301',
  // (Nachzahlung) One time refund(Addition payment) - Format `N`
  '1800601',
  // (Anpassungsbetrag) Adjusted pension amount - Format `N`
  '1800606',
  // Pension expenses sum - Format `U`
  '1804903',
  // Prior pension start date - Format `D`
  '1800806',
  // Prior pension end date - Format `D`
  '1800906',
  // Current pension start date - Format `D`
  '1800501',
];

/**
 * @param {number} year
 * @returns {*}
 */
const getBesteuerungsanteilForYear = (year) => {
  let besteuerungsanteil = 0;

  if (year < MIN_BESTEUERUNGSANTEIL_YEAR) {
    besteuerungsanteil = besteuerungsanteilBreakdown[MIN_BESTEUERUNGSANTEIL_YEAR.toString()];
  } else if (year > MAX_BESTEUERUNGSANTEIL_YEAR) {
    besteuerungsanteil = besteuerungsanteilBreakdown[MAX_BESTEUERUNGSANTEIL_YEAR.toString()];
  } else {
    besteuerungsanteil = besteuerungsanteilBreakdown[year.toString()];
  }

  // Value is divided by 100 cause its a percentage
  return new Decimal(besteuerungsanteil).div(100);
};

/**
 * @param indexFields
 * @returns {*}
 */
const getBesteuerungsanteil = (indexFields) => {
  const priorPensionStartDate = getValue(indexFields, '1800806');
  const priorPensionEndDate = getValue(indexFields, '1800906');
  const currentPensionStartDate = getValue(indexFields, '1800501');
  const currentPensionStartDateYear = getDateYear(currentPensionStartDate);

  if (!currentPensionStartDate) {
    return 0;
  } else if (!priorPensionStartDate || !priorPensionEndDate) {
    return getBesteuerungsanteilForYear(currentPensionStartDateYear);
  } else if (getDateYear(priorPensionEndDate) <= MIN_BESTEUERUNGSANTEIL_YEAR - 1) {
    return getBesteuerungsanteilForYear(currentPensionStartDateYear);
  } else if (getDateYear(priorPensionEndDate) >= MIN_BESTEUERUNGSANTEIL_YEAR) {
    /**
     * Take prior pension start date and reset to start of the month
     * @type {moment.Moment}
     */
    const startDate = moment(priorPensionStartDate, DATE_FORMAT).startOf('month');

    /**
     * Take prior pension end date and reset to end of the month
     * We add one day to go to the first day of the next month.
     * This way moment will consider the entirety of the priorPensionEndDate
     * @type {moment.Moment}
     */
    const endDate = moment(priorPensionEndDate, DATE_FORMAT).endOf('month').add(1, 'day');

    /**
     * Take the month difference between the start and end date.
     * @type {number}
     */
    const priorPensionYearDiffInMonths = endDate.diff(startDate, 'months');

    /**
     * Subtract the priorPensionYearDiffInMonths from the currentPensionStartDate
     * @type {string}
     */
    const currentPensionWithPriorDiffYear = moment(currentPensionStartDate, DATE_FORMAT)
      .subtract(priorPensionYearDiffInMonths, 'months')
      .format(YEAR_FORMAT);

    return getBesteuerungsanteilForYear(parseInt(currentPensionWithPriorDiffYear, 10));
  }
};

/**
 * @param lfdNrFields
 */
const calculatePension = (lfdNrFields) => {
  let totalTaxableAmount = new Decimal(0);

  getFieldsByIndex(lfdNrFields, calculationNrs).forEach((indexFields) => {
    // Pension Revenue - Format `N`
    const renteneinnahmen = getDecimal(indexFields, '1800301');
    // One time refund(Addition payment) - Format `N`
    const nachzahlung = getDecimal(indexFields, '1800601');
    // Adjusted pension amount - Format `N`
    const anpassungsbetrag = getDecimal(indexFields, '1800606');
    // Tax portion
    const besteuerungsanteil = getBesteuerungsanteil(indexFields);

    // Calculation
    const sum1 = renteneinnahmen.sub(nachzahlung);
    const sum2 = sum1.sub(anpassungsbetrag);
    const sum3 = sum2.times(besteuerungsanteil).floor();
    const taxableAmount1 = sum3.add(anpassungsbetrag);
    const taxableAmount2 = nachzahlung.times(besteuerungsanteil).floor();
    const total = taxableAmount1.add(taxableAmount2);

    totalTaxableAmount = totalTaxableAmount.add(total);
  });

  return totalTaxableAmount;
};

/**
 * @param fields
 * @returns {{personB, total, personA, totalTaxableAmount: *[], expenses: *[]}}
 */
function calculatePensionIncome(fields) {
  const result = {
    // Person A total before expenses deduction
    personA: new Decimal(0),
    // Person B total before expenses deduction
    personB: new Decimal(0),
    // Sum total of Person A & B
    total: new Decimal(0),
    // Person [A, B] expenses
    expenses: [new Decimal(0), new Decimal(0)],
    // Person [A, B] totalTaxableAmount before expense deduction
    totalTaxableAmount: [new Decimal(0), new Decimal(0)],
  };

  const maxIterations = isJointAssessment(fields) ? 2 : 1;

  const lfdNrGroups = getLfdNrGroupsByNrs(fields, [...calculationNrs]);

  iterateLfNr(
    lfdNrGroups,
    (lfdNrFields, lfdNr) => {
      const totalTaxableAmount = result.totalTaxableAmount[lfdNr].add(
        calculatePension(lfdNrFields),
      );
      const pensionExpenses = getDecimal(lfdNrFields, '1804903');
      result.expenses[lfdNr] = result.expenses[lfdNr].add(pensionExpenses);
      result.totalTaxableAmount[lfdNr] = totalTaxableAmount;
    },
    maxIterations,
  );

  return result;
}

module.exports = calculatePensionIncome;
