// @flow

import BaseAnswer from './Base';

class YesNo extends BaseAnswer {
  constructor() {
    super('yes_no');

    this.defaultType = 'boolean';
  }

  format(value: any): boolean {
    super.format(value); // make sure default validations running
    return (value: boolean);
  }
}

export default new YesNo();
