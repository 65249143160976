'use strict';

const {
  getDecimal,
  iterateLfdNrAndIndex,
  updateField,
} = require('../utils');

const Decimal = require('decimal.js');

/**
 * Input: 0307701
 * Output: 0307801
 * Logic: Sum of entries in Input, except the first entry, will be written in the Output
 */
module.exports = fields => {
  let treasureSaleSum = new Decimal(0);
  try {
    iterateLfdNrAndIndex(fields, [
      '0307701'
    ], (indexedFields, lfdNr, index) => {
      if (index > 1) {
        treasureSaleSum = treasureSaleSum.add(getDecimal(indexedFields, '0307701'));
      }
    });
    if (treasureSaleSum.gt(0)) {
      updateField(fields, '0307801', 1, 1, treasureSaleSum.toNumber());
    } else {
      console.log('Treasure sales :: 1 or less sales - not writing anything to 0307701');
    } 
  } catch (error) {
    console.log('Error while post-processing treasure sales', error);
    throw error;
  }

  return fields;
};