// @flow

import BaseAnswer from './Base';
import { FormatterError } from '../error';
import { isKindOfArray } from '../utils';

class MultipleSelect extends BaseAnswer {
  constructor() {
    super('multiple_select');
  }

  validate(value: any): boolean {
    return isKindOfArray(value);
  }

  format(): string {
    throw new FormatterError('No formatters make sense on MultipleSelect :(');
  }

  output(): Array<string> {
    return ['string'];
  }
}

export default new MultipleSelect();
