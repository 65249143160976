'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const moment = require('moment');
const {
  getValue,
  getLfdNrGroupsByNrs,
  iterateLfNr
} = require('../utils/utils_fields');
const dateUtils = require('../utils/utils_date');
const { toString } = require('../utils/utils_logs');

function allowancesSingleParent2016PerChild(fields, lfdNr, childIndex, lumpsums) {
  logger.debug('\n-------------------------\nALLOWANCES SINGLE PARENT');
  let sumPerChild = new Decimal(0);
  let months = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const birthdateStr = getValue(fields, '0500701');
  logger.debug('birthdateStr', birthdateStr);
  /**
   * fromToInMyApartment format: DD.MM.YYYY-DD.MM.YYYY
   */
  let fromToInMyApartment = getValue(fields, '0503801');
  if (fromToInMyApartment && birthdateStr) {
    try {
      const intervalParts = fromToInMyApartment.split('-').map(date => moment(date, 'DD.MM.YYYY'));
      if (moment(birthdateStr, 'DD.MM.YYYY').isBetween(intervalParts[0], intervalParts[1], null, '[]')) {
        fromToInMyApartment = `${birthdateStr}-${intervalParts[1].format('DD.MM.YYYY')}`;
      }
    } catch (error) {
      logger.warn('Error adapting the from/to in my appartment', error);
    }
  }

  logger.debug('fromToInMyApartment', fromToInMyApartment);
  dateUtils.updateMonthsIfMatchDDMM(months, fromToInMyApartment, lumpsums.year, 1);

  let fromToOtherOfAgePerson;
  try {
    fromToOtherOfAgePerson = getValue(fields, '0503803');
  } catch (error) {
    fromToOtherOfAgePerson = getValue(fields, '0503803', 1);
  }

  logger.debug('fromToOtherOfAgePerson', fromToOtherOfAgePerson);
  dateUtils.updateMonthsIfMatchDDMM(months, fromToOtherOfAgePerson, lumpsums.year, 0);

  let fromToSharedFlat;
  try {
    fromToSharedFlat = getValue(fields, '0503804');
  } catch (error) {
    fromToSharedFlat = getValue(fields, '0503804', 1);
  }

  logger.debug('fromToSharedFlat', fromToSharedFlat);
  dateUtils.updateMonthsIfMatchDDMM(months, fromToSharedFlat, lumpsums.year, 0);

  // sum up all 1s in the array
  let numberOfMonths = months.reduce((a, b) => a + b);

  logger.debug('numberOfMonths', numberOfMonths);
  let allowance = childIndex === 0 ? 1908 : 240;

  if (numberOfMonths > 0) {
    // let allowance = childIndex === 0 ? 1908 : 240;
    sumPerChild = sumPerChild.add(allowance);
    
    // allowances are for the whole year - reduce that to actual number of months
    if (allowance !== 240) {
      sumPerChild = sumPerChild.div(12).times(numberOfMonths);
    }
  }

  return sumPerChild;
}

//
//
function allowancesSingleParent2016(fields, lumpsums) {
  let groups = getLfdNrGroupsByNrs(fields,
    [
      '0500701',  // birthday => mandatory, make sure we catch all children
      '0503801',
      '0503803',
      '0503804'
    ]
  );

  let allowances = new Decimal(0);
  let childIndex = 0;

  iterateLfNr(groups, (lfdNrFields, lfdNr) => {
    let a = allowancesSingleParent2016PerChild(lfdNrFields, lfdNr, childIndex++, lumpsums);
    logger.debug('allowance single parent', toString(a), 'children index', childIndex);
    allowances = allowances.add(a);
  });

  logger.debug('asp allowances', toString(allowances));

  return allowances;
}

module.exports = allowancesSingleParent2016;
