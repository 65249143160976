'use strict';

const getLumSum = require('./getLumSum');
const Decimal = require('decimal.js');


/**
 * Documentation can be found in docs/CALCULATE_INCOME_TAX.md
 * zvE === Income
 * Est === Income Tax
 *
 * @param zvE
 * @param taxYear
 * @returns {*}
 */
function calculateIncomeTax(zvE, taxYear) {
  const lumpsums = getLumSum(taxYear);
  const incomeTaxConfig = lumpsums.incomeTax;

  let y;
  let z;

  // Alternative A
  let Est = new Decimal(0);

  // Alternative B
  if (zvE.gt(incomeTaxConfig.a) && zvE.lte(incomeTaxConfig.b)) {
    y = zvE.sub(incomeTaxConfig.a).div(10000);
    Est = y.times(incomeTaxConfig.e).add(1400).times(y);

    return Est;
  }

  // Alternative C
  if (zvE.gt(incomeTaxConfig.b) && zvE.lte(incomeTaxConfig.c)) {
    z = zvE.sub(incomeTaxConfig.b).div(10000);
    Est = z.times(incomeTaxConfig.f).add(2397).times(z).add(incomeTaxConfig.g);

    return Est;
  }

  // Alternative D
  if (zvE.gt(incomeTaxConfig.c) && zvE.lte(incomeTaxConfig.d)) {
    Est = zvE.times(0.42).sub(incomeTaxConfig.h);

    return Est;
  }

  // Alternative E
  if (zvE.gt(incomeTaxConfig.d)) {
    Est = zvE.times(0.45).sub(incomeTaxConfig.i);

    return Est;
  }

  return Est;
}

module.exports = calculateIncomeTax;