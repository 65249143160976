'use strict';
module.exports = validate21;
module.exports.default = validate21;
var schema6 = {
  $id: 'http://taxfix.de/schemas/answers/duration.json',
  $schema: 'http://json-schema.org/draft-06/schema#',
  type: 'object',
  properties: {
    duration: {
      type: 'object',
      properties: {
        from: {
          type: 'string',
          pattern: '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$',
        },
        until: {
          type: 'string',
          pattern: '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$',
        },
      },
      additionalProperties: false,
      required: ['from', 'until'],
    },
    days: { type: 'number', minimum: 0 },
    nights: { type: 'number', minimum: 0 },
    months: { type: 'number', minimum: 0 },
  },
  additionalProperties: false,
  required: ['duration', 'days', 'nights'],
};
var pattern0 = new RegExp('^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$', 'u');

function validate21(data, valCxt) {
  'use strict' /*# sourceURL="http://taxfix.de/schemas/answers/duration.json" */;
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.duration === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'duration' },
        message: "should have required property '" + 'duration' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.days === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'days' },
        message: "should have required property '" + 'days' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.nights === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'nights' },
        message: "should have required property '" + 'nights' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    for (var key0 in data) {
      if (!(key0 === 'duration' || key0 === 'days' || key0 === 'nights' || key0 === 'months')) {
        var err3 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.duration !== undefined) {
      var data0 = data.duration;
      if (data0 && typeof data0 == 'object' && !Array.isArray(data0)) {
        if (data0.from === undefined) {
          var err4 = {
            keyword: 'required',
            dataPath: dataPath + '/duration',
            schemaPath: '#/properties/duration/required',
            params: { missingProperty: 'from' },
            message: "should have required property '" + 'from' + "'",
          };
          if (vErrors === null) {
            vErrors = [err4];
          } else {
            vErrors.push(err4);
          }
          errors++;
        }
        if (data0.until === undefined) {
          var err5 = {
            keyword: 'required',
            dataPath: dataPath + '/duration',
            schemaPath: '#/properties/duration/required',
            params: { missingProperty: 'until' },
            message: "should have required property '" + 'until' + "'",
          };
          if (vErrors === null) {
            vErrors = [err5];
          } else {
            vErrors.push(err5);
          }
          errors++;
        }
        for (var key1 in data0) {
          if (!(key1 === 'from' || key1 === 'until')) {
            var err6 = {
              keyword: 'additionalProperties',
              dataPath: dataPath + '/duration',
              schemaPath: '#/properties/duration/additionalProperties',
              params: { additionalProperty: key1 },
              message: 'should NOT have additional properties',
            };
            if (vErrors === null) {
              vErrors = [err6];
            } else {
              vErrors.push(err6);
            }
            errors++;
          }
        }
        if (data0.from !== undefined) {
          var data1 = data0.from;
          if (typeof data1 === 'string') {
            if (!pattern0.test(data1)) {
              var err7 = {
                keyword: 'pattern',
                dataPath: dataPath + '/duration/from',
                schemaPath: '#/properties/duration/properties/from/pattern',
                params: { pattern: '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$' },
                message:
                  'should match pattern "' +
                  '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$' +
                  '"',
              };
              if (vErrors === null) {
                vErrors = [err7];
              } else {
                vErrors.push(err7);
              }
              errors++;
            }
          } else {
            var err8 = {
              keyword: 'type',
              dataPath: dataPath + '/duration/from',
              schemaPath: '#/properties/duration/properties/from/type',
              params: { type: 'string' },
              message: 'should be string',
            };
            if (vErrors === null) {
              vErrors = [err8];
            } else {
              vErrors.push(err8);
            }
            errors++;
          }
        }
        if (data0.until !== undefined) {
          var data2 = data0.until;
          if (typeof data2 === 'string') {
            if (!pattern0.test(data2)) {
              var err9 = {
                keyword: 'pattern',
                dataPath: dataPath + '/duration/until',
                schemaPath: '#/properties/duration/properties/until/pattern',
                params: { pattern: '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$' },
                message:
                  'should match pattern "' +
                  '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$' +
                  '"',
              };
              if (vErrors === null) {
                vErrors = [err9];
              } else {
                vErrors.push(err9);
              }
              errors++;
            }
          } else {
            var err10 = {
              keyword: 'type',
              dataPath: dataPath + '/duration/until',
              schemaPath: '#/properties/duration/properties/until/type',
              params: { type: 'string' },
              message: 'should be string',
            };
            if (vErrors === null) {
              vErrors = [err10];
            } else {
              vErrors.push(err10);
            }
            errors++;
          }
        }
      } else {
        var err11 = {
          keyword: 'type',
          dataPath: dataPath + '/duration',
          schemaPath: '#/properties/duration/type',
          params: { type: 'object' },
          message: 'should be object',
        };
        if (vErrors === null) {
          vErrors = [err11];
        } else {
          vErrors.push(err11);
        }
        errors++;
      }
    }
    if (data.days !== undefined) {
      var data3 = data.days;
      if (typeof data3 == 'number') {
        if (data3 < 0 || isNaN(data3)) {
          var err12 = {
            keyword: 'minimum',
            dataPath: dataPath + '/days',
            schemaPath: '#/properties/days/minimum',
            params: { comparison: '>=', limit: 0 },
            message: 'should be >= 0',
          };
          if (vErrors === null) {
            vErrors = [err12];
          } else {
            vErrors.push(err12);
          }
          errors++;
        }
      } else {
        var err13 = {
          keyword: 'type',
          dataPath: dataPath + '/days',
          schemaPath: '#/properties/days/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err13];
        } else {
          vErrors.push(err13);
        }
        errors++;
      }
    }
    if (data.nights !== undefined) {
      var data4 = data.nights;
      if (typeof data4 == 'number') {
        if (data4 < 0 || isNaN(data4)) {
          var err14 = {
            keyword: 'minimum',
            dataPath: dataPath + '/nights',
            schemaPath: '#/properties/nights/minimum',
            params: { comparison: '>=', limit: 0 },
            message: 'should be >= 0',
          };
          if (vErrors === null) {
            vErrors = [err14];
          } else {
            vErrors.push(err14);
          }
          errors++;
        }
      } else {
        var err15 = {
          keyword: 'type',
          dataPath: dataPath + '/nights',
          schemaPath: '#/properties/nights/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err15];
        } else {
          vErrors.push(err15);
        }
        errors++;
      }
    }
    if (data.months !== undefined) {
      var data5 = data.months;
      if (typeof data5 == 'number') {
        if (data5 < 0 || isNaN(data5)) {
          var err16 = {
            keyword: 'minimum',
            dataPath: dataPath + '/months',
            schemaPath: '#/properties/months/minimum',
            params: { comparison: '>=', limit: 0 },
            message: 'should be >= 0',
          };
          if (vErrors === null) {
            vErrors = [err16];
          } else {
            vErrors.push(err16);
          }
          errors++;
        }
      } else {
        var err17 = {
          keyword: 'type',
          dataPath: dataPath + '/months',
          schemaPath: '#/properties/months/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err17];
        } else {
          vErrors.push(err17);
        }
        errors++;
      }
    }
  } else {
    var err18 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err18];
    } else {
      vErrors.push(err18);
    }
    errors++;
  }
  validate21.errors = vErrors;
  return errors === 0;
}
