'use strict';

const { TaxYears } = require('@taxfix/de-itc-types');

const calculatorForIncomeTax = require('./calculator');

function Calculator2015() {

}

/**
 * Tax Calculator for 2015
 * 
 * fields (array) - Elster fields
 * detailedResponse (bool) - flag to toggle the detail level in the response
 * - true = the entire calculation stack
 * - false = just the incomeTax
 */
Calculator2015.prototype.calculateIncomeTax = function (fields, detailedResponse = false) {
 return calculatorForIncomeTax(fields, TaxYears['2k15'], detailedResponse);
};

module.exports = new Calculator2015();
