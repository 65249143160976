// @flow

import * as ibantools from 'ibantools';
import BaseAnswer from './Base';
import { iban } from './custom-formats/iban';
import type { ValidationFunction, ValidationSchema } from '../flowtypes';

class Iban extends BaseAnswer {
  constructor() {
    super('iban');
    this.defaultType = 'string';
    this.hasCustomFormat = true;
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/iban.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/iban');
    /* eslint-enable global-require */
  }

  ibanTools() {
    return ibantools;
  }

  validateIBAN(data: string) {
    return iban(data);
  }

  format(value: any): string {
    super.format(value); // make sure default validations running
    return (value: string);
  }
}

export default new Iban();
