// @flow

import type {
  Refs,
  TreeNode,
} from '../types';

const nodes2refs = (list: TreeNode[]): Refs => {
  const refs: Refs = {};

  list.forEach((node) => { refs[node.id] = node; });

  return refs;
};

export default nodes2refs;
