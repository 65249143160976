'use strict';

const Decimal = require('decimal.js');
const { getDecimal } = require('../utils/utils_fields');

const MAX_HOME_OFFICE_LUMP_SUM = 600;
const HOME_OFFICE_LUMP_SUM_PER_DAY = 5;

function homeOffice2021(fields) {
  let expenses = new Decimal(0);
  let lumpsum = new Decimal(0);

  const costsA = getDecimal(fields, '0204504', 1, 1);
  expenses = expenses.add(costsA);

  const homeOfficeDaysA = getDecimal(fields, '0204507', 1, 1);
  const homeOfficeLumpSumDaysA = homeOfficeDaysA.times(new Decimal(HOME_OFFICE_LUMP_SUM_PER_DAY));

  lumpsum = lumpsum.add(Decimal.min(MAX_HOME_OFFICE_LUMP_SUM, homeOfficeLumpSumDaysA));

  // Person B
  const costsB = getDecimal(fields, '0204504', 1, 2);
  expenses = expenses.add(costsB);

  const homeOfficeDaysB = getDecimal(fields, '0204507', 1, 2);
  const homeOfficeLumpSumDaysB = homeOfficeDaysB.times(new Decimal(HOME_OFFICE_LUMP_SUM_PER_DAY));

  lumpsum = lumpsum.add(Decimal.min(MAX_HOME_OFFICE_LUMP_SUM, homeOfficeLumpSumDaysB));

  return {
    expenses,
    lumpsum,
  };
}

module.exports = homeOffice2021;
