'use strict';

const { TaxYears } = require('@taxfix/de-itc-types');

const specialExpenses2021 = require('../2021/special_expenses_2021');
const specialExpenses2020 = require('../2020/special_expenses_2020');
const specialExpenses2019 = require('../2019/special_expenses_2019');
const specialExpenses2016 = require('../2016/special_expenses_2016');

function getSpecialExpenses(fields, totalIncome, incomeWithoutProfessionalExpensesPerPerson, lumpsums) {
  switch (lumpsums.year) {
    case TaxYears['2k21']:
      return specialExpenses2021(fields, totalIncome, incomeWithoutProfessionalExpensesPerPerson, lumpsums);
    case TaxYears['2k20']:
      return specialExpenses2020(fields, totalIncome, incomeWithoutProfessionalExpensesPerPerson, lumpsums);
    case TaxYears['2k19']:
      return specialExpenses2019(fields, totalIncome, incomeWithoutProfessionalExpensesPerPerson, lumpsums);
    default:
      return specialExpenses2016(fields, totalIncome, incomeWithoutProfessionalExpensesPerPerson, lumpsums);
  }
}

module.exports = getSpecialExpenses;