'use strict';

const { TaxYears } = require('@taxfix/de-itc-types');

const calculatorForIncomeTax = require('./calculator');

function Calculator2017() {

}

/**
 * Tax Calculator for 2017
 * 
 * fields (array) - Elster fields
 * detailedResponse (bool) - flag to toggle the detail level in the response
 * - true = the entire calculation stack
 * - false = just the incomeTax
 */
Calculator2017.prototype.calculateIncomeTax = function (fields, detailedResponse = false) {
  return calculatorForIncomeTax(fields, TaxYears['2k17'], detailedResponse);
};

module.exports = new Calculator2017();
