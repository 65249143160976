'use strict';

const { TaxYears } = require('@taxfix/de-itc-types');

const capitalGains2021 = require('../2021/capital_gains_2021');
const capitalGains2017 = require('../2017/capital_gains_2017');
const capitalGains2016 = require('../2016/capital_gains_2016');

function calculateCapitalGains(fields, churchInfo, taxYear) {
  switch (taxYear) {
    case TaxYears['2k21']:
      return capitalGains2021(fields, churchInfo);
    case TaxYears['2k20']:
    case TaxYears['2k19']:
    case TaxYears['2k18']:
    case TaxYears['2k17']:
      return capitalGains2017(fields, churchInfo);
    default:
      return capitalGains2016(fields, churchInfo);
  }
}

module.exports = calculateCapitalGains;