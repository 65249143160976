// @flow
import _ from 'lodash';

const bindRecursive = (func) => {
  const recur = (...args) => func.call(this, recur, ...args);
  return recur;
};

export const cartesianProduct = (...collections: any[]) => {
  const product = [];
  bindRecursive((recur, collection) => {
    if (collection.length < collections.length) {
      _.forEach(collections[collection.length], (value) => {
        recur(_.concat(collection, [value]));
      });
    } else {
      product.push(collection);
    }
  })([]);
  return product;
};
