// @flow
/* eslint-disable */
import _ from 'lodash';
import set from 'lodash/fp/set';

import list2refs from '../transformer/list2refs';
import tree2list from '../transformer/tree2list';
import inputResolver from '../calculator/inputResolver';
import { appendIndex, indexFromId } from '../reference';
import { asResponses } from '../q-and-a/answersHelper';
import type {
  Answers,
  Cache,
  Input,
  Loop,
  LoopContext,
  Refs,
  TaxReference,
  TreeNode
} from '../types';

/* WARNING !!
 * You are about to enter into a dark place...
 */

type OutputResolver = (nodeId: string, outputName?: string) => any;

type Register = (node: TreeNode) => TreeNode;

type ExtendedLoopContext = LoopContext & { elsterIndex?: number, elsterRunningNumber?: number };

// TreeNode is a Functor
const map = (tree: TreeNode, fn: (TreeNode, ?TreeNode) => TreeNode, parent?: TreeNode) => {
  const node = fn(tree, parent);

  return {
    ...node,
    children: (node.children || []).map(child => map(child, fn, node)),
  };
};

const find = (tree: TreeNode, id) => {
  if (tree.id === id) {
    return tree;
  }

  if (tree.children == null || !tree.children.length) {
    return null;
  }
  
  const treeLength = tree.children.length;
  for (let i = 0; i < treeLength; i++) {
    const element = tree.children[i];
    const found = find(element, id);
    if (found) {
      return element;
    }
  }
};

const isLoop = (tree: TreeNode) => tree.loop && !_.isNil(tree.loop.basedOn);

const getLoopLength = (loop: ?Loop, resolver: OutputResolver): number => {
  if (!loop) {
    return 0;
  }
  const value = resolver(loop.basedOn);
  return value ? parseInt(value) : (loop.default || 0);
};

export const indexed = (id: string, indexChain: number[]) => _.reduce(indexChain, appendIndex, id);

/* CAREFUL HERE: this function uses a global CACHE, we assume we only using this with an original
 * tree that never changes in the current process
 */
const isInTree = _.memoize(
  (id: string, root: TreeNode) => !_.isNil(id) && find(root, id) != null,
  (id, root) => `${root.questionId}:${id}`,
);

export const remapInputReferences = (input: Input, root: TreeNode, indexChain: number[]) => {
  switch (input.type) {
    case 'value':
      if (_.isNil(input.properties.idx) && isInTree(input.properties.reference, root)) {
        return set('properties.reference', indexed(input.properties.reference, indexChain), input);
      }
      break;
    case 'format':
      if (isInTree(input.properties.value, root)) {
        return set('properties.value', indexed(input.properties.value, indexChain), input);
      }
      break;

    case 'sum':
      return set('properties.reference', indexed(input.properties.reference, indexChain), input);
    default:
      break;
  }
  return input;
};

export const remapTaxReference = (taxRefenrence: TaxReference, context: ExtendedLoopContext, parentId: string): TaxReference =>
  ({
    ...taxRefenrence,
    index: context.elsterIndex || 1,
    runningNumber: context.elsterRunningNumber || 1,
    partnerB: context.partnerB || false,
    id: context.loopId,
    loopId: context.loopId,
    groupId: parentId,
  });

export const remapReferences = (originalTree: TreeNode, tree: TreeNode, indexChain: number[]) =>
  map(tree, node => ({
    ...node,
    inputs: _.mapValues(node.inputs, _.partial(remapInputReferences, _, originalTree, indexChain)),
    loop: node.loop && isInTree(node.loop.basedOn, originalTree) ?
      { ...node.loop, basedOn: indexed(node.loop.basedOn, indexChain) }
      : node.loop,
    preconditions: _.mapKeys((precondition, id) => (isInTree(id, originalTree) ?
      indexed(id, indexChain)
      : id)),
    taxReferences: _.map(node.taxReferences, _.partial(remapTaxReference, _, tree.loopContext, node.parentId)),
  }));

export const withIndex = (originalTree: TreeNode, indexChain: number[], tree: TreeNode, parent: ?TreeNode): TreeNode => {
  const index = _.last(indexChain);
  const parentContext = originalTree.loopContext || {};
  const context = {
    ...parentContext,
    indexes: {
      ...parentContext.indexes || {},
      [originalTree.id]: index,
      [originalTree.questionId]: index,
    },
    loopId: originalTree.id,
  };

  switch (_.get(originalTree.loop, 'incrementField')) {
    case 'index':
      context.elsterIndex = index + 1;
      break;
    case 'running_number':
      context.elsterRunningNumber = index + 1;
      break;
    case 'partner_a_b':
      context.partnerB = index !== 0;
      break;
    default:
      break;
  }
  const indexedTree = ({
    ...tree,
    id: indexed(tree.id, indexChain),
    parentId: parent ? parent.id : tree.parentId,
    ancestors: parent ? (parent.ancestors || []).concat([parent.id]) : tree.ancestors,
    loopContext: context,
  });
  return remapReferences(originalTree, indexedTree, indexChain);
};

export const expandLoop = (tree: TreeNode, resolver: OutputResolver, register: Register): TreeNode => {
  if (isLoop(tree)) {
    const length = getLoopLength(tree.loop, resolver);
    const rangeLength = _.range(length);
    const mapNewChildren = (indexToUse) => (tree.children || []).map(child => {
      return map(child, (node: TreeNode, parent: ?TreeNode) => {
        const nodeWithIndex = withIndex(tree, [indexToUse], node, parent);

        return register(nodeWithIndex)
      });
    });

    const newChildren = length > 0 ? _.flatMap(
      rangeLength,
        index => mapNewChildren(index)
    ) : [];

    return register({
      ...tree,
      // expand children
      children: newChildren,
      loop: {
        ...tree.loop,
        count: length,
      }
    });
  }
  return tree;
};

/**
 * Constructs a function that takes a node an optional output ID and returns
 * the resolved value for the output. If no output name is given the value
 * of the firt output is retutned.
 */
const outputResolver = (answers: Answers, year: number, cache: Cache, refs: Refs) =>
  (nodeId: string, outputName?: string): any => {
    const node = refs[nodeId];
    if (node) {
      const output = outputName || _.head(node.outputs);
      if (output && node.inputs) {
        const input = node.inputs[output];
        if (input) {
          return inputResolver(
            input,
            asResponses(answers),
            node.inputs || {},
            refs,
            node.id,
            cache,
            year,
          );
        }
      }
    }
  };

/**
 * Returns the progression of index chains that leads the specified final index chain
 * Example:
 *  - [1,2,3] => [[1], [2], [3]]
 */
const indexProgression = (indexChain: number[]): number[][] =>
  _.map(indexChain, elem => [elem]);

const ancestorLoops = (node: TreeNode, refs: Refs): TreeNode[] =>
  (node.ancestors || []).map(id => refs[id]).filter(isLoop);

const topAncestorLoop = (node: TreeNode, refs: Refs) => _.head(ancestorLoops(node, refs));

/**
 * This function will take an original loop and a current subtree (tree), and re-shape the original
 * loop according to the progressive expanssions observed from tree.
 */
const indexedLoop = (loop: TreeNode, tree: TreeNode, refs: Refs): TreeNode => {
  const indexChain = indexFromId(tree.id);
  const ancestors = ancestorLoops(loop, refs);
  if (indexChain && !_.isEmpty(indexChain)) {
    return _.zip(indexProgression(indexChain), ancestors).reduce(
      (node, [chain, ancestor]) => map(node, _.partial(withIndex, ancestor, chain, _, _)),
      loop,
    );
  }
  return loop;
};

/*
Note: In any regular case, `tree` should not be null.
But we added null check below `nestedLoops` and `_.find(allLoops, n => n && n.questionId === node.questionId);`
because we forcely cut the tree to only use WAY on Mobile Web due to the perfomance reason,
https://github.com/taxfix/webapp/pull/537
And App crashes without this null check because it's looking for the targetLoops that is forcely cut.
*/
const nestedLoops = (tree: TreeNode) => 
  tree ? _.filter(tree.children, isLoop).concat(_.flatMap(tree.children, nestedLoops)) : null;

const unrollTargetLoops = (targetLoops: TreeNode[], resolver: OutputResolver, register: Register, refs: Refs) => {
  const allLoops = targetLoops.concat(_.flatMap(targetLoops, nestedLoops));
  return (node: TreeNode, parent: ?TreeNode) => {
    let target = _.find(allLoops, n => n && n.questionId === node.questionId);
    if (target) {
      target.parentId = parent ? parent.id : null;
      target.ancestors = parent ? (parent.ancestors || []).concat([parent.id]) : target.ancestors;
      /* At this point we capture the original target loop. However if that loop is a nested loop
       * then we need transform it into the same shape as it would have had after the unrolling of
       * the parent loop.
       */
      target = indexedLoop(target, node, refs);
      return expandLoop(target, resolver, register);
    }
    return node;
  };
}
/**
* Returns the hierarchy of loops in the given tree. Loops are grouped together according to
* how deeply between they are in the tree. The output is an array of loop groups sorted by
* deepth in assending order.
*
* Example:
*    root
*    /   \
* loop1 loop2    =>   [[loop1, loop2], [loop3]]
*   |
* loop3
*/
export const allLoops = (tree: TreeNode): TreeNode[][] => {
  const treeToList = tree2list(tree);

  return _.chain(treeToList)
    .filter(isLoop)
    .groupBy(node => node.ancestors.length)
    .values()
    .value();
}

export const resolveLoopsWithRefs = (
  tree: TreeNode,
  answers: Answers = {},
  year: number,
  cache: Cache = new Map(),
  refs: Refs = list2refs(tree2list(tree)),
  targetLoops?: TreeNode[],
) => {
  const resolver = outputResolver(answers, year, cache, refs);
  // This MUTATES the Refs map to provide constant-time access
  // to nodes in the tree
  const register = (node: TreeNode): TreeNode => {
    refs[node.id] = node;
    return node;
  };

  if (targetLoops) {
    return {
      tree: map(tree, unrollTargetLoops(targetLoops, resolver, register, refs)),
      refs,
    };
  }

  const layers = allLoops(tree);

  return layers.reduce(
    (acc, loops) => {
      const newTree = map(acc.tree, unrollTargetLoops(loops, resolver, register, acc.refs));

      return {
        tree: newTree,
        refs,
      }
    },
    { tree, refs },
  );
};
