'use strict';

const moment = require('moment');
const Decimal = require('decimal.js');
const logger = require('../logger');
const {
  getDecimal,
  getFieldsByIndex,
  getValue
} = require('../utils/utils_fields');
const { toString } = require('../utils/utils_logs');
const constants = require('../2016/constants-2016.json');
const { costForDistanceSince2021 } = require('../utils/utils_distance');

const MAX_ACCOMODATION_PER_MONTH = 1000;

function doubleHousekeeping2020(fields, currentTaxYear) {
  let expenses = new Decimal(0);

  // TODO this could all happen multiple times !!!
  getFieldsByIndex(fields, [
  '0206704', '0206103', '0206304', '0206903', '0206904', '0206906', '0206907',
  '0207003', '0207116', '0207117', '0207211', '0207511', '0207302', '0207304',
  '0207305', '0207411', '0207611', '0207901', '0208011', '0208301', '0208107',
  '0208202'
  ]).forEach(indexFields => {
    // Ansatz von mehreren Heimfahrten statt Mehraufwendungen doppelte
    // Haushaltsführung
    let dontUseDoubleHousekeeping = getValue(indexFields, '0206704');
    if (dontUseDoubleHousekeeping) {
      return;
    }

    let from = getValue(indexFields, '0206103');
    from = moment(from, 'DD.MM.YYYY');

    // if from is before current tax year, use first of january as start
    if (from.year() < currentTaxYear) from = moment(`01.01.${currentTaxYear}`, 'DD.MM.YYYY');
    let to = getValue(indexFields, '0206304');
    to = moment(to, 'DD.MM.YYYY');

    // calculate months: to.MM - from.MM + 1 b/c it's inclusive
    let numberOfMonths = to.month() - from.month() + 1;

    //
    // Fahrtkosten
    let kmWithPrivateCar = getDecimal(indexFields, '0206903');
    logger.debug('kmWithPrivateCar', toString(kmWithPrivateCar));

    let pricePerKmWithPrivateCar = getDecimal(indexFields, '0206904');
    if (pricePerKmWithPrivateCar.eq(0)) {
      pricePerKmWithPrivateCar = new Decimal(0.3);
    }
    let costPrivateCar = kmWithPrivateCar.times(pricePerKmWithPrivateCar);

    let kmWithPrivateMotorbike = getDecimal(indexFields, '0206906');
    let pricePerKmWithPrivateMotorbike = getDecimal(indexFields, '0206907');
    if (pricePerKmWithPrivateMotorbike.eq(0)) {
      pricePerKmWithPrivateMotorbike = new Decimal(0.3);
    }
    let costPrivateMotorbike = kmWithPrivateMotorbike.times(pricePerKmWithPrivateMotorbike);

    costPrivateCar = costPrivateCar.round();
    costPrivateMotorbike = costPrivateMotorbike.round();

    logger.debug('costPrivateCar', toString(costPrivateCar));
    logger.debug('costPrivateMotorbike', toString(costPrivateMotorbike));

    expenses = expenses.add(costPrivateCar);
    expenses = expenses.add(costPrivateMotorbike);

    // Erste / letzte Fahrt - öffentliche Verkehrsmittel / entgeltliche Sammelbeförderung
    let publicTransport = getDecimal(indexFields, '0207003');
    expenses = expenses.add(publicTransport);

    // wöchentliche Heimfahrten
    let goingHomeKm = getDecimal(indexFields, '0207116');
    let goingHomeTimes = getDecimal(indexFields, '0207117');

    logger.debug('goingHomeKm', toString(goingHomeKm));
    logger.debug('goingHomeTimes', toString(goingHomeTimes));

    let goingHomeCost = costForDistanceSince2021(goingHomeKm);
    logger.debug('goingHomeCost without times', toString(goingHomeCost));

    goingHomeCost = goingHomeCost.times(goingHomeTimes);
    logger.debug('goingHomeCost', toString(goingHomeCost));
    // expenses = expenses.add(goingHomeCost);

    let goingHomePublicTransport = getDecimal(indexFields, '0207211');
    logger.debug('goingHomePublicTransport', toString(goingHomePublicTransport));
    // expenses = expenses.add(goingHomePublicTransport);

    // CHECK
    let maxGoingHomeCost = Decimal.max(goingHomeCost, goingHomePublicTransport);
    logger.debug('maxGoingHomeCost (compare homeCost and public transport)', toString(maxGoingHomeCost));
    expenses = expenses.add(maxGoingHomeCost);

    // wöchentliche Heimfahrten - Flug- / Fährkosten / öffentliche Sammelbeförderung
    let othersCost = getDecimal(indexFields, '0207511');
    expenses = expenses.add(othersCost);

    //
    // Behinderungsgrad von 70 oder 50 und G
    let disabledKm = getDecimal(indexFields, '0207302');
    let disabledTimes = getDecimal(indexFields, '0207304');
    let disabledCostPerKm = getDecimal(indexFields, '0207305') || constants.PRICE_PER_KM;

    let disabledCost = disabledKm.times(disabledCostPerKm).times(disabledTimes);
    expenses = expenses.add(disabledCost);

    let disabledPublicTransport = getDecimal(indexFields, '0207411');
    expenses = expenses.add(disabledPublicTransport);

    //
    // Kosten der Unterkunft Summe
    let accomodation = getDecimal(indexFields, '0207611');
    let maxCostAccomodation = numberOfMonths * MAX_ACCOMODATION_PER_MONTH;
    if (accomodation > maxCostAccomodation) {
      accomodation = maxCostAccomodation;
    }

    logger.debug('accomodation', toString(accomodation));
    expenses = expenses.add(accomodation);

    let daysCost = new Decimal(0);

    // Anzahl An- und Abreisetage
    // 2020: increased from 12 to 14
    const daysOfArrivalDeparture = getDecimal(indexFields, '0207901');
    const daysOfArrivalDepartureTotal = daysOfArrivalDeparture.times(14);
    logger.debug(toString(daysOfArrivalDeparture) + ' * 14 = ' + toString(daysOfArrivalDepartureTotal));
    daysCost = daysCost.add(daysOfArrivalDepartureTotal);

    // Anzahl Tage Abwesenheit von 24 Std.
    // 2020: increased from 24 to 28
    const daysMoreThan24 = getDecimal(indexFields, '0208011');
    const daysMoreThan24Total = daysMoreThan24.times(28);
    logger.debug(toString(daysMoreThan24) + ' * 28 = ' + toString(daysMoreThan24Total));
    daysCost = daysCost.add(daysMoreThan24Total);

    // Kürzungsbetrag Mahlzeitengestellung
    let freeMeals = getDecimal(indexFields, '0208301');
    logger.debug('freeMeals', toString(freeMeals));
    daysCost = daysCost.sub(freeMeals);

    daysCost = Decimal.max(daysCost, 0);

    logger.debug('daysCost', toString(daysCost));
    expenses = expenses.add(daysCost);

    // Ausland: Summe Aufwendungen
    let abroad = getDecimal(indexFields, '0208107');
    expenses = expenses.add(abroad);

    // Sonstige Aufwendungen - Betrag
    let otherCosts = getDecimal(indexFields, '0208202');
    logger.debug('sonstige kosten', toString(otherCosts));
    expenses = expenses.add(otherCosts);

    logger.debug('doppelter haushalt', toString(expenses));
  });

  //
  // Erstattungsbeträge AG / Agentur für Arbeit
  let reimbursement = getDecimal(fields, '0206303');
  logger.debug('doppelter haushalt erstattungen', toString(reimbursement));
  expenses = expenses.sub(reimbursement);

  expenses = Decimal.max(expenses, 0);
  return expenses;
}

module.exports = doubleHousekeeping2020;
