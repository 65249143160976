'use strict';
module.exports = validate21;
module.exports.default = validate21;
var schema6 = {
  $id: 'http://taxfix.de/schemas/answers/bank_account.json',
  $schema: 'http://json-schema.org/draft-06/schema#',
  type: 'object',
  properties: {
    iban: { type: 'string', format: 'iban' },
    bic: { type: 'string', format: 'bic' },
    bank_name: { type: 'string' },
  },
  additionalProperties: false,
  required: ['iban'],
};
var formats0 = require('../custom-formats/bank_account').iban;
var formats2 = require('../custom-formats/bank_account').bic;

function validate21(data, valCxt) {
  'use strict' /*# sourceURL="http://taxfix.de/schemas/answers/bank_account.json" */;
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.iban === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'iban' },
        message: "should have required property '" + 'iban' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    for (var key0 in data) {
      if (!(key0 === 'iban' || key0 === 'bic' || key0 === 'bank_name')) {
        var err1 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
    if (data.iban !== undefined) {
      var data0 = data.iban;
      if (typeof data0 === 'string') {
        if (!formats0(data0)) {
          var err2 = {
            keyword: 'format',
            dataPath: dataPath + '/iban',
            schemaPath: '#/properties/iban/format',
            params: { format: 'iban' },
            message: 'should match format "' + 'iban' + '"',
          };
          if (vErrors === null) {
            vErrors = [err2];
          } else {
            vErrors.push(err2);
          }
          errors++;
        }
      } else {
        var err3 = {
          keyword: 'type',
          dataPath: dataPath + '/iban',
          schemaPath: '#/properties/iban/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.bic !== undefined) {
      var data1 = data.bic;
      if (typeof data1 === 'string') {
        if (!formats2(data1)) {
          var err4 = {
            keyword: 'format',
            dataPath: dataPath + '/bic',
            schemaPath: '#/properties/bic/format',
            params: { format: 'bic' },
            message: 'should match format "' + 'bic' + '"',
          };
          if (vErrors === null) {
            vErrors = [err4];
          } else {
            vErrors.push(err4);
          }
          errors++;
        }
      } else {
        var err5 = {
          keyword: 'type',
          dataPath: dataPath + '/bic',
          schemaPath: '#/properties/bic/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
    }
    if (data.bank_name !== undefined) {
      if (typeof data.bank_name !== 'string') {
        var err6 = {
          keyword: 'type',
          dataPath: dataPath + '/bank_name',
          schemaPath: '#/properties/bank_name/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
  } else {
    var err7 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err7];
    } else {
      vErrors.push(err7);
    }
    errors++;
  }
  validate21.errors = vErrors;
  return errors === 0;
}
