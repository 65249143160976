'use strict';

const Decimal = require('decimal.js');
const { TaxYears } = require('@taxfix/de-itc-types');

const logger = require('../logger');
const constants = require('./../2016/constants-2016.json');
const calculatePensionIncome = require('../base/pensionIncome');
const { percent: getPercent } = require('../utils/utils_decimal');
const { match,  getBool, getDecimal } = require('../utils/utils_fields');
const { calculateExpenses } = require('../utils/utils_calculate_expenses');

/**
 * @param fields
 */
function transform2016Fields(fields) {
  const lfdnrFieldMapping = {
    '0318903': '0318902',
    '0304503': '0304502',
    '0300516': '0300515',
    '0304602': '0304601',
    '0301907': '0301906',
    '0305502': '0305501',
    '0312202': '0312201',
    '0304702': '0304701',
    '0301917': '0301916',
    '0312106': '0312105',
    '0305302': '0305301',
    '0318907': '0318906',
    '0306802': '0306801',
    '0306902': '0306901',
    '0307702': '0307701',
    '0307802': '0307801',
    '0312206': '0312205'
  };

  /**
   * Person B Fields
   * @type {string[]}
   */
  const personBFields = Object.keys(lfdnrFieldMapping);

  /**
   * Group the fields into other fields and personB fields
   * this is so that we can manipulate the personB fields without
   * tampering with other fields
   */
  const previousValues = fields.reduce((previousValues, currentValue) => {
    if (personBFields.includes(currentValue.nr)) {
      previousValues = {
        ...previousValues,
        personBFields: [
          ...previousValues.personBFields,
          currentValue
        ]
      };
    } else {
      previousValues = {
        ...previousValues,
        otherFields: [
          ...previousValues.otherFields,
          currentValue
        ]
      };
    }
    return previousValues;
  }, {
    otherFields: [],
    personBFields: [],
  });

  /**
   * Update the personB fields to use lfdNr: 2 and personA nr
   */
  previousValues.personBFields = previousValues.personBFields.map((personBField) => {
    return {
      ...personBField,
      nr: lfdnrFieldMapping[personBField.nr],
      lfdNr: 2,
    };
  });

  return [].concat(previousValues.otherFields, previousValues.personBFields);
}

/**
 * @param fields
 * @param lfdNr
 * @returns {{privateSales: (*), fromBenefits: (*), alimony: *, sum}}
 */
function perPerson(fields, lfdNr, pensionIncome) {
  let sum = new Decimal(0);

  // Anteile an Einkünften - Anteile Gewinn / Verlust Summe
  sum = sum.add(getDecimal(fields, '0318902', 1, lfdNr));

  // Ausgleichszahlungen Vermeidung Versorgungsausgleich Betrag
  let alimonyPayment1 = getDecimal(fields, '0300515', 1, lfdNr);

  // Wiederkehrende Bezüge: Betrag Einnahmen aus gemeinnützige
  // Körperschaft / andere Stiftung
  let f2 = getDecimal(fields, '0301906', 1, lfdNr);

  // TODO: find what this code is: 0304502
  let alimonyPayment2 = getDecimal(fields, '0304502', 1, lfdNr);

  // Damit verbundene Werbungskosten
  let alimonyExpenses = getDecimal(fields, '0304701', 1, lfdNr);

  // Unterhaltsleistungen Betrag
  alimonyPayment1 = alimonyPayment1.add(getDecimal(fields, '0304601', 1, lfdNr));
  alimonyPayment1 = alimonyPayment1.add(alimonyPayment2);

  // Alimony revenues + pension expenses and lump sum consideration
  const revExpensesLumpSum = new Decimal(constants.REVENUE_RELATED_EXPENSES_LUMPSUM);
  let calculatedExpenses = new Decimal(0);
  const pensionExpenses = pensionIncome.expenses[lfdNr - 1];
  const pensionTaxableAmount = pensionIncome.totalTaxableAmount[lfdNr - 1];
  if (pensionTaxableAmount.gt(new Decimal(0))) {
    const totalExpenses = pensionExpenses.add(alimonyExpenses);
    const totalAmount = pensionTaxableAmount.add(alimonyPayment1);

    calculatedExpenses = calculateExpenses(totalExpenses, totalAmount, revExpensesLumpSum);
    // show expenses lump sum in pension breakdown and set alimony Expenses to 0
    if (calculatedExpenses.eq(revExpensesLumpSum)) {
      alimonyExpenses = new Decimal(0);
    }
    // if expenses > lump sum, leave both expenses as they are
    if (calculatedExpenses.gt(revExpensesLumpSum)) {
      calculatedExpenses = pensionExpenses;
    }
  } else {
    alimonyExpenses = calculateExpenses(alimonyExpenses, alimonyPayment1, revExpensesLumpSum);
  }

  const alimony = alimonyPayment1.sub(alimonyExpenses);

  sum = sum.add(alimonyPayment1);

  sum = sum.add(getPercent(f2, constants.FOUNDATION_PAYMENTS_PERCENT));

  // Abgeordneten Bezüge - Steuerpflichtige Einnahmen ohne Vergütungen für
  // mehrere Jahre
  // Abgeordneten Bezüge - Steuerpflichtige Einnahmen - Vergütungen für
  // mehrereJahre - Summe
  match(fields, {
    into: ['0305501', '0312201'],
    lfdNr,
  }).forEach((field) => {
    sum.add(getDecimal(fields, field.nr, field.index, field.lfdNr));
  });

  // Wiederkehrende Bezüge: Werbungskosten Einnahmen aus gemeinnützige 
  // Körperschaft / andere Stiftung
  let f3o1 = getDecimal(fields, '0301916', 1, lfdNr);
  alimonyExpenses = alimonyExpenses.add(getPercent(f3o1, constants.FOUNDATION_PAYMENTS_PERCENT));

  // Abgeordneten Bezüge - Werbungskosten 
  match(fields, {
    into: ['0312105'],
    lfdNr,
  }).forEach((field) => {
    alimonyExpenses = alimonyExpenses.add(getDecimal(fields, field.nr, field.index, field.lfdNr));
  });

  sum = sum.sub(alimonyExpenses);

  // andere Leistungen Einkünfte
  let f4a = getDecimal(fields, '0305301', 1, lfdNr);

  if (f4a.gt(constants.OTHER_SERVICES_MIN_THRESHOLD)) {
    sum = sum.add(f4a);
  }

  // Verechnung Verluste private Veräußerungsgeschäfte
  let f5 = getDecimal(fields, '0318906', 1, lfdNr);

  // Veräußerung Grundstück / Rechte - Zurechnung Gewinn / Verlust
  match(fields, {
    into: ['0306801'],
    lfdNr,
  }).forEach((field) => {
    f5 = f5.add(getDecimal(fields, field.nr, field.index, field.lfdNr));
  });

  // Veräußerung weitere Grundstücke / Rechte - Zurechnung Gewinn / Verlust 
  f5 = f5.add(getDecimal(fields, '0306901', 1, lfdNr));

  // Veräußerung andere WG - Zurechnung Gewinn / Verlust  
  let sumOfEachSale = new Decimal(0);

  match(fields, {
    into: ['0307701'],
    lfdNr,
  }).forEach((field) => {
    sumOfEachSale = sumOfEachSale.add(getDecimal(fields, field.nr, field.index, field.lfdNr));
  });

  // 7.6.17 comment this out while working out robert. Req by @mira
  // Veräußerung andere WG / Rechte - Zurechnung Gewinn / Verlust 
  if (lfdNr === 1) {
    f5 = f5.add(sumOfEachSale);

    if (f5.gt(constants.GAIN_LOSS_MIN_THRESHOLD)) {
      sum = sum.add(f5);
    }
  } else {
    // Partner B should also look over to partner A
    f5 = f5.add(sumOfEachSale);

    if (f5.lt(0)) {
      f5 = f5.add(getDecimal(fields, '0307801', 1, lfdNr));
      sum = sum.add(f5);
    }
  }

  // Einkünfte aus Steuerstundungsmodellen - Summe
  sum = sum.add(getDecimal(fields, '0312205', 1, lfdNr));

  return {
    sum,
    privateSales: f5,
    alimony,
    fromBenefits: f4a,
    calculatedExpenses,
  };
}

/**
 * @param taxYear
 * @param fields
 * @returns {{
 *  incomePrivateSales: *,
 *  incomePrivateSalesPerPerson: *[],
 *  incomeFromBenefits: *,
 *  incomeFromBenefitsPerPerson: *[],
 *  incomePerAlimony: *,
 *  otherIncomePerPerson: *[],
 *  pensionIncome: {personB, total, personA, totalTaxableAmount: *[], expenses: *[]},
 *  incomePerAlimonyPerPerson: *[]}
 * }
 */
function calculateOtherIncome(taxYear, fields) {
  logger.debug('\n-------------------------\OTHER INCOME\n');
    /**
   * Calculate pension income to consider expenses and lumpsums
   *
   * @type {{personB, total, personA, totalTaxableAmount: *[], expenses: *[]}}
   */
  const pensionIncome = calculatePensionIncome(fields);

  let newFields = [...fields];

  if (taxYear < TaxYears['2k20']) {
    newFields = transform2016Fields(fields);
  }

  const joint = getBool(fields, '0101201', 'X');
  let otherIncomePerPerson = [new Decimal(0), new Decimal(0)];
  let incomePrivateSalesPerPerson = [new Decimal(0), new Decimal(0)];
  let incomePerAlimonyPerPerson = [new Decimal(0), new Decimal(0)];
  let incomeFromBenefitsPerPerson = [new Decimal(0), new Decimal(0)];

  const {
    sum: personA,
    privateSales: privateSalesPersonA,
    alimony: alimonyPersonA,
    fromBenefits: fromBenefitsPersonA,
    calculatedExpenses,
  } = perPerson(newFields, 1, pensionIncome);

  otherIncomePerPerson[0] = personA;
  incomePrivateSalesPerPerson[0] = privateSalesPersonA;
  incomePerAlimonyPerPerson[0] = alimonyPersonA;
  incomeFromBenefitsPerPerson[0] = fromBenefitsPersonA;
  pensionIncome.expenses[0] = calculatedExpenses;

  if (joint) {
    const {
      sum: personB,
      alimony: alimonyPersonB,
      privateSales: privateSalesPersonB,
      fromBenefits: fromBenefitsPersonB,
      calculatedExpenses,
    } = perPerson(newFields, 2, pensionIncome);

    otherIncomePerPerson[1] = personB;
    incomePrivateSalesPerPerson[1] = privateSalesPersonB;
    incomePerAlimonyPerPerson[1] = alimonyPersonB;
    incomeFromBenefitsPerPerson[1] = fromBenefitsPersonB;
    pensionIncome.expenses[1] = calculatedExpenses;
  }

  /**
   * Update pensionIncome after considering expenses
   */
  pensionIncome.personA = pensionIncome.totalTaxableAmount[0].sub(pensionIncome.expenses[0]);
  pensionIncome.personB = pensionIncome.totalTaxableAmount[1].sub(pensionIncome.expenses[1]);
  pensionIncome.total = pensionIncome.personA.add(pensionIncome.personB);

  /**
   * Update personA otherIncome
   */
  otherIncomePerPerson[0] = otherIncomePerPerson[0].add(pensionIncome.personA);

  /**
   * Update personB otherIncome is its joint assessment
   */
  otherIncomePerPerson[1] = otherIncomePerPerson[1].add(pensionIncome.personB);

  /**
   * Sum private sales, per alimony and from benefits for both personA & personB
   */
  const incomePrivateSales = incomePrivateSalesPerPerson[0].add(incomePrivateSalesPerPerson[1]);
  const incomePerAlimony = incomePerAlimonyPerPerson[0].add(incomePerAlimonyPerPerson[1]);
  const incomeFromBenefits = incomeFromBenefitsPerPerson[0].add(incomeFromBenefitsPerPerson[1]);

  return {
    pensionIncome,
    incomePerAlimony,
    incomeFromBenefits,
    incomePrivateSales,
    otherIncomePerPerson,
    incomePerAlimonyPerPerson,
    incomeFromBenefitsPerPerson,
    incomePrivateSalesPerPerson,
  };
}

module.exports = {
  transform2016Fields,
  calculateOtherIncome
};

