'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _State = require('./State');

var _State2 = _interopRequireDefault(_State);

var _renderProps = require('../utils/renderProps');

var _renderProps2 = _interopRequireDefault(_renderProps);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var Form = function Form(_ref) {
  var _ref$initial = _ref.initial,
      initial = _ref$initial === undefined ? {} : _ref$initial,
      onChange = _ref.onChange,
      props = _objectWithoutProperties(_ref, ['initial', 'onChange']);

  return _react2.default.createElement(
    _State2.default,
    { initial: _extends({}, initial), onChange: onChange },
    function (_ref2) {
      var state = _ref2.state,
          setState = _ref2.setState;
      return (0, _renderProps2.default)(props, {
        input: function input(id) {
          var value = state[id] || '';
          var setValue = function setValue(value) {
            return setState(_defineProperty({}, id, value));
          };

          return {
            bind: {
              onChange: function onChange(event) {
                return setValue(event.target.value);
              },
              value: value
            },
            setValue: setValue,
            value: value
          };
        }
      });
    }
  );
};

exports.default = Form;