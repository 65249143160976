'use strict';

const Decimal = require('decimal.js');
const constants = require('../2016/constants-2016.json');

const ZERO = new Decimal(0);
const PRICE_PER_KM = new Decimal(constants.PRICE_PER_KM);
const PRICE_PER_KM_AFTER_20 = new Decimal(constants.PRICE_PER_KM_AFTER_20);
const THRESHOLD = new Decimal(constants.COMMUTING_THRESHOLD);

/**
 * This get the commuting distance expenses using
 * totalDays and totalDistance between commute
 */
function getCommutingExpenses(totalDays, totalDistance) {
  const isMoreThan20KM = totalDistance.gt(THRESHOLD);

  const distanceUpTo20KM = isMoreThan20KM ? THRESHOLD : totalDistance;
  const distanceOver20KM = totalDistance.sub(distanceUpTo20KM);

  const expenseUpTo20KM = distanceUpTo20KM.times(totalDays).times(PRICE_PER_KM);
  const expenseOver20KM = distanceOver20KM.times(totalDays).times(PRICE_PER_KM_AFTER_20);

  return {
    distanceUpTo20KM,
    distanceOver20KM,
    expenseUpTo20KM,
    expenseOver20KM,
  };
}

function costForDistanceBasic(km) {
  if (!(km instanceof Decimal)) {
    return ZERO;
  }

  return km.ceil().times(constants.PRICE_PER_KM);
}

function costForDistanceSince2021(distanceCar, distanceOther) {
  let totalCost = {
    costOwnCar: ZERO,
    costOther: ZERO,
  };

  if (!(distanceCar instanceof Decimal) || !(distanceOther instanceof Decimal)) {
    return ZERO;
  }

  // keep track of the 20km threshold
  let KM20Threshold = THRESHOLD;

  // 1. calculate distanceOtherCost
  if (distanceOther.gt(ZERO)) {
    const roundedDistanceOther = distanceOther.ceil();
    const { distanceUpTo20KM, expenseUpTo20KM, expenseOver20KM } = getCommutingExpenses(
      new Decimal(1),
      roundedDistanceOther,
    );

    // subtract first <= 20 distanceOther km from threshold
    KM20Threshold = KM20Threshold.sub(distanceUpTo20KM);

    const distanceOtherCost = expenseUpTo20KM.add(expenseOver20KM);
    totalCost.costOther = totalCost.costOther.add(distanceOtherCost);
  }

  // 2. calculate distanceCarCost
  if (distanceCar.gt(ZERO)) {
    const roundedDistanceCar = distanceCar.ceil();

    // case that distance is smaller than threshold
    if (roundedDistanceCar.lte(KM20Threshold)) {
      totalCost.costOwnCar = costForDistanceBasic(roundedDistanceCar);
      return totalCost;
    }

    const leftoverDistance = roundedDistanceCar.sub(KM20Threshold);
    const leftoverDistanceCost = leftoverDistance.times(PRICE_PER_KM_AFTER_20);

    // take threshold into account and add the left-over distance cost
    const distanceCarCost = KM20Threshold.times(PRICE_PER_KM).add(leftoverDistanceCost);
    totalCost.costOwnCar = totalCost.costOwnCar.add(distanceCarCost);
  }

  return totalCost;
}

module.exports = {
  costForDistanceBasic,
  costForDistanceSince2021,
  getCommutingExpenses,
};