// @flow

import BaseAnswer from './Base';
import { FormatterError } from '../error';
import type { PlaceAnswer } from './shared/Address';
import type { ValidationFunction, ValidationSchema } from '../flowtypes';

type DistanceAnswer = {
  value: number,
  route: {
    distanceInMetres: number,
    from: PlaceAnswer,
    to: PlaceAnswer,
  },
};

class Distance extends BaseAnswer {
  constructor() {
    const formats = [{ name: 'KILOMETRES', type: 'number' }];
    super('distance', formats);
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/distance.json'),
      refs: [require('../schema/shared/place.json')],
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/distance');
    /* eslint-enable global-require */
  }

  format(value: any, formatKey: string): number {
    super.format(value);

    const distanceValue = (value: DistanceAnswer);

    switch (formatKey) {
      case this.formats.KILOMETRES:
        return distanceValue.value;

      default:
        break;
    }

    throw new FormatterError(`Unknown format: ${formatKey}`);
  }
}

export default new Distance();
