// @flow

import FormatterError from "./error/FormatterError";
import * as AnswerTypes from './answer-types';
import type { Answer } from "./flowtypes";

class AnswerType {
  /**
   * All supported answer types
   *
   * @type {{[p: string]: *}}
   */
  answerTypes: { [key: string]: any };

  /**
   * All supported answer type formats
   * This is used to generate default output
   *
   * @type {{[p: string]: *}}
   */
  answerTypeFormats: { [key: string]: any };

  /**
   * Run all set up here. We would add support
   * to decorate the answer type via the constructor later.
   */
  constructor() {
    this.answerTypes = {};
    this.answerTypeFormats = {};
    this.setUpAnswerTypes();
  }

  /**
   * Set up the answer types in an easily accessible manner
   */
  setUpAnswerTypes() {
    Object.keys(AnswerTypes).forEach((key) => {
      const answerType = AnswerTypes[key];
      this.answerTypes[answerType.name] = answerType;

      if (answerType.hasFormats) {
        this.answerTypeFormats[answerType.name] = answerType.formats;
      } else {
        this.answerTypeFormats[answerType.name] = ['default'];
      }
    });
  }

  /**
   * Get AnswerType year by the type name
   *
   * @param answerTypeName
   * @returns {*}
   */
  getAnswerTypeByName(answerTypeName: string) {
    return this.answerTypes[answerTypeName];
  }

  /**
   * Call the validation function of a supported answer type
   * Returns false if the answer type is not found.
   *
   * @param answerTypeName
   * @param answer
   * @returns {boolean}
   */
  validate(answerTypeName: string, answer: Answer) {
    const answerType = this.getAnswerTypeByName(answerTypeName);

    if (!answerType) {
      return false;
    }

    return answerType.validate(answer);
  }

  /**
   * Format the answer when a formatter is specified,
   * hence the supplied answer is returned
   *
   * @param answerTypeName
   * @param format
   * @param answer
   * @throws {FormatterError}
   * @returns {Answer|*}
   */
  format(answerTypeName: string, format: string, answer: Answer) {
    const answerType = this.getAnswerTypeByName(answerTypeName);

    if (!answerType) {
      throw new FormatterError(`Unknown type: ${answerTypeName}.`);
    }

    /**
     * If no formatters, and it's a simple type, then return its value
     */
    if (!answerType.hasFormats && answerType.isPrimitiveType) {
      return answer;
    }

    return answerType.format(answer, format);
  }
}

export default new AnswerType();