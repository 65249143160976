'use strict';
module.exports = validate21;
module.exports.default = validate21;
var schema6 = {
  $id: 'http://taxfix.de/schemas/answers/taxauthority.json',
  $schema: 'http://json-schema.org/draft-06/schema#',
  type: 'object',
  properties: {
    id: { type: 'string' },
    name: { type: 'string' },
    street: { type: 'string' },
    city: { type: 'string' },
    state: { type: 'string' },
    postalCode: { type: 'string' },
  },
  additionalProperties: false,
  required: ['id', 'name', 'street', 'city', 'state', 'postalCode'],
};

function validate21(data, valCxt) {
  'use strict' /*# sourceURL="http://taxfix.de/schemas/answers/taxauthority.json" */;
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.id === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'id' },
        message: "should have required property '" + 'id' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.name === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'name' },
        message: "should have required property '" + 'name' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.street === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'street' },
        message: "should have required property '" + 'street' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.city === undefined) {
      var err3 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'city' },
        message: "should have required property '" + 'city' + "'",
      };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.state === undefined) {
      var err4 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'state' },
        message: "should have required property '" + 'state' + "'",
      };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data.postalCode === undefined) {
      var err5 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'postalCode' },
        message: "should have required property '" + 'postalCode' + "'",
      };
      if (vErrors === null) {
        vErrors = [err5];
      } else {
        vErrors.push(err5);
      }
      errors++;
    }
    for (var key0 in data) {
      if (
        !(
          key0 === 'id' ||
          key0 === 'name' ||
          key0 === 'street' ||
          key0 === 'city' ||
          key0 === 'state' ||
          key0 === 'postalCode'
        )
      ) {
        var err6 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
    if (data.id !== undefined) {
      if (typeof data.id !== 'string') {
        var err7 = {
          keyword: 'type',
          dataPath: dataPath + '/id',
          schemaPath: '#/properties/id/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err7];
        } else {
          vErrors.push(err7);
        }
        errors++;
      }
    }
    if (data.name !== undefined) {
      if (typeof data.name !== 'string') {
        var err8 = {
          keyword: 'type',
          dataPath: dataPath + '/name',
          schemaPath: '#/properties/name/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
    if (data.street !== undefined) {
      if (typeof data.street !== 'string') {
        var err9 = {
          keyword: 'type',
          dataPath: dataPath + '/street',
          schemaPath: '#/properties/street/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
    }
    if (data.city !== undefined) {
      if (typeof data.city !== 'string') {
        var err10 = {
          keyword: 'type',
          dataPath: dataPath + '/city',
          schemaPath: '#/properties/city/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err10];
        } else {
          vErrors.push(err10);
        }
        errors++;
      }
    }
    if (data.state !== undefined) {
      if (typeof data.state !== 'string') {
        var err11 = {
          keyword: 'type',
          dataPath: dataPath + '/state',
          schemaPath: '#/properties/state/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err11];
        } else {
          vErrors.push(err11);
        }
        errors++;
      }
    }
    if (data.postalCode !== undefined) {
      if (typeof data.postalCode !== 'string') {
        var err12 = {
          keyword: 'type',
          dataPath: dataPath + '/postalCode',
          schemaPath: '#/properties/postalCode/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err12];
        } else {
          vErrors.push(err12);
        }
        errors++;
      }
    }
  } else {
    var err13 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err13];
    } else {
      vErrors.push(err13);
    }
    errors++;
  }
  validate21.errors = vErrors;
  return errors === 0;
}
