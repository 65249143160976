'use strict';
module.exports = validate21;
module.exports.default = validate21;
var schema6 = {
  $id: 'http://taxfix.de/schemas/answers/shared/address.json',
  $schema: 'http://json-schema.org/draft-06/schema#',
  type: 'object',
  properties: {
    displayName: { type: ['string', 'null'] },
    coordinate: { $ref: '#/definitions/coordinate' },
    postalCode: { type: 'string' },
    state: { type: ['string', 'null'] },
    countryCode: { type: 'string' },
    address: { $ref: '#/definitions/address' },
  },
  additionalProperties: false,
  required: ['displayName', 'coordinate', 'postalCode', 'state', 'countryCode', 'address'],
  definitions: {
    address: {
      type: 'object',
      properties: {
        street: {
          type: 'string',
          pattern:
            '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
          maxLength: 25,
          minLength: 1,
        },
        number: { type: 'string', pattern: '^\\d+$', maxLength: 6 },
        numberExtension: {
          type: ['string', 'null'],
          pattern:
            '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
        },
        addition: {
          type: ['string', 'null'],
          pattern:
            '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
        },
        postalCode: {
          type: 'string',
          pattern:
            '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
          minLength: 1,
        },
        city: {
          type: 'string',
          pattern:
            '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
          maxLength: 20,
          minLength: 1,
        },
      },
      additionalProperties: false,
      required: ['street', 'number', 'postalCode', 'city'],
    },
    coordinate: {
      type: ['object', 'null'],
      properties: { latitude: { type: 'number' }, longitude: { type: 'number' } },
      additionalProperties: false,
      required: ['latitude', 'longitude'],
    },
  },
};
var schema7 = {
  type: ['object', 'null'],
  properties: { latitude: { type: 'number' }, longitude: { type: 'number' } },
  additionalProperties: false,
  required: ['latitude', 'longitude'],
};

function validate22(data, valCxt) {
  'use strict';
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (!(data && typeof data == 'object' && !Array.isArray(data)) && data !== null) {
    var err0 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: schema7.type },
      message: 'should be object,null',
    };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.latitude === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'latitude' },
        message: "should have required property '" + 'latitude' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.longitude === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'longitude' },
        message: "should have required property '" + 'longitude' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    for (var key0 in data) {
      if (!(key0 === 'latitude' || key0 === 'longitude')) {
        var err3 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.latitude !== undefined) {
      if (!(typeof data.latitude == 'number')) {
        var err4 = {
          keyword: 'type',
          dataPath: dataPath + '/latitude',
          schemaPath: '#/properties/latitude/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
    if (data.longitude !== undefined) {
      if (!(typeof data.longitude == 'number')) {
        var err5 = {
          keyword: 'type',
          dataPath: dataPath + '/longitude',
          schemaPath: '#/properties/longitude/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
    }
  }
  validate22.errors = vErrors;
  return errors === 0;
}

var schema8 = {
  type: 'object',
  properties: {
    street: {
      type: 'string',
      pattern:
        '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
      maxLength: 25,
      minLength: 1,
    },
    number: { type: 'string', pattern: '^\\d+$', maxLength: 6 },
    numberExtension: {
      type: ['string', 'null'],
      pattern:
        '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
    },
    addition: {
      type: ['string', 'null'],
      pattern:
        '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
    },
    postalCode: {
      type: 'string',
      pattern:
        '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
      minLength: 1,
    },
    city: {
      type: 'string',
      pattern:
        '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
      maxLength: 20,
      minLength: 1,
    },
  },
  additionalProperties: false,
  required: ['street', 'number', 'postalCode', 'city'],
};
var func3 = require('ajv/dist/compile/ucs2length').default;
var pattern0 = new RegExp(
  '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
  'u',
);
var pattern1 = new RegExp('^\\d+$', 'u');

function validate24(data, valCxt) {
  'use strict';
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.street === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'street' },
        message: "should have required property '" + 'street' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.number === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'number' },
        message: "should have required property '" + 'number' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.postalCode === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'postalCode' },
        message: "should have required property '" + 'postalCode' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.city === undefined) {
      var err3 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'city' },
        message: "should have required property '" + 'city' + "'",
      };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    for (var key0 in data) {
      if (
        !(
          key0 === 'street' ||
          key0 === 'number' ||
          key0 === 'numberExtension' ||
          key0 === 'addition' ||
          key0 === 'postalCode' ||
          key0 === 'city'
        )
      ) {
        var err4 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
    if (data.street !== undefined) {
      var data0 = data.street;
      if (typeof data0 === 'string') {
        if (func3(data0) > 25) {
          var err5 = {
            keyword: 'maxLength',
            dataPath: dataPath + '/street',
            schemaPath: '#/properties/street/maxLength',
            params: { limit: 25 },
            message: 'should NOT have more than 25 characters',
          };
          if (vErrors === null) {
            vErrors = [err5];
          } else {
            vErrors.push(err5);
          }
          errors++;
        }
        if (func3(data0) < 1) {
          var err6 = {
            keyword: 'minLength',
            dataPath: dataPath + '/street',
            schemaPath: '#/properties/street/minLength',
            params: { limit: 1 },
            message: 'should NOT have fewer than 1 characters',
          };
          if (vErrors === null) {
            vErrors = [err6];
          } else {
            vErrors.push(err6);
          }
          errors++;
        }
        if (!pattern0.test(data0)) {
          var err7 = {
            keyword: 'pattern',
            dataPath: dataPath + '/street',
            schemaPath: '#/properties/street/pattern',
            params: {
              pattern:
                '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
            },
            message:
              'should match pattern "' +
              '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$' +
              '"',
          };
          if (vErrors === null) {
            vErrors = [err7];
          } else {
            vErrors.push(err7);
          }
          errors++;
        }
      } else {
        var err8 = {
          keyword: 'type',
          dataPath: dataPath + '/street',
          schemaPath: '#/properties/street/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
    if (data.number !== undefined) {
      var data1 = data.number;
      if (typeof data1 === 'string') {
        if (func3(data1) > 6) {
          var err9 = {
            keyword: 'maxLength',
            dataPath: dataPath + '/number',
            schemaPath: '#/properties/number/maxLength',
            params: { limit: 6 },
            message: 'should NOT have more than 6 characters',
          };
          if (vErrors === null) {
            vErrors = [err9];
          } else {
            vErrors.push(err9);
          }
          errors++;
        }
        if (!pattern1.test(data1)) {
          var err10 = {
            keyword: 'pattern',
            dataPath: dataPath + '/number',
            schemaPath: '#/properties/number/pattern',
            params: { pattern: '^\\d+$' },
            message: 'should match pattern "' + '^\\d+$' + '"',
          };
          if (vErrors === null) {
            vErrors = [err10];
          } else {
            vErrors.push(err10);
          }
          errors++;
        }
      } else {
        var err11 = {
          keyword: 'type',
          dataPath: dataPath + '/number',
          schemaPath: '#/properties/number/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err11];
        } else {
          vErrors.push(err11);
        }
        errors++;
      }
    }
    if (data.numberExtension !== undefined) {
      var data2 = data.numberExtension;
      if (typeof data2 !== 'string' && data2 !== null) {
        var err12 = {
          keyword: 'type',
          dataPath: dataPath + '/numberExtension',
          schemaPath: '#/properties/numberExtension/type',
          params: { type: schema8.properties.numberExtension.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err12];
        } else {
          vErrors.push(err12);
        }
        errors++;
      }
      if (typeof data2 === 'string') {
        if (!pattern0.test(data2)) {
          var err13 = {
            keyword: 'pattern',
            dataPath: dataPath + '/numberExtension',
            schemaPath: '#/properties/numberExtension/pattern',
            params: {
              pattern:
                '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
            },
            message:
              'should match pattern "' +
              '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$' +
              '"',
          };
          if (vErrors === null) {
            vErrors = [err13];
          } else {
            vErrors.push(err13);
          }
          errors++;
        }
      }
    }
    if (data.addition !== undefined) {
      var data3 = data.addition;
      if (typeof data3 !== 'string' && data3 !== null) {
        var err14 = {
          keyword: 'type',
          dataPath: dataPath + '/addition',
          schemaPath: '#/properties/addition/type',
          params: { type: schema8.properties.addition.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err14];
        } else {
          vErrors.push(err14);
        }
        errors++;
      }
      if (typeof data3 === 'string') {
        if (!pattern0.test(data3)) {
          var err15 = {
            keyword: 'pattern',
            dataPath: dataPath + '/addition',
            schemaPath: '#/properties/addition/pattern',
            params: {
              pattern:
                '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
            },
            message:
              'should match pattern "' +
              '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$' +
              '"',
          };
          if (vErrors === null) {
            vErrors = [err15];
          } else {
            vErrors.push(err15);
          }
          errors++;
        }
      }
    }
    if (data.postalCode !== undefined) {
      var data4 = data.postalCode;
      if (typeof data4 === 'string') {
        if (func3(data4) < 1) {
          var err16 = {
            keyword: 'minLength',
            dataPath: dataPath + '/postalCode',
            schemaPath: '#/properties/postalCode/minLength',
            params: { limit: 1 },
            message: 'should NOT have fewer than 1 characters',
          };
          if (vErrors === null) {
            vErrors = [err16];
          } else {
            vErrors.push(err16);
          }
          errors++;
        }
        if (!pattern0.test(data4)) {
          var err17 = {
            keyword: 'pattern',
            dataPath: dataPath + '/postalCode',
            schemaPath: '#/properties/postalCode/pattern',
            params: {
              pattern:
                '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
            },
            message:
              'should match pattern "' +
              '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$' +
              '"',
          };
          if (vErrors === null) {
            vErrors = [err17];
          } else {
            vErrors.push(err17);
          }
          errors++;
        }
      } else {
        var err18 = {
          keyword: 'type',
          dataPath: dataPath + '/postalCode',
          schemaPath: '#/properties/postalCode/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err18];
        } else {
          vErrors.push(err18);
        }
        errors++;
      }
    }
    if (data.city !== undefined) {
      var data5 = data.city;
      if (typeof data5 === 'string') {
        if (func3(data5) > 20) {
          var err19 = {
            keyword: 'maxLength',
            dataPath: dataPath + '/city',
            schemaPath: '#/properties/city/maxLength',
            params: { limit: 20 },
            message: 'should NOT have more than 20 characters',
          };
          if (vErrors === null) {
            vErrors = [err19];
          } else {
            vErrors.push(err19);
          }
          errors++;
        }
        if (func3(data5) < 1) {
          var err20 = {
            keyword: 'minLength',
            dataPath: dataPath + '/city',
            schemaPath: '#/properties/city/minLength',
            params: { limit: 1 },
            message: 'should NOT have fewer than 1 characters',
          };
          if (vErrors === null) {
            vErrors = [err20];
          } else {
            vErrors.push(err20);
          }
          errors++;
        }
        if (!pattern0.test(data5)) {
          var err21 = {
            keyword: 'pattern',
            dataPath: dataPath + '/city',
            schemaPath: '#/properties/city/pattern',
            params: {
              pattern:
                '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$',
            },
            message:
              'should match pattern "' +
              '^[a-zA-Z0-9àäáâæãåçéèêëîíìñöôòóõœ øßšüûùúÿïÄÀÁÂÆÃÅÇÉÈÊËÎÏÍÌÑÖÔÒÓÕŒØŠÜÛÙÚŸ#\'=*-_+/,;°|"`<>^:.()&![/\\]/]*$' +
              '"',
          };
          if (vErrors === null) {
            vErrors = [err21];
          } else {
            vErrors.push(err21);
          }
          errors++;
        }
      } else {
        var err22 = {
          keyword: 'type',
          dataPath: dataPath + '/city',
          schemaPath: '#/properties/city/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err22];
        } else {
          vErrors.push(err22);
        }
        errors++;
      }
    }
  } else {
    var err23 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err23];
    } else {
      vErrors.push(err23);
    }
    errors++;
  }
  validate24.errors = vErrors;
  return errors === 0;
}

function validate21(data, valCxt) {
  'use strict' /*# sourceURL="http://taxfix.de/schemas/answers/shared/address.json" */;
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.displayName === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'displayName' },
        message: "should have required property '" + 'displayName' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.coordinate === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'coordinate' },
        message: "should have required property '" + 'coordinate' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.postalCode === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'postalCode' },
        message: "should have required property '" + 'postalCode' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.state === undefined) {
      var err3 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'state' },
        message: "should have required property '" + 'state' + "'",
      };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.countryCode === undefined) {
      var err4 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'countryCode' },
        message: "should have required property '" + 'countryCode' + "'",
      };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data.address === undefined) {
      var err5 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'address' },
        message: "should have required property '" + 'address' + "'",
      };
      if (vErrors === null) {
        vErrors = [err5];
      } else {
        vErrors.push(err5);
      }
      errors++;
    }
    for (var key0 in data) {
      if (
        !(
          key0 === 'displayName' ||
          key0 === 'coordinate' ||
          key0 === 'postalCode' ||
          key0 === 'state' ||
          key0 === 'countryCode' ||
          key0 === 'address'
        )
      ) {
        var err6 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
    if (data.displayName !== undefined) {
      var data0 = data.displayName;
      if (typeof data0 !== 'string' && data0 !== null) {
        var err7 = {
          keyword: 'type',
          dataPath: dataPath + '/displayName',
          schemaPath: '#/properties/displayName/type',
          params: { type: schema6.properties.displayName.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err7];
        } else {
          vErrors.push(err7);
        }
        errors++;
      }
    }
    if (data.coordinate !== undefined) {
      if (
        !validate22(data.coordinate, {
          dataPath: dataPath + '/coordinate',
          parentData: data,
          parentDataProperty: 'coordinate',
          rootData: rootData,
        })
      ) {
        vErrors = vErrors === null ? validate22.errors : vErrors.concat(validate22.errors);
        errors = vErrors.length;
      }
    }
    if (data.postalCode !== undefined) {
      if (typeof data.postalCode !== 'string') {
        var err8 = {
          keyword: 'type',
          dataPath: dataPath + '/postalCode',
          schemaPath: '#/properties/postalCode/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
    if (data.state !== undefined) {
      var data3 = data.state;
      if (typeof data3 !== 'string' && data3 !== null) {
        var err9 = {
          keyword: 'type',
          dataPath: dataPath + '/state',
          schemaPath: '#/properties/state/type',
          params: { type: schema6.properties.state.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
    }
    if (data.countryCode !== undefined) {
      if (typeof data.countryCode !== 'string') {
        var err10 = {
          keyword: 'type',
          dataPath: dataPath + '/countryCode',
          schemaPath: '#/properties/countryCode/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err10];
        } else {
          vErrors.push(err10);
        }
        errors++;
      }
    }
    if (data.address !== undefined) {
      if (
        !validate24(data.address, {
          dataPath: dataPath + '/address',
          parentData: data,
          parentDataProperty: 'address',
          rootData: rootData,
        })
      ) {
        vErrors = vErrors === null ? validate24.errors : vErrors.concat(validate24.errors);
        errors = vErrors.length;
      }
    }
  } else {
    var err11 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err11];
    } else {
      vErrors.push(err11);
    }
    errors++;
  }
  validate21.errors = vErrors;
  return errors === 0;
}
