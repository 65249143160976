// @flow

import * as ibantools from 'ibantools';

export const iban = (data: string) => {
  const electronicFormatIBAN = ibantools.electronicFormatIBAN(data);

  return ibantools.isValidIBAN(electronicFormatIBAN);
};

export default iban;
