// @flow

import BaseAnswer from './Base';
import { FormatterError } from '../error';

class MultipleChoice extends BaseAnswer {
  constructor() {
    super('multiple_choice');

    this.defaultType = 'string';
  }

  validate(value: any): boolean {
    return typeof value === 'string';
  }

  format(): string {
    throw new FormatterError('No formatters make sense on MultipleChoice :(');
  }
}

export default new MultipleChoice();
