'use strict';

const Decimal = require('decimal.js');
const { getDecimal } = require('../utils/utils_fields');

function reducedTaxRate2016(fields) {
  let reduced = new Decimal(0);

  // Entschädigungen / Arbeitslohn für mehrere Jahre: Summe
  reduced = reduced.add(getDecimal(fields, '0201002'));
  reduced = reduced.add(getDecimal(fields, '0201806'));
  // Nachzahlung is subject to reduced tax GTT-625
  reduced = reduced.add(getDecimal(fields, '1800601'));
  // Werbungskosten Entschädigungen / Arbeitslohn für mehrere Jahres - Betrag
  reduced = reduced.sub(getDecimal(fields, '0206502'));

  return reduced;
}

module.exports = reducedTaxRate2016;
