'use strict';

const { TaxYears } = require('@taxfix/de-itc-types');

const incomeSubjectToProgressionTax2020 = require('../2020/income_subject_to_progression_2020');
const { incomeSubjectToProgressionTax2016 } = require('../2016/income_subject_to_progression_2016');

function calculateIncomeSubjectToProgressionTax(fields, personEarnings, werbungskostenpauschbetrag, taxYear) {
  switch (taxYear) {
    case TaxYears['2k21']:
    case TaxYears['2k20']:
      return incomeSubjectToProgressionTax2020(fields, personEarnings, werbungskostenpauschbetrag);
    default:
      return incomeSubjectToProgressionTax2016(fields, personEarnings, werbungskostenpauschbetrag);
  }
}

module.exports = calculateIncomeSubjectToProgressionTax;