'use strict';

const { TaxYears } = require('@taxfix/de-itc-types');

const allowancesSingleParent2020 = require('../2020/allowances_single_parent_2020');
const allowancesSingleParent2016 = require('../2016/allowances_single_parent_2016');

function getSingleParentAllowances(fields, lumpsums) {
  switch (lumpsums.year) {
    case TaxYears['2k21']:
    case TaxYears['2k20']:
      return allowancesSingleParent2020(fields, lumpsums);
    default:
      return allowancesSingleParent2016(fields, lumpsums);
  }
}

module.exports = getSingleParentAllowances;