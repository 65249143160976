'use strict';

const Decimal = require('decimal.js');

const constants = require('../2016/constants-2016.json');
const { isJointAssessment } = require('../utils/utils_field');
const { getCommutingExpenses } = require('../utils/utils_distance');
const {
  getDecimal,
  iterateLfNr,
  getFieldsByIndex,
  getLfdNrGroupsByNrs,
} = require('../utils/utils_fields');

const ZERO = new Decimal(0);
const BASIC_TAX_FREE_AMOUNT_SA = new Decimal(9744);
const BASIC_TAX_FREE_AMOUNT_JA = new Decimal(19488);
const PROFESSIONAL_EXPENSES_LUMPSUM = new Decimal(constants.OVERALL_LUMP_SUM);
const MOBILITY_BONUS_PERCENTAGE = new Decimal(14).div(100);
const MINIMUM_MOBILITY_BONUS = new Decimal(10);

const commuteNrs = [
  // (aufgesucht an Tagen) Days per commute to first workplace - Format `N`
  '0203503',
  // (davon mit PKW zurückgelegt) Distance driven by car to first workplace - Format `N`
  '0203505',
  // (aufgesucht an Tagen) Days per commute to collection point - Format `N`
  '0203511',
  // (davon mit PKW zurückgelegt) Distance driven by car to collection point - Format `N`
  '0203513',
];

const doubleHouseHoldNrs = [
  // (Anzahl) Days of weekly home drives - Format `N`
  '0207117',
  // (Einfache Entfernung) Distance of weekly home drives - Format `N`
  '0207116',
];


const elsterNrs = [...commuteNrs, ...doubleHouseHoldNrs];
const defaultPersonResult = {
  amountToConsiderForMobilityBonus: ZERO,
  totalCommuteExpenseUpTo20KM: ZERO,
  totalCommuteExpenseOver20KM: ZERO,
  totalDoubleHouseholdExpenseUpTo20KM: ZERO,
  totalDoubleHouseholdExpenseOver20KM: ZERO,
  eligibleForMobilityBonus: false,
  eligibleForCommuteBonus: null,
  eligibleForProfessionalExpensesBonus: null,
};

const calculateMobilityBonusForPerson = (fields, personEarnings) => {
  const result = { ...defaultPersonResult };
  let totalCommuteDistanceOver20KM = ZERO;

  // Step 2: Check eligibility based on commute distance over 20km
  getFieldsByIndex(fields, commuteNrs).forEach((indexFields) => {
    const totalWorkPlaceDays = getDecimal(indexFields, '0203503');
    const totalWorkPlaceDistanceByCar = getDecimal(indexFields, '0203505');
    const totalCollectiveDays = getDecimal(indexFields, '0203511');
    const totalCollectiveDistanceByCar = getDecimal(indexFields, '0203513');

    const totalDays = totalWorkPlaceDays.add(totalCollectiveDays);
    const totalDistance = totalWorkPlaceDistanceByCar.add(totalCollectiveDistanceByCar);

    const { distanceOver20KM, expenseUpTo20KM, expenseOver20KM } = getCommutingExpenses(totalDays, totalDistance);

    totalCommuteDistanceOver20KM = totalCommuteDistanceOver20KM.add(distanceOver20KM);
    result.totalCommuteExpenseUpTo20KM = result.totalCommuteExpenseUpTo20KM.add(expenseUpTo20KM);
    result.totalCommuteExpenseOver20KM = result.totalCommuteExpenseOver20KM.add(expenseOver20KM);
  });

  /**
   * Set eligibleForCommuteBonus for the current person
   * If totalCommuteDistanceOver20KM is not greater than 0,
   * then this person won't be eligible for mobility bonus
   */
  result.eligibleForCommuteBonus = totalCommuteDistanceOver20KM.gt(ZERO);

  /**
   * Get possible extra bonus as a result of double household.
   * This has nothing to do with the person's eligibility
   */
  getFieldsByIndex(fields, doubleHouseHoldNrs).forEach((indexFields) => {
    const totalDrivingHomeDays = getDecimal(indexFields, '0207117');
    const totalDrivingHomeDistance = getDecimal(indexFields, '0207116');
    const { expenseUpTo20KM, expenseOver20KM } = getCommutingExpenses(
      totalDrivingHomeDays,
      totalDrivingHomeDistance
    );

    result.totalDoubleHouseholdExpenseUpTo20KM = result.totalDoubleHouseholdExpenseUpTo20KM.add(expenseUpTo20KM);
    result.totalDoubleHouseholdExpenseOver20KM = result.totalDoubleHouseholdExpenseOver20KM.add(expenseOver20KM);
  });

  // Get the professional expenses for the current person
  const professionalExpenseForPerson = personEarnings ? personEarnings.professionalExpenses : ZERO;
 
  // Step 3: Check eligibility based on professional expenses
  result.eligibleForProfessionalExpensesBonus = professionalExpenseForPerson.gt(PROFESSIONAL_EXPENSES_LUMPSUM);

  // Sets that the person is eligible for mobility bonus
  const { eligibleForCommuteBonus, eligibleForProfessionalExpensesBonus } = result;
  result.eligibleForMobilityBonus = eligibleForCommuteBonus && eligibleForProfessionalExpensesBonus;

  /**
   * From here on, we calculate the amount to consider
   * for mobility bonus for this person if they are
   * eligible.
   */
  if (result.eligibleForMobilityBonus) {
    const { totalCommuteExpenseOver20KM,  totalDoubleHouseholdExpenseOver20KM } = result;
    const sumAllCommuteExpensesOver20Km = totalCommuteExpenseOver20KM.add(totalDoubleHouseholdExpenseOver20KM);
    const professionalExpenseWithoutLumpSum = professionalExpenseForPerson.sub(PROFESSIONAL_EXPENSES_LUMPSUM);

    if (professionalExpenseWithoutLumpSum.lte(ZERO)) {
      result.amountToConsiderForMobilityBonus = ZERO;
    } else if (professionalExpenseWithoutLumpSum.gt(PROFESSIONAL_EXPENSES_LUMPSUM)) {
      result.amountToConsiderForMobilityBonus = Decimal.max(ZERO, sumAllCommuteExpensesOver20Km);
    } else if (
      professionalExpenseWithoutLumpSum.gt(ZERO) &&
      professionalExpenseWithoutLumpSum.lt(PROFESSIONAL_EXPENSES_LUMPSUM)
    ) {
      result.amountToConsiderForMobilityBonus = Decimal.max(
        ZERO,
        Decimal.min(
          sumAllCommuteExpensesOver20Km,
          professionalExpenseWithoutLumpSum
        )
      );
    }
  }

  return result;
};

const calculateMobilityBonus2021 = (fields, totalTaxableIncome, personsEarnings) => {
  const isJA = isJointAssessment(fields);

  /**
   * For personA to be eligible for mobility bonus,
   * personA.eligibleForMobilityBonus must be true Which means:
   * eligibleForMobilityBonus = true,
   * personA.eligibleForCommuteBonus = true,
   * personA.eligibleForProfessionalExpensesBonus = true
   *
   * For personB to be eligible for mobility bonus,
   * personB.eligibleForMobilityBonus must be true Which means:
   * eligibleForMobilityBonus = true,
   * personB.eligibleForCommuteBonus = true,
   * personB.eligibleForProfessionalExpensesBonus = true
   */
  const result = {
    totalMobilityBonus: ZERO,
    eligibleForTaxFreeBonus: false,
    personA: { ...defaultPersonResult },
    personB: { ...defaultPersonResult },
  };

  // Get basic tax-free amount based on filers
  const basicTaxFreeAmount = isJA ? BASIC_TAX_FREE_AMOUNT_JA : BASIC_TAX_FREE_AMOUNT_SA;

  // Step 1: Check eligibility based on total taxable income
  result.eligibleForTaxFreeBonus = totalTaxableIncome.lt(basicTaxFreeAmount);

  if (!result.eligibleForTaxFreeBonus) {
    return result;
  }

  const lfdNrGroups = getLfdNrGroupsByNrs(fields, elsterNrs);
  const maxIterations = isJA ? 2 : 1;

  // For each person
  iterateLfNr(
    lfdNrGroups,
    (lfdNrFields, lfdNr) => {
      // Calculate mobility bonus for personA
      if (lfdNr === 0) {
        const personAEarnings = personsEarnings[0];
        result.personA = calculateMobilityBonusForPerson(lfdNrFields, personAEarnings);
      } else if (lfdNr === 1) {
        const personBEarnings = personsEarnings[1];
        result.personB = calculateMobilityBonusForPerson(lfdNrFields, personBEarnings);
      }
    },
    maxIterations
  );

  // Calculate the total mobility bonus if any
  // of the filers are eligible for mobility bonus
  const { personA, personB } = result;

  if (personA.eligibleForMobilityBonus || personB.eligibleForMobilityBonus) {
    let usableMobilityBonus = ZERO;

    // Get difference between tax-free amount and total taxable income
    const diffTaxableIncomeAndTaxFreeAmount = Decimal.max(ZERO, basicTaxFreeAmount.sub(totalTaxableIncome));
    const totalAmountToConsiderForMobilityBonus =
      personA.amountToConsiderForMobilityBonus.add(
        personB.amountToConsiderForMobilityBonus
      );

    if (totalAmountToConsiderForMobilityBonus.lt(diffTaxableIncomeAndTaxFreeAmount)) {
      usableMobilityBonus = totalAmountToConsiderForMobilityBonus;
    } else if (totalAmountToConsiderForMobilityBonus.gt(diffTaxableIncomeAndTaxFreeAmount)) {
      usableMobilityBonus = diffTaxableIncomeAndTaxFreeAmount;
    }

    // Apply MobilityBonusPercentage(14%) to the calculated mobility bonus
    const totalMobilityBonus = usableMobilityBonus.times(MOBILITY_BONUS_PERCENTAGE).toDP(2);

    /**
     * https://taxfix.atlassian.net/browse/DEM4-653
     * The total mobility bonus must be greater or equal to
     * the allowed minimum mobility bonus(10)
     */
    result.totalMobilityBonus = totalMobilityBonus.lt(MINIMUM_MOBILITY_BONUS) ? ZERO : totalMobilityBonus;
  }

  return result;
};

module.exports = calculateMobilityBonus2021;
