// @flow
import countries from 'i18n-iso-countries';

import BaseAnswer from './Base';

import type { PlaceAnswer } from './shared/Address';

import type { ValidationSchema, ValidationFunction } from '../flowtypes';

/**
 * This needed to support all working environments
 */
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));

export class Address extends BaseAnswer {
  constructor(name?: string) {
    const formats = [
      { name: 'STREET', type: 'string' },
      { name: 'NUMBER', type: 'string' },
      { name: 'NUMBER_EXTENSION', type: 'string' },
      { name: 'ADDITION', type: 'string' },
      { name: 'CITY', type: 'string' },
      { name: 'STATE', type: 'string' },
      { name: 'POSTAL_CODE', type: 'string' },
      { name: 'COUNTRY', type: 'string' },
      { name: 'ISO_COUNTRY_CODE', type: 'string' },
      { name: 'IS_COUNTRY_DE', type: 'boolean' },
      { name: 'SINGLE_LINE', type: 'string' },
      { name: 'COUNTRY_NAME_IN_DE', type: 'string' },
    ];

    super(name || 'address', formats);
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/address.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/address');
    /* eslint-enable global-require */
  }

  format(value: any, formatKey: string): ?(string | boolean) {
    super.format(value);

    const addressValue: PlaceAnswer = value;
    const requestedFormat = this.getFormat(formatKey).name;

    const countryCode = this.valueOf(addressValue, 'countryCode');

    switch (requestedFormat) {
      case this.formats.STREET:
        return this.valueOf(addressValue, 'address.street');

      case this.formats.NUMBER:
        return this.valueOf(addressValue, 'address.number');

      case this.formats.NUMBER_EXTENSION:
        return this.valueOf(addressValue, 'address.numberExtension');

      case this.formats.ADDITION:
        return this.valueOf(addressValue, 'address.addition');

      case this.formats.CITY:
        return this.valueOf(addressValue, 'address.city');

      case this.formats.STATE:
        return this.valueOf(addressValue, 'state');

      case this.formats.POSTAL_CODE:
        return this.valueOf(addressValue, 'postalCode');

      case this.formats.COUNTRY:
      case this.formats.ISO_COUNTRY_CODE:
        return countryCode;

      case this.formats.IS_COUNTRY_DE:
        return this.get(addressValue, 'countryCode', '').toUpperCase() === 'DE';

      // We want the full name of the country in a language of submitting country.
      case this.formats.COUNTRY_NAME_IN_DE:
        return countries.getName(countryCode, 'de');

      case this.formats.SINGLE_LINE:
        // this is reasonably fragile and specific to germany.
        // TODO: find an address formatting library, maybe?
        return [
          `${this.get(addressValue, 'address.street')} ${this.get(
            addressValue,
            'address.number',
          )}${this.get(addressValue, 'address.numberExtension')}`,
          this.get(addressValue, 'address.addition'),
          `${this.get(addressValue, 'address.postalCode')} ${this.get(
            addressValue,
            'address.city',
          )}`,
        ]
          .filter(line => line != null && line !== '')
          .join(', ');

      default:
        break;
    }

    return '';
  }
}

export default new Address();
