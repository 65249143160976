'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const { getBool, getDecimal } = require('../utils/utils_fields');
const { toString } = require('../utils/utils_logs');

function taxLossDeduction2016 (fields) {
  logger.debug('\n-------------------------\n TAX LOSS DEDUCTION');

  let expenses = new Decimal(0);
  try {
    const taxLossPersonA = getBool(fields, '0109205', 'X');
    if (taxLossPersonA === true) {
      expenses = getDecimal(fields, 'txf-tax-loss-deduction');
    }
    const taxLossPersonB = getBool(fields, '0109206', 'X');
    if (taxLossPersonB === true) {
      expenses = expenses.add(getDecimal(fields, 'txf-tax-loss-deduction-B'));
    }
  } catch (error) {
    throw error;
  }
  logger.debug('TaxLoss Deduction together', toString(expenses));

  return expenses;
}

module.exports = taxLossDeduction2016;