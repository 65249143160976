'use strict';

const { getValue, getValuesByLfdNr } = require('../utils');

const MAX_INCOME_SA = 9744;
const MAX_INCOME_JA = 19488;
const MIN_DISTANCE = 20;
const MIN_SALARY = 0;

function applyMobilityBonus(fields, calculationResult = {}) {
  // If mobilityBonus already exists stop processing
  const existingMobilityBonus = getValue(fields, '0420401');
  if (existingMobilityBonus != null) return fields;

  // If calculations aren't given stop processing
  const { taxableIncome, grossSalaryPersonA, grossSalaryPersonB } = calculationResult;
  if (
    taxableIncome == null ||
    grossSalaryPersonA == null
  ) return fields;

  const isJA = getValue(fields, '0101201') === 'X';

  if (isJA && grossSalaryPersonB == null) return fields;

  const {
    personA: firstWorkplaceDistancePersonA,
    personB: firstWorkplaceDistancePersonB,
  } = getValuesByLfdNr(fields, '0203504');
  const {
    personA: meetingPointDistancePersonA,
    personB: meetingPointDistancePersonB,
  } = getValuesByLfdNr(fields, '0203512');
  const {
    personA: doubleHouseholdPersonA,
    personB: doubleHouseholdPersonB,
  } = getValuesByLfdNr(fields, '0207116');

  const distanceAboveMinDistancePersonA = firstWorkplaceDistancePersonA > MIN_DISTANCE ||
                                          meetingPointDistancePersonA > MIN_DISTANCE ||
                                          doubleHouseholdPersonA > MIN_DISTANCE;

  const distanceAboveMinDistancePersonB = firstWorkplaceDistancePersonB > MIN_DISTANCE ||
                                          meetingPointDistancePersonB > MIN_DISTANCE ||
                                          doubleHouseholdPersonB > MIN_DISTANCE;

  const salaryAboveMinSalaryPersonA = grossSalaryPersonA > MIN_SALARY;
  const salaryAboveMinSalaryPersonB = grossSalaryPersonB > MIN_SALARY;

  const incomeBeloweMaxIncome = isJA ?
    taxableIncome <= MAX_INCOME_JA : taxableIncome <= MAX_INCOME_SA;

  const personAHasDistanceAndSalary =
    distanceAboveMinDistancePersonA && salaryAboveMinSalaryPersonA;
  const personBHasDistanceAndSalary =
    distanceAboveMinDistancePersonB && salaryAboveMinSalaryPersonB;

  const hasMobilityBonus =
    incomeBeloweMaxIncome &&
    (personAHasDistanceAndSalary || personBHasDistanceAndSalary)
  ;

  // Stop here if mobilityBonus doesn't apply
  if (!hasMobilityBonus) return fields;

  // Fill 0420401
  fields.push({ nr: '0420401', index: 1, lfdNr: 1, value: '1' });
  fields.push({ nr: '0100302', index: 1, lfdNr: 1, value: 'X' });

  // Fill 0420501 and 0420601 for PersonA
  if (personAHasDistanceAndSalary) {
    fields.push({ nr: '0420501', index: 1, lfdNr: 1, value: '1' });
    fields.push({ nr: '0420601', index: 1, lfdNr: 1, value: '2' });
  }

  // Fill 0420501 and 0420601 for PersonB
  if (personBHasDistanceAndSalary) {
    fields.push({ nr: '0420501', index: 1, lfdNr: 2, value: '1' });
    fields.push({ nr: '0420601', index: 1, lfdNr: 2, value: '2' });
  }

  return fields;
}

module.exports = {
  applyMobilityBonus,
};
