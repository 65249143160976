'use strict';
module.exports = validate21;
module.exports.default = validate21;
var schema6 = {
  $id: 'http://taxfix.de/schemas/answers/bankconfirmation.json',
  $schema: 'http://json-schema.org/draft-06/schema#',
  type: 'object',
  properties: {
    kapitalertrage: { type: 'number' },
    davonAktienverausserungen: { type: 'number' },
    kapitalertrageLebensversicherungen: { type: 'number' },
    ersatzbemessungsgrundlage: { type: 'number' },
    verlusteOhneAktien: { type: 'number' },
    verlusteAusAktien: { type: 'number' },
    sparerPauschbetragGenutzt: { type: 'number' },
    kapest: { type: 'number' },
    solz: { type: 'number' },
    kist: { type: 'number' },
    anrechenbareAuslandSteuern: { type: 'number' },
    nochNichtAngerechneteAuslandSteuern: { type: 'number' },
    nochNichtAngerechneteAuslandSteuernIsNegative: { type: 'boolean' },
    leistungenSteuerlEinlagekonto: { type: 'boolean' },
    auslandInvestmentfonds: { type: 'boolean' },
    auslandInvestmentfondsErtrage: { type: 'number' },
    auslandInvestmentfondsAngerechneteSteuern: { type: 'number' },
    auslandInvestmentfondsI: { type: 'boolean' },
    auslandInvestmentfondsIErtrage: { type: 'number' },
    auslandInvestmentfondsIAngerechneteSteuern: { type: 'number' },
    zugeflosseneErtrageOhneSteuerabzug: { type: 'number' },
    kapitalertraegeLebensversicherungenAb2018: { type: 'number' },
    kapitalertraegeLebensversicherungenKapESt: { type: 'number' },
    kapitalertraegeLebensversicherungenSolZ: { type: 'number' },
    kapitalertraegeLebensversicherungenKiSt: { type: 'number' },
    anteileAuslaendischeInvFondsErworbenvor2018: { type: 'boolean' },
    zugeflosseneErtraegeOhneSteuerabzugAb2018: { type: 'number' },
    davonTermingeschaefteStillhalterpraemien: { type: 'number' },
    davonVeraeusserungenAltAnteile: { type: 'number' },
    verluste20abs6s5Termingeschaefte: { type: 'number' },
    verluste20abs6s6UneinbringlichWertlos: { type: 'number' },
  },
  additionalProperties: false,
  required: [
    'kapitalertrage',
    'davonAktienverausserungen',
    'ersatzbemessungsgrundlage',
    'verlusteOhneAktien',
    'verlusteAusAktien',
    'sparerPauschbetragGenutzt',
    'kapest',
    'solz',
    'kist',
    'anrechenbareAuslandSteuern',
    'nochNichtAngerechneteAuslandSteuern',
    'leistungenSteuerlEinlagekonto',
  ],
};
var func3 = Object.prototype.hasOwnProperty;

function validate21(data, valCxt) {
  'use strict' /*# sourceURL="http://taxfix.de/schemas/answers/bankconfirmation.json" */;
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.kapitalertrage === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'kapitalertrage' },
        message: "should have required property '" + 'kapitalertrage' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.davonAktienverausserungen === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'davonAktienverausserungen' },
        message: "should have required property '" + 'davonAktienverausserungen' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.ersatzbemessungsgrundlage === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'ersatzbemessungsgrundlage' },
        message: "should have required property '" + 'ersatzbemessungsgrundlage' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.verlusteOhneAktien === undefined) {
      var err3 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'verlusteOhneAktien' },
        message: "should have required property '" + 'verlusteOhneAktien' + "'",
      };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.verlusteAusAktien === undefined) {
      var err4 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'verlusteAusAktien' },
        message: "should have required property '" + 'verlusteAusAktien' + "'",
      };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data.sparerPauschbetragGenutzt === undefined) {
      var err5 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'sparerPauschbetragGenutzt' },
        message: "should have required property '" + 'sparerPauschbetragGenutzt' + "'",
      };
      if (vErrors === null) {
        vErrors = [err5];
      } else {
        vErrors.push(err5);
      }
      errors++;
    }
    if (data.kapest === undefined) {
      var err6 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'kapest' },
        message: "should have required property '" + 'kapest' + "'",
      };
      if (vErrors === null) {
        vErrors = [err6];
      } else {
        vErrors.push(err6);
      }
      errors++;
    }
    if (data.solz === undefined) {
      var err7 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'solz' },
        message: "should have required property '" + 'solz' + "'",
      };
      if (vErrors === null) {
        vErrors = [err7];
      } else {
        vErrors.push(err7);
      }
      errors++;
    }
    if (data.kist === undefined) {
      var err8 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'kist' },
        message: "should have required property '" + 'kist' + "'",
      };
      if (vErrors === null) {
        vErrors = [err8];
      } else {
        vErrors.push(err8);
      }
      errors++;
    }
    if (data.anrechenbareAuslandSteuern === undefined) {
      var err9 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'anrechenbareAuslandSteuern' },
        message: "should have required property '" + 'anrechenbareAuslandSteuern' + "'",
      };
      if (vErrors === null) {
        vErrors = [err9];
      } else {
        vErrors.push(err9);
      }
      errors++;
    }
    if (data.nochNichtAngerechneteAuslandSteuern === undefined) {
      var err10 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'nochNichtAngerechneteAuslandSteuern' },
        message: "should have required property '" + 'nochNichtAngerechneteAuslandSteuern' + "'",
      };
      if (vErrors === null) {
        vErrors = [err10];
      } else {
        vErrors.push(err10);
      }
      errors++;
    }
    if (data.leistungenSteuerlEinlagekonto === undefined) {
      var err11 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'leistungenSteuerlEinlagekonto' },
        message: "should have required property '" + 'leistungenSteuerlEinlagekonto' + "'",
      };
      if (vErrors === null) {
        vErrors = [err11];
      } else {
        vErrors.push(err11);
      }
      errors++;
    }
    for (var key0 in data) {
      if (!func3.call(schema6.properties, key0)) {
        var err12 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err12];
        } else {
          vErrors.push(err12);
        }
        errors++;
      }
    }
    if (data.kapitalertrage !== undefined) {
      if (!(typeof data.kapitalertrage == 'number')) {
        var err13 = {
          keyword: 'type',
          dataPath: dataPath + '/kapitalertrage',
          schemaPath: '#/properties/kapitalertrage/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err13];
        } else {
          vErrors.push(err13);
        }
        errors++;
      }
    }
    if (data.davonAktienverausserungen !== undefined) {
      if (!(typeof data.davonAktienverausserungen == 'number')) {
        var err14 = {
          keyword: 'type',
          dataPath: dataPath + '/davonAktienverausserungen',
          schemaPath: '#/properties/davonAktienverausserungen/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err14];
        } else {
          vErrors.push(err14);
        }
        errors++;
      }
    }
    if (data.kapitalertrageLebensversicherungen !== undefined) {
      if (!(typeof data.kapitalertrageLebensversicherungen == 'number')) {
        var err15 = {
          keyword: 'type',
          dataPath: dataPath + '/kapitalertrageLebensversicherungen',
          schemaPath: '#/properties/kapitalertrageLebensversicherungen/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err15];
        } else {
          vErrors.push(err15);
        }
        errors++;
      }
    }
    if (data.ersatzbemessungsgrundlage !== undefined) {
      if (!(typeof data.ersatzbemessungsgrundlage == 'number')) {
        var err16 = {
          keyword: 'type',
          dataPath: dataPath + '/ersatzbemessungsgrundlage',
          schemaPath: '#/properties/ersatzbemessungsgrundlage/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err16];
        } else {
          vErrors.push(err16);
        }
        errors++;
      }
    }
    if (data.verlusteOhneAktien !== undefined) {
      if (!(typeof data.verlusteOhneAktien == 'number')) {
        var err17 = {
          keyword: 'type',
          dataPath: dataPath + '/verlusteOhneAktien',
          schemaPath: '#/properties/verlusteOhneAktien/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err17];
        } else {
          vErrors.push(err17);
        }
        errors++;
      }
    }
    if (data.verlusteAusAktien !== undefined) {
      if (!(typeof data.verlusteAusAktien == 'number')) {
        var err18 = {
          keyword: 'type',
          dataPath: dataPath + '/verlusteAusAktien',
          schemaPath: '#/properties/verlusteAusAktien/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err18];
        } else {
          vErrors.push(err18);
        }
        errors++;
      }
    }
    if (data.sparerPauschbetragGenutzt !== undefined) {
      if (!(typeof data.sparerPauschbetragGenutzt == 'number')) {
        var err19 = {
          keyword: 'type',
          dataPath: dataPath + '/sparerPauschbetragGenutzt',
          schemaPath: '#/properties/sparerPauschbetragGenutzt/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err19];
        } else {
          vErrors.push(err19);
        }
        errors++;
      }
    }
    if (data.kapest !== undefined) {
      if (!(typeof data.kapest == 'number')) {
        var err20 = {
          keyword: 'type',
          dataPath: dataPath + '/kapest',
          schemaPath: '#/properties/kapest/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err20];
        } else {
          vErrors.push(err20);
        }
        errors++;
      }
    }
    if (data.solz !== undefined) {
      if (!(typeof data.solz == 'number')) {
        var err21 = {
          keyword: 'type',
          dataPath: dataPath + '/solz',
          schemaPath: '#/properties/solz/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err21];
        } else {
          vErrors.push(err21);
        }
        errors++;
      }
    }
    if (data.kist !== undefined) {
      if (!(typeof data.kist == 'number')) {
        var err22 = {
          keyword: 'type',
          dataPath: dataPath + '/kist',
          schemaPath: '#/properties/kist/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err22];
        } else {
          vErrors.push(err22);
        }
        errors++;
      }
    }
    if (data.anrechenbareAuslandSteuern !== undefined) {
      if (!(typeof data.anrechenbareAuslandSteuern == 'number')) {
        var err23 = {
          keyword: 'type',
          dataPath: dataPath + '/anrechenbareAuslandSteuern',
          schemaPath: '#/properties/anrechenbareAuslandSteuern/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err23];
        } else {
          vErrors.push(err23);
        }
        errors++;
      }
    }
    if (data.nochNichtAngerechneteAuslandSteuern !== undefined) {
      if (!(typeof data.nochNichtAngerechneteAuslandSteuern == 'number')) {
        var err24 = {
          keyword: 'type',
          dataPath: dataPath + '/nochNichtAngerechneteAuslandSteuern',
          schemaPath: '#/properties/nochNichtAngerechneteAuslandSteuern/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err24];
        } else {
          vErrors.push(err24);
        }
        errors++;
      }
    }
    if (data.nochNichtAngerechneteAuslandSteuernIsNegative !== undefined) {
      if (typeof data.nochNichtAngerechneteAuslandSteuernIsNegative !== 'boolean') {
        var err25 = {
          keyword: 'type',
          dataPath: dataPath + '/nochNichtAngerechneteAuslandSteuernIsNegative',
          schemaPath: '#/properties/nochNichtAngerechneteAuslandSteuernIsNegative/type',
          params: { type: 'boolean' },
          message: 'should be boolean',
        };
        if (vErrors === null) {
          vErrors = [err25];
        } else {
          vErrors.push(err25);
        }
        errors++;
      }
    }
    if (data.leistungenSteuerlEinlagekonto !== undefined) {
      if (typeof data.leistungenSteuerlEinlagekonto !== 'boolean') {
        var err26 = {
          keyword: 'type',
          dataPath: dataPath + '/leistungenSteuerlEinlagekonto',
          schemaPath: '#/properties/leistungenSteuerlEinlagekonto/type',
          params: { type: 'boolean' },
          message: 'should be boolean',
        };
        if (vErrors === null) {
          vErrors = [err26];
        } else {
          vErrors.push(err26);
        }
        errors++;
      }
    }
    if (data.auslandInvestmentfonds !== undefined) {
      if (typeof data.auslandInvestmentfonds !== 'boolean') {
        var err27 = {
          keyword: 'type',
          dataPath: dataPath + '/auslandInvestmentfonds',
          schemaPath: '#/properties/auslandInvestmentfonds/type',
          params: { type: 'boolean' },
          message: 'should be boolean',
        };
        if (vErrors === null) {
          vErrors = [err27];
        } else {
          vErrors.push(err27);
        }
        errors++;
      }
    }
    if (data.auslandInvestmentfondsErtrage !== undefined) {
      if (!(typeof data.auslandInvestmentfondsErtrage == 'number')) {
        var err28 = {
          keyword: 'type',
          dataPath: dataPath + '/auslandInvestmentfondsErtrage',
          schemaPath: '#/properties/auslandInvestmentfondsErtrage/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err28];
        } else {
          vErrors.push(err28);
        }
        errors++;
      }
    }
    if (data.auslandInvestmentfondsAngerechneteSteuern !== undefined) {
      if (!(typeof data.auslandInvestmentfondsAngerechneteSteuern == 'number')) {
        var err29 = {
          keyword: 'type',
          dataPath: dataPath + '/auslandInvestmentfondsAngerechneteSteuern',
          schemaPath: '#/properties/auslandInvestmentfondsAngerechneteSteuern/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err29];
        } else {
          vErrors.push(err29);
        }
        errors++;
      }
    }
    if (data.auslandInvestmentfondsI !== undefined) {
      if (typeof data.auslandInvestmentfondsI !== 'boolean') {
        var err30 = {
          keyword: 'type',
          dataPath: dataPath + '/auslandInvestmentfondsI',
          schemaPath: '#/properties/auslandInvestmentfondsI/type',
          params: { type: 'boolean' },
          message: 'should be boolean',
        };
        if (vErrors === null) {
          vErrors = [err30];
        } else {
          vErrors.push(err30);
        }
        errors++;
      }
    }
    if (data.auslandInvestmentfondsIErtrage !== undefined) {
      if (!(typeof data.auslandInvestmentfondsIErtrage == 'number')) {
        var err31 = {
          keyword: 'type',
          dataPath: dataPath + '/auslandInvestmentfondsIErtrage',
          schemaPath: '#/properties/auslandInvestmentfondsIErtrage/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err31];
        } else {
          vErrors.push(err31);
        }
        errors++;
      }
    }
    if (data.auslandInvestmentfondsIAngerechneteSteuern !== undefined) {
      if (!(typeof data.auslandInvestmentfondsIAngerechneteSteuern == 'number')) {
        var err32 = {
          keyword: 'type',
          dataPath: dataPath + '/auslandInvestmentfondsIAngerechneteSteuern',
          schemaPath: '#/properties/auslandInvestmentfondsIAngerechneteSteuern/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err32];
        } else {
          vErrors.push(err32);
        }
        errors++;
      }
    }
    if (data.zugeflosseneErtrageOhneSteuerabzug !== undefined) {
      if (!(typeof data.zugeflosseneErtrageOhneSteuerabzug == 'number')) {
        var err33 = {
          keyword: 'type',
          dataPath: dataPath + '/zugeflosseneErtrageOhneSteuerabzug',
          schemaPath: '#/properties/zugeflosseneErtrageOhneSteuerabzug/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err33];
        } else {
          vErrors.push(err33);
        }
        errors++;
      }
    }
    if (data.kapitalertraegeLebensversicherungenAb2018 !== undefined) {
      if (!(typeof data.kapitalertraegeLebensversicherungenAb2018 == 'number')) {
        var err34 = {
          keyword: 'type',
          dataPath: dataPath + '/kapitalertraegeLebensversicherungenAb2018',
          schemaPath: '#/properties/kapitalertraegeLebensversicherungenAb2018/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err34];
        } else {
          vErrors.push(err34);
        }
        errors++;
      }
    }
    if (data.kapitalertraegeLebensversicherungenKapESt !== undefined) {
      if (!(typeof data.kapitalertraegeLebensversicherungenKapESt == 'number')) {
        var err35 = {
          keyword: 'type',
          dataPath: dataPath + '/kapitalertraegeLebensversicherungenKapESt',
          schemaPath: '#/properties/kapitalertraegeLebensversicherungenKapESt/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err35];
        } else {
          vErrors.push(err35);
        }
        errors++;
      }
    }
    if (data.kapitalertraegeLebensversicherungenSolZ !== undefined) {
      if (!(typeof data.kapitalertraegeLebensversicherungenSolZ == 'number')) {
        var err36 = {
          keyword: 'type',
          dataPath: dataPath + '/kapitalertraegeLebensversicherungenSolZ',
          schemaPath: '#/properties/kapitalertraegeLebensversicherungenSolZ/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err36];
        } else {
          vErrors.push(err36);
        }
        errors++;
      }
    }
    if (data.kapitalertraegeLebensversicherungenKiSt !== undefined) {
      if (!(typeof data.kapitalertraegeLebensversicherungenKiSt == 'number')) {
        var err37 = {
          keyword: 'type',
          dataPath: dataPath + '/kapitalertraegeLebensversicherungenKiSt',
          schemaPath: '#/properties/kapitalertraegeLebensversicherungenKiSt/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err37];
        } else {
          vErrors.push(err37);
        }
        errors++;
      }
    }
    if (data.anteileAuslaendischeInvFondsErworbenvor2018 !== undefined) {
      if (typeof data.anteileAuslaendischeInvFondsErworbenvor2018 !== 'boolean') {
        var err38 = {
          keyword: 'type',
          dataPath: dataPath + '/anteileAuslaendischeInvFondsErworbenvor2018',
          schemaPath: '#/properties/anteileAuslaendischeInvFondsErworbenvor2018/type',
          params: { type: 'boolean' },
          message: 'should be boolean',
        };
        if (vErrors === null) {
          vErrors = [err38];
        } else {
          vErrors.push(err38);
        }
        errors++;
      }
    }
    if (data.zugeflosseneErtraegeOhneSteuerabzugAb2018 !== undefined) {
      if (!(typeof data.zugeflosseneErtraegeOhneSteuerabzugAb2018 == 'number')) {
        var err39 = {
          keyword: 'type',
          dataPath: dataPath + '/zugeflosseneErtraegeOhneSteuerabzugAb2018',
          schemaPath: '#/properties/zugeflosseneErtraegeOhneSteuerabzugAb2018/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err39];
        } else {
          vErrors.push(err39);
        }
        errors++;
      }
    }
    if (data.davonTermingeschaefteStillhalterpraemien !== undefined) {
      if (!(typeof data.davonTermingeschaefteStillhalterpraemien == 'number')) {
        var err40 = {
          keyword: 'type',
          dataPath: dataPath + '/davonTermingeschaefteStillhalterpraemien',
          schemaPath: '#/properties/davonTermingeschaefteStillhalterpraemien/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err40];
        } else {
          vErrors.push(err40);
        }
        errors++;
      }
    }
    if (data.davonVeraeusserungenAltAnteile !== undefined) {
      if (!(typeof data.davonVeraeusserungenAltAnteile == 'number')) {
        var err41 = {
          keyword: 'type',
          dataPath: dataPath + '/davonVeraeusserungenAltAnteile',
          schemaPath: '#/properties/davonVeraeusserungenAltAnteile/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err41];
        } else {
          vErrors.push(err41);
        }
        errors++;
      }
    }
    if (data.verluste20abs6s5Termingeschaefte !== undefined) {
      if (!(typeof data.verluste20abs6s5Termingeschaefte == 'number')) {
        var err42 = {
          keyword: 'type',
          dataPath: dataPath + '/verluste20abs6s5Termingeschaefte',
          schemaPath: '#/properties/verluste20abs6s5Termingeschaefte/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err42];
        } else {
          vErrors.push(err42);
        }
        errors++;
      }
    }
    if (data.verluste20abs6s6UneinbringlichWertlos !== undefined) {
      if (!(typeof data.verluste20abs6s6UneinbringlichWertlos == 'number')) {
        var err43 = {
          keyword: 'type',
          dataPath: dataPath + '/verluste20abs6s6UneinbringlichWertlos',
          schemaPath: '#/properties/verluste20abs6s6UneinbringlichWertlos/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err43];
        } else {
          vErrors.push(err43);
        }
        errors++;
      }
    }
  } else {
    var err44 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err44];
    } else {
      vErrors.push(err44);
    }
    errors++;
  }
  validate21.errors = vErrors;
  return errors === 0;
}
