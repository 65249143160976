'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const { getDecimal, getBoolWithLfdNr } = require('../utils/utils_fields');
const { toString } = require('../utils/utils_logs');

function taxLossDeduction2020 (fields) {
  logger.debug('\n-------------------------\n TAX LOSS DEDUCTION');
  // getBoolWithLfdNr({ fields, nr: '0190702', lookFor: 'X', lfdNr: 2 });
  let expenses = new Decimal(0);
  try {
    const taxLossPersonA = getBoolWithLfdNr({ fields, nr: '0190701', lookFor: 1, lfdNr: 1 });
    if (taxLossPersonA === true) {
      expenses = getDecimal(fields, 'txf-tax-loss-deduction');
    }
    const taxLossPersonB = getBoolWithLfdNr({ fields, nr: '0190701', lookFor: 1, lfdNr: 2 });
    if (taxLossPersonB === true) {
      expenses = expenses.add(getDecimal(fields, 'txf-tax-loss-deduction-B'));
    }
  } catch (error) {
    throw error;
  }
  logger.debug('TaxLoss Deduction together', toString(expenses));

  return expenses;
}

module.exports = taxLossDeduction2020;
