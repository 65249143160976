// @flow
import type { Id, TreeNode, Translations } from '../types';

/**
 * Returns the translations keys of the specified question, by applying possible variations
 * @param node the question
 * @param inputs resolved inputs for this questions
 * @returns {{question: string, summary: string, info: string}}
 */
// eslint-disable-next-line import/prefer-default-export
export const translationKeys = (node: TreeNode, inputs: {[id: Id]: any} = {}): Translations => {
  let variationId: ?string;
  if (node.translationVariations) {
    const variation = node.translationVariations.find(tv => inputs[tv.condition] === true);
    if (variation) {
      variationId = variation.id;
    }
  }
  return {
    question: variationId ? `question::${node.questionId}::${variationId}` : `question::${node.questionId}`,
    summary: `summary::${node.questionId}`,
    info: `information::${node.questionId}`,
  };
};
