'use strict';

const Decimal = require('decimal.js');

const logger = require('../logger');

function percent(num, percent) {
  if (num instanceof Decimal && 
      (
        (typeof percent === 'number' && percent > 0) ||
        (percent instanceof Decimal && percent.greaterThan(0))
      ) 
    ) {
    return num.times(percent).div(100);
  }
  return new Decimal(0);
}


function toNumber(val) {
  if (typeof val === 'number') {
    return val;
  }

  if (val && typeof val === 'object' && typeof val.toNumber === 'function') {
    return val.toNumber();
  } else {
    logger.warn('You are trying to call `toNumber()` on a non-decimal value. Actual value is', val);
  }
}

module.exports = {
  percent,
  toNumber
};

