'use strict';

const { TaxYears } = require('@taxfix/de-itc-types');

const allowancesChildren2017 = require('../2017/allowances_children_2017');
const allowancesChildren2016 = require('../2016/allowances_children_2016');

function getChildrenAllowances(fields, lumpsums) {
  switch (lumpsums.year) {
    case TaxYears['2k21']:
    case TaxYears['2k20']:
    case TaxYears['2k19']:
    case TaxYears['2k18']:
    case TaxYears['2k17']:
      return allowancesChildren2017(fields, lumpsums);
    default:
      return allowancesChildren2016(fields, lumpsums);
  }
}

module.exports = getChildrenAllowances;