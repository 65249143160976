'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const { toString } = require('../utils/utils_logs');
const { applyLumpSumToBenefit } = require('../2016/income_subject_to_progression_2016');
const {
  getDecimal,
  getLfdNrGroupsByNrs,
  iterateLfNr
} = require('../utils/utils_fields');

function incomeSubjectToProgressionTax2020(fields, personEarnings, werbungskostenpauschbetrag) {
  let personA = new Decimal(0);
  let personB = new Decimal(0);
  let total = new Decimal(0);

  logger.debug('calculate subject to progression');
  // Steuerpflichtige Person: Einkommensersatzleistungen Summe
  personA = personA.add(getDecimal(fields, '0104801', 1, 1));
  logger.debug('sum A', toString(personA));

  // Steuerpflichtige Person B: Einkommensersatzleistungen Summe
  personB = personB.add(getDecimal(fields, '0104801', 1, 2));
  logger.debug('sum B', toString(personB));

  // sum Entgeltersatzleistungen (im Arbeitsverhältnis): Summe
  iterateLfNr(getLfdNrGroupsByNrs(fields, ['0202001']), (lfdNrFields, lfdNr) => {
    const replacementNumber = getDecimal(lfdNrFields, '0202001');
    if (lfdNr === 0) personA = personA.add(replacementNumber);
    if (lfdNr === 1) personB = personB.add(replacementNumber);
  });

  /**
   * Foreign income that was received outside the periods mentioned
   * in lines 4 and / or 5 and was not subject to German income tax.
   */
  const foreignIncome = getDecimal(fields, '0105101');
  logger.debug('Foreign income', toString(foreignIncome));

  const incomeReplacementBenefits = personA.add(personB);
  logger.debug('sum after replacment - A', toString(personA));
  logger.debug('sum after replacment - B', toString(personB));

  // Take werbungskostenpauschbetrag into account
  personA = applyLumpSumToBenefit(personA, personEarnings[0], werbungskostenpauschbetrag);
  personB = applyLumpSumToBenefit(personB, personEarnings[1], werbungskostenpauschbetrag);

  logger.debug('sum after Werbungskostenpauschbetrag - A', toString(personA));
  logger.debug('sum after Werbungskostenpauschbetrag - B', toString(personB));

  total = total.add(personA);
  total = total.add(personB);
  total = total.add(foreignIncome);

  logger.debug('Total "incomeSubjectToProgression2016"', toString(total));

  return {
    total,
    foreignIncome,
    incomeReplacementBenefits,
  };
}

module.exports = incomeSubjectToProgressionTax2020;

