// @flow

import { zipObject, has, get } from 'lodash';

import validateSchema from '../validateSchema';
import { FormatterError, ValueError } from '../error';

import type { ValidationFunction, ValidationSchema } from '../flowtypes';

type FormatType = {
  name: string,
  type: string,
};

type TransformedFormatType = {
  [key: string]: string,
};

class BaseAnswer {
  name: string;
  givenFormats: FormatType[];
  formats: TransformedFormatType;
  defaultType: string; // used by primitive types to define the only used type
  /**
   * Used to determine if a custom format file exist in the
   * custom-formats directory in the form `{this.name}.js`
   *
   * @type {boolean}
   */
  hasCustomFormat: boolean;

  constructor(name: string, formats: FormatType[] = []) {
    this.name = name;
    this.givenFormats = formats;
    this.hasCustomFormat = false;

    // transform formats to object list as before, to easier reference them from the outside
    const names = formats.map(format => format.name);
    this.formats = zipObject(names.map(n => n.toUpperCase()), names);
  }

  get validationSchema(): ?ValidationSchema {
    return null;
  }

  get validationFunction(): ?ValidationFunction {
    return null;
  }

  validate(value: any): boolean {
    if (this.isPrimitiveType) {
      // eslint-disable-next-line valid-typeof
      return typeof value === this.defaultType;
    }

    if (this.validationFunction == null) {
      return false;
    }

    return validateSchema(value, this.validationFunction, this.name);
  }

  format(...values: any): any {
    const value = values[0];
    if (!this.validate(value)) {
      throw new ValueError(`Invalid value: ${JSON.stringify(value)}`);
    }
  }

  output(formatKey: string): string | Array<string> {
    if (this.isPrimitiveType) {
      return this.defaultType;
    }
    return this.getFormat(formatKey).type;
  }

  getFormat(key: string): FormatType {
    const format = this.givenFormats.find(entry => key === entry.name);
    if (format === undefined) {
      throw new FormatterError(`Unknown format: ${key}`);
    }
    return format;
  }

  get isPrimitiveType(): boolean {
    return this.defaultType !== undefined;
  }

  get hasFormats(): boolean {
    return Object.keys(this.formats).length > 0;
  }

  valueOf(value: any, keyPath: string) {
    if (!has(value, keyPath)) {
      throw new FormatterError(`Missing value at keyPath: ${keyPath}`);
    }
    return this.get(value, keyPath);
  }

  // because lodash's get function defaultvalue doesn't work with null values,
  // this is a wrapper that also checks for null
  get(obj: any, path: string, defaultValue: string = '') {
    let res = get(obj, path, defaultValue);
    if (res === null) {
      res = defaultValue;
    }
    return res;
  }
}

export default BaseAnswer;
