// @flow

import { tinValidator } from '@taxfix/tin-validator';

export const IT_tax_number = (data: string) => tinValidator.validate('IT')(data);

export const customFormats = {
  IT_tax_number,
};

export default customFormats;
