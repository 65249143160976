'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _renderProps = require('./renderProps');

var _renderProps2 = _interopRequireDefault(_renderProps);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var isElement = function isElement(element) {
  return typeof element.type === 'function';
};

var compose = function compose() {
  for (var _len = arguments.length, elements = Array(_len), _key = 0; _key < _len; _key++) {
    elements[_key] = arguments[_key];
  }

  return function (composedProps) {

    // Stack children arguments recursively and pass
    // it down until the last component that render children
    // with these stacked arguments
    function stackProps(i, elements) {
      var stacked = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var element = elements[i];
      var isTheLast = i === 0;

      // Check if is latest component.
      // If is latest then render children,
      // Otherwise continue stacking arguments
      var renderFn = function renderFn(props) {
        return isTheLast ? (0, _renderProps2.default)(composedProps, _extends({}, props, stacked)) : stackProps(i - 1, elements, _extends({}, props, stacked));
      };

      // Clone a element if it's passed created as <Element initial={} />
      // Or create it if passed as just Element
      var elementFn = isElement(element) ? _react2.default.cloneElement : _react2.default.createElement;

      return elementFn(element, {}, renderFn);
    }

    return stackProps(elements.length - 1, elements);
  };
};

exports.default = compose;