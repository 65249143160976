'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/* eslint-disable no-console */

var warn = function warn(condition, message) {
  var trace = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

  if (condition) {
    console.warn('[react-powerplug]: ' + message);
    console.trace && trace && console.trace('Trace');
  }
};

exports.default = warn;