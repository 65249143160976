// @flow

import * as JSONRules from '@taxfix/json-rules';
import { isEmpty } from 'lodash';
import inputsForQuestion from './calculator/inputsForQuestion';
import type { TreeNode, Refs, Responses, Cache } from './types/index';

export type PlausibilityErrors = {
  id: string,
  info: string,
  infoTranslationKey: string,
  message: string,
  messageTranslationKey: string,
  type: string
};

export default (
  question: TreeNode,
  refs: Refs,
  responses: Responses,
  cache: Cache,
  year: number,
): PlausibilityErrors[] => {
  if (question.rules && question.rules.length !== 0) {
    const input = inputsForQuestion(question, refs, responses, cache, year);
    // $FlowFixMe
    return question.rules
      .map(rule => JSONRules.providers.resolve(rule.rule, input))
      .filter(out => !isEmpty(out));
  }
  return [];
};
