'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const { getDecimal } = require('../utils/utils_fields');

const commuting2016 = require('./commuting_2016');
const doubleHousehold2016 = require('./double_housekeeping_2016');
const homeOffice2016 = require('./home_office_2016');
const workEquipment2016 = require('./work_equipment_2016');
const otherWorkRelatedExpenses2016 = require('./other_work_related_expenses_2016');
const travelling2016 = require('./travelling_2016');
const { toString } = require('../utils/utils_logs');

function professionalExpenses2016(fields, incomePerPerson, grossSalary, currentTaxYear) {
  let expensesPerPerson = new Decimal(0);
  let extraIncomePerPerson = new Decimal(0);
  logger.debug('\n-------------------------\nPROFESSIONAL EXPENSES');

  let commuting = commuting2016(fields);
  expensesPerPerson = expensesPerPerson.add(commuting);
  logger.debug('commuting', toString(commuting));

  let doubleHousehold = doubleHousehold2016(fields, currentTaxYear);
  expensesPerPerson = expensesPerPerson.add(doubleHousehold);
  logger.debug('doubleHousehold', toString(doubleHousehold));

  let homeOffice = homeOffice2016(fields);
  expensesPerPerson = expensesPerPerson.add(homeOffice);
  logger.debug('homeOffice', toString(homeOffice));

  let workEquipment = workEquipment2016(fields, grossSalary);
  expensesPerPerson = expensesPerPerson.add(workEquipment);
  logger.debug('workEquipment', toString(workEquipment));

  let otherWorkRelatedExpenses = otherWorkRelatedExpenses2016(fields);
  expensesPerPerson = expensesPerPerson.add(otherWorkRelatedExpenses);
  logger.debug('otherWorkRelatedExpenses', toString(otherWorkRelatedExpenses));

  let education = getDecimal(fields, '0204812');
  expensesPerPerson = expensesPerPerson.add(education);
  logger.debug('education', toString(education));

  let berufsverbaende = getDecimal(fields, '0204002');
  expensesPerPerson = expensesPerPerson.add(berufsverbaende);
  logger.debug('berufsverbaende', toString(berufsverbaende));

  let travelling = travelling2016(fields);
  if (travelling.gte(0)) {
    expensesPerPerson = expensesPerPerson.add(travelling);
  }

  logger.debug('travelling', toString(travelling));
  logger.debug('income', toString(incomePerPerson));

  return {
    expensesPerPerson,
    extraIncomePerPerson,
    commutingPerPerson: commuting,
    doubleHouseholdPerPerson: doubleHousehold,
    homeOfficePerPerson: homeOffice,
    workEquipmentPerPerson: workEquipment,
    otherWorkRelatedExpensesPerPerson: otherWorkRelatedExpenses,
    travellingPerPerson: travelling,
    educationPerPerson: education,
    professionalAssociationsPerPerson: berufsverbaende,
  };
}

module.exports = professionalExpenses2016;
