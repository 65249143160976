// @flow

import BaseAnswer from './Base';
import { FormatterError } from '../error';
import type { ValidationFunction, ValidationSchema } from '../flowtypes';

type LumpSum = {
  hours24: number,
  hours8: number,
  accommodation: number,
};

type Country = {
  name: string,
  code: string,
};

export type CountryLumpSumsAnswer = {
  isAbroad: boolean,
  lumpSums: ?LumpSum,
  country: ?Country,
  city: ?string,
};

class CountryLumpSums extends BaseAnswer {
  constructor() {
    const formats = [
      { name: 'IS_ABROAD', type: 'boolean' },
      { name: 'HOURS24', type: 'number' },
      { name: 'HOURS8', type: 'number' },
      { name: 'ACCOMMODATION', type: 'number' },
      { name: 'COUNTRY_NAME_CITY_NAME', type: 'string' },
    ];

    super('country_lump_sums', formats);
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/country_lump_sums.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/country_lump_sums');
    /* eslint-enable global-require */
  }

  formatCountryCity(value: CountryLumpSumsAnswer): string {
    const country = this.valueOf(value, 'country.name');
    const city = this.valueOf(value, 'city');

    if (typeof country !== 'string') {
      throw new FormatterError('Missing country name');
    }

    if (typeof city !== 'string') {
      throw new FormatterError('Missing city name');
    }

    return `${country}, ${city}`;
  }

  format(value: any, formatKey: string): number | boolean | string {
    super.format(value);

    const lumpSumValue: CountryLumpSumsAnswer = value;

    if (this.valueOf(lumpSumValue, 'isAbroad') === false) {
      switch (formatKey) {
        case this.formats.IS_ABROAD:
          return false;

        case this.formats.HOURS24:
        case this.formats.HOURS8:
        case this.formats.ACCOMMODATION:
          return 0;

        case this.formats.COUNTRY_NAME_CITY_NAME:
          return '';

        default:
          break;
      }
    } else {
      switch (formatKey) {
        case this.formats.IS_ABROAD:
          return this.valueOf(lumpSumValue, 'isAbroad');

        case this.formats.HOURS24:
          return this.valueOf(lumpSumValue, 'lumpSums.hours24');

        case this.formats.HOURS8:
          return this.valueOf(lumpSumValue, 'lumpSums.hours8');

        case this.formats.ACCOMMODATION:
          return this.valueOf(lumpSumValue, 'lumpSums.accommodation');

        case this.formats.COUNTRY_NAME_CITY_NAME:
          return this.formatCountryCity(lumpSumValue);

        default:
          break;
      }
    }

    throw new FormatterError(`Unknown format: ${formatKey}`);
  }
}

export default new CountryLumpSums();
