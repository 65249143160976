'use strict';

const Decimal = require('decimal.js');

/**
 *
 * @param actualExpenses the actual expenses calculate
 * @param totalAmount the totalAmount to check against expenses
 * @param lumpSum corresponding lumpSum
 * @returns {*}
 */
function calculateExpenses(actualExpenses, totalAmount, lumpSum) {
  const decimalExpenses = new Decimal(actualExpenses);
  const decimalTotalAmount = new Decimal(totalAmount);
  const decimalLumpSum = new Decimal(lumpSum);

  if (decimalExpenses.lessThan(decimalLumpSum)) {
    if (decimalTotalAmount.lessThan(decimalLumpSum)) {
      return Decimal.max(0, decimalExpenses, decimalTotalAmount);
    } else {
      return decimalLumpSum;
    }
  }

  return decimalExpenses;
}

module.exports = {
  calculateExpenses,
};