// @flow

import moment from 'moment';

import BaseAnswer from './Base';
import { FormatterError } from '../error';
import type { ValidationFunction, ValidationSchema } from '../flowtypes';

type DateString = string;
type DateStrings = { [key: string]: DateString };
type Formatters = { [key: string]: { format: DateString } };

export type DurationRange = {
  from: DateString,
  until: DateString,
};

export type DurationAnswer = {
  duration: DurationRange,
  days: number,
  nights: number,
};

class Duration extends BaseAnswer {
  dateStrings: DateStrings;
  formatters: Formatters;

  constructor() {
    const formats = [
      { name: 'FROM_DDMM', type: 'string' },
      { name: 'FROM_DDMMYYYY', type: 'string' },
      { name: 'UNTIL_DDMM', type: 'string' },
      { name: 'UNTIL_DDMMYYYY', type: 'string' },
      { name: 'DDMMDDMM', type: 'string' },
      { name: 'DDMMYYYYDDMMYYYY', type: 'string' },
      { name: 'DAYS', type: 'number' },
      { name: 'NIGHTS', type: 'number' },
      { name: 'MONTHS', type: 'number' },
    ];
    super('duration', formats);

    this.dateStrings = {
      DD_MM: 'DD.MM',
      DD_MM_YYYY: 'DD.MM.YYYY',
    };

    this.formatters = {
      FROM_DDMM: { format: this.dateStrings.DD_MM },
      FROM_DDMMYYYY: { format: this.dateStrings.DD_MM_YYYY },
      UNTIL_DDMM: { format: this.dateStrings.DD_MM },
      UNTIL_DDMMYYYY: { format: this.dateStrings.DD_MM_YYYY },
    };
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/duration.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/duration');
    /* eslint-enable global-require */
  }

  format(value: any, formatKey: string): string | number {
    super.format(value);
    const durationValue: DurationAnswer = value;
    const requestedFormat = this.getFormat(formatKey).name;
    const formatter = this.formatters[requestedFormat];

    switch (requestedFormat) {
      case this.formats.FROM_DDMM:
      case this.formats.FROM_DDMMYYYY:
        return moment(durationValue.duration.from).format(formatter.format);

      case this.formats.UNTIL_DDMM:
      case this.formats.UNTIL_DDMMYYYY:
        return moment(durationValue.duration.until).format(formatter.format);

      case this.formats.DDMMDDMM: {
        const from: string = moment(durationValue.duration.from).format(this.dateStrings.DD_MM);
        const until: string = moment(durationValue.duration.until).format(this.dateStrings.DD_MM);
        return `${from}-${until}`;
      }

      case this.formats.DDMMYYYYDDMMYYYY: {
        const from: string = moment(durationValue.duration.from).format(
          this.dateStrings.DD_MM_YYYY,
        );
        const until: string = moment(durationValue.duration.until).format(
          this.dateStrings.DD_MM_YYYY,
        );
        return `${from}-${until}`;
      }

      case this.formats.DAYS:
        return durationValue.days;

      case this.formats.NIGHTS:
        return durationValue.nights;

      case this.formats.MONTHS: {
        const from = moment(durationValue.duration.from);
        const until = moment(durationValue.duration.until);
        return Math.abs((until.year() - from.year()) * 12 + (until.month() - from.month())) + 1;
      }

      default:
        // XXX: should this have a default output?
        break;
    }

    throw new FormatterError(`Unknown format: ${formatKey}`);
  }
}

export default new Duration();
