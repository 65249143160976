// @flow

import BaseAnswer from './Base';

class StringAnswer extends BaseAnswer {
  constructor() {
    super('string');

    this.defaultType = 'string';
  }

  format(value: any): string {
    super.format(value);

    return (value: string);
  }
}

export default new StringAnswer();
