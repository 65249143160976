// @flow

import { Address } from './Address';

/**
 * Technically it is the same address.
 * Based on this answer type you should be able to search across the globe.
 */
class GlobalAddress extends Address {
  constructor() {
    super('global_address');
  }
}

export default new GlobalAddress();
