// @flow

import BaseAnswer from './Base';

class NumberAnswer extends BaseAnswer {
  constructor() {
    super('number');

    this.defaultType = 'number';
  }

  format(value: any): number {
    super.format(value);

    return (value: number);
  }
}

export default new NumberAnswer();
