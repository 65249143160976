'use strict';

const { TaxYears } = require('@taxfix/de-itc-types');

const exceptionalExpenses2021 = require('../2021/exceptional_expenses_2021');
const exceptionalExpenses2020 = require('../2020/exceptional_expenses_2020');
const exceptionalExpenses2017 = require('../2017/exceptional_expenses_2017');
const exceptionalExpenses2016 = require('../2016/exceptional_expenses_2016');

function getExceptionalExpenses(fields, totalAmountOfEarnings, lumpsums) {
  switch (lumpsums.year) {
    case TaxYears['2k21']:
      return exceptionalExpenses2021(fields, totalAmountOfEarnings, lumpsums);
    case TaxYears['2k20']:
      return exceptionalExpenses2020(fields, totalAmountOfEarnings, lumpsums);
    case TaxYears['2k19']:
    case TaxYears['2k18']:
    case TaxYears['2k17']:
      return exceptionalExpenses2017(fields, totalAmountOfEarnings, lumpsums);
    default:
      return exceptionalExpenses2016(fields, totalAmountOfEarnings, lumpsums);
  }
}

module.exports = getExceptionalExpenses;