'use strict';

const Decimal = require('decimal.js');
const { TaxYears } = require('@taxfix/de-itc-types');

const incomeTaxCalculator = require('./incomeTaxCalculator');
const { percent: getPercent} = require('../utils/utils_decimal');
const { isJointAssessment } = require('../utils/utils_field');
const { getDecimal, getLfdNrGroupsByNrs, iterateLfNr } = require('../utils/utils_fields');

const SOLIDARITY_SURCHARGE_PERCENT = 5.5;
const ZONE_BASED_SOLIDARITY_SURCHARGE_PERCENT = 11.9;

function calculateSolidaritySurcharge({ taxYear, isJA, incomeTax, capitalGainsTax }) {
  let solidaritySurcharge = new Decimal(0);

  /**
   * From tax year 2021, we started applying
   * zone based formular to solidarity surcharge
   */
  if (taxYear > TaxYears['2k20']) {
    const MIN_SURCHARGE = isJA ? new Decimal(33912) : new Decimal(16956);
    const MAX_SURCHARGE = isJA ? new Decimal(193640) : new Decimal(96820);
    const incomeTaxToUse = Decimal.max(new Decimal(0), incomeTax.sub(capitalGainsTax));
    const capitalGainSurcharge = getPercent(capitalGainsTax, SOLIDARITY_SURCHARGE_PERCENT);
    let zoneBasedSolidaritySurcharge = new Decimal(0);

    if (incomeTaxToUse.lte(MIN_SURCHARGE)) {
      zoneBasedSolidaritySurcharge = new Decimal(0);
    } else if (incomeTaxToUse.gte(MAX_SURCHARGE)) {
      zoneBasedSolidaritySurcharge = getPercent(incomeTaxToUse, SOLIDARITY_SURCHARGE_PERCENT);
    } else if (incomeTaxToUse.gt(MIN_SURCHARGE) && incomeTaxToUse.lt(MAX_SURCHARGE)) {
      zoneBasedSolidaritySurcharge = getPercent(incomeTaxToUse.sub(MIN_SURCHARGE), ZONE_BASED_SOLIDARITY_SURCHARGE_PERCENT);
    }

    solidaritySurcharge = zoneBasedSolidaritySurcharge.add(capitalGainSurcharge);
  } else {
    solidaritySurcharge = getPercent(incomeTax, SOLIDARITY_SURCHARGE_PERCENT);
  }

  return solidaritySurcharge.toDecimalPlaces(2, Decimal.ROUND_DOWN);
}

function solidaritySurcharge({
  fields,
  taxYear,
  lumpsums,
  taxableIncome,
  taxDeductions,
  capitalGainsTax,
  riesterAllowances,
  calculateIncomeTax,
  incomeReducedTaxRate,
  incomeSubjectToProgression,
  isCapitalGainsIncomeIncludedInTotalIncome,
  allowancesForChildrenExemptAmountForTaxes,
}) {
  const isJA = isJointAssessment(fields);

  // deduct max amount freibetrag from taxableIncome
  const income = new Decimal(taxableIncome).sub(allowancesForChildrenExemptAmountForTaxes);

  let { incomeTax } = incomeTaxCalculator(
    fields,
    income,
    incomeReducedTaxRate,
    incomeSubjectToProgression,
    calculateIncomeTax,
    { roundCalculatedIncomeTax: true }
  );

  incomeTax = incomeTax.sub(taxDeductions);
  incomeTax = incomeTax.add(capitalGainsTax);
  incomeTax = incomeTax.add(riesterAllowances);
  incomeTax = Decimal.max(incomeTax, new Decimal(0));

  /**
   * The reason we need capitalGainsTax is that for
   * zone based solidarity surcharge, we need to remove
   * capitalGainsTax from the incomeTax to use.
   *
   * So, if for any reason we move the addition of
   * capitalGainsTax to the incomeTax before we
   * calculate solidarity surcharge, then we need
   * to update this function.
   */
  let soliAssessed = calculateSolidaritySurcharge({
    isJA,
    taxYear,
    incomeTax,
    capitalGainsTax,
  });

  // Härteklausel nach § 4 S. 2 und 4 SolZG 1995
  let hardshipClause = incomeTax.sub(capitalGainsTax);

  // maßgebende Freigrenze bei Anwednung der Grundtabelle/Splittingtabelle
  const { solidaritySurcharge } = lumpsums;
  const freeLimit = isJA ? new Decimal(solidaritySurcharge.joint) : new Decimal(solidaritySurcharge.single);
  hardshipClause = hardshipClause.sub(freeLimit);
  hardshipClause = Decimal.max(hardshipClause, 0);
  hardshipClause = getPercent(hardshipClause, 20);

  const soliForCapitalGains = getPercent(capitalGainsTax, SOLIDARITY_SURCHARGE_PERCENT);

  if (!isCapitalGainsIncomeIncludedInTotalIncome) {
    hardshipClause = hardshipClause.add(soliForCapitalGains);
  }

  if (hardshipClause.lt(soliAssessed)) {
    soliAssessed = hardshipClause;
  }

  let soliPaidPayroll = new Decimal(0);

  // bereits bezahlte beträge LOHN
  iterateLfNr(getLfdNrGroupsByNrs(fields,
    [
      '0200401',
      '0200403',
      '0201202'
    ]
  ), lfdNrGroups => {
    // Solidaritätszuschlag (Stkl. 1-5)
    soliPaidPayroll = soliPaidPayroll.add(getDecimal(lfdNrGroups, '0200401'));

    // Solidaritätszuschlag (Stkl. 6 / Urlaubskasse)
    soliPaidPayroll = soliPaidPayroll.add(getDecimal(lfdNrGroups, '0200403'));

    // Entschädigungen / Arbeitslohn für mehrere Jahre: SolZ - Summe
    soliPaidPayroll = soliPaidPayroll.add(getDecimal(lfdNrGroups, '0201202'));
  });

  let soliPaidCapitalGains = new Decimal(0);

  // bereits bezahlte beträge KAP
  iterateLfNr(getLfdNrGroupsByNrs(fields,
    [
      '1904901',
      '1904902',
      '1905602',
      '1905501'
    ]
  ), lfdNrGroups => {
    // Steuerabzugsbeträge: SolZ - lt. Bescheinigung
    soliPaidCapitalGains = soliPaidCapitalGains.add(getDecimal(lfdNrGroups, '1904901'));

    // Steuerabzugsbeträge: SolZ - Beteiligungen
    soliPaidCapitalGains = soliPaidCapitalGains.add(getDecimal(lfdNrGroups, '1904902'));

    // Anzurechnende Steuern: SolZ - lt. Bescheinigung
    soliPaidCapitalGains = soliPaidCapitalGains.add(getDecimal(lfdNrGroups, '1905602'));

    // Anzurechnende Steuern: SolZ - Beteiligungen
    soliPaidCapitalGains = soliPaidCapitalGains.add(getDecimal(lfdNrGroups, '1905501'));
  });

  // 8.1.1 solidaritySurcharge prepayment -> maybe there is a better way of labeling this?
  const solidaritySurchargePrepayment = getDecimal(fields, 'txf-solidarity-prepayment');
  const soliDifference = soliAssessed
    .sub(soliPaidPayroll)
    .sub(soliPaidCapitalGains)
    .sub(solidaritySurchargePrepayment);

  return {
    soliAssessed,
    soliDifference,
    soliPaidPayroll,
    soliPaidCapitalGains,
    solidaritySurchargePrepayment
  };
}

module.exports = solidaritySurcharge;
