'use strict';
module.exports = validate21;
module.exports.default = validate21;
var schema6 = {
  $id: 'http://taxfix.de/schemas/answers/distance.json',
  $schema: 'http://json-schema.org/draft-06/schema#',
  type: 'object',
  properties: {
    value: { type: 'number', minimum: 0, maximum: 13000 },
    route: {
      properties: {
        distanceInMetres: { type: 'integer', minimum: 0, maximum: 13000000 },
        from: { $ref: 'http://taxfix.de/schemas/answers/shared/place.json#' },
        to: { $ref: 'http://taxfix.de/schemas/answers/shared/place.json#' },
      },
      type: 'object',
      additionalProperties: false,
      required: ['distanceInMetres', 'from', 'to'],
    },
  },
  additionalProperties: false,
  required: ['value', 'route'],
};
var schema7 = {
  $id: 'http://taxfix.de/schemas/answers/shared/place.json',
  $schema: 'http://json-schema.org/draft-06/schema#',
  type: 'object',
  properties: {
    displayName: { type: ['string', 'null'] },
    coordinate: { $ref: '#/definitions/coordinate' },
    postalCode: { type: 'string' },
    state: { type: ['string', 'null'] },
    countryCode: { type: 'string' },
    address: { $ref: '#/definitions/address' },
  },
  additionalProperties: false,
  required: ['displayName', 'coordinate', 'postalCode', 'state', 'countryCode'],
  definitions: {
    address: {
      type: 'object',
      properties: {
        street: { type: ['string', 'null'] },
        number: { type: ['string', 'null'] },
        numberExtension: { type: ['string', 'null'] },
        addition: { type: ['string', 'null'] },
        postalCode: { type: ['string', 'null'] },
        city: { type: ['string', 'null'] },
      },
      additionalProperties: false,
      required: ['street', 'number', 'numberExtension', 'addition', 'postalCode', 'city'],
    },
    coordinate: {
      type: ['object', 'null'],
      properties: { latitude: { type: 'number' }, longitude: { type: 'number' } },
      additionalProperties: false,
      required: ['latitude', 'longitude'],
    },
  },
};
var schema8 = {
  type: ['object', 'null'],
  properties: { latitude: { type: 'number' }, longitude: { type: 'number' } },
  additionalProperties: false,
  required: ['latitude', 'longitude'],
};

function validate23(data, valCxt) {
  'use strict';
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (!(data && typeof data == 'object' && !Array.isArray(data)) && data !== null) {
    var err0 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: schema8.type },
      message: 'should be object,null',
    };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.latitude === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'latitude' },
        message: "should have required property '" + 'latitude' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.longitude === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'longitude' },
        message: "should have required property '" + 'longitude' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    for (var key0 in data) {
      if (!(key0 === 'latitude' || key0 === 'longitude')) {
        var err3 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.latitude !== undefined) {
      if (!(typeof data.latitude == 'number')) {
        var err4 = {
          keyword: 'type',
          dataPath: dataPath + '/latitude',
          schemaPath: '#/properties/latitude/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
    if (data.longitude !== undefined) {
      if (!(typeof data.longitude == 'number')) {
        var err5 = {
          keyword: 'type',
          dataPath: dataPath + '/longitude',
          schemaPath: '#/properties/longitude/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
    }
  }
  validate23.errors = vErrors;
  return errors === 0;
}

var schema9 = {
  type: 'object',
  properties: {
    street: { type: ['string', 'null'] },
    number: { type: ['string', 'null'] },
    numberExtension: { type: ['string', 'null'] },
    addition: { type: ['string', 'null'] },
    postalCode: { type: ['string', 'null'] },
    city: { type: ['string', 'null'] },
  },
  additionalProperties: false,
  required: ['street', 'number', 'numberExtension', 'addition', 'postalCode', 'city'],
};

function validate25(data, valCxt) {
  'use strict';
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.street === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'street' },
        message: "should have required property '" + 'street' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.number === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'number' },
        message: "should have required property '" + 'number' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.numberExtension === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'numberExtension' },
        message: "should have required property '" + 'numberExtension' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.addition === undefined) {
      var err3 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'addition' },
        message: "should have required property '" + 'addition' + "'",
      };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.postalCode === undefined) {
      var err4 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'postalCode' },
        message: "should have required property '" + 'postalCode' + "'",
      };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data.city === undefined) {
      var err5 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'city' },
        message: "should have required property '" + 'city' + "'",
      };
      if (vErrors === null) {
        vErrors = [err5];
      } else {
        vErrors.push(err5);
      }
      errors++;
    }
    for (var key0 in data) {
      if (
        !(
          key0 === 'street' ||
          key0 === 'number' ||
          key0 === 'numberExtension' ||
          key0 === 'addition' ||
          key0 === 'postalCode' ||
          key0 === 'city'
        )
      ) {
        var err6 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
    if (data.street !== undefined) {
      var data0 = data.street;
      if (typeof data0 !== 'string' && data0 !== null) {
        var err7 = {
          keyword: 'type',
          dataPath: dataPath + '/street',
          schemaPath: '#/properties/street/type',
          params: { type: schema9.properties.street.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err7];
        } else {
          vErrors.push(err7);
        }
        errors++;
      }
    }
    if (data.number !== undefined) {
      var data1 = data.number;
      if (typeof data1 !== 'string' && data1 !== null) {
        var err8 = {
          keyword: 'type',
          dataPath: dataPath + '/number',
          schemaPath: '#/properties/number/type',
          params: { type: schema9.properties.number.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
    if (data.numberExtension !== undefined) {
      var data2 = data.numberExtension;
      if (typeof data2 !== 'string' && data2 !== null) {
        var err9 = {
          keyword: 'type',
          dataPath: dataPath + '/numberExtension',
          schemaPath: '#/properties/numberExtension/type',
          params: { type: schema9.properties.numberExtension.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
    }
    if (data.addition !== undefined) {
      var data3 = data.addition;
      if (typeof data3 !== 'string' && data3 !== null) {
        var err10 = {
          keyword: 'type',
          dataPath: dataPath + '/addition',
          schemaPath: '#/properties/addition/type',
          params: { type: schema9.properties.addition.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err10];
        } else {
          vErrors.push(err10);
        }
        errors++;
      }
    }
    if (data.postalCode !== undefined) {
      var data4 = data.postalCode;
      if (typeof data4 !== 'string' && data4 !== null) {
        var err11 = {
          keyword: 'type',
          dataPath: dataPath + '/postalCode',
          schemaPath: '#/properties/postalCode/type',
          params: { type: schema9.properties.postalCode.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err11];
        } else {
          vErrors.push(err11);
        }
        errors++;
      }
    }
    if (data.city !== undefined) {
      var data5 = data.city;
      if (typeof data5 !== 'string' && data5 !== null) {
        var err12 = {
          keyword: 'type',
          dataPath: dataPath + '/city',
          schemaPath: '#/properties/city/type',
          params: { type: schema9.properties.city.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err12];
        } else {
          vErrors.push(err12);
        }
        errors++;
      }
    }
  } else {
    var err13 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err13];
    } else {
      vErrors.push(err13);
    }
    errors++;
  }
  validate25.errors = vErrors;
  return errors === 0;
}

function validate22(data, valCxt) {
  'use strict' /*# sourceURL="http://taxfix.de/schemas/answers/shared/place.json" */;
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.displayName === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'displayName' },
        message: "should have required property '" + 'displayName' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.coordinate === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'coordinate' },
        message: "should have required property '" + 'coordinate' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.postalCode === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'postalCode' },
        message: "should have required property '" + 'postalCode' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.state === undefined) {
      var err3 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'state' },
        message: "should have required property '" + 'state' + "'",
      };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.countryCode === undefined) {
      var err4 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'countryCode' },
        message: "should have required property '" + 'countryCode' + "'",
      };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    for (var key0 in data) {
      if (
        !(
          key0 === 'displayName' ||
          key0 === 'coordinate' ||
          key0 === 'postalCode' ||
          key0 === 'state' ||
          key0 === 'countryCode' ||
          key0 === 'address'
        )
      ) {
        var err5 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
    }
    if (data.displayName !== undefined) {
      var data0 = data.displayName;
      if (typeof data0 !== 'string' && data0 !== null) {
        var err6 = {
          keyword: 'type',
          dataPath: dataPath + '/displayName',
          schemaPath: '#/properties/displayName/type',
          params: { type: schema7.properties.displayName.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
    if (data.coordinate !== undefined) {
      if (
        !validate23(data.coordinate, {
          dataPath: dataPath + '/coordinate',
          parentData: data,
          parentDataProperty: 'coordinate',
          rootData: rootData,
        })
      ) {
        vErrors = vErrors === null ? validate23.errors : vErrors.concat(validate23.errors);
        errors = vErrors.length;
      }
    }
    if (data.postalCode !== undefined) {
      if (typeof data.postalCode !== 'string') {
        var err7 = {
          keyword: 'type',
          dataPath: dataPath + '/postalCode',
          schemaPath: '#/properties/postalCode/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err7];
        } else {
          vErrors.push(err7);
        }
        errors++;
      }
    }
    if (data.state !== undefined) {
      var data3 = data.state;
      if (typeof data3 !== 'string' && data3 !== null) {
        var err8 = {
          keyword: 'type',
          dataPath: dataPath + '/state',
          schemaPath: '#/properties/state/type',
          params: { type: schema7.properties.state.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
    if (data.countryCode !== undefined) {
      if (typeof data.countryCode !== 'string') {
        var err9 = {
          keyword: 'type',
          dataPath: dataPath + '/countryCode',
          schemaPath: '#/properties/countryCode/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
    }
    if (data.address !== undefined) {
      if (
        !validate25(data.address, {
          dataPath: dataPath + '/address',
          parentData: data,
          parentDataProperty: 'address',
          rootData: rootData,
        })
      ) {
        vErrors = vErrors === null ? validate25.errors : vErrors.concat(validate25.errors);
        errors = vErrors.length;
      }
    }
  } else {
    var err10 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err10];
    } else {
      vErrors.push(err10);
    }
    errors++;
  }
  validate22.errors = vErrors;
  return errors === 0;
}

function validate21(data, valCxt) {
  'use strict' /*# sourceURL="http://taxfix.de/schemas/answers/distance.json" */;
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.value === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'value' },
        message: "should have required property '" + 'value' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.route === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'route' },
        message: "should have required property '" + 'route' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    for (var key0 in data) {
      if (!(key0 === 'value' || key0 === 'route')) {
        var err2 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.value !== undefined) {
      var data0 = data.value;
      if (typeof data0 == 'number') {
        if (data0 > 13000 || isNaN(data0)) {
          var err3 = {
            keyword: 'maximum',
            dataPath: dataPath + '/value',
            schemaPath: '#/properties/value/maximum',
            params: { comparison: '<=', limit: 13000 },
            message: 'should be <= 13000',
          };
          if (vErrors === null) {
            vErrors = [err3];
          } else {
            vErrors.push(err3);
          }
          errors++;
        }
        if (data0 < 0 || isNaN(data0)) {
          var err4 = {
            keyword: 'minimum',
            dataPath: dataPath + '/value',
            schemaPath: '#/properties/value/minimum',
            params: { comparison: '>=', limit: 0 },
            message: 'should be >= 0',
          };
          if (vErrors === null) {
            vErrors = [err4];
          } else {
            vErrors.push(err4);
          }
          errors++;
        }
      } else {
        var err5 = {
          keyword: 'type',
          dataPath: dataPath + '/value',
          schemaPath: '#/properties/value/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
    }
    if (data.route !== undefined) {
      var data1 = data.route;
      if (data1 && typeof data1 == 'object' && !Array.isArray(data1)) {
        if (data1.distanceInMetres === undefined) {
          var err6 = {
            keyword: 'required',
            dataPath: dataPath + '/route',
            schemaPath: '#/properties/route/required',
            params: { missingProperty: 'distanceInMetres' },
            message: "should have required property '" + 'distanceInMetres' + "'",
          };
          if (vErrors === null) {
            vErrors = [err6];
          } else {
            vErrors.push(err6);
          }
          errors++;
        }
        if (data1.from === undefined) {
          var err7 = {
            keyword: 'required',
            dataPath: dataPath + '/route',
            schemaPath: '#/properties/route/required',
            params: { missingProperty: 'from' },
            message: "should have required property '" + 'from' + "'",
          };
          if (vErrors === null) {
            vErrors = [err7];
          } else {
            vErrors.push(err7);
          }
          errors++;
        }
        if (data1.to === undefined) {
          var err8 = {
            keyword: 'required',
            dataPath: dataPath + '/route',
            schemaPath: '#/properties/route/required',
            params: { missingProperty: 'to' },
            message: "should have required property '" + 'to' + "'",
          };
          if (vErrors === null) {
            vErrors = [err8];
          } else {
            vErrors.push(err8);
          }
          errors++;
        }
        for (var key1 in data1) {
          if (!(key1 === 'distanceInMetres' || key1 === 'from' || key1 === 'to')) {
            var err9 = {
              keyword: 'additionalProperties',
              dataPath: dataPath + '/route',
              schemaPath: '#/properties/route/additionalProperties',
              params: { additionalProperty: key1 },
              message: 'should NOT have additional properties',
            };
            if (vErrors === null) {
              vErrors = [err9];
            } else {
              vErrors.push(err9);
            }
            errors++;
          }
        }
        if (data1.distanceInMetres !== undefined) {
          var data2 = data1.distanceInMetres;
          if (!(typeof data2 == 'number' && (!(data2 % 1) && !isNaN(data2)))) {
            var err10 = {
              keyword: 'type',
              dataPath: dataPath + '/route/distanceInMetres',
              schemaPath: '#/properties/route/properties/distanceInMetres/type',
              params: { type: 'integer' },
              message: 'should be integer',
            };
            if (vErrors === null) {
              vErrors = [err10];
            } else {
              vErrors.push(err10);
            }
            errors++;
          }
          if (typeof data2 == 'number') {
            if (data2 > 13000000 || isNaN(data2)) {
              var err11 = {
                keyword: 'maximum',
                dataPath: dataPath + '/route/distanceInMetres',
                schemaPath: '#/properties/route/properties/distanceInMetres/maximum',
                params: { comparison: '<=', limit: 13000000 },
                message: 'should be <= 13000000',
              };
              if (vErrors === null) {
                vErrors = [err11];
              } else {
                vErrors.push(err11);
              }
              errors++;
            }
            if (data2 < 0 || isNaN(data2)) {
              var err12 = {
                keyword: 'minimum',
                dataPath: dataPath + '/route/distanceInMetres',
                schemaPath: '#/properties/route/properties/distanceInMetres/minimum',
                params: { comparison: '>=', limit: 0 },
                message: 'should be >= 0',
              };
              if (vErrors === null) {
                vErrors = [err12];
              } else {
                vErrors.push(err12);
              }
              errors++;
            }
          }
        }
        if (data1.from !== undefined) {
          if (
            !validate22(data1.from, {
              dataPath: dataPath + '/route/from',
              parentData: data1,
              parentDataProperty: 'from',
              rootData: rootData,
            })
          ) {
            vErrors = vErrors === null ? validate22.errors : vErrors.concat(validate22.errors);
            errors = vErrors.length;
          }
        }
        if (data1.to !== undefined) {
          if (
            !validate22(data1.to, {
              dataPath: dataPath + '/route/to',
              parentData: data1,
              parentDataProperty: 'to',
              rootData: rootData,
            })
          ) {
            vErrors = vErrors === null ? validate22.errors : vErrors.concat(validate22.errors);
            errors = vErrors.length;
          }
        }
      } else {
        var err13 = {
          keyword: 'type',
          dataPath: dataPath + '/route',
          schemaPath: '#/properties/route/type',
          params: { type: 'object' },
          message: 'should be object',
        };
        if (vErrors === null) {
          vErrors = [err13];
        } else {
          vErrors.push(err13);
        }
        errors++;
      }
    }
  } else {
    var err14 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err14];
    } else {
      vErrors.push(err14);
    }
    errors++;
  }
  validate21.errors = vErrors;
  return errors === 0;
}
