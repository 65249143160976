// @flow

import moment from 'moment';

/*
  WARNING:
  The `value` passed into the `inputResolver` can be formatted
  to a number of date-like formats. We need to try all these formats
  to get a valid date for comparison.
*/
const dateFormats = ['YYYY-MM-DD', 'DD.MM.YYYY'];
const dateCache = new Map();

const parseValidDate = (possibleDate: any): ?Date => {
  if (dateCache.has(possibleDate)) {
    const cached = dateCache.get(possibleDate);
    return cached == null ? cached : new Date(cached);
  }

  const parsedValue = moment(possibleDate, dateFormats, true /* strict parsing */);

  // `valueOf()` returns `null` if date is invalid
  dateCache.set(possibleDate, parsedValue.valueOf());

  if (parsedValue.isValid()) {
    return parsedValue.toDate();
  }

  return null;
};

export default parseValidDate;
