'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const {
  getBool,
  getDecimal,
  getFieldsByIndex,
  getFieldsByNr
} = require('../utils/utils_fields');
const { percent: getPercent } = require('../utils/utils_decimal');

const childcareCost2016 = require('./../2016/childcare_cost_2016');
const riesterRente2020 = require('./../2020/riester_rente_2020');
const educationCost2020 = require('./../2020/education_cost_2020');
const schoolFees2016 = require('./../2016/school_fees_2016');
const donationsInstitutions2016 = require('./../2016/donations_institutions_2016');
const donationsPolitical2019 = require('./../2019/donations_political_2019');
// const culturalAssets2016 = require('./../2016/cultural_assets_2016');
// const donationsFoundations2016 = require('./../2016/donations_foundations_2016');
const provisionCost2020 = require('./../2020/provision_cost_2020');
const { toString } = require('../utils/utils_logs');

const MIN_SPECIAL_EXPENSES_SINGLE = 36;
const MIN_SPECIAL_EXPENSES_JOINT = 72;
const MAX_ALIMONY_PER_YEAR = 13805;
const MAX_PERCENT_DONATIONS_INSTITUTIONAL = 20;
const LIMIT_DONATION_SINGLE = 1650;
const LIMIT_DONATION_JOINT = 3300;
// const MAX_PERCENT_POLITICAL_DONATIONS = 50;

function specialExpenses2019(fields, totalIncome, incomeWithoutProfessionalExpensesPerPerson, lumpsums) {
  let expenses = new Decimal(0);
  logger.debug('\n-------------------------\nSPECIAL EXPENSES');

  // AN-Anteil Rente
  let pensionPaidByEmployee = new Decimal(0);

  getFieldsByIndex(fields, [
    '0107302', '0107303'
  ]).forEach(indexFields => {
    // Gezahlte Versorungsleistungen - Renten: tatsächlich gezahlt
    let pensionPaid = getDecimal(indexFields, '0107302');
    // Gezahlte Versorungsleistungen - Renten: abziehbar %
    let pensionPercent = getDecimal(indexFields, '0107303');
    let pension = getPercent(pensionPaid, pensionPercent);
    pensionPaidByEmployee = pensionPaidByEmployee.add(pension);
  });

  // Gezahlte Versorungsleistungen - dauernde Lasten: tatsächlich gezahlt
  pensionPaidByEmployee = pensionPaidByEmployee.add(getDecimal(fields, '0107402'));

  // Ausgleichszahlungen schuldrechtlicher Ausgleich: tatsächlich gezahlt
  pensionPaidByEmployee = pensionPaidByEmployee.add(getDecimal(fields, '0104308'));

  // Ausgleichszahlungen Vermeidung Versorgungsausgleich: tatsächlich gezahlt
  pensionPaidByEmployee = pensionPaidByEmployee.add(getDecimal(fields, '0103903'));
  logger.debug('pensionPaidByEmployee', toString(pensionPaidByEmployee));

  /**
   * Provision cost (Vorsorgeaufwendungen)
   */
  let {
    pensionInsurancePaidByEmployee,
    pensionInsuranceRefunds,
    pensionInsurancePaidByEmployer,
    pensionInsuranceBeforeMax,
    pensionInsuranceMax,
    pensionInsuranceAfterMax,
    pensionInsuranceProportionalMaxValue,
    pensionInsurance,
    pensionInsurancePercentage,

    healthInsurancePaidEmployeeA,
    healthInsurancePaidEmployeeB,
    healthInsurancePaidEmployee,
    healthInsuranceNoSickpayA,
    healthInsuranceNoSickpayB,
    healthInsuranceNoSickpay,
    healthInsuranceWithSickpayA,
    healthInsuranceWithSickpayB,
    healthInsuranceWithSickpay,
    healthInsuranceRefunds,
    healthInsuranceReimbursements,
    healthInsuranceTaxfreeEmployerBenefitsA,
    healthInsuranceTaxfreeEmployerBenefitsB,
    healthInsuranceTaxfreeEmployerBenefits,

    additionalHealthInsuranceA,
    additionalHealthInsuranceB,

    foreignKVA,
    foreignKVB,

    foreignNoClaimSickPayA,
    foreignNoClaimSickPayB,

    healthInsuranceCutSum,
    healthInsuranceCut4Percent,
    healthInsuranceAfterCut,
    socialCareInsurance,
    socialCareInsuranceA,
    socialCareInsuranceB,
    socialCareInsuranceTaxfreeEmployerBenefitsA,
    socialCareInsuranceTaxfreeEmployerBenefitsB,
    socialCareInsuranceTaxfreeEmployerBenefits,
    taxfreeEmployerBenefitsA,
    taxfreeEmployerBenefitsB,
    taxfreeEmployerBenefits,
    steuerfreieAGZuschuesse,
    healthInsuranceSumFeesP10Abs1Nr3,
    healthInsuranceSumP10Abs1Nr3,
    additionalHealthInsurance,
    unemploymentInsurance,
    incapacityToWorkInsurance,
    accidentEtcInsurance,
    healthInsuranceSum,
    healthInsuranceSumAfterMax,
    healthInsuranceMax,
    healthInsurance,
    erstattungsuberhang10Abs4b,

    provisionCostP10Abs3And4,
    provisionCost,

    childKV,

    subContributionHealthInsurance,

    healthInsuranceSumForAnzuHoch,
    additionalInsurances,
  } = provisionCost2020(fields, incomeWithoutProfessionalExpensesPerPerson, lumpsums);

  expenses = expenses.add(provisionCost);

  // sonstige abzugsfähige Sonderausgaben
  let other = new Decimal(0);

  other = other.add(pensionPaidByEmployee);
  // Kirchensteuer
  let churchTaxSpecialExpense = new Decimal(0);

  // Kirchensteuer: gezahlt
  let churchTaxPaid = getDecimal(fields, '0107601');
  logger.debug('Kirchensteuer: gezahlt', toString(churchTaxPaid));
  churchTaxSpecialExpense = churchTaxSpecialExpense.add(churchTaxPaid);

  // Kirchensteuer: erstattet
  let churchTaxRefund = getDecimal(fields, '0107602');
  logger.debug('Kirchensteuer: erstattet', toString(churchTaxRefund));
  churchTaxSpecialExpense = churchTaxSpecialExpense.sub(churchTaxRefund);

  let churchTaxAsIncome = new Decimal(0);

  if (churchTaxSpecialExpense.lt(0)) {
    churchTaxAsIncome = churchTaxAsIncome.sub(churchTaxSpecialExpense);
    churchTaxSpecialExpense = new Decimal(0);
  }

  logger.debug('gezahlte Kirchensteuer', toString(churchTaxSpecialExpense));

  other = other.add(churchTaxSpecialExpense);

  let sonstigeAbzugsfaehigeSonderausgaben = new Decimal(0);

  // 3.2.4 Child care costs
  let childcareCost = childcareCost2016(fields);
  logger.debug('Kinderbetreuungskosten', toString(childcareCost));

  sonstigeAbzugsfaehigeSonderausgaben = sonstigeAbzugsfaehigeSonderausgaben.add(childcareCost);  
  other = other.add(childcareCost);

  // 3.2.5 Costs for initial education
  let education = educationCost2020(fields);
  logger.debug('Ausbildung', toString(education));
  sonstigeAbzugsfaehigeSonderausgaben = sonstigeAbzugsfaehigeSonderausgaben.add(education);  
  other = other.add(education);

  // 3.2.6 School fees
  let schoolFees = schoolFees2016(fields);
  logger.debug('schoolFees', toString(schoolFees));
  sonstigeAbzugsfaehigeSonderausgaben = sonstigeAbzugsfaehigeSonderausgaben.add(schoolFees);  
  other = other.add(schoolFees);

  // 3.2.7 Alimony payments
  // Unterhaltsleistungen: tatsächlich gezahlt - Summe
  let alimony = new Decimal(0);

  getFieldsByNr(fields, '0104408')
    .forEach(field => {
      alimony = alimony.add(
        new Decimal(field.value)
      );
    });

  alimony = Decimal.min(alimony, MAX_ALIMONY_PER_YEAR);
  logger.debug('alimony', toString(alimony));
  sonstigeAbzugsfaehigeSonderausgaben = sonstigeAbzugsfaehigeSonderausgaben.add(alimony);  
  other = other.add(alimony);
  let sumAlimony = alimony.add(getDecimal(fields,'0104308'));
  sumAlimony = sumAlimony.add(getDecimal(fields, '0103903'));

  // 3.2.3 Riester-Rente
  let { riester, riesterAllowances } = riesterRente2020(fields, lumpsums);
  expenses = expenses.add(riester);

  let donations = new Decimal(0);

  // 3.2.8 Donations to tax privileged institutions
  let donationsInstitutions = donationsInstitutions2016(fields);
  logger.debug('Spenden an Institutionen', toString(donationsInstitutions), toString(totalIncome));

  // only 20% of the total amount of earnings is deductable
  let maxDonationsInstitutions = getPercent(totalIncome, MAX_PERCENT_DONATIONS_INSTITUTIONAL);
  donationsInstitutions = Decimal.min(donationsInstitutions, maxDonationsInstitutions);
  logger.debug('Spenden an Institutionen final', toString(donationsInstitutions));

  donations = donations.add(donationsInstitutions);
  other = other.add(donationsInstitutions);

  // check for joint assessment => min amount for other special expenses
  let joint = getBool(fields, '0101201', 'X');

  // 3.2.9 Donations to political parties (also 6.1.5)
  let donationsPolitical = donationsPolitical2019(fields);
  logger.debug('donationsPolitical', toString(donationsPolitical));
  let deductable = donationsPolitical;
  let limitDonations = joint ? LIMIT_DONATION_JOINT : LIMIT_DONATION_SINGLE;
  if (donationsPolitical.gt(LIMIT_DONATION_SINGLE)) {
    deductable = Decimal.min(donationsPolitical, limitDonations);
    logger.debug('davon sind als Sonderausgaben anzusetzen', toString(deductable));
  }

  donations = donations.add(deductable);
  other = other.add(deductable);

  logger.debug('Spenden total', toString(donations));

  logger.debug('sonstigeAbzugsfaehigeSonderausgaben', toString(sonstigeAbzugsfaehigeSonderausgaben), toString(other));

  // only 50% of the amount over limit is deductable
  // if (donationsPolitical.greaterThan(limitDonations)) {
  //   let deductable = donationsPolitical.sub(limitDonations);
  //   deductable = getPercent(deductable, 50);
  //   logger.debug('50%',toNumber(deductable));
  //   // and max is the same as the limit
  //   deductable = Decimal.min(deductable, limitDonations);
  //   logger.debug('davon sind als Sonderausgaben anzusetzen',toNumber(deductable));
  //   expenses = expenses.add(deductable);
  // }

  // removed for now! 3.2.10 Expenses for cultural assets
  // let culturalAssets = culturalAssets2016(fields);
  // expenses = expenses.add(culturalAssets);

  // removed for now! 3.2.11 Donations to tax-privileged foundations
  // let donationsFoundations = donationsFoundations2016(fields);
  // expenses = expenses.add(donationsFoundations);

let minAmountSpecialExpenses = joint ?
  MIN_SPECIAL_EXPENSES_JOINT :
  MIN_SPECIAL_EXPENSES_SINGLE;

  other = Decimal.max(other, minAmountSpecialExpenses);
  expenses = expenses.add(other);

  logger.debug('Summe der gesamt anzusetzenden Sonderausgaben', toString(expenses));
  logger.debug('other', toString(other));

  let erstattungsuberhang = new Decimal(0);
  if (subContributionHealthInsurance.lt(0)) {
    erstattungsuberhang = erstattungsuberhang.sub(subContributionHealthInsurance);
  }

  erstattungsuberhang = erstattungsuberhang.add(churchTaxAsIncome);
  
  logger.debug('Erstattungsüberhänge final',toString(erstattungsuberhang));

  //
  return {
    pensionPaidByEmployee,

    pensionInsurancePaidByEmployee,
    pensionInsuranceRefunds,
    pensionInsurancePaidByEmployer,
    pensionInsuranceBeforeMax,
    pensionInsuranceMax,
    pensionInsuranceAfterMax,
    pensionInsuranceProportionalMaxValue,
    pensionInsurance,
    pensionInsurancePercentage,
    healthInsurancePaidEmployeeA,
    healthInsurancePaidEmployeeB,
    healthInsurancePaidEmployee,
    healthInsuranceNoSickpayA,
    healthInsuranceNoSickpayB,
    healthInsuranceNoSickpay,
    healthInsuranceWithSickpayA,
    healthInsuranceWithSickpayB,
    healthInsuranceWithSickpay,
    healthInsuranceRefunds,
    healthInsuranceReimbursements,
    healthInsuranceTaxfreeEmployerBenefitsA,
    healthInsuranceTaxfreeEmployerBenefitsB,
    healthInsuranceTaxfreeEmployerBenefits,
    healthInsuranceCutSum,
    healthInsuranceCut4Percent,
    healthInsuranceAfterCut,
    socialCareInsurance,
    socialCareInsuranceA,
    socialCareInsuranceB,
    socialCareInsuranceTaxfreeEmployerBenefitsA,
    socialCareInsuranceTaxfreeEmployerBenefitsB,
    socialCareInsuranceTaxfreeEmployerBenefits,
    taxfreeEmployerBenefitsA,
    taxfreeEmployerBenefitsB,
    taxfreeEmployerBenefits,
    steuerfreieAGZuschuesse,
    healthInsuranceSumFeesP10Abs1Nr3,
    healthInsuranceSumP10Abs1Nr3,
    additionalHealthInsurance,
    unemploymentInsurance,
    incapacityToWorkInsurance,
    accidentEtcInsurance,
    healthInsuranceSum,
    healthInsuranceSumAfterMax,
    healthInsuranceMax,
    healthInsurance,
    provisionCostP10Abs3And4,
    provisionCost,
    erstattungsuberhang10Abs4b,
    additionalHealthInsuranceA,
    additionalHealthInsuranceB,
    
    churchTaxSpecialExpense,
    churchTaxPaid,
    churchTaxRefund,
    churchTaxAsIncome,
    
    childcareCost,

    otherSpecialExpenses: other,

    riester,
    riesterAllowances,

    donations,
    donationsInstitutions,
    donationsPolitical,
    // culturalAssets,
    // donationsFoundations,

    foreignKVA,
    foreignKVB,
    foreignNoClaimSickPayA,
    foreignNoClaimSickPayB,
    childKV,

    specialExpenses: expenses,
    erstattungsuberhang,

    specialExpensesAlimony: sumAlimony,
    specialExpensesInitialEducation: education,
    specialExpensesDonations: donations,
    specialExpensesSchoolFees: schoolFees,
    healthInsuranceSumForAnzuHoch,
    additionalInsurances,
  };
}

module.exports = specialExpenses2019;
