'use strict';

function toString(value) {
  if(typeof value === 'string') {
    return value;
  }
  if(value === undefined) {
    return 'undefined';
  }
  if(Number.isNaN(value)) {
    return 'NaN';
  }
  if(value != null && typeof value.toNumber === 'function') {
    return value.toNumber().toString();
  }
  return JSON.stringify(value);
}

module.exports = {
  toString: toString
};