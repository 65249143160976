'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const {
  getDecimal,
  getBool,
  getFieldsByIndex
} = require('../utils/utils_fields');
const constants = require('./constants-2016.json');
const { toString } = require('../utils/utils_logs');
const { costForDistanceBasic } = require('../utils/utils_distance');

function commuting2016(fields) {
  logger.debug('\n-------------------------\nCOMMUTING');

  let expenses = new Decimal(0);
  let expensesDisabled = new Decimal(0);
  const COMMUTING_LUMP_SUM = new Decimal(constants.COMMUTING_LUMP_SUM);
  const HIGH_GRADE_DISABILITY = new Decimal(70);
  const EXTRA_MID_GRADE_DISABILITY = new Decimal(60);
  const MID_GRADE_DISABILITY = new Decimal(50);
  const ZERO = new Decimal(0);
  const TWO = new Decimal(2);

  // we need to handle disability degree in here, so that we double the commuting values
  // if the person is disabled
  // BEWARE: the same check is also done in taxml-pre-processing!
  const gradeOfDisability = getDecimal(fields, '0109708');
  logger.debug('gradeOfDisability', toString(gradeOfDisability));

  const hampered = getBool(fields, '0109707', 1);
  logger.debug('hampered', hampered);

  const hasHighGradeOfDisability = gradeOfDisability.gte(HIGH_GRADE_DISABILITY);
  const hasExtraMidGradeOfDisability = gradeOfDisability.equals(EXTRA_MID_GRADE_DISABILITY);
  const hasMidGradeOfDisability = gradeOfDisability.equals(MID_GRADE_DISABILITY);
  const hasMediumGradeOfDisability = hasExtraMidGradeOfDisability || hasMidGradeOfDisability;
  const isDisabled = (hasMediumGradeOfDisability && hampered === true) || hasHighGradeOfDisability;

  let superLumpSum = new Decimal(0);
  let superCost = new Decimal(0);
  let workplaceLumpSumCarTotal = new Decimal(0);
  let workplaceLumpSumOtherTotal = new Decimal(0);

  // Workplace commuting
  getFieldsByIndex(fields, [
    '0203503',
    '0203505',
    '0203506',
    '0203510',
    '0203611',
    '0109708',
    '0109707'
  ]).forEach(indexFields => {
    let workplaceDays = getDecimal(indexFields, '0203503');
    let workplaceDistanceOwnCar = getDecimal(indexFields, '0203505');
    let workplacePublicTransport = getDecimal(indexFields, '0203611');

    if (isDisabled && workplaceDistanceOwnCar.gt(ZERO)) {
      // double the amount of work days
      logger.debug('Doubled the number of workplaceDays (drives) b/c person is disabled');
      const workplaceDaysDoubled = workplaceDays.times(TWO);

      // calculate only car with basic calculation
      let costOwnCarDisabled = costForDistanceBasic(workplaceDistanceOwnCar);
      costOwnCarDisabled = costOwnCarDisabled.times(workplaceDaysDoubled);

      // take actual public transport costs or commuting lump sum
      const workplacePublicTransportDisabled = Decimal.min(workplacePublicTransport, COMMUTING_LUMP_SUM);
      expensesDisabled = expensesDisabled.add(costOwnCarDisabled).add(workplacePublicTransportDisabled);
      logger.debug('expenses disability', toString(expensesDisabled));
    }

    let workplaceDistanceOther = getDecimal(indexFields, '0203506');
    let costOwnCar = costForDistanceBasic(workplaceDistanceOwnCar);
    let costOther = costForDistanceBasic(workplaceDistanceOther);

    logger.debug(
      'workplaceDistanceOwnCar', toString(workplaceDistanceOwnCar),
      'workplaceDistanceOther', toString(workplaceDistanceOther),
      'workplacePublicTransport', toString(workplacePublicTransport),
      'costOwnCar', toString(costOwnCar),
      'workplaceDays', toString(workplaceDays),
      'costOther', toString(costOther)
    );

    costOwnCar = costOwnCar.times(workplaceDays);
    costOther = costOther.times(workplaceDays);
    workplaceLumpSumCarTotal = workplaceLumpSumCarTotal.add(costOwnCar);
    workplaceLumpSumOtherTotal = workplaceLumpSumOtherTotal.add(costOther);
    superCost = superCost.add(workplacePublicTransport);
  });

  workplaceLumpSumOtherTotal = Decimal.min(workplaceLumpSumOtherTotal, COMMUTING_LUMP_SUM);
  superLumpSum = superLumpSum.add(workplaceLumpSumCarTotal).add(workplaceLumpSumOtherTotal);

  logger.debug('superLumpSum vs. superCost', toString(superLumpSum), toString(superCost));
  let overallExpenses = Decimal.max(superLumpSum, superCost);
  expenses = expenses.add(overallExpenses);

  let meetingPointSuperLumpSum = new Decimal(0);
  let meetingPointSuperCost = new Decimal(0);
  let meetingPointLumpSumCarTotal = new Decimal(0);
  let meetingPointLumpSumOtherTotal = new Decimal(0);

  getFieldsByIndex(fields, [
    '0203511',
    '0203513',
    '0203514',
    '0203515',
    '0203516',
    '0203512',
    '0109708',
    '0109707'
  ]).forEach(indexFields => {
    let collectiveDays = getDecimal(indexFields, '0203511');
    let collectiveDistanceOwnCar = getDecimal(indexFields, '0203513');
    let collectiveDistanceOther = getDecimal(indexFields, '0203515');
    let collectivePublicTransport = getDecimal(indexFields, '0203516');

    if (isDisabled && collectiveDistanceOwnCar.gt(ZERO)) {
      // double the amount of work days
      logger.debug('Doubled the number of workplaceDays (drives) b/c person is disabled');
      const collectiveDaysDoubled = collectiveDays.times(TWO);

      // calculate only car with basic calculation
      let costOwnCarDisabled = costForDistanceBasic(collectiveDistanceOwnCar);
      costOwnCarDisabled = costOwnCarDisabled.times(collectiveDaysDoubled);

      // take actual public transport costs or commuting lump sum
      const collectivePublicTransportDisabled = Decimal.min(collectivePublicTransport, COMMUTING_LUMP_SUM);
      expensesDisabled = expensesDisabled.add(costOwnCarDisabled).add(collectivePublicTransportDisabled);
      logger.debug('expenses disability', toString(expensesDisabled));
    }

    let costOwnCar = costForDistanceBasic(collectiveDistanceOwnCar);
    let costOther = costForDistanceBasic(collectiveDistanceOther);

    logger.debug(
      'collectiveDistanceOwnCar', toString(collectiveDistanceOwnCar),
      'collectiveDistanceOther', toString(collectiveDistanceOther),
      'collectivePublicTransport', toString(collectivePublicTransport),
      'costOwnCar', toString(costOwnCar),
      'collectiveDays', toString(collectiveDays)
    );

    costOwnCar = costOwnCar.times(collectiveDays);
    costOther = costOther.times(collectiveDays);
    meetingPointLumpSumCarTotal = meetingPointLumpSumCarTotal.add(costOwnCar);
    meetingPointLumpSumOtherTotal = meetingPointLumpSumOtherTotal.add(costOther);
    meetingPointSuperCost = meetingPointSuperCost.add(collectivePublicTransport);
  });

  meetingPointLumpSumOtherTotal = Decimal.min(meetingPointLumpSumOtherTotal, COMMUTING_LUMP_SUM);
  meetingPointSuperLumpSum = meetingPointSuperLumpSum.add(meetingPointLumpSumCarTotal).add(meetingPointLumpSumOtherTotal);

  logger.debug('expenses 1', toString(expenses));
  logger.debug('meetingPointSuperLumpSum vs. meetingPointSuperCost', toString(meetingPointSuperLumpSum), toString(meetingPointSuperCost));
  let meetingPointOverallExpenses = Decimal.max(meetingPointSuperLumpSum, meetingPointSuperCost);
  expenses = expenses.add(meetingPointOverallExpenses);

  // expenses = expenses.add(meetingPointSuperLumpSum);

  // got money back from employer?
  let paybackEmployer = getDecimal(fields, '0204103');
  logger.debug('paybackEmployer', toString(paybackEmployer));
  expenses = expenses.sub(paybackEmployer);

  // got money from job center?
  let paybackJobCenter = getDecimal(fields, '0204004');
  logger.debug('paybackJobCenter', toString(paybackJobCenter));
  expenses = expenses.sub(paybackJobCenter);

  let taxLumpSumEmployer = getDecimal(fields, '0203901');
  logger.debug('taxLumpSumEmployer', toString(taxLumpSumEmployer));
  expenses = expenses.sub(taxLumpSumEmployer);
  logger.debug('expenses 2', toString(expenses));

  expenses = expenses.ceil();
  expensesDisabled = expensesDisabled.ceil();
  logger.debug('expenses 3', toString(expenses));

  return Decimal.max(expenses, expensesDisabled);
}

module.exports = commuting2016;
