'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const { getBool, getDecimal, getBoolWithLfdNr } = require('../utils/utils_fields');
const { percent: getPercent } = require('../utils/utils_decimal');
const { toString } = require('../utils/utils_logs');

const getAllowanceForChildPerFields = (fields, lumpsums) => (fieldNr) => {
  let childCount = getDecimal(fields, fieldNr);
  logger.debug(`childCount for ${fieldNr}`, toString(childCount));

  const allowance = ['2002103', '2002204', '2005101', '2005201'].includes(fieldNr) ?
      lumpsums.special_expenses.childAllowanceBefore2008 :
      lumpsums.special_expenses.childAllowanceSince2008;

  return childCount.times(allowance);
};

function riesterRente2020(fields, lumpsums) {
  const getAllowanceForChild = getAllowanceForChildPerFields(fields, lumpsums);

  let deductableExpenses = new Decimal(0);
  let deductableExpensesA = new Decimal(0);
  let deductableExpensesB = new Decimal(0);

  let joint = getBool(fields, '0101201', 'X');
  let separate = getBool(fields, '0102602', 'X');

  let allowancesReceived = new Decimal(0);
  let allowancesReceivedA = new Decimal(0);
  let allowancesReceivedB = new Decimal(0);

  logger.debug('\n-------------------------\nRIESTER\n');

  //
  // PERSON A

  // (Ankreuzfeld) Steuerpflichtige Person: Unmittelbare Begünstigung in 2016
  let payloadForBool = { fields, nr: '2003901', lookFor: 1, lfdNr: 1 };
  const personANotDirectlyBenefited = getBoolWithLfdNr(payloadForBool) ||
      getBoolWithLfdNr({ ...payloadForBool, lookFor: '1' });
  // (Ankreuzfeld) Steuerpflichtige Person: Unmittelbare Begünstigung in 2016
  const personBNotDirectlyBenefited = getBoolWithLfdNr({ ...payloadForBool, lfdNr: 2 }) ||
      getBoolWithLfdNr({ ...payloadForBool, lookFor: '1', lfdNr: 2 });

  payloadForBool = { fields, nr: '2004901', lookFor: 2, lfdNr: 1 };
  const personADirectlyBenefited = getBoolWithLfdNr(payloadForBool) ||
      getBoolWithLfdNr({ ...payloadForBool, lookFor: '2' });
  const personBDirectlyBenefited = getBoolWithLfdNr({ ...payloadForBool, lfdNr: 2 }) ||
      getBoolWithLfdNr({ ...payloadForBool, lookFor: '2', lfdNr: 2 });

  // debugger
  // TODO if joint and one person is indirectly benefited, than increase the
  // maximum amount of deductableExpenses MIN_ALLOWANCE_PER_PERSON
  // // (Ankreuzfeld) Steuerpflichtige Person B: Mittelbare Begünstigung in 2016
  // let personBIndirectlyBenefited = getBool(fields, '2004902', 'X');

  if (personANotDirectlyBenefited) {
    logger.debug(
      'person A unmittelbar begünstigt',
      `personB ${personBDirectlyBenefited ? 'mittelbar' : 'unmittelbar'} begünstigt`
    );
    let incomePersonA = new Decimal(0);

    // Steuerpflichtige Person: Beitragspflichtige Einnahmen 2015
    let incomeContributionPersonA = getDecimal(fields, '2004001', 1, 1);
    incomePersonA = incomePersonA.add(incomeContributionPersonA);

    // Steuerpflichtige Person: Inländische Besoldung / Amtsbezüge / Einn. beurlaubter Beamter 2015
    let domesticSalaryPersonA = getDecimal(fields, '2004101', 1, 1);
    incomePersonA = incomePersonA.add(domesticSalaryPersonA);

    // Steuerpflichtige Person: Entgeltersatzleistungen 2015
    let compensationPersonA = getDecimal(fields, '2004201', 1, 1);
    incomePersonA = incomePersonA.add(compensationPersonA);

    // Steuerpflichtige Person: Tatsächliches Entgelt 2015
    let paymentPersonA = getDecimal(fields, '2004301', 1, 1);

    if (typeof paymentPersonA !== 'undefined' &&
        incomePersonA.lessThan(paymentPersonA)) {
      incomePersonA = paymentPersonA;
    }

    logger.debug('incomePersonA', toString(incomePersonA));

    // person A has to pay at least this amount to get allowances
    let minimumFeesPersonA = getPercent(incomePersonA, 4);

    // but there is also a maximum
    if (minimumFeesPersonA.greaterThan(lumpsums.special_expenses.maxAllowancePerPerson)) {
      minimumFeesPersonA = new Decimal(lumpsums.special_expenses.maxAllowancePerPerson);
    }

    let maxAllowance = new Decimal(lumpsums.special_expenses.basicAllowance);
    allowancesReceivedA = allowancesReceivedA.add(lumpsums.special_expenses.basicAllowance);
    if (personBDirectlyBenefited) {
      maxAllowance = maxAllowance.add(lumpsums.special_expenses.basicAllowance);
      allowancesReceivedA = allowancesReceivedA.add(lumpsums.special_expenses.basicAllowance);
    }

    logger.debug('basic allowance', toString(maxAllowance), toString(allowancesReceivedA));
    // allowance depends on kindergeld and who received it
    if (joint || separate) {
      // Anzahl Kinder (Kindergeld in 2016 gezahlt) Zusammenveranlagung möglich
      // Zordnung steuerpflichtige Person - geboren vor dem 1.1.08
      const childrenTogetherBefore2008PersonA = getAllowanceForChild('2002204');
      maxAllowance = maxAllowance.add(childrenTogetherBefore2008PersonA);
      allowancesReceivedA = allowancesReceivedA.add(childrenTogetherBefore2008PersonA);

      // Anzahl Kinder (Kindergeld in 2016 gezahlt) Zusammenveranlagung möglich
      // Zordnung steuerpflichtige Person - geboren nach dem 31.12.07
      const childrenTogetherSince2008PersonA = getAllowanceForChild('2002205');
      maxAllowance = maxAllowance.add(childrenTogetherSince2008PersonA);
      allowancesReceivedA = allowancesReceivedA.add(childrenTogetherSince2008PersonA);

      if (personBDirectlyBenefited) {
        const childrenPartnerBefore2008PersonB = getAllowanceForChild('2002103');
        maxAllowance = maxAllowance.add(childrenPartnerBefore2008PersonB);
        allowancesReceivedA = allowancesReceivedA.add(childrenPartnerBefore2008PersonB);

        const childrenPartnerSince2008PersonB = getAllowanceForChild('2002104');
        maxAllowance = maxAllowance.add(childrenPartnerSince2008PersonB);
        allowancesReceivedA = allowancesReceivedA.add(childrenPartnerSince2008PersonB);
      }
    } else {
      // Anzahl Kinder (Kindergeld in 2016 gezahlt) Zusammenveranlagung nicht möglich
      // Zordnung steuerpflichtige Person - geboren vor dem 1.1.08
      const childrenAloneBefore2008PersonA = getAllowanceForChild('2005101');
      maxAllowance = maxAllowance.add(childrenAloneBefore2008PersonA);
      allowancesReceivedA = allowancesReceivedA.add(childrenAloneBefore2008PersonA);

      // Anzahl Kinder (Kindergeld in 2016 gezahlt) Zusammenveranlagung nicht möglich
      // Zordnung steuerpflichtige Person - geboren nach dem 31.12.07
      const childrenAloneSince2008PersonA = getAllowanceForChild('2005102');
      maxAllowance = maxAllowance.add(childrenAloneSince2008PersonA);
      allowancesReceivedA = allowancesReceivedA.add(childrenAloneSince2008PersonA);

      if (personBDirectlyBenefited) {
        const childrenPartnerBefore2008PersonB = getAllowanceForChild('2005201');
        maxAllowance = maxAllowance.add(childrenPartnerBefore2008PersonB);
        allowancesReceivedA = allowancesReceivedA.add(childrenPartnerBefore2008PersonB);

        const childrenPartnerSince2008PersonB = getAllowanceForChild('2005202');
        maxAllowance = maxAllowance.add(childrenPartnerSince2008PersonB);
        allowancesReceivedA = allowancesReceivedA.add(childrenPartnerSince2008PersonB);
      }
    }

    logger.debug('maxAllowance', toString(maxAllowance));

    minimumFeesPersonA = minimumFeesPersonA.sub(maxAllowance);
    minimumFeesPersonA = Decimal.max(minimumFeesPersonA, lumpsums.special_expenses.minAllowancePerPerson);

    // Steuerpflichtige Person: geleistete Altersvorsorgebeiträge
    let contributionPersonA = getDecimal(fields, 'txf-riester-contribution');
    logger.debug('contributionPersonA', toString(contributionPersonA));

    logger.debug('minimumFeesPersonA', toString(minimumFeesPersonA));

    let percent = contributionPersonA.div(minimumFeesPersonA).times(100);
    percent = Decimal.min(percent, 100);

    logger.debug('percent', toString(percent));

    let allowance;

    if (personBDirectlyBenefited) {
      let childPersonB = new Decimal(0);
      let childPersonA = new Decimal(0);
      if (joint) {
        childPersonB = childPersonB.add(getAllowanceForChild('2002103').add(getAllowanceForChild('2002104')));
        childPersonA = childPersonA.add(getAllowanceForChild('2002204').add(getAllowanceForChild('2002205')));
      } else {
        childPersonB = childPersonB.add(getAllowanceForChild('2005201').add(getAllowanceForChild('2005202')));
        childPersonA = childPersonA.add(getAllowanceForChild('2005101').add(getAllowanceForChild('2005102')));
      }
      logger.debug('childPersonB allowance', toString(childPersonB));
      logger.debug('childPersonA allowance', toString(childPersonA));
      let allowancePersonA = (new Decimal(lumpsums.special_expenses.basicAllowance)).add(childPersonA);
      allowancePersonA = getPercent(allowancePersonA, percent);
      let allowancePersonB = (new Decimal(lumpsums.special_expenses.basicAllowance)).add(childPersonB);
      allowancePersonB = getPercent(allowancePersonB, percent);

      logger.debug('personB allowance', toString(allowancePersonB));
      logger.debug('personA allowance', toString(allowancePersonA));
      allowance = allowancePersonA.add(allowancePersonB);
    } else {
      allowance = getPercent(maxAllowance, percent);
    }

    logger.debug('allowance', toString(allowance));
    deductableExpensesA = contributionPersonA.add(allowance);
    logger.debug('MAX_ALLOWANCE_PER_PERSON', lumpsums.special_expenses.maxAllowancePerPerson);
    deductableExpensesA = Decimal.min(deductableExpensesA, lumpsums.special_expenses.maxAllowancePerPerson);

    logger.debug('1 deductableExpensesA', toString(deductableExpensesA));
    logger.debug('1 allowancesReceivedA', toString(allowancesReceivedA));

    deductableExpenses = deductableExpenses.add(deductableExpensesA);

    allowancesReceivedA = getPercent(allowancesReceivedA, percent);
    allowancesReceived = allowancesReceived.add(allowancesReceivedA);
  }

  // TODO don't duplicate code

  //
  // PERSON B

  if (personBNotDirectlyBenefited) {
    logger.debug('person B unmittelbar begünstigt', `personA ${personADirectlyBenefited ? 'mittelbar' : 'unmittelbar'} begünstigt`);
    let incomePersonB = new Decimal(0);

    // Steuerpflichtige Person: Beitragspflichtige Einnahmen 2015
    let incomeContributionPersonB = getDecimal(fields, '2004001', 1, 2);
    incomePersonB = incomePersonB.add(incomeContributionPersonB);

    // Steuerpflichtige Person: Inländische Besoldung / Amtsbezüge / Einn. beurlaubter Beamter 2015
    let domesticSalaryPersonB = getDecimal(fields, '2004101', 1, 2);
    incomePersonB = incomePersonB.add(domesticSalaryPersonB);

    // Steuerpflichtige Person: Entgeltersatzleistungen 2015
    let compensationPersonB = getDecimal(fields, '2004201', 1, 2);
    incomePersonB = incomePersonB.add(compensationPersonB);

    // Steuerpflichtige Person: Tatsächliches Entgelt 2015
    let paymentPersonB = getDecimal(fields, '2004301', 1, 2);

    if (typeof paymentPersonB !== 'undefined' &&
        incomePersonB.lessThan(paymentPersonB)) {
      incomePersonB = paymentPersonB;
    }

    // person A has to pay at least this amount to get allowances
    let minimumFeesPersonB = getPercent(incomePersonB, 4);
    // but there is also a maximum
    minimumFeesPersonB = Decimal.min(minimumFeesPersonB, lumpsums.special_expenses.maxAllowancePerPerson);

    let maxAllowance = new Decimal(lumpsums.special_expenses.basicAllowance);
    allowancesReceivedB = allowancesReceivedB.add(lumpsums.special_expenses.basicAllowance);
    if (personADirectlyBenefited) {
      maxAllowance = maxAllowance.add(lumpsums.special_expenses.basicAllowance);
      allowancesReceivedB = allowancesReceivedB.add(lumpsums.special_expenses.basicAllowance);
    }

    logger.debug('basic allowance', toString(maxAllowance), toString(allowancesReceivedB));

    // allowance depends on kindergeld and who received it
    if (joint || separate) {
      // Anzahl Kinder (Kindergeld in 2016 gezahlt) Zusammenveranlagung möglich
      // Zordnung steuerpflichtige Person - geboren vor dem 1.1.08
      const childrenTogetherBefore2008PersonB = getAllowanceForChild('2002103');
      maxAllowance = maxAllowance.add(childrenTogetherBefore2008PersonB);
      allowancesReceivedB = allowancesReceivedB.add(childrenTogetherBefore2008PersonB);

      // Anzahl Kinder (Kindergeld in 2016 gezahlt) Zusammenveranlagung möglich
      // Zordnung steuerpflichtige Person - geboren nach dem 31.12.07
      const childrenTogetherSince2008PersonB = getAllowanceForChild('2002104');
      maxAllowance = maxAllowance.add(childrenTogetherSince2008PersonB);
      allowancesReceivedB = allowancesReceivedB.add(childrenTogetherSince2008PersonB);

      if (personADirectlyBenefited) {
        const childrenPartnerBefore2008PersonB = getAllowanceForChild('2002204');
        maxAllowance = maxAllowance.add(childrenPartnerBefore2008PersonB);
        allowancesReceivedB = allowancesReceivedB.add(childrenPartnerBefore2008PersonB);

        const childrenPartnerSince2008PersonB = getAllowanceForChild('2002205');
        maxAllowance = maxAllowance.add(childrenPartnerSince2008PersonB);
        allowancesReceivedB = allowancesReceivedB.add(childrenPartnerSince2008PersonB);
      }
    } else {
      // Anzahl Kinder (Kindergeld in 2016 gezahlt) Zusammenveranlagung nicht möglich
      // Zordnung steuerpflichtige Person - geboren vor dem 1.1.08
      const childrenAloneBefore2008PersonB = getAllowanceForChild('2005201', personADirectlyBenefited);
      maxAllowance = maxAllowance.add(childrenAloneBefore2008PersonB);
      allowancesReceivedB = allowancesReceivedB.add(childrenAloneBefore2008PersonB);

      // Anzahl Kinder (Kindergeld in 2016 gezahlt) Zusammenveranlagung nicht möglich
      // Zordnung steuerpflichtige Person - geboren nach dem 31.12.07
      const childrenAloneSince2008PersonB = getAllowanceForChild('2005202', personADirectlyBenefited);
      maxAllowance = maxAllowance.add(childrenAloneSince2008PersonB);
      allowancesReceivedB = allowancesReceivedB.add(childrenAloneSince2008PersonB);

      if (personADirectlyBenefited) {
        const childrenPartnerBefore2008PersonB = getAllowanceForChild('2005101');
        maxAllowance = maxAllowance.add(childrenPartnerBefore2008PersonB);
        allowancesReceivedB = allowancesReceivedB.add(childrenPartnerBefore2008PersonB);

        const childrenPartnerSince2008PersonB = getAllowanceForChild('2005102');
        maxAllowance = maxAllowance.add(childrenPartnerSince2008PersonB);
        allowancesReceivedB = allowancesReceivedB.add(childrenPartnerSince2008PersonB);
      }
    }

    logger.debug('maxAllowance', toString(maxAllowance));

    minimumFeesPersonB = minimumFeesPersonB.sub(maxAllowance);
    minimumFeesPersonB = Decimal.max(minimumFeesPersonB, lumpsums.special_expenses.minAllowancePerPerson);

    // Steuerpflichtige Person: geleistete Altersvorsorgebeiträge
    let contributionPersonB = getDecimal(fields, 'txf-riester-contribution-B');
    logger.debug('contributionPersonB', toString(contributionPersonB));

    logger.debug('minimumFeesPersonB', toString(minimumFeesPersonB));


    let percent = contributionPersonB.div(minimumFeesPersonB).times(100);
    logger.debug('percent', toString(percent));

    percent = Decimal.min(percent, 100);

    logger.debug('percent', toString(percent));

    let allowance;

    if (personADirectlyBenefited) {
      let childPersonB = new Decimal(0);
      let childPersonA = new Decimal(0);
      if (joint) {
        childPersonB = childPersonB.add(getAllowanceForChild('2002103').add(getAllowanceForChild('2002104')));
        childPersonA = childPersonA.add(getAllowanceForChild('2002204').add(getAllowanceForChild('2002205')));
      } else {
        childPersonB = childPersonB.add(getAllowanceForChild('2005201').add(getAllowanceForChild('2005202')));
        childPersonA = childPersonA.add(getAllowanceForChild('2005101').add(getAllowanceForChild('2005102')));
      }
      logger.debug('childPersonB allowance', toString(childPersonB));
      logger.debug('childPersonA allowance', toString(childPersonA));
      let allowancePersonA = (new Decimal(lumpsums.special_expenses.basicAllowance)).add(childPersonA);
      allowancePersonA = getPercent(allowancePersonA, percent);
      let allowancePersonB = (new Decimal(lumpsums.special_expenses.basicAllowance)).add(childPersonB);
      allowancePersonB = getPercent(allowancePersonB, percent);

      logger.debug('personB allowance', toString(allowancePersonB));
      logger.debug('personA allowance', toString(allowancePersonA));
      allowance = allowancePersonA.add(allowancePersonB);
    } else {
      allowance = getPercent(maxAllowance, percent);
    }

    logger.debug('allowance', toString(allowance));
    deductableExpensesB = contributionPersonB.add(allowance);
    logger.debug('MAX_ALLOWANCE_PER_PERSON', lumpsums.special_expenses.maxAllowancePerPerson);
    deductableExpensesB = Decimal.min(deductableExpensesB, lumpsums.special_expenses.maxAllowancePerPerson);

    logger.debug('2 deductableExpensesB', toString(deductableExpensesB));
    logger.debug('2 allowancesReceivedB', toString(allowancesReceivedB));
    deductableExpenses = deductableExpenses.add(deductableExpensesB);

    allowancesReceivedB = getPercent(allowancesReceivedB, percent);
    allowancesReceived = allowancesReceived.add(allowancesReceivedB);
  }

  if (personADirectlyBenefited) {
    logger.debug('person A mittelbar begünstigt');

    let contributionPersonB = getDecimal(fields, 'txf-riester-contribution-B');
    logger.debug('contributionPersonB', toString(contributionPersonB));

    let subtotal = new Decimal(lumpsums.special_expenses.maxAllowancePerPerson).sub(contributionPersonB).sub(allowancesReceivedB);
    subtotal = Decimal.max(subtotal, 0);
    logger.debug('subtotal', toString(subtotal));

    subtotal = subtotal.add(lumpsums.special_expenses.minAllowancePerPerson);
    logger.debug('subtotal after min allowance', toString(subtotal));

    let contributionPersonA = getDecimal(fields, 'txf-riester-contribution');
    contributionPersonA = Decimal.min(contributionPersonA, lumpsums.special_expenses.maxAllowancePerPerson);
    logger.debug('contributionPersonA', toString(contributionPersonA));

    const allowance = subtotal.lte(contributionPersonA) ? subtotal : contributionPersonA;
    logger.debug('allowance', toString(allowance));
    deductableExpenses = deductableExpenses.add(allowance);
  }

  if (personBDirectlyBenefited) {
    logger.debug('person B mittelbar begünstigt');

    let contributionPersonA = getDecimal(fields, 'txf-riester-contribution');
    logger.debug('contributionPersonA', toString(contributionPersonA));

    let subtotal = new Decimal(lumpsums.special_expenses.maxAllowancePerPerson).sub(contributionPersonA).sub(allowancesReceivedA);
    subtotal = Decimal.max(subtotal, 0);
    logger.debug('subtotal', toString(subtotal));

    subtotal = subtotal.add(lumpsums.special_expenses.minAllowancePerPerson);
    logger.debug('subtotal after min allowance', toString(subtotal));

    let contributionPersonB = getDecimal(fields, 'txf-riester-contribution-B');
    contributionPersonB = Decimal.min(contributionPersonB, lumpsums.special_expenses.maxAllowancePerPerson);
    logger.debug('contributionPersonB', toString(contributionPersonB));

    const allowance = subtotal.lte(contributionPersonB) ? subtotal : contributionPersonB;
    logger.debug('allowance', toString(allowance));
    deductableExpenses = deductableExpenses.add(allowance);
  }

  logger.debug('\n=', toString(deductableExpenses));
  logger.debug('-------------------------');

  return {
    riester: deductableExpenses,
    riesterAllowances: allowancesReceived
  };
}

module.exports = riesterRente2020;
