// @flow
import isArray from 'lodash/isArray';
import isAfter from 'date-fns/is_after';
import isBefore from 'date-fns/is_before';
import isValid from 'date-fns/is_valid';
import parse from 'date-fns/parse';
import isNil from 'lodash/isNil';

import {
  parseValidDate,
} from '@taxfix/dates';

type Precondition = Array<any>;

const isValidPrecondition = (precondition: Precondition): boolean => (
  Array.isArray(precondition) && precondition.length >= 1
);

const compareDate = (value: any, precondition: Precondition, direction: 'before' | 'after') => {
  if (value == null) {
    return false;
  }

  const comparator = direction === 'before' ? isBefore : isAfter;

  const preconditionDate = parse(precondition[1]);
  const valueDate = parseValidDate(value);

  if (!isValid(preconditionDate)) {
    throw new Error(`Invalid precondition date: "${precondition[1]}"`);
  }

  if (valueDate == null) {
    throw new Error(`Invalid value date: "${value}"`);
  }

  return comparator(valueDate, preconditionDate);
};

const meetsPrecondition = (value: any, precondition: Precondition) => {
  if (!isValidPrecondition(precondition)) {
    throw new Error(`Invalid precondition "${JSON.stringify(precondition)}"`);
  }

  switch (precondition[0]) {
    case '==':
      if (precondition.length !== 2) {
        throw new Error(`Invalid precondition args "${JSON.stringify(precondition)}"`);
      }

      if (value == null) {
        return false;
      }

      return value === precondition[1];

    case '!=':
      if (precondition.length !== 2) {
        throw new Error(`Invalid precondition args "${JSON.stringify(precondition)}"`);
      }

      return !(meetsPrecondition(value, ['==', precondition[1]]));

    case '>':
      if (precondition.length !== 2) {
        throw new Error(`Invalid precondition args "${JSON.stringify(precondition)}"`);
      }

      if (value == null) {
        return false;
      }

      return value > precondition[1];

    case '>=':
      if (precondition.length !== 2) {
        throw new Error(`Invalid precondition args "${JSON.stringify(precondition)}"`);
      }

      if (value == null) {
        return false;
      }

      return value >= precondition[1];

    case '<':
      if (precondition.length !== 2) {
        throw new Error(`Invalid precondition args "${JSON.stringify(precondition)}"`);
      }

      if (value == null) {
        return false;
      }

      return value < precondition[1];

    case '<=':
      if (precondition.length !== 2) {
        throw new Error(`Invalid precondition args "${JSON.stringify(precondition)}"`);
      }

      if (value == null) {
        return false;
      }

      return value <= precondition[1];

    case 'in':
      if (precondition.length !== 2 || !isArray(precondition[1])) {
        throw new Error(`Invalid precondition args "${JSON.stringify(precondition)}"`);
      }

      if (value == null) {
        return false;
      }

      return precondition[1].indexOf(value) > -1;

    case 'not_in':
      if (precondition.length !== 2 || !isArray(precondition[1])) {
        throw new Error(`Invalid precondition args "${JSON.stringify(precondition)}"`);
      }

      return !(meetsPrecondition(value, ['in', precondition[1]]));

    case 'contains':
      if (precondition.length !== 2 || isArray(precondition[1])) {
        throw new Error(`Invalid precondition args "${JSON.stringify(precondition)}"`);
      }

      return (value || []).indexOf(precondition[1]) > -1;

    case 'contains_any': {
      if (precondition.length !== 2 || !isArray(precondition[1])) {
        throw new Error(`Invalid precondition args "${JSON.stringify(precondition)}"`);
      }

      if (value == null) {
        return false;
      }

      return (precondition[1]).reduce((acc, input) => (
        acc || (value || []).indexOf(input) > -1
      ), false);
    }

    case 'does_not_contain_any': {
      if (precondition.length !== 2 || !isArray(precondition[1])) {
        throw new Error(`Invalid precondition args "${JSON.stringify(precondition)}"`);
      }

      return !(meetsPrecondition(value, ['contains_any', precondition[1]]));
    }

    case 'does_not_contain':
      if (precondition.length !== 2 || isArray(precondition[1])) {
        throw new Error(`Invalid precondition args "${JSON.stringify(precondition)}"`);
      }

      return !(meetsPrecondition(value, ['contains', precondition[1]]));

    case 'after':
    case 'before':
      if (precondition.length !== 2 || precondition[1] == null) {
        throw new Error(`Invalid precondition args "${JSON.stringify(precondition)}"`);
      }

      return compareDate(value, precondition, precondition[0]);

    case 'is_undefined':
      return isNil(value);
    case 'is_defined':
      return !isNil(value);

    default:
      break;
  }

  throw new Error(`Unknown precondition "${precondition[0]}"`);
};

export default meetsPrecondition;
