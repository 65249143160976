'use strict';

const disability = require('./disability');
const confessionChange = require('./confession-change');
const treasureSales = require('./treasure-sales');
const gunstigerprufungPartner = require('./gunstigerprufung-partner');
const { markLossCarryForward } = require('./mark-loss-carry-forward');

module.exports = (fields, calculationResult = {}) => {
  fields = disability(fields);
  fields = confessionChange(fields);
  fields = treasureSales(fields);
  fields = gunstigerprufungPartner(fields);
  fields = markLossCarryForward(fields, calculationResult);

  return fields;
};
