'use strict';
module.exports = validate21;
module.exports.default = validate21;
var schema6 = {
  $id: 'http://taxfix.de/schemas/answers/shared/foreign-address.json',
  $schema: 'http://json-schema.org/draft-06/schema#',
  type: 'object',
  properties: {
    street: { type: 'string', maxLength: 250, minLength: 1 },
    houseNumber: { type: 'string', maxLength: 50, minLength: 1 },
    postalCode: { type: 'string', maxLength: 50, minLength: 1 },
    city: { type: 'string', maxLength: 100, minLength: 1 },
    country: { type: 'string', maxLength: 100, minLength: 1 },
    state: { type: ['string', 'null'], maxLength: 50 },
    additionalInfo: { type: ['string', 'null'], maxLength: 150 },
  },
  required: ['street', 'houseNumber', 'postalCode', 'city', 'country'],
  additionalProperties: false,
};
var func3 = require('ajv/dist/compile/ucs2length').default;

function validate21(data, valCxt) {
  'use strict' /*# sourceURL="http://taxfix.de/schemas/answers/shared/foreign-address.json" */;
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.street === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'street' },
        message: "should have required property '" + 'street' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.houseNumber === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'houseNumber' },
        message: "should have required property '" + 'houseNumber' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.postalCode === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'postalCode' },
        message: "should have required property '" + 'postalCode' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.city === undefined) {
      var err3 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'city' },
        message: "should have required property '" + 'city' + "'",
      };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.country === undefined) {
      var err4 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'country' },
        message: "should have required property '" + 'country' + "'",
      };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    for (var key0 in data) {
      if (
        !(
          key0 === 'street' ||
          key0 === 'houseNumber' ||
          key0 === 'postalCode' ||
          key0 === 'city' ||
          key0 === 'country' ||
          key0 === 'state' ||
          key0 === 'additionalInfo'
        )
      ) {
        var err5 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
    }
    if (data.street !== undefined) {
      var data0 = data.street;
      if (typeof data0 === 'string') {
        if (func3(data0) > 250) {
          var err6 = {
            keyword: 'maxLength',
            dataPath: dataPath + '/street',
            schemaPath: '#/properties/street/maxLength',
            params: { limit: 250 },
            message: 'should NOT have more than 250 characters',
          };
          if (vErrors === null) {
            vErrors = [err6];
          } else {
            vErrors.push(err6);
          }
          errors++;
        }
        if (func3(data0) < 1) {
          var err7 = {
            keyword: 'minLength',
            dataPath: dataPath + '/street',
            schemaPath: '#/properties/street/minLength',
            params: { limit: 1 },
            message: 'should NOT have fewer than 1 characters',
          };
          if (vErrors === null) {
            vErrors = [err7];
          } else {
            vErrors.push(err7);
          }
          errors++;
        }
      } else {
        var err8 = {
          keyword: 'type',
          dataPath: dataPath + '/street',
          schemaPath: '#/properties/street/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
    if (data.houseNumber !== undefined) {
      var data1 = data.houseNumber;
      if (typeof data1 === 'string') {
        if (func3(data1) > 50) {
          var err9 = {
            keyword: 'maxLength',
            dataPath: dataPath + '/houseNumber',
            schemaPath: '#/properties/houseNumber/maxLength',
            params: { limit: 50 },
            message: 'should NOT have more than 50 characters',
          };
          if (vErrors === null) {
            vErrors = [err9];
          } else {
            vErrors.push(err9);
          }
          errors++;
        }
        if (func3(data1) < 1) {
          var err10 = {
            keyword: 'minLength',
            dataPath: dataPath + '/houseNumber',
            schemaPath: '#/properties/houseNumber/minLength',
            params: { limit: 1 },
            message: 'should NOT have fewer than 1 characters',
          };
          if (vErrors === null) {
            vErrors = [err10];
          } else {
            vErrors.push(err10);
          }
          errors++;
        }
      } else {
        var err11 = {
          keyword: 'type',
          dataPath: dataPath + '/houseNumber',
          schemaPath: '#/properties/houseNumber/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err11];
        } else {
          vErrors.push(err11);
        }
        errors++;
      }
    }
    if (data.postalCode !== undefined) {
      var data2 = data.postalCode;
      if (typeof data2 === 'string') {
        if (func3(data2) > 50) {
          var err12 = {
            keyword: 'maxLength',
            dataPath: dataPath + '/postalCode',
            schemaPath: '#/properties/postalCode/maxLength',
            params: { limit: 50 },
            message: 'should NOT have more than 50 characters',
          };
          if (vErrors === null) {
            vErrors = [err12];
          } else {
            vErrors.push(err12);
          }
          errors++;
        }
        if (func3(data2) < 1) {
          var err13 = {
            keyword: 'minLength',
            dataPath: dataPath + '/postalCode',
            schemaPath: '#/properties/postalCode/minLength',
            params: { limit: 1 },
            message: 'should NOT have fewer than 1 characters',
          };
          if (vErrors === null) {
            vErrors = [err13];
          } else {
            vErrors.push(err13);
          }
          errors++;
        }
      } else {
        var err14 = {
          keyword: 'type',
          dataPath: dataPath + '/postalCode',
          schemaPath: '#/properties/postalCode/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err14];
        } else {
          vErrors.push(err14);
        }
        errors++;
      }
    }
    if (data.city !== undefined) {
      var data3 = data.city;
      if (typeof data3 === 'string') {
        if (func3(data3) > 100) {
          var err15 = {
            keyword: 'maxLength',
            dataPath: dataPath + '/city',
            schemaPath: '#/properties/city/maxLength',
            params: { limit: 100 },
            message: 'should NOT have more than 100 characters',
          };
          if (vErrors === null) {
            vErrors = [err15];
          } else {
            vErrors.push(err15);
          }
          errors++;
        }
        if (func3(data3) < 1) {
          var err16 = {
            keyword: 'minLength',
            dataPath: dataPath + '/city',
            schemaPath: '#/properties/city/minLength',
            params: { limit: 1 },
            message: 'should NOT have fewer than 1 characters',
          };
          if (vErrors === null) {
            vErrors = [err16];
          } else {
            vErrors.push(err16);
          }
          errors++;
        }
      } else {
        var err17 = {
          keyword: 'type',
          dataPath: dataPath + '/city',
          schemaPath: '#/properties/city/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err17];
        } else {
          vErrors.push(err17);
        }
        errors++;
      }
    }
    if (data.country !== undefined) {
      var data4 = data.country;
      if (typeof data4 === 'string') {
        if (func3(data4) > 100) {
          var err18 = {
            keyword: 'maxLength',
            dataPath: dataPath + '/country',
            schemaPath: '#/properties/country/maxLength',
            params: { limit: 100 },
            message: 'should NOT have more than 100 characters',
          };
          if (vErrors === null) {
            vErrors = [err18];
          } else {
            vErrors.push(err18);
          }
          errors++;
        }
        if (func3(data4) < 1) {
          var err19 = {
            keyword: 'minLength',
            dataPath: dataPath + '/country',
            schemaPath: '#/properties/country/minLength',
            params: { limit: 1 },
            message: 'should NOT have fewer than 1 characters',
          };
          if (vErrors === null) {
            vErrors = [err19];
          } else {
            vErrors.push(err19);
          }
          errors++;
        }
      } else {
        var err20 = {
          keyword: 'type',
          dataPath: dataPath + '/country',
          schemaPath: '#/properties/country/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err20];
        } else {
          vErrors.push(err20);
        }
        errors++;
      }
    }
    if (data.state !== undefined) {
      var data5 = data.state;
      if (typeof data5 !== 'string' && data5 !== null) {
        var err21 = {
          keyword: 'type',
          dataPath: dataPath + '/state',
          schemaPath: '#/properties/state/type',
          params: { type: schema6.properties.state.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err21];
        } else {
          vErrors.push(err21);
        }
        errors++;
      }
      if (typeof data5 === 'string') {
        if (func3(data5) > 50) {
          var err22 = {
            keyword: 'maxLength',
            dataPath: dataPath + '/state',
            schemaPath: '#/properties/state/maxLength',
            params: { limit: 50 },
            message: 'should NOT have more than 50 characters',
          };
          if (vErrors === null) {
            vErrors = [err22];
          } else {
            vErrors.push(err22);
          }
          errors++;
        }
      }
    }
    if (data.additionalInfo !== undefined) {
      var data6 = data.additionalInfo;
      if (typeof data6 !== 'string' && data6 !== null) {
        var err23 = {
          keyword: 'type',
          dataPath: dataPath + '/additionalInfo',
          schemaPath: '#/properties/additionalInfo/type',
          params: { type: schema6.properties.additionalInfo.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err23];
        } else {
          vErrors.push(err23);
        }
        errors++;
      }
      if (typeof data6 === 'string') {
        if (func3(data6) > 150) {
          var err24 = {
            keyword: 'maxLength',
            dataPath: dataPath + '/additionalInfo',
            schemaPath: '#/properties/additionalInfo/maxLength',
            params: { limit: 150 },
            message: 'should NOT have more than 150 characters',
          };
          if (vErrors === null) {
            vErrors = [err24];
          } else {
            vErrors.push(err24);
          }
          errors++;
        }
      }
    }
  } else {
    var err25 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err25];
    } else {
      vErrors.push(err25);
    }
    errors++;
  }
  validate21.errors = vErrors;
  return errors === 0;
}
