'use strict';
module.exports = validate21;
module.exports.default = validate21;
var schema6 = {
  $id: 'http://taxfix.de/schemas/answers/shared/ES_address.json',
  $schema: 'http://json-schema.org/draft-06/schema#',
  type: 'object',
  properties: {
    country: { type: 'string' },
    region: { type: 'string' },
    province: { type: 'string' },
    town: { type: 'string' },
    location: { type: ['string', 'null'] },
    postalCode: { type: 'string' },
    streetType: { type: 'string' },
    streetName: { type: 'string' },
    numberType: { type: 'string' },
    number: { type: ['string', 'null'] },
    block: { type: ['string', 'null'] },
    stair: { type: ['string', 'null'] },
    floor: { type: ['string', 'null'] },
    door: { type: ['string', 'null'] },
    cadastralReference: { type: 'string' },
    inSmallTown: { type: 'boolean' },
  },
  required: [
    'country',
    'region',
    'province',
    'town',
    'location',
    'postalCode',
    'streetType',
    'streetName',
    'numberType',
    'number',
    'cadastralReference',
    'inSmallTown',
  ],
  additionalProperties: false,
};
var func3 = Object.prototype.hasOwnProperty;

function validate21(data, valCxt) {
  'use strict' /*# sourceURL="http://taxfix.de/schemas/answers/shared/ES_address.json" */;
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.country === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'country' },
        message: "should have required property '" + 'country' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.region === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'region' },
        message: "should have required property '" + 'region' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.province === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'province' },
        message: "should have required property '" + 'province' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.town === undefined) {
      var err3 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'town' },
        message: "should have required property '" + 'town' + "'",
      };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.location === undefined) {
      var err4 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'location' },
        message: "should have required property '" + 'location' + "'",
      };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data.postalCode === undefined) {
      var err5 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'postalCode' },
        message: "should have required property '" + 'postalCode' + "'",
      };
      if (vErrors === null) {
        vErrors = [err5];
      } else {
        vErrors.push(err5);
      }
      errors++;
    }
    if (data.streetType === undefined) {
      var err6 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'streetType' },
        message: "should have required property '" + 'streetType' + "'",
      };
      if (vErrors === null) {
        vErrors = [err6];
      } else {
        vErrors.push(err6);
      }
      errors++;
    }
    if (data.streetName === undefined) {
      var err7 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'streetName' },
        message: "should have required property '" + 'streetName' + "'",
      };
      if (vErrors === null) {
        vErrors = [err7];
      } else {
        vErrors.push(err7);
      }
      errors++;
    }
    if (data.numberType === undefined) {
      var err8 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'numberType' },
        message: "should have required property '" + 'numberType' + "'",
      };
      if (vErrors === null) {
        vErrors = [err8];
      } else {
        vErrors.push(err8);
      }
      errors++;
    }
    if (data.number === undefined) {
      var err9 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'number' },
        message: "should have required property '" + 'number' + "'",
      };
      if (vErrors === null) {
        vErrors = [err9];
      } else {
        vErrors.push(err9);
      }
      errors++;
    }
    if (data.cadastralReference === undefined) {
      var err10 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'cadastralReference' },
        message: "should have required property '" + 'cadastralReference' + "'",
      };
      if (vErrors === null) {
        vErrors = [err10];
      } else {
        vErrors.push(err10);
      }
      errors++;
    }
    if (data.inSmallTown === undefined) {
      var err11 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'inSmallTown' },
        message: "should have required property '" + 'inSmallTown' + "'",
      };
      if (vErrors === null) {
        vErrors = [err11];
      } else {
        vErrors.push(err11);
      }
      errors++;
    }
    for (var key0 in data) {
      if (!func3.call(schema6.properties, key0)) {
        var err12 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err12];
        } else {
          vErrors.push(err12);
        }
        errors++;
      }
    }
    if (data.country !== undefined) {
      if (typeof data.country !== 'string') {
        var err13 = {
          keyword: 'type',
          dataPath: dataPath + '/country',
          schemaPath: '#/properties/country/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err13];
        } else {
          vErrors.push(err13);
        }
        errors++;
      }
    }
    if (data.region !== undefined) {
      if (typeof data.region !== 'string') {
        var err14 = {
          keyword: 'type',
          dataPath: dataPath + '/region',
          schemaPath: '#/properties/region/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err14];
        } else {
          vErrors.push(err14);
        }
        errors++;
      }
    }
    if (data.province !== undefined) {
      if (typeof data.province !== 'string') {
        var err15 = {
          keyword: 'type',
          dataPath: dataPath + '/province',
          schemaPath: '#/properties/province/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err15];
        } else {
          vErrors.push(err15);
        }
        errors++;
      }
    }
    if (data.town !== undefined) {
      if (typeof data.town !== 'string') {
        var err16 = {
          keyword: 'type',
          dataPath: dataPath + '/town',
          schemaPath: '#/properties/town/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err16];
        } else {
          vErrors.push(err16);
        }
        errors++;
      }
    }
    if (data.location !== undefined) {
      var data4 = data.location;
      if (typeof data4 !== 'string' && data4 !== null) {
        var err17 = {
          keyword: 'type',
          dataPath: dataPath + '/location',
          schemaPath: '#/properties/location/type',
          params: { type: schema6.properties.location.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err17];
        } else {
          vErrors.push(err17);
        }
        errors++;
      }
    }
    if (data.postalCode !== undefined) {
      if (typeof data.postalCode !== 'string') {
        var err18 = {
          keyword: 'type',
          dataPath: dataPath + '/postalCode',
          schemaPath: '#/properties/postalCode/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err18];
        } else {
          vErrors.push(err18);
        }
        errors++;
      }
    }
    if (data.streetType !== undefined) {
      if (typeof data.streetType !== 'string') {
        var err19 = {
          keyword: 'type',
          dataPath: dataPath + '/streetType',
          schemaPath: '#/properties/streetType/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err19];
        } else {
          vErrors.push(err19);
        }
        errors++;
      }
    }
    if (data.streetName !== undefined) {
      if (typeof data.streetName !== 'string') {
        var err20 = {
          keyword: 'type',
          dataPath: dataPath + '/streetName',
          schemaPath: '#/properties/streetName/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err20];
        } else {
          vErrors.push(err20);
        }
        errors++;
      }
    }
    if (data.numberType !== undefined) {
      if (typeof data.numberType !== 'string') {
        var err21 = {
          keyword: 'type',
          dataPath: dataPath + '/numberType',
          schemaPath: '#/properties/numberType/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err21];
        } else {
          vErrors.push(err21);
        }
        errors++;
      }
    }
    if (data.number !== undefined) {
      var data9 = data.number;
      if (typeof data9 !== 'string' && data9 !== null) {
        var err22 = {
          keyword: 'type',
          dataPath: dataPath + '/number',
          schemaPath: '#/properties/number/type',
          params: { type: schema6.properties.number.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err22];
        } else {
          vErrors.push(err22);
        }
        errors++;
      }
    }
    if (data.block !== undefined) {
      var data10 = data.block;
      if (typeof data10 !== 'string' && data10 !== null) {
        var err23 = {
          keyword: 'type',
          dataPath: dataPath + '/block',
          schemaPath: '#/properties/block/type',
          params: { type: schema6.properties.block.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err23];
        } else {
          vErrors.push(err23);
        }
        errors++;
      }
    }
    if (data.stair !== undefined) {
      var data11 = data.stair;
      if (typeof data11 !== 'string' && data11 !== null) {
        var err24 = {
          keyword: 'type',
          dataPath: dataPath + '/stair',
          schemaPath: '#/properties/stair/type',
          params: { type: schema6.properties.stair.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err24];
        } else {
          vErrors.push(err24);
        }
        errors++;
      }
    }
    if (data.floor !== undefined) {
      var data12 = data.floor;
      if (typeof data12 !== 'string' && data12 !== null) {
        var err25 = {
          keyword: 'type',
          dataPath: dataPath + '/floor',
          schemaPath: '#/properties/floor/type',
          params: { type: schema6.properties.floor.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err25];
        } else {
          vErrors.push(err25);
        }
        errors++;
      }
    }
    if (data.door !== undefined) {
      var data13 = data.door;
      if (typeof data13 !== 'string' && data13 !== null) {
        var err26 = {
          keyword: 'type',
          dataPath: dataPath + '/door',
          schemaPath: '#/properties/door/type',
          params: { type: schema6.properties.door.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err26];
        } else {
          vErrors.push(err26);
        }
        errors++;
      }
    }
    if (data.cadastralReference !== undefined) {
      if (typeof data.cadastralReference !== 'string') {
        var err27 = {
          keyword: 'type',
          dataPath: dataPath + '/cadastralReference',
          schemaPath: '#/properties/cadastralReference/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err27];
        } else {
          vErrors.push(err27);
        }
        errors++;
      }
    }
    if (data.inSmallTown !== undefined) {
      if (typeof data.inSmallTown !== 'boolean') {
        var err28 = {
          keyword: 'type',
          dataPath: dataPath + '/inSmallTown',
          schemaPath: '#/properties/inSmallTown/type',
          params: { type: 'boolean' },
          message: 'should be boolean',
        };
        if (vErrors === null) {
          vErrors = [err28];
        } else {
          vErrors.push(err28);
        }
        errors++;
      }
    }
  } else {
    var err29 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err29];
    } else {
      vErrors.push(err29);
    }
    errors++;
  }
  validate21.errors = vErrors;
  return errors === 0;
}
