'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const pensionInsurance2020 = require('./pension_insurance_2020');
const healthInsurance2020 = require('./../2020/health_insurance_2020');
const { toString } = require('../utils/utils_logs');

function provisionCost2020(fields, incomeWithoutProfessionalExpensesPerPerson, lumpsums) {
  let expenses = new Decimal(0);

  logger.debug('\n-------------------------\PROVISION COSTS\n');

  // pension insurance
  let {
    paidByEmployee,
    // variable 'refunds' is not exported from pensionInsurance2020(..)
    refunds, 
    paidByEmployer,
    sumBeforeMax,
    max,
    sumAfterMax,
    proportionalMaxValue,
    pensionInsurance,
    subContributionPensionInsurance,
    percentage,
  } = pensionInsurance2020(fields, incomeWithoutProfessionalExpensesPerPerson, { case104a: true }, lumpsums);
  expenses = expenses.add(pensionInsurance);
  logger.debug('pensionInsurance', toString(pensionInsurance));

  // health insurance
  let {
    healthInsurancePaidEmployeeA,
    healthInsurancePaidEmployeeB,
    healthInsurancePaidEmployee,
    
    healthInsuranceNoSickpayA,
    healthInsuranceNoSickpayB,
    healthInsuranceNoSickpay,
    healthInsuranceWithSickpayA,
    healthInsuranceWithSickpayB,
    healthInsuranceWithSickpay,
    
    healthInsuranceRefunds,
    healthInsuranceReimbursements,
    healthInsuranceTaxfreeEmployerBenefits,
    healthInsuranceTaxfreeEmployerBenefitsA,
    healthInsuranceTaxfreeEmployerBenefitsB,

    additionalHealthInsuranceA,
    additionalHealthInsuranceB,
    foreignKVA,
    foreignKVB,
    foreignNoClaimSickPayA,
    foreignNoClaimSickPayB,

    healthInsuranceCutSum,
    healthInsuranceCut4Percent,
    healthInsuranceAfterCut,


    socialCareInsurance,
    socialCareInsuranceA,
    socialCareInsuranceB,
    socialCareInsuranceTaxfreeEmployerBenefitsA,
    socialCareInsuranceTaxfreeEmployerBenefitsB,
    socialCareInsuranceTaxfreeEmployerBenefits,

    taxfreeEmployerBenefitsA,
    taxfreeEmployerBenefitsB,
    taxfreeEmployerBenefits,
    steuerfreieAGZuschuesse,

    healthInsuranceSumFeesP10Abs1Nr3,
    healthInsuranceSumP10Abs1Nr3,

    additionalHealthInsurance,
    unemploymentInsurance,
    incapacityToWorkInsurance,
    accidentEtcInsurance,
    childKV,

    healthInsuranceSum,
    healthInsuranceSumForAnzuHoch,
    healthInsuranceSumAfterMax,
    healthInsuranceMax,
    additionalInsurances,
    erstattungsuberhang10Abs4b,
    subContributionHealthInsurance,
    subContributionOtherInsurance

  } = healthInsurance2020(fields);

  // check which is higher
  let healthInsurance = additionalInsurances;
  if (healthInsurance.lt(healthInsuranceSumP10Abs1Nr3)) {
    healthInsurance = healthInsuranceSumP10Abs1Nr3;
  }

  expenses = expenses.add(healthInsurance);
  logger.debug('healthInsurance', toString(healthInsurance));
  logger.debug('healthInsuranceSumP10Abs1Nr3', toString(healthInsuranceSumP10Abs1Nr3));
  logger.debug('healthInsurance erstattungsuberhang10Abs4b', toString(erstattungsuberhang10Abs4b));
  logger.debug('healthInsurance healthInsuranceSumForAnzuHoch',toString(healthInsuranceSumForAnzuHoch));

  const provisionCostP10Abs3And4 = new Decimal(expenses);

  return {
    pensionInsurancePaidByEmployee: paidByEmployee,
    pensionInsuranceRefunds: refunds,
    pensionInsurancePaidByEmployer: paidByEmployer,
    pensionInsuranceBeforeMax: sumBeforeMax,
    pensionInsuranceMax: max,
    pensionInsuranceAfterMax: sumAfterMax,
    pensionInsuranceProportionalMaxValue: proportionalMaxValue,
    pensionInsurance,
    pensionInsurancePercentage: percentage,


    healthInsurancePaidEmployeeA,
    healthInsurancePaidEmployeeB,
    healthInsurancePaidEmployee,
    healthInsuranceNoSickpayA,
    healthInsuranceNoSickpayB,
    healthInsuranceNoSickpay,
    healthInsuranceWithSickpayA,
    healthInsuranceWithSickpayB,
    healthInsuranceWithSickpay,
    healthInsuranceRefunds,
    healthInsuranceReimbursements,
    healthInsuranceTaxfreeEmployerBenefits,
    healthInsuranceTaxfreeEmployerBenefitsA,
    healthInsuranceTaxfreeEmployerBenefitsB,
    healthInsuranceCutSum,
    healthInsuranceCut4Percent,
    healthInsuranceAfterCut,
    healthInsuranceSumForAnzuHoch,
    additionalInsurances,
    socialCareInsurance,
    socialCareInsuranceA,
    socialCareInsuranceB,
    socialCareInsuranceTaxfreeEmployerBenefitsA,
    socialCareInsuranceTaxfreeEmployerBenefitsB,
    socialCareInsuranceTaxfreeEmployerBenefits,
    healthInsuranceSumFeesP10Abs1Nr3,
    healthInsuranceSumP10Abs1Nr3,
    taxfreeEmployerBenefitsA,
    taxfreeEmployerBenefitsB,
    taxfreeEmployerBenefits,
    steuerfreieAGZuschuesse,
    additionalHealthInsurance,
    unemploymentInsurance,
    incapacityToWorkInsurance,
    accidentEtcInsurance,
    healthInsuranceMax,
    healthInsuranceSum,
    healthInsuranceSumAfterMax,
    healthInsurance,
    erstattungsuberhang10Abs4b,

    provisionCostP10Abs3And4,
    provisionCost: expenses,

    subContributionPensionInsurance,
    subContributionHealthInsurance,

    additionalHealthInsuranceA,
    additionalHealthInsuranceB,

    foreignKVA,
    foreignKVB,

    foreignNoClaimSickPayA,
    foreignNoClaimSickPayB,
    childKV,
    
    subContributionOtherInsurance
  };
}

module.exports = provisionCost2020;
