'use strict';

const Decimal = require('decimal.js');
const { taxTypes } = require('@taxfix/de-itc-types');

const determine = (tax) => {
  const taxAmount = new Decimal(tax);
  if (taxAmount.gt(0)) return taxTypes.Shortfall;
  if (taxAmount.eq(0)) return taxTypes.Even;
  return taxTypes.Refund;
};

module.exports = determine;
