// @flow

import BaseAnswer from './Base';
import { IT_tax_number } from './custom-formats/IT_tax_number';
import type { ValidationFunction, ValidationSchema } from '../flowtypes';

class ITTaxNumber extends BaseAnswer {
  constructor() {
    super('IT_tax_number');
    this.defaultType = 'string';
    this.hasCustomFormat = true;
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/IT_tax_number.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/IT_tax_number');
    /* eslint-enable global-require */
  }

  validateITTaxNumber(data: string) {
    return IT_tax_number(data);
  }

  format(value: any): string {
    super.format(value); // make sure default validations running
    return (value: string);
  }
}

export default new ITTaxNumber();
