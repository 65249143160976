'use strict';
module.exports = validate21;
module.exports.default = validate21;
var schema6 = {
  $id: 'http://taxfix.de/schemas/answers/payrolldata.json',
  $schema: 'http://json-schema.org/draft-06/schema#',
  type: 'object',
  properties: {
    '3': { type: 'number' },
    '4': { type: 'number' },
    '5': { type: 'number' },
    '6': { type: 'number' },
    '7': { type: 'number' },
    '10': { type: 'number' },
    '11': { type: 'number' },
    '12': { type: 'number' },
    '13': { type: 'number' },
    '14': { type: 'number' },
    '15': { type: 'number' },
    '17': { type: 'number' },
    '18': { type: 'number' },
    '19': { type: 'number' },
    '20': { type: 'number' },
    '21': { type: 'number' },
    '25': { type: 'number' },
    '26': { type: 'number' },
    '27': { type: 'number' },
    '28': { type: 'number' },
    '33': { type: 'number' },
    eTin: { type: ['string', 'null'] },
    taxClass: { type: 'string' },
    duration: {
      type: 'object',
      properties: {
        from: {
          type: 'string',
          pattern: '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$',
        },
        until: {
          type: 'string',
          pattern: '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$',
        },
      },
      additionalProperties: false,
      required: ['from', 'until'],
    },
    '22a': { type: 'number' },
    '22b': { type: 'number' },
    '23a': { type: 'number' },
    '23b': { type: 'number' },
    '24a': { type: 'number' },
    '24b': { type: 'number' },
    '24c': { type: 'number' },
  },
  additionalProperties: true,
  required: [
    'taxClass',
    '3',
    '4',
    '5',
    '6',
    '7',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22a',
    '22b',
    '23a',
    '23b',
    '24a',
    '24b',
    '24c',
    '25',
    '26',
    '27',
    '28',
    '33',
  ],
};
var pattern0 = new RegExp('^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$', 'u');

function validate21(data, valCxt) {
  'use strict' /*# sourceURL="http://taxfix.de/schemas/answers/payrolldata.json" */;
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.taxClass === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'taxClass' },
        message: "should have required property '" + 'taxClass' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data['3'] === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '3' },
        message: "should have required property '" + '3' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data['4'] === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '4' },
        message: "should have required property '" + '4' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data['5'] === undefined) {
      var err3 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '5' },
        message: "should have required property '" + '5' + "'",
      };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data['6'] === undefined) {
      var err4 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '6' },
        message: "should have required property '" + '6' + "'",
      };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data['7'] === undefined) {
      var err5 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '7' },
        message: "should have required property '" + '7' + "'",
      };
      if (vErrors === null) {
        vErrors = [err5];
      } else {
        vErrors.push(err5);
      }
      errors++;
    }
    if (data['10'] === undefined) {
      var err6 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '10' },
        message: "should have required property '" + '10' + "'",
      };
      if (vErrors === null) {
        vErrors = [err6];
      } else {
        vErrors.push(err6);
      }
      errors++;
    }
    if (data['11'] === undefined) {
      var err7 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '11' },
        message: "should have required property '" + '11' + "'",
      };
      if (vErrors === null) {
        vErrors = [err7];
      } else {
        vErrors.push(err7);
      }
      errors++;
    }
    if (data['12'] === undefined) {
      var err8 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '12' },
        message: "should have required property '" + '12' + "'",
      };
      if (vErrors === null) {
        vErrors = [err8];
      } else {
        vErrors.push(err8);
      }
      errors++;
    }
    if (data['13'] === undefined) {
      var err9 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '13' },
        message: "should have required property '" + '13' + "'",
      };
      if (vErrors === null) {
        vErrors = [err9];
      } else {
        vErrors.push(err9);
      }
      errors++;
    }
    if (data['14'] === undefined) {
      var err10 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '14' },
        message: "should have required property '" + '14' + "'",
      };
      if (vErrors === null) {
        vErrors = [err10];
      } else {
        vErrors.push(err10);
      }
      errors++;
    }
    if (data['15'] === undefined) {
      var err11 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '15' },
        message: "should have required property '" + '15' + "'",
      };
      if (vErrors === null) {
        vErrors = [err11];
      } else {
        vErrors.push(err11);
      }
      errors++;
    }
    if (data['17'] === undefined) {
      var err12 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '17' },
        message: "should have required property '" + '17' + "'",
      };
      if (vErrors === null) {
        vErrors = [err12];
      } else {
        vErrors.push(err12);
      }
      errors++;
    }
    if (data['18'] === undefined) {
      var err13 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '18' },
        message: "should have required property '" + '18' + "'",
      };
      if (vErrors === null) {
        vErrors = [err13];
      } else {
        vErrors.push(err13);
      }
      errors++;
    }
    if (data['19'] === undefined) {
      var err14 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '19' },
        message: "should have required property '" + '19' + "'",
      };
      if (vErrors === null) {
        vErrors = [err14];
      } else {
        vErrors.push(err14);
      }
      errors++;
    }
    if (data['20'] === undefined) {
      var err15 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '20' },
        message: "should have required property '" + '20' + "'",
      };
      if (vErrors === null) {
        vErrors = [err15];
      } else {
        vErrors.push(err15);
      }
      errors++;
    }
    if (data['21'] === undefined) {
      var err16 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '21' },
        message: "should have required property '" + '21' + "'",
      };
      if (vErrors === null) {
        vErrors = [err16];
      } else {
        vErrors.push(err16);
      }
      errors++;
    }
    if (data['22a'] === undefined) {
      var err17 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '22a' },
        message: "should have required property '" + '22a' + "'",
      };
      if (vErrors === null) {
        vErrors = [err17];
      } else {
        vErrors.push(err17);
      }
      errors++;
    }
    if (data['22b'] === undefined) {
      var err18 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '22b' },
        message: "should have required property '" + '22b' + "'",
      };
      if (vErrors === null) {
        vErrors = [err18];
      } else {
        vErrors.push(err18);
      }
      errors++;
    }
    if (data['23a'] === undefined) {
      var err19 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '23a' },
        message: "should have required property '" + '23a' + "'",
      };
      if (vErrors === null) {
        vErrors = [err19];
      } else {
        vErrors.push(err19);
      }
      errors++;
    }
    if (data['23b'] === undefined) {
      var err20 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '23b' },
        message: "should have required property '" + '23b' + "'",
      };
      if (vErrors === null) {
        vErrors = [err20];
      } else {
        vErrors.push(err20);
      }
      errors++;
    }
    if (data['24a'] === undefined) {
      var err21 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '24a' },
        message: "should have required property '" + '24a' + "'",
      };
      if (vErrors === null) {
        vErrors = [err21];
      } else {
        vErrors.push(err21);
      }
      errors++;
    }
    if (data['24b'] === undefined) {
      var err22 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '24b' },
        message: "should have required property '" + '24b' + "'",
      };
      if (vErrors === null) {
        vErrors = [err22];
      } else {
        vErrors.push(err22);
      }
      errors++;
    }
    if (data['24c'] === undefined) {
      var err23 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '24c' },
        message: "should have required property '" + '24c' + "'",
      };
      if (vErrors === null) {
        vErrors = [err23];
      } else {
        vErrors.push(err23);
      }
      errors++;
    }
    if (data['25'] === undefined) {
      var err24 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '25' },
        message: "should have required property '" + '25' + "'",
      };
      if (vErrors === null) {
        vErrors = [err24];
      } else {
        vErrors.push(err24);
      }
      errors++;
    }
    if (data['26'] === undefined) {
      var err25 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '26' },
        message: "should have required property '" + '26' + "'",
      };
      if (vErrors === null) {
        vErrors = [err25];
      } else {
        vErrors.push(err25);
      }
      errors++;
    }
    if (data['27'] === undefined) {
      var err26 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '27' },
        message: "should have required property '" + '27' + "'",
      };
      if (vErrors === null) {
        vErrors = [err26];
      } else {
        vErrors.push(err26);
      }
      errors++;
    }
    if (data['28'] === undefined) {
      var err27 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '28' },
        message: "should have required property '" + '28' + "'",
      };
      if (vErrors === null) {
        vErrors = [err27];
      } else {
        vErrors.push(err27);
      }
      errors++;
    }
    if (data['33'] === undefined) {
      var err28 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: '33' },
        message: "should have required property '" + '33' + "'",
      };
      if (vErrors === null) {
        vErrors = [err28];
      } else {
        vErrors.push(err28);
      }
      errors++;
    }
    if (data['3'] !== undefined) {
      if (!(typeof data['3'] == 'number')) {
        var err29 = {
          keyword: 'type',
          dataPath: dataPath + '/3',
          schemaPath: '#/properties/3/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err29];
        } else {
          vErrors.push(err29);
        }
        errors++;
      }
    }
    if (data['4'] !== undefined) {
      if (!(typeof data['4'] == 'number')) {
        var err30 = {
          keyword: 'type',
          dataPath: dataPath + '/4',
          schemaPath: '#/properties/4/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err30];
        } else {
          vErrors.push(err30);
        }
        errors++;
      }
    }
    if (data['5'] !== undefined) {
      if (!(typeof data['5'] == 'number')) {
        var err31 = {
          keyword: 'type',
          dataPath: dataPath + '/5',
          schemaPath: '#/properties/5/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err31];
        } else {
          vErrors.push(err31);
        }
        errors++;
      }
    }
    if (data['6'] !== undefined) {
      if (!(typeof data['6'] == 'number')) {
        var err32 = {
          keyword: 'type',
          dataPath: dataPath + '/6',
          schemaPath: '#/properties/6/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err32];
        } else {
          vErrors.push(err32);
        }
        errors++;
      }
    }
    if (data['7'] !== undefined) {
      if (!(typeof data['7'] == 'number')) {
        var err33 = {
          keyword: 'type',
          dataPath: dataPath + '/7',
          schemaPath: '#/properties/7/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err33];
        } else {
          vErrors.push(err33);
        }
        errors++;
      }
    }
    if (data['10'] !== undefined) {
      if (!(typeof data['10'] == 'number')) {
        var err34 = {
          keyword: 'type',
          dataPath: dataPath + '/10',
          schemaPath: '#/properties/10/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err34];
        } else {
          vErrors.push(err34);
        }
        errors++;
      }
    }
    if (data['11'] !== undefined) {
      if (!(typeof data['11'] == 'number')) {
        var err35 = {
          keyword: 'type',
          dataPath: dataPath + '/11',
          schemaPath: '#/properties/11/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err35];
        } else {
          vErrors.push(err35);
        }
        errors++;
      }
    }
    if (data['12'] !== undefined) {
      if (!(typeof data['12'] == 'number')) {
        var err36 = {
          keyword: 'type',
          dataPath: dataPath + '/12',
          schemaPath: '#/properties/12/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err36];
        } else {
          vErrors.push(err36);
        }
        errors++;
      }
    }
    if (data['13'] !== undefined) {
      if (!(typeof data['13'] == 'number')) {
        var err37 = {
          keyword: 'type',
          dataPath: dataPath + '/13',
          schemaPath: '#/properties/13/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err37];
        } else {
          vErrors.push(err37);
        }
        errors++;
      }
    }
    if (data['14'] !== undefined) {
      if (!(typeof data['14'] == 'number')) {
        var err38 = {
          keyword: 'type',
          dataPath: dataPath + '/14',
          schemaPath: '#/properties/14/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err38];
        } else {
          vErrors.push(err38);
        }
        errors++;
      }
    }
    if (data['15'] !== undefined) {
      if (!(typeof data['15'] == 'number')) {
        var err39 = {
          keyword: 'type',
          dataPath: dataPath + '/15',
          schemaPath: '#/properties/15/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err39];
        } else {
          vErrors.push(err39);
        }
        errors++;
      }
    }
    if (data['17'] !== undefined) {
      if (!(typeof data['17'] == 'number')) {
        var err40 = {
          keyword: 'type',
          dataPath: dataPath + '/17',
          schemaPath: '#/properties/17/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err40];
        } else {
          vErrors.push(err40);
        }
        errors++;
      }
    }
    if (data['18'] !== undefined) {
      if (!(typeof data['18'] == 'number')) {
        var err41 = {
          keyword: 'type',
          dataPath: dataPath + '/18',
          schemaPath: '#/properties/18/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err41];
        } else {
          vErrors.push(err41);
        }
        errors++;
      }
    }
    if (data['19'] !== undefined) {
      if (!(typeof data['19'] == 'number')) {
        var err42 = {
          keyword: 'type',
          dataPath: dataPath + '/19',
          schemaPath: '#/properties/19/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err42];
        } else {
          vErrors.push(err42);
        }
        errors++;
      }
    }
    if (data['20'] !== undefined) {
      if (!(typeof data['20'] == 'number')) {
        var err43 = {
          keyword: 'type',
          dataPath: dataPath + '/20',
          schemaPath: '#/properties/20/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err43];
        } else {
          vErrors.push(err43);
        }
        errors++;
      }
    }
    if (data['21'] !== undefined) {
      if (!(typeof data['21'] == 'number')) {
        var err44 = {
          keyword: 'type',
          dataPath: dataPath + '/21',
          schemaPath: '#/properties/21/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err44];
        } else {
          vErrors.push(err44);
        }
        errors++;
      }
    }
    if (data['25'] !== undefined) {
      if (!(typeof data['25'] == 'number')) {
        var err45 = {
          keyword: 'type',
          dataPath: dataPath + '/25',
          schemaPath: '#/properties/25/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err45];
        } else {
          vErrors.push(err45);
        }
        errors++;
      }
    }
    if (data['26'] !== undefined) {
      if (!(typeof data['26'] == 'number')) {
        var err46 = {
          keyword: 'type',
          dataPath: dataPath + '/26',
          schemaPath: '#/properties/26/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err46];
        } else {
          vErrors.push(err46);
        }
        errors++;
      }
    }
    if (data['27'] !== undefined) {
      if (!(typeof data['27'] == 'number')) {
        var err47 = {
          keyword: 'type',
          dataPath: dataPath + '/27',
          schemaPath: '#/properties/27/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err47];
        } else {
          vErrors.push(err47);
        }
        errors++;
      }
    }
    if (data['28'] !== undefined) {
      if (!(typeof data['28'] == 'number')) {
        var err48 = {
          keyword: 'type',
          dataPath: dataPath + '/28',
          schemaPath: '#/properties/28/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err48];
        } else {
          vErrors.push(err48);
        }
        errors++;
      }
    }
    if (data['33'] !== undefined) {
      if (!(typeof data['33'] == 'number')) {
        var err49 = {
          keyword: 'type',
          dataPath: dataPath + '/33',
          schemaPath: '#/properties/33/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err49];
        } else {
          vErrors.push(err49);
        }
        errors++;
      }
    }
    if (data.eTin !== undefined) {
      var data21 = data.eTin;
      if (typeof data21 !== 'string' && data21 !== null) {
        var err50 = {
          keyword: 'type',
          dataPath: dataPath + '/eTin',
          schemaPath: '#/properties/eTin/type',
          params: { type: schema6.properties.eTin.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err50];
        } else {
          vErrors.push(err50);
        }
        errors++;
      }
    }
    if (data.taxClass !== undefined) {
      if (typeof data.taxClass !== 'string') {
        var err51 = {
          keyword: 'type',
          dataPath: dataPath + '/taxClass',
          schemaPath: '#/properties/taxClass/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err51];
        } else {
          vErrors.push(err51);
        }
        errors++;
      }
    }
    if (data.duration !== undefined) {
      var data23 = data.duration;
      if (data23 && typeof data23 == 'object' && !Array.isArray(data23)) {
        if (data23.from === undefined) {
          var err52 = {
            keyword: 'required',
            dataPath: dataPath + '/duration',
            schemaPath: '#/properties/duration/required',
            params: { missingProperty: 'from' },
            message: "should have required property '" + 'from' + "'",
          };
          if (vErrors === null) {
            vErrors = [err52];
          } else {
            vErrors.push(err52);
          }
          errors++;
        }
        if (data23.until === undefined) {
          var err53 = {
            keyword: 'required',
            dataPath: dataPath + '/duration',
            schemaPath: '#/properties/duration/required',
            params: { missingProperty: 'until' },
            message: "should have required property '" + 'until' + "'",
          };
          if (vErrors === null) {
            vErrors = [err53];
          } else {
            vErrors.push(err53);
          }
          errors++;
        }
        for (var key0 in data23) {
          if (!(key0 === 'from' || key0 === 'until')) {
            var err54 = {
              keyword: 'additionalProperties',
              dataPath: dataPath + '/duration',
              schemaPath: '#/properties/duration/additionalProperties',
              params: { additionalProperty: key0 },
              message: 'should NOT have additional properties',
            };
            if (vErrors === null) {
              vErrors = [err54];
            } else {
              vErrors.push(err54);
            }
            errors++;
          }
        }
        if (data23.from !== undefined) {
          var data24 = data23.from;
          if (typeof data24 === 'string') {
            if (!pattern0.test(data24)) {
              var err55 = {
                keyword: 'pattern',
                dataPath: dataPath + '/duration/from',
                schemaPath: '#/properties/duration/properties/from/pattern',
                params: { pattern: '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$' },
                message:
                  'should match pattern "' +
                  '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$' +
                  '"',
              };
              if (vErrors === null) {
                vErrors = [err55];
              } else {
                vErrors.push(err55);
              }
              errors++;
            }
          } else {
            var err56 = {
              keyword: 'type',
              dataPath: dataPath + '/duration/from',
              schemaPath: '#/properties/duration/properties/from/type',
              params: { type: 'string' },
              message: 'should be string',
            };
            if (vErrors === null) {
              vErrors = [err56];
            } else {
              vErrors.push(err56);
            }
            errors++;
          }
        }
        if (data23.until !== undefined) {
          var data25 = data23.until;
          if (typeof data25 === 'string') {
            if (!pattern0.test(data25)) {
              var err57 = {
                keyword: 'pattern',
                dataPath: dataPath + '/duration/until',
                schemaPath: '#/properties/duration/properties/until/pattern',
                params: { pattern: '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$' },
                message:
                  'should match pattern "' +
                  '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$' +
                  '"',
              };
              if (vErrors === null) {
                vErrors = [err57];
              } else {
                vErrors.push(err57);
              }
              errors++;
            }
          } else {
            var err58 = {
              keyword: 'type',
              dataPath: dataPath + '/duration/until',
              schemaPath: '#/properties/duration/properties/until/type',
              params: { type: 'string' },
              message: 'should be string',
            };
            if (vErrors === null) {
              vErrors = [err58];
            } else {
              vErrors.push(err58);
            }
            errors++;
          }
        }
      } else {
        var err59 = {
          keyword: 'type',
          dataPath: dataPath + '/duration',
          schemaPath: '#/properties/duration/type',
          params: { type: 'object' },
          message: 'should be object',
        };
        if (vErrors === null) {
          vErrors = [err59];
        } else {
          vErrors.push(err59);
        }
        errors++;
      }
    }
    if (data['22a'] !== undefined) {
      if (!(typeof data['22a'] == 'number')) {
        var err60 = {
          keyword: 'type',
          dataPath: dataPath + '/22a',
          schemaPath: '#/properties/22a/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err60];
        } else {
          vErrors.push(err60);
        }
        errors++;
      }
    }
    if (data['22b'] !== undefined) {
      if (!(typeof data['22b'] == 'number')) {
        var err61 = {
          keyword: 'type',
          dataPath: dataPath + '/22b',
          schemaPath: '#/properties/22b/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err61];
        } else {
          vErrors.push(err61);
        }
        errors++;
      }
    }
    if (data['23a'] !== undefined) {
      if (!(typeof data['23a'] == 'number')) {
        var err62 = {
          keyword: 'type',
          dataPath: dataPath + '/23a',
          schemaPath: '#/properties/23a/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err62];
        } else {
          vErrors.push(err62);
        }
        errors++;
      }
    }
    if (data['23b'] !== undefined) {
      if (!(typeof data['23b'] == 'number')) {
        var err63 = {
          keyword: 'type',
          dataPath: dataPath + '/23b',
          schemaPath: '#/properties/23b/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err63];
        } else {
          vErrors.push(err63);
        }
        errors++;
      }
    }
    if (data['24a'] !== undefined) {
      if (!(typeof data['24a'] == 'number')) {
        var err64 = {
          keyword: 'type',
          dataPath: dataPath + '/24a',
          schemaPath: '#/properties/24a/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err64];
        } else {
          vErrors.push(err64);
        }
        errors++;
      }
    }
    if (data['24b'] !== undefined) {
      if (!(typeof data['24b'] == 'number')) {
        var err65 = {
          keyword: 'type',
          dataPath: dataPath + '/24b',
          schemaPath: '#/properties/24b/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err65];
        } else {
          vErrors.push(err65);
        }
        errors++;
      }
    }
    if (data['24c'] !== undefined) {
      if (!(typeof data['24c'] == 'number')) {
        var err66 = {
          keyword: 'type',
          dataPath: dataPath + '/24c',
          schemaPath: '#/properties/24c/type',
          params: { type: 'number' },
          message: 'should be number',
        };
        if (vErrors === null) {
          vErrors = [err66];
        } else {
          vErrors.push(err66);
        }
        errors++;
      }
    }
  } else {
    var err67 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err67];
    } else {
      vErrors.push(err67);
    }
    errors++;
  }
  validate21.errors = vErrors;
  return errors === 0;
}
