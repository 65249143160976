'use strict';

const Decimal = require('decimal.js');
const { TaxYears } = require('@taxfix/de-itc-types');

const { find, getBool, getDecimal } = require('../utils/utils_fields');
const { percent: getPercent } = require('../utils/utils_decimal');
const { toNumber } = require('../utils/utils_decimal');
const { isJointAssessment } = require('../utils/utils_field');

const MAX_AMOUNT_FOR_35C = new Decimal(14000);

/**
 * @param sumOfEnergeticCosts
 * @param costForEnergyConsultant
 * @param percentage
 * @param share
 * @returns {*}
 */
function calculateCostsFor35c(sumOfEnergeticCosts, costForEnergyConsultant, percentage, share) {
  const measureToBeConsidered = getPercent(sumOfEnergeticCosts, percentage);
  const consultantToBeConsidered = getPercent(costForEnergyConsultant, percentage);

  const dedMeasure = getPercent(measureToBeConsidered.times(share), 7);
  const dedConsultant = getPercent(consultantToBeConsidered.times(share), 50);
  const res = dedMeasure.add(dedConsultant);

  return Decimal.min(res, MAX_AMOUNT_FOR_35C);
}

/**
 * @param taxYear {number}
 * @param fields {*}
 * @returns {*}
 */
function calculateDeductionFor35c(taxYear, fields) {
  let deductions35c = new Decimal(0);

  const isJA = isJointAssessment(fields);
  const overallSpaceQM = getDecimal(fields, '0240801');
  const occupiedSpaceQM = getDecimal(fields, '0240802');
  const share = occupiedSpaceQM.div(overallSpaceQM);

  const sumOfEnergeticCosts = getDecimal(fields, '0241901');
  const costForEnergyConsultant = getDecimal(fields, '0242001');
  let ownershipShare = getDecimal(fields, '0242301', 1, 1);

  const ownershipShareFieldA = find(fields, '0242301', 1, 1);
  const ownershipShareFieldB = find(fields, '0242301', 1, 2);
  const isGreaterCosts = sumOfEnergeticCosts.add(costForEnergyConsultant).gt(0);

  if (isJA) {
    if (!ownershipShareFieldA && !ownershipShareFieldB) {
      deductions35c = new Decimal(0);
    } else {
      const ownershipShareB = getDecimal(fields, '0242301', 1, 2);
      const p = ownershipShare.add(ownershipShareB);
      deductions35c = calculateCostsFor35c(sumOfEnergeticCosts, costForEnergyConsultant, p.toNumber(), share);
    }
  } else {
    if (!ownershipShareFieldA) {
      if (isGreaterCosts) {
        deductions35c = calculateCostsFor35c(sumOfEnergeticCosts, costForEnergyConsultant, 100, share);
      } else {
        deductions35c = new Decimal(0);
      }
    } else {
      deductions35c = calculateCostsFor35c(sumOfEnergeticCosts, costForEnergyConsultant, ownershipShare.toNumber(), share);
    }
  }

  // calculate energetic costs for the second year
  const secondYearEnergeticCosts = getDecimal(fields, '0242501');

  if (secondYearEnergeticCosts.gt(0)) {
    let secondYearAmount = getPercent(secondYearEnergeticCosts, 7);

    secondYearAmount = Decimal.min(secondYearAmount, MAX_AMOUNT_FOR_35C);
    deductions35c = deductions35c.add(secondYearAmount);
  }

  deductions35c = Decimal.min(deductions35c, MAX_AMOUNT_FOR_35C);
  deductions35c = deductions35c.ceil();

  return deductions35c;
}

function taxDeductions({ taxYear, fields, donationsPolitical }) {
  const isJA = isJointAssessment(fields);
  let separate = getBool(fields, '0102602', 'X');
  let fiftyFifty = getBool(fields, '0109504', 1);

  let percent;

  if (isJA) {
    percent = 100;
  } else if (separate && fiftyFifty) {
    percent = 50;
  } else {
    let customPercent = getDecimal(fields, '0107703');

    if (customPercent.eq(0)) {
      let otherPersonsInHousehold = getDecimal(fields, '0107606');
      const ONE = new Decimal(1);
      percent = ONE.div(otherPersonsInHousehold.add(ONE)).times(100);
    } else {
      percent = customPercent;
    }

    percent = toNumber(percent);
  }

  let deductions = new Decimal(0);

  // Andere außergewöhnliche Belastungen: davon Pflegeleistungen geringfügige Beschäftigung  
  let a = getDecimal(fields, '0106813');

  // Geringfügige Beschäftigungen im Privathaushalt: Summe 
  a = a.add(getDecimal(fields, '0104109'));

  // only 20% and max 510
  a = getPercent(a, 20);
  a = Decimal.min(a, getPercent(new Decimal(510), percent));

  deductions = deductions.add(a);
  let deductions35a = a;

  // Andere außergewöhnliche Belastungen: davon Pflegeleistungen
  // sozialversicherungspfl. Beschäftigung 
  let b = getDecimal(fields, '0106906');

  // Haushaltsnahe Dienstleistungen / sozialversicherungspflichtige Beschäftigung: Summe 
  b = b.add(getDecimal(fields, '0107208'));
  
  // For 2015 we need to sum 0107208 with 0104603, 0104304, and 0111115 
  b = b.add(getDecimal(fields, '0104603'));
  b = b.add(getDecimal(fields, '0104304'));
  b = b.add(getDecimal(fields, '0111115'));

  // only 20% and max 4000
  b = getPercent(b, 20);
  b = Decimal.min(b, getPercent(new Decimal(4000), percent));

  deductions35a = deductions35a.add(b);
  deductions = deductions.add(b);

  // Andere außergewöhnliche Belastungen: davon Arbeitskosten für Handwerkerleistungen
  let c = getDecimal(fields, '0107005');

  // Handwerkerleistungen im Haushalt: Summe 
  c = c.add(getDecimal(fields, '0111215'));

  // only 20% and max 1200  
  c = getPercent(c, 20);
  c = Decimal.min(c, getPercent(new Decimal(1200), percent));

  deductions35a = deductions35a.add(c).ceil();
  deductions = deductions.add(c);

  // 6.1.5 Donations to political parties, but max 825/1650
  let donationLimit = isJA ? new Decimal(3300) : new Decimal(1650);
  let deductions34g = Decimal.min(donationsPolitical, donationLimit);

  // we are only allowed to take 50%
  deductions34g = getPercent(deductions34g, 50);

  // Wählervereinigungen Donations
  let votersAssociationDonations = new Decimal(0);

  // unabhängige Wählervereinigungen - Summe lt. Bestätigung
  votersAssociationDonations = votersAssociationDonations.add(getDecimal(fields, '0108801'));

  // unabhängige Wählervereinigungen - Summe lt. Nachweis Betriebs-FA
  votersAssociationDonations = votersAssociationDonations.add(getDecimal(fields, '0108802'));

  if (taxYear < TaxYears['2k19']) {
    // unabhängige Wählervereinigungen (elektron. Übermittlung) Person A
    votersAssociationDonations = votersAssociationDonations.add(getDecimal(fields, '0105203'));

    // unabhängige Wählervereinigungen (elektron. Übermittlung) Person B
    votersAssociationDonations = votersAssociationDonations.add(getDecimal(fields, '0105204'));
  } else {
    // unabhängige Wählervereinigungen (elektron. Übermittlung) Person A
    votersAssociationDonations = votersAssociationDonations.add(getDecimal(fields, 'txf-electric-voter-donation'));

    // unabhängige Wählervereinigungen (elektron. Übermittlung) Person B
    votersAssociationDonations = votersAssociationDonations.add(getDecimal(fields, 'txf-electric-voter-donation-B'));
  }

  if (votersAssociationDonations.lt(new Decimal(825))) {
    votersAssociationDonations = getPercent(votersAssociationDonations, 50);
  } else {
    votersAssociationDonations = new Decimal(825);
  }

  deductions34g = deductions34g.add(votersAssociationDonations).ceil();
  deductions = deductions.add(deductions34g);

  const result = {
    deductions35a,
    deductions34g,
    deductions,
    deductions35c: undefined,
  };

  /**
   * We started calculation of deductions35c from
   * tax year 2020
   */
  if (taxYear > TaxYears['2k19']) {
    result.deductions35c = calculateDeductionFor35c(taxYear, fields);
    result.deductions =  result.deductions.add(result.deductions35c);
  }

  return result;
}

module.exports = taxDeductions;
