// @flow

import BaseAnswer from './Base';
import { FormatterError } from '../error';
import type { ValidationFunction, ValidationSchema } from '../flowtypes';

type Currency = number;

export type BankConfirmationAnswer = {
  kapitalertrage: Currency,
  davonAktienverausserungen: Currency,
  kapitalertrageLebensversicherungen: Currency,
  ersatzbemessungsgrundlage: Currency,
  verlusteOhneAktien: Currency,
  verlusteAusAktien: Currency,
  sparerPauschbetragGenutzt: Currency,
  kapest: Currency,
  solz: Currency,
  kist: Currency,
  anrechenbareAuslandSteuern: Currency,
  nochNichtAngerechneteAuslandSteuern: Currency,
  nochNichtAngerechneteAuslandSteuernIsNegative: boolean,
  leistungenSteuerlEinlagekonto: boolean,
  auslandInvestmentfonds: boolean,
  auslandInvestmentfondsErtrage: Currency,
  auslandInvestmentfondsAngerechneteSteuern: Currency,
  auslandInvestmentfondsI: boolean,
  auslandInvestmentfondsIErtrage: Currency,
  auslandInvestmentfondsIAngerechneteSteuern: Currency,
  zugeflosseneErtrageOhneSteuerabzug: Currency,
  // 2018
  kapitalertraegeLebensversicherungenAb2018: Currency,
  kapitalertraegeLebensversicherungenKapESt: Currency,
  kapitalertraegeLebensversicherungenSolZ: Currency,
  kapitalertraegeLebensversicherungenKiSt: Currency,
  anteileAuslaendischeInvFondsErworbenvor2018: boolean,
  zugeflosseneErtraegeOhneSteuerabzugAb2018: Currency,
  // 2021
  davonTermingeschaefteStillhalterpraemien: Currency,
  davonVeraeusserungenAltAnteile: Currency,
  verluste20abs6s5Termingeschaefte: Currency,
  verluste20abs6s6UneinbringlichWertlos: Currency,
};

class BankConfirmation extends BaseAnswer {
  constructor() {
    const formats = [
      { name: 'KAPITALERTRAGE', type: 'number' },
      { name: 'DAVON_AKTIENVERAUSSERUNGEN', type: 'number' },
      { name: 'KAPITALERTRAGE_LEBENSVERSICHERUNGEN', type: 'number' },
      { name: 'ERSATZBEMESSUNGSGRUNDLAGE', type: 'number' },
      { name: 'VERLUSTE_OHNE_AKTIEN', type: 'number' },
      { name: 'VERLUSTE_AUS_AKTIEN', type: 'number' },
      { name: 'SPARER_PAUSCHBETRAG_GENUTZT', type: 'number' },
      { name: 'KAPEST', type: 'number' },
      { name: 'SOLZ', type: 'number' },
      { name: 'KIST', type: 'number' },
      { name: 'ANRECHENBARE_AUSLAND_STEUERN', type: 'number' },
      { name: 'NOCH_NICHT_ANGERECHNETE_AUSLAND_STEUERN', type: 'number' },
      { name: 'NOCH_NICHT_ANGERECHNETE_AUSLAND_STEUERN_IS_NEGATIVE', type: 'boolean' },
      { name: 'LEISTUNGEN_STEUERL_EINLAGEKONTO', type: 'boolean' },
      { name: 'AUSLAND_INVESTMENTFONDS', type: 'boolean' },
      { name: 'AUSLAND_INVESTMENTFONDS_ERTRAGE', type: 'number' },
      { name: 'AUSLAND_INVESTMENTFONDS_ANGERECHNETE_STEUERN', type: 'number' },
      { name: 'AUSLAND_INVESTMENTFONDS_I', type: 'boolean' },
      { name: 'AUSLAND_INVESTMENTFONDS_I_ERTRAGE', type: 'number' },
      { name: 'AUSLAND_INVESTMENTFONDS_I_ANGERECHNETE_STEUERN', type: 'number' },
      { name: 'ZUGEFLOSSENE_ERTRAGE_OHNE_STEUERABZUG', type: 'number' },
      // 2018
      { name: 'KAPITALERTRAEGE_LEBENSVERSICHERUNGEN_AB2018', type: 'number' },
      { name: 'KAPITALERTRAEGE_LEBENSVERSICHERUNGEN_KAPEST', type: 'number' },
      { name: 'KAPITALERTRAEGE_LEBENSVERSICHERUNGEN_SOLZ', type: 'number' },
      { name: 'KAPITALERTRAEGE_LEBENSVERSICHERUNGEN_KIST', type: 'number' },
      { name: 'ANTEILE_AUSLAENDISCHE_INVFONDS_ERWORBENVOR2018', type: 'boolean' },
      { name: 'ZUGEFLOSSENE_ERTRAEGE_OHNE_STEUERABZUG_AB2018', type: 'number' },
      // 2021
      { name: 'DAVON_TERMINGESCHAEFTE_STILLHALTERPRAEMIEN', type: 'number' },
      { name: 'DAVON_VERAEUSSERUNGEN_ALT_ANTEILE', type: 'number' },
      { name: 'VERLUSTE_20_AB_S6S5_TERMINGESCHAEFTE', type: 'number' },
      { name: 'VERLUSTE_20_AB_S6S6_UNEINBRINGLICH_WERTLOS', type: 'number' },
    ];
    super('bank_confirmation', formats);
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/bank_confirmation.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/bank_confirmation');
    /* eslint-enable global-require */
  }

  format(value: any, formatKey: string): Currency | boolean {
    super.format(value);

    const bankConfirmationValue: BankConfirmationAnswer = value;

    switch (formatKey) {
      case this.formats.KAPITALERTRAGE:
        return bankConfirmationValue.kapitalertrage;
      case this.formats.DAVON_AKTIENVERAUSSERUNGEN:
        return bankConfirmationValue.davonAktienverausserungen;
      case this.formats.KAPITALERTRAGE_LEBENSVERSICHERUNGEN:
        return bankConfirmationValue.kapitalertrageLebensversicherungen;
      case this.formats.ERSATZBEMESSUNGSGRUNDLAGE:
        return bankConfirmationValue.ersatzbemessungsgrundlage;
      case this.formats.VERLUSTE_OHNE_AKTIEN:
        return bankConfirmationValue.verlusteOhneAktien;
      case this.formats.VERLUSTE_AUS_AKTIEN:
        return bankConfirmationValue.verlusteAusAktien;
      case this.formats.SPARER_PAUSCHBETRAG_GENUTZT:
        return bankConfirmationValue.sparerPauschbetragGenutzt;
      case this.formats.KAPEST:
        return bankConfirmationValue.kapest;
      case this.formats.SOLZ:
        return bankConfirmationValue.solz;
      case this.formats.KIST:
        return bankConfirmationValue.kist;
      case this.formats.ANRECHENBARE_AUSLAND_STEUERN:
        return bankConfirmationValue.anrechenbareAuslandSteuern;
      case this.formats.NOCH_NICHT_ANGERECHNETE_AUSLAND_STEUERN:
        return bankConfirmationValue.nochNichtAngerechneteAuslandSteuern;
      case this.formats.NOCH_NICHT_ANGERECHNETE_AUSLAND_STEUERN_IS_NEGATIVE:
        return bankConfirmationValue.nochNichtAngerechneteAuslandSteuernIsNegative;
      case this.formats.LEISTUNGEN_STEUERL_EINLAGEKONTO:
        return bankConfirmationValue.leistungenSteuerlEinlagekonto;
      case this.formats.AUSLAND_INVESTMENTFONDS:
        return bankConfirmationValue.auslandInvestmentfonds;
      case this.formats.AUSLAND_INVESTMENTFONDS_ERTRAGE:
        return bankConfirmationValue.auslandInvestmentfondsErtrage;
      case this.formats.AUSLAND_INVESTMENTFONDS_ANGERECHNETE_STEUERN:
        return bankConfirmationValue.auslandInvestmentfondsAngerechneteSteuern;
      case this.formats.AUSLAND_INVESTMENTFONDS_I:
        return bankConfirmationValue.auslandInvestmentfondsI;
      case this.formats.AUSLAND_INVESTMENTFONDS_I_ERTRAGE:
        return bankConfirmationValue.auslandInvestmentfondsIErtrage;
      case this.formats.AUSLAND_INVESTMENTFONDS_I_ANGERECHNETE_STEUERN:
        return bankConfirmationValue.auslandInvestmentfondsIAngerechneteSteuern;
      case this.formats.ZUGEFLOSSENE_ERTRAGE_OHNE_STEUERABZUG:
        return bankConfirmationValue.zugeflosseneErtrageOhneSteuerabzug;
      case this.formats.KAPITALERTRAEGE_LEBENSVERSICHERUNGEN_AB2018:
        return bankConfirmationValue.kapitalertraegeLebensversicherungenAb2018;
      case this.formats.KAPITALERTRAEGE_LEBENSVERSICHERUNGEN_KAPEST:
        return bankConfirmationValue.kapitalertraegeLebensversicherungenKapESt;
      case this.formats.KAPITALERTRAEGE_LEBENSVERSICHERUNGEN_SOLZ:
        return bankConfirmationValue.kapitalertraegeLebensversicherungenSolZ;
      case this.formats.KAPITALERTRAEGE_LEBENSVERSICHERUNGEN_KIST:
        return bankConfirmationValue.kapitalertraegeLebensversicherungenKiSt;
      case this.formats.ANTEILE_AUSLAENDISCHE_INVFONDS_ERWORBENVOR2018:
        return bankConfirmationValue.anteileAuslaendischeInvFondsErworbenvor2018;
      case this.formats.ZUGEFLOSSENE_ERTRAEGE_OHNE_STEUERABZUG_AB2018:
        return bankConfirmationValue.zugeflosseneErtraegeOhneSteuerabzugAb2018;
      case this.formats.DAVON_TERMINGESCHAEFTE_STILLHALTERPRAEMIEN:
        return bankConfirmationValue.davonTermingeschaefteStillhalterpraemien;
      case this.formats.DAVON_VERAEUSSERUNGEN_ALT_ANTEILE:
        return bankConfirmationValue.davonVeraeusserungenAltAnteile;
      case this.formats.VERLUSTE_20_AB_S6S5_TERMINGESCHAEFTE:
        return bankConfirmationValue.verluste20abs6s5Termingeschaefte;
      case this.formats.VERLUSTE_20_AB_S6S6_UNEINBRINGLICH_WERTLOS:
        return bankConfirmationValue.verluste20abs6s6UneinbringlichWertlos;

      default:
        break;
    }

    throw new FormatterError(`Unknown format: ${formatKey}`);
  }
}

export default new BankConfirmation();
