'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const { getDecimal, getFieldsByIndex } = require('../utils/utils_fields');
const { toString } = require('../utils/utils_logs');

function otherWorkRelatedExpenses2016(fields) {
  let expenses = new Decimal(0);

  // TODO this could all happen multiple times !!!

  getFieldsByIndex(fields, ['0205406']).forEach(indexFields => {
    // Bewerbungskosten: Betrag
    let jobApplication = getDecimal(indexFields, '0205406');
    logger.debug('jobApplication', toString(jobApplication));
    expenses = expenses.add(jobApplication);
  });

  getFieldsByIndex(fields, ['0205408']).forEach(indexFields => {
    // Umzugskosten: Betrag
    let relocation = getDecimal(indexFields, '0205408');

    // removing this, will be implemented by mira in the editor
    // TODO there are different lump sums if you're married and/or have kids
    // if (relocation.greaterThan(0) && relocation.lessThan(746)) {
    //   relocation = new Decimal(746);
    // }

    logger.debug('relocation', toString(relocation));
    expenses = expenses.add(relocation);
  });

  let otherSum = new Decimal(0);
  getFieldsByIndex(fields, ['0204802']).forEach(indexFields => {
    // Sonstiges: Betrag
    let other = getDecimal(indexFields, '0204802');
    logger.debug('other', toString(other));
    otherSum = otherSum.add(other);
  });

  expenses = expenses.add(otherSum);

  return expenses;
}

module.exports = otherWorkRelatedExpenses2016;
