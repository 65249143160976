'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const {
  getBool,
  getDecimal,
  getFieldsByIndex,
  getLfdNrGroupsByNrs,
  getMonths,
  getValue,
  iterateLfNr
} = require('../utils/utils_fields');
const { percent: getPercent } = require('../utils/utils_decimal');
const { toString } = require('../utils/utils_logs');
const dateUtils = require('../utils/utils_date');

//
//
function disabilityCost(fields, nrA, nrB, nrC) {
  let lumpsum = new Decimal(0);

  // (Ankreuzfeld) Steuerpflichtige Person: hinterblieben  
  let b1 = getBool(fields, nrA, 1);
  if (b1) {
    lumpsum = lumpsum.add(370);
  }
  // (Ankreuzfeld) Steuerpflichtige Person: blind / ständig hilflos
  let b2 = getBool(fields, nrB, 1);
  if (b2) {
    lumpsum = lumpsum.add(3700);
  } else {
    // Steuerpflichtige Person: Grad der Behinderung 
    let b3 = getDecimal(fields, nrC).toNumber();

    let l = new Decimal(0);

    if (b3 >= 25) {
      if (b3 <= 30) {
        l = new Decimal(310);
      } else if (b3 <= 40) {
        l = new Decimal(430);
      } else if (b3 <= 50) {
        l = new Decimal(570);
      } else if (b3 <= 60) {
        l = new Decimal(720);
      } else if (b3 <= 70) {
        l = new Decimal(890);
      } else if (b3 <= 80) {
        l = new Decimal(1060);
      } else if (b3 <= 90) {
        l = new Decimal(1230);
      } else if (b3 <= 100) {
        l = new Decimal(1420);
      }
    }

    lumpsum = lumpsum.add(l);
  }

  return lumpsum;
}

//
//
function exceptionalExpenses2017(fields, totalAmountOfEarnings, lumpsums) {
    logger.debug('\n\t-----------------\n\tEXCEPTIONAL EXPENSES');

  let expenses = new Decimal(0);

  // Andere außergewöhnliche Belastungen
  // Summe der Aufwendungen 
  let a = getDecimal(fields, '0111904');
  // Erhaltene | Anspruch auf Versicherungsleistungen usw.
  a = a.sub(getDecimal(fields, '0111905'));

  logger.debug('expenses', toString(a));

  // Berufsausbildung: auswärtige Unterbringung
  let educationCost = new Decimal(0);
  let joint = getBool(fields, '0101201', 'X');
  let numberOfChildren = 0;

  iterateLfNr(getLfdNrGroupsByNrs(fields, [
    '0500701',  // add birthday as a mandatory field
    '0504806',
    '0506202',
    '0501610'
  ]), lfdNrFields => {
    ++numberOfChildren;
    
    
    // Volljähriges Kind: Berufsausbildung - auswärtige Unterbringung - von-bis
    let months = getMonths(lumpsums.year, lfdNrFields, '0504806');
    let isMonthsInTrainingSet = !!getValue(lfdNrFields, '0501610');
    if (isMonthsInTrainingSet) {
      const birthdateStr = getValue(lfdNrFields, '0500701');
      let monthsInTraining = getMonths(lumpsums.year, lfdNrFields, '0501610');
      logger.debug('isMonthsInTrainingSet monthsInTraining', toString(monthsInTraining));
      monthsInTraining = dateUtils.correctMonthsArrayAgainstBirtdate(monthsInTraining, birthdateStr, lumpsums.year);
      logger.debug('isMonthsInTrainingSet corrected monthsInTraining', toString(monthsInTraining));

      months = months.map((monthValue, index) => {
        return (monthValue ===  1 && monthsInTraining[index] === 1) ? 1 : 0;
      });
    }
    let numberOfMonths = months.reduce((a, b) => a + b);

    if (numberOfMonths === 0) {
      return;
    }

    let totalCost = new Decimal(924).times(numberOfMonths).div(12);
    
    let percent = 50;

    if (joint) {
      percent = 100;
    } else {
      // Keine Zusammenveranlagung: Volljähriges Kind: Berufsausbildung - 
      // auswärtige Unterbringung - Anteil Stpfl. an Aufwendungen
      let p = getValue(lfdNrFields, '0506202');
      if (typeof p !== 'undefined') {
        percent = parseInt(p, 10);
      }
    }

    educationCost = educationCost.add(getPercent(totalCost, percent));
  });
  logger.debug('educationCost',  toString(educationCost));
  expenses = expenses.add(educationCost);

  // Pauschbetrag Behinderung
  let disabilityA = disabilityCost(fields, '0109704', '0109706', '0109708');
  let disabilityB = disabilityCost(fields, '0109804', '0109806', '0109808');

  logger.debug('disabilityA', toString(disabilityA));
  logger.debug('disabilityB', toString(disabilityB));

  expenses = expenses.add(disabilityA);
  expenses = expenses.add(disabilityB);

  // pauschbetrag für behinderte kinder
  iterateLfNr(getLfdNrGroupsByNrs(fields, [
    '0505805',
    '0505807',
    '0505809',
    '0506007'
  ]), (lfdNrFields, index) => {
    const lfdNr = index + 1;
    let disability = disabilityCost(lfdNrFields, '0505805', '0505807', '0505809', lfdNr);
    logger.debug('\t disability', toString(disability));
    let percent = 50;

    if (joint) {
      percent = 100;
    } else {
      let p = getValue(lfdNrFields, '0506007');
      if (typeof p !== 'undefined') {
        try {
          percent = parseInt(p, 10);
        } catch (error) {
          throw new Error('Handicap percentage is not a number (0506007): ' + p);
        }
      }
    }

    logger.debug('\t percent', percent);
    expenses = expenses.add(getPercent(disability, percent));
  });

  logger.debug('1 expenses', toString(expenses));

  // Pauschbetrag unbezahlte Pflege
  const careFields = ['0110601', '0106603','0110602', '0110603', '0110604', '0110605', '0110606', '0106607', '0106507'];
  let groups = getLfdNrGroupsByNrs(fields, careFields);
  getFieldsByIndex(groups[0], careFields).forEach(indexFields => {
    // Unentgeltliche persönliche Pflege: Name, Anschrift, 
    // Verwandschaftsverhältnis der hilflosen Person 
    let person = getValue(indexFields, '0110601');
    logger.debug('person', person);
    if (typeof person !== 'undefined') {
      // Anzahl weiterer Pflegepersonen
      let numberOfPersonal = getDecimal(indexFields, '0106603');
      
      // For 2015 we need to change the logic to count the numberOfPersonal from
      // fields 0110602-0110607
      if (numberOfPersonal.toNumber() === 0) {
        numberOfPersonal = numberOfPersonal.add((!!getValue(indexFields, '0110602') ? 1 : 0));
        numberOfPersonal = numberOfPersonal.add((!!getValue(indexFields, '0110603') ? 1 : 0));
        numberOfPersonal = numberOfPersonal.add((!!getValue(indexFields, '0110604') ? 1 : 0));
        numberOfPersonal = numberOfPersonal.add((!!getValue(indexFields, '0110605') ? 1 : 0));
        numberOfPersonal = numberOfPersonal.add((!!getValue(indexFields, '0110606') ? 1 : 0));
        numberOfPersonal = numberOfPersonal.add((!!getValue(indexFields, '0110607') ? 1 : 0));
      }
      

      logger.debug('numberOfPersonal', toString(numberOfPersonal));
      
      let lumpsum;
      // cared by person a = 1
      // cared by person b = 2
      // cared by person a and b = 3
      let caredBy = getValue(indexFields, '0106507');
      if (caredBy === 3) {
        lumpsum = new Decimal(924).div(numberOfPersonal.add(2)).mul(2);
      } else {
        lumpsum = new Decimal(924).div(numberOfPersonal.add(1));
      }
      logger.debug('lumpsum', toString(lumpsum));
      expenses = expenses.add(lumpsum);
    }
  });

  logger.debug('2 expenses', toString(expenses));
  
  logger.debug('\t number of children', numberOfChildren);

  // zumutbare Belastung
  // let reasonableCostPercent;

  // Taxation tiers
  const firstTier = { max: new Decimal(15340), single: 5, joint: 4, lessThanOrTwoKids: 2, moreThanTwoKids: 1 };
  const secondTier =  { max: new Decimal(51130), single: 6, joint: 5, lessThanOrTwoKids: 3, moreThanTwoKids: 1 };
  const thirdTier =  { single: 7, joint: 6, lessThanOrTwoKids: 4, moreThanTwoKids: 2 };

  const getTaxationPercent = (tierData, joint, numberOfChildren) => {
    let percent;

    if (numberOfChildren === 0) {
      percent = joint ? tierData.joint : tierData.single;
    } else if (numberOfChildren <= 2) {
      percent = tierData.lessThanOrTwoKids;
    } else {
      percent = tierData.moreThanTwoKids;
    }
    return percent;
  };

  let reasonableCalculatedCost;

  logger.debug('\t totalAmountOfEarnings', toString(totalAmountOfEarnings));
  if (totalAmountOfEarnings.gt(0)) {
    if (totalAmountOfEarnings.lte(firstTier.max)) {
      const percent = getTaxationPercent(firstTier, joint, numberOfChildren);
      reasonableCalculatedCost = getPercent(totalAmountOfEarnings, percent);
    } else if (totalAmountOfEarnings.lte(secondTier.max)) {
      //we calculate for two tiers, with different percentages
      const firstTierPercent = getTaxationPercent(firstTier, joint, numberOfChildren);
      reasonableCalculatedCost = getPercent(firstTier.max, firstTierPercent);
      logger.debug('\t firstTierPercent', firstTierPercent);
      logger.debug('\t after first tier reasonableCalculatedCost', toString(reasonableCalculatedCost));
      const secondTierPercent = getTaxationPercent(secondTier, joint, numberOfChildren);
      logger.debug('\t secondTierPercent', secondTierPercent);
      // calculate the rest included in the second tier
      // by subtracting the first fier max value out of totalAmountOfEarnings
      const secondTierRest = totalAmountOfEarnings.sub(new Decimal(firstTier.max));
      reasonableCalculatedCost = 
        reasonableCalculatedCost.add(getPercent(secondTierRest , secondTierPercent));
      logger.debug('\t after second tier reasonableCalculatedCost', toString(reasonableCalculatedCost));
    } else {
      //we are in the third tier > 51130
      //we calculate for three tiers, with different percentages
      const firstTierPercent = getTaxationPercent(firstTier, joint, numberOfChildren);
      reasonableCalculatedCost = getPercent(firstTier.max, firstTierPercent);
      logger.debug('\t firstTierPercent', firstTierPercent);
      logger.debug('\t after first tier reasonableCalculatedCost', toString(reasonableCalculatedCost));
      const secondTierPercent = getTaxationPercent(secondTier, joint, numberOfChildren);
      logger.debug('\t secondTierPercent', secondTierPercent);
      reasonableCalculatedCost = 
        reasonableCalculatedCost.add(getPercent((secondTier.max.sub(firstTier.max)) , secondTierPercent));
      logger.debug('\t after second tier reasonableCalculatedCost', toString(reasonableCalculatedCost));
      const thirdTierPercent = getTaxationPercent(thirdTier, joint, numberOfChildren);
      const thirdTierRest = totalAmountOfEarnings.sub(new Decimal(secondTier.max));
      reasonableCalculatedCost = 
        reasonableCalculatedCost.add(getPercent(thirdTierRest , thirdTierPercent));
      logger.debug('\t after third tier reasonableCalculatedCost', toString(reasonableCalculatedCost));
    }
  }

  logger.debug('\t reasonableCalcuatedCost', toString(reasonableCalculatedCost));

  logger.debug('\t a', toString(a));
  
  const sumExtraordinary = a;
  const disabilityLumpsum = disabilityA.add(disabilityB);

  if (reasonableCalculatedCost) {
    a = a.sub(reasonableCalculatedCost);
  }
  a = Decimal.max(a, 0);

  expenses = expenses.add(a);
  logger.debug('\t total exceptional expenses', toString(expenses));
  
  return {
    exceptionalExpenses: expenses,
    sumExtraordinary,
    deductibleExtraordinary: a,
    disabilityLumpsum,
    adultChildTrainingLumpsum: educationCost,
  };
}

module.exports = exceptionalExpenses2017;
