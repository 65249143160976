'use strict';

const Decimal = require('decimal.js');
const logger = require('../logger');
const { getDecimal } = require('../utils/utils_fields');

const commuting2021 = require('./../2021/commuting_2021');
const doubleHousehold2021 = require('./../2021/double_housekeeping_2021');
const homeOffice2021 = require('./../2021/home_office_2021');
const workEquipment2016 = require('./../2016/work_equipment_2016');
const travelling2020 = require('../2020/travelling_2020');
const { toString } = require('../utils/utils_logs');

function professionalExpenses2021(fields, incomePerPerson, grossSalary, currentTaxYear) {
  let expensesPerPerson = new Decimal(0);
  let extraIncomePerPerson = new Decimal(0);
  logger.debug('\n-------------------------\nPROFESSIONAL EXPENSES');

  let commuting = commuting2021(fields);
  expensesPerPerson = expensesPerPerson.add(commuting);
  logger.debug('commuting', toString(commuting));

  let doubleHousehold = doubleHousehold2021(fields, currentTaxYear);
  expensesPerPerson = expensesPerPerson.add(doubleHousehold);
  logger.debug('doubleHousehold', toString(doubleHousehold));

  let homeOffice = homeOffice2021(fields);
  expensesPerPerson = expensesPerPerson.add(homeOffice.expenses);
  logger.debug('homeOffice', toString(homeOffice));

  let workEquipment = workEquipment2016(fields, grossSalary);
  expensesPerPerson = expensesPerPerson.add(workEquipment);
  logger.debug('workEquipment', toString(workEquipment));

  // 2020:
  let otherWorkRelatedExpenses = getDecimal(fields, '0204803');
  expensesPerPerson = expensesPerPerson.add(otherWorkRelatedExpenses);
  expensesPerPerson = expensesPerPerson.add(homeOffice.lumpsum);
  logger.debug('otherWorkRelatedExpenses', toString(otherWorkRelatedExpenses));
  logger.debug('homeOffice lump sum', toString(homeOffice.lumpsum));

  let education = getDecimal(fields, '0204812');
  expensesPerPerson = expensesPerPerson.add(education);
  logger.debug('education', toString(education));

  let berufsverbaende = getDecimal(fields, '0204002');
  expensesPerPerson = expensesPerPerson.add(berufsverbaende);
  logger.debug('berufsverbaende', toString(berufsverbaende));

  let travelling = travelling2020(fields);
  if (travelling.gte(0)) {
    expensesPerPerson = expensesPerPerson.add(travelling);
  }

  logger.debug('travelling', toString(travelling));
  logger.debug('income', toString(incomePerPerson));

  return {
    expensesPerPerson,
    extraIncomePerPerson,
    commutingPerPerson: commuting,
    doubleHouseholdPerPerson: doubleHousehold,
    homeOfficePerPerson: homeOffice.expenses,
    homeOfficeLumpSumPerPerson: homeOffice.lumpsum,
    workEquipmentPerPerson: workEquipment,
    otherWorkRelatedExpensesPerPerson: otherWorkRelatedExpenses,
    travellingPerPerson: travelling,
    educationPerPerson: education,
    professionalAssociationsPerPerson: berufsverbaende,
  };
}

module.exports = professionalExpenses2021;
