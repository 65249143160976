// @flow

import mapValues from 'lodash/mapValues';
import type {
  Answers,
  Id,
  Responses,
  TreeNode,
} from '../types';

const answerFor = (id: Id, responses: Responses): ?any => (
  responses[id] ? responses[id].answer : undefined
);

const isAnsweredFor = (id: Id, responses: Responses): boolean => (
  answerFor(id, responses) != null
);

const isAnswered = (question: TreeNode, responses: Responses): boolean => (
  isAnsweredFor(question.id, responses)
);

const skippedFor = (id: Id, responses: Responses): ?boolean => (
  responses[id] ? responses[id].skipped : undefined
);

const isSkippedFor = (id: Id, responses: Responses): boolean => {
  const skipped = skippedFor(id, responses);

  return skipped != null && skipped === true;
};

const isSkipped = (question: TreeNode, responses: Responses): boolean => (
  isSkippedFor(question.id, responses)
);

const asResponses = (answers: Answers): Responses => mapValues(answers, ans => ({ answer: ans }));

export {
  answerFor,
  isAnswered,
  isAnsweredFor,
  skippedFor,
  isSkipped,
  isSkippedFor,
  asResponses,
};
