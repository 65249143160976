// @flow

import moment from 'moment';

import BaseAnswer from './Base';
import type { ValidationFunction, ValidationSchema } from '../flowtypes';

type DateAnswer = string;

type DateFormat = { [key: string]: string };

class Date extends BaseAnswer {
  dateFormats: DateFormat;

  constructor() {
    const formats = [
      { name: 'DDMM', type: 'string' },
      { name: 'DDMMYY', type: 'string' },
      { name: 'DDMMYYYY', type: 'string' },
      { name: 'MMYY', type: 'string' },
    ];
    super('date', formats);

    this.dateFormats = {
      DDMM: 'DD.MM',
      DDMMYY: 'DD.MM.YY',
      DDMMYYYY: 'DD.MM.YYYY',
      MMYY: 'MM.YY',
    };
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/date.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/date');
    /* eslint-enable global-require */
  }

  format(value: any, formatKey: string): string {
    /*
     * on some occasions we may have blank dates,
     * when we use data from other years to create a submission,
     * to prevent the user from getting stuck in the application flow
     * we want to avoid throwing errors when trying to format blank dates,
     * simply returning their unformatted initial value
     */
    if (!value) return value;
    super.format(value);
    const dateValue = (value: DateAnswer);
    return moment(dateValue).format(this.dateFormats[this.getFormat(formatKey).name]);
  }
}

export default new Date();
