import { type CallOptions, CoreSDKClient } from '@taxfix/core-sdk';
import type { UserWithId } from '../types';
import type {
  CheckPasswordResetRequest,
  CheckPasswordResetResponse,
  ConfirmEmailChangeRequest,
  ConfirmEmailChangeResponse,
  ConfirmEmailRequest,
  ConfirmEmailResponse,
  CreateEmailChange,
  EmailChanges,
  RequestPasswordResetRequest,
  ResendUnblockOTPRequest,
  ResetPinRequest,
  UnblockUserRequest,
  UpdateUserRequest,
} from './types';

const USERS_PREFIX = '/api/users';
const PUBLIC_PREFIX = '/api/users/public';

export class UsersAPIClient extends CoreSDKClient {
  createEmailChange(
    params: CreateEmailChange,
    opts: CallOptions = {},
  ): Promise<void> {
    return this.perform<void>(opts, {
      method: 'POST',
      url: `${USERS_PREFIX}/v1/users/me/email-change`,
      data: params,
    });
  }

  checkPasswordReset(
    params: CheckPasswordResetRequest,
    opts: CallOptions = {},
  ): Promise<CheckPasswordResetResponse> {
    return this.perform(opts, {
      url: `${PUBLIC_PREFIX}/v1/users/password-reset/check`,
      method: 'POST',
      data: params,
    });
  }

  confirmEmail(
    params: ConfirmEmailRequest,
    opts: CallOptions = {},
  ): Promise<ConfirmEmailResponse> {
    return this.perform(opts, {
      url: `${PUBLIC_PREFIX}/v1/users/confirm`,
      method: 'POST',
      data: params,
    });
  }

  confirmEmailChange(
    params: ConfirmEmailChangeRequest,
    opts: CallOptions = {},
  ): Promise<ConfirmEmailChangeResponse> {
    return this.perform(opts, {
      url: `${PUBLIC_PREFIX}/v1/users/confirm-email-change`,
      method: 'POST',
      data: params,
    });
  }

  getCurrentUser(opts: CallOptions = {}): Promise<UserWithId> {
    return this.perform(opts, {
      method: 'GET',
      url: `${USERS_PREFIX}/v1/users/me`,
    });
  }

  getEmailChanges(opts: CallOptions = {}): Promise<EmailChanges> {
    return this.perform(opts, {
      method: 'GET',
      url: `${USERS_PREFIX}/v1/users/me/email-changes`,
    });
  }

  logoutCurrentUser(opts: CallOptions = {}): Promise<void> {
    return this.perform(opts, {
      method: 'DELETE',
      url: `${USERS_PREFIX}/v2/users/me/logout`,
      data: {},
      headers: {
        'content-type': 'application/json',
      },
    });
  }

  updateCurrentUser(
    params: UpdateUserRequest,
    opts: CallOptions = {},
  ): Promise<UserWithId> {
    return this.perform(opts, {
      method: 'PATCH',
      url: `${USERS_PREFIX}/v1/users/me`,
      data: params,
    });
  }

  resendUnblockOTP(
    params: ResendUnblockOTPRequest,
    opts: CallOptions = {},
  ): Promise<void> {
    return this.perform(opts, {
      method: 'POST',
      url: `${PUBLIC_PREFIX}/v1/users/resend-unblock-code`,
      data: params,
    });
  }

  requestPasswordReset(
    params: RequestPasswordResetRequest,
    opts: CallOptions = {},
  ): Promise<void> {
    return this.perform(opts, {
      method: 'POST',
      url: `${PUBLIC_PREFIX}/v1/users/password-reset`,
      data: params,
    });
  }

  resetPin(params: ResetPinRequest, opts: CallOptions = {}): Promise<void> {
    return this.perform(opts, {
      method: 'PUT',
      url: `${PUBLIC_PREFIX}/v1/users/password-reset`,
      data: params,
    });
  }

  unblockUser(
    params: UnblockUserRequest,
    opts: CallOptions = {},
  ): Promise<void> {
    return this.perform(opts, {
      method: 'DELETE',
      url: `${PUBLIC_PREFIX}/v1/users/blocked`,
      data: params,
    });
  }
}
