// @flow

/*
 * Takes an object hash of nodes, and transforms children into ordered arrays of nodes, based on
 * `children` properties.
 */
import * as _ from 'lodash';
import type {
  Id,
  Information,
  Input,
  Loop,
  Outputs,
  Preconditions,
  Response,
  ResponseType,
  Summary,
  TranslationVariation,
  TreeNode,
} from '../types';

type NodeList<T> = {
  [key: Id]: T,
};

type InLoop = {
  based_on: Id,
  default?: number,
  increment_field: 'index' | 'running_number',
}

type InTaxReference = {
  value: string,
  elster_id: string,
};

type InNode = {
  id: string,
  children?: [string],
  information?: Information,
  inputs?: { [key: Id]: Input },
  loop?: InLoop,
  outputs: Outputs,
  precondition?: Id,
  preconditions?: Preconditions,
  responseType?: ResponseType,
  response?: Response,
  summary?: Summary,
  tax_references?: InTaxReference[],
  rules?: any[],
  translationVariations?: TranslationVariation[],
  tags?: string[],
  targetLoops?: string[],
};

type OutNode = TreeNode;

const nodes2tree = (nodes: NodeList<InNode>, root: string, ancestors: Id[] = []): OutNode => {
  if (nodes[root] === undefined) {
    throw new Error(`Node with id '${root}' not found`);
  }

  const subtree: InNode = nodes[root];

  const nextAncestors = [...ancestors, root];

  const out: OutNode = {
    id: root,
    questionId: root,
    parentId: ancestors.length > 0 ? ancestors[ancestors.length - 1] : null,
    loopContext: null,
    ancestors,
    tags: subtree.tags,
    targetLoops: subtree.targetLoops,
  };

  if (subtree.children) {
    out.children = subtree.children.map(childId => nodes2tree(nodes, childId, nextAncestors));
  }

  if (subtree.information) {
    out.information = subtree.information;
  }

  if (subtree.inputs) {
    out.inputs = subtree.inputs;
  }

  if (subtree.loop) {
    const obj: Loop = {
      basedOn: subtree.loop.based_on,
      incrementField: subtree.loop.increment_field,
    };

    if (subtree.loop.default != null) {
      obj.default = subtree.loop.default;
    }

    out.loop = obj;
  }

  if (subtree.outputs) {
    out.outputs = subtree.outputs;
  }

  if (subtree.precondition) {
    out.precondition = subtree.precondition;
  }

  if (subtree.preconditions) {
    out.preconditions = subtree.preconditions;
  }

  if (subtree.responseType) {
    out.responseType = subtree.responseType;
  }

  if (subtree.response) {
    out.response = subtree.response;

    const { properties } = out.response;

    if (properties && properties.options && properties.options.map) {
      properties.options = properties.options.map(option => ({
        ...option,
        translationKey: `${out.id}.${option.id}`,
      }));
    }
  }

  if (subtree.summary) {
    out.summary = subtree.summary;
  }

  if (subtree.tax_references) {
    out.taxReferences = subtree.tax_references.map(ref => (
      {
        id: '<null>',
        groupId: ancestors.length !== 0 ? ancestors[ancestors.length - 1] : '',
        loopId: null,
        value: ref.value,
        elsterFieldNumber: ref.elster_id,
        index: 1,
        runningNumber: 1,
      }
    ));
  }

  if (subtree.rules) {
    out.rules = subtree.rules.map((rule) => {
      const ruleCopy = _.cloneDeep(rule);
      _.set(ruleCopy, ['rule', 'args', 1, 'args', 'messageTranslationKey'], `rule::${subtree.id}::${rule.id}::error`);
      _.set(ruleCopy, ['rule', 'args', 1, 'args', 'infoTranslationKey'], `rule::${subtree.id}::${rule.id}::info`);
      return ruleCopy;
    });
  }

  if (subtree.translationVariations) {
    out.translationVariations = subtree.translationVariations;
  }

  return out;
};

export default nodes2tree;
