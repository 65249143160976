'use strict';

const Decimal = require('decimal.js');
const { getDecimal } = require('../utils/utils_fields');

function donationsInstitutions2016(fields) {
  let donations = new Decimal(0);

  // Spenden / Mitgliedsbeiträge

  // Steuerbegünstigte Zwecke - Summe lt. Bestätigung
  donations = donations.add(getDecimal(fields, '0108105'));

  // Steuerbegünstigte Zwecke EU/Ausland- Summe lt. Bestätigung
  donations = donations.add(getDecimal(fields, '0104702'));

  // Steuerbegünstigte Zwecke - Summe lt. Nachweis Betriebs-FA
  donations = donations.add(getDecimal(fields, '0108106'));

  // Steuerbegünstigte Zwecke EU/Ausland - Summe lt. Nachweis Betriebs-FA
  donations = donations.add(getDecimal(fields, '0104703'));

  // Steuerbegünstigte Zwecke (elektron. Übermittlung)
  donations = donations.add(getDecimal(fields, '0105005'));
  
  // Steuerbegünstigte Zwecke (elektron. Übermittlung) Person B
  donations = donations.add(getDecimal(fields, '0105006'));

  return donations;
}

module.exports = donationsInstitutions2016;
