'use strict';

const processing2016 = require('./2016');
const processing2017 = require('./2017');
const processing2019 = require('./2019');
const processing2020 = require('./2020');
const processing2021 = require('./2021');

// Export mobility bonus separately as its needed for the income-tax-calculator
// to avoid running complete post processing again in the calculator
const { applyMobilityBonus: applyMobilityBonus2021 } = require('./2021/mobility-bonus');
module.exports = {
  applyMobilityBonus2021
};

module.exports = (year, fields = [], calculationResult = {}) => {
  if (typeof year === 'string' || typeof year === 'number') {
    year = parseInt(year, 10);
  } else {
    throw new Error('year should be number/string');
  }

  if (isNaN(year)) {
    throw new Error('year is not a valid number');
  }

  if (!Array.isArray(fields)) {
    throw new Error('fields should be array');
  }

  let processedFields = JSON.parse(JSON.stringify(fields));

  switch (year) {
    case 2016:
    case 2015:
      processedFields = processing2016(processedFields, calculationResult);
      break;
    case 2017:
    case 2018:
      processedFields = processing2017(processedFields, calculationResult);
      break;
    case 2019:
      processedFields = processing2019(processedFields, calculationResult);
      break;
    case 2020:
      processedFields = processing2020(processedFields, calculationResult);
      break;
    case 2021:
      processedFields = processing2021(processedFields, calculationResult);
      break;

    default:
      console.log(`${year} does not have any post-processing`);
  }

  return processedFields;
};
