
'use strict';

const {
  getBool,
} = require('../utils');

const GP_FIELD_NR = '1900401';

/**
 * if one person applies for the favourable review for the capital gains 
 * we need to request this for the other person as well (if joint assessment)
 * 
 * https://taxfix.atlassian.net/browse/PREP-647
 * @param {*} fields 
 */
module.exports = fields => {
  const joint = getBool(fields, '0101201', 'X');
  if (joint) {
    const partnerAField = fields.find(x => x.nr === GP_FIELD_NR && x.index === 1 && x.lfdNr === 1);
    const partnerBField = fields.find(x => x.nr === GP_FIELD_NR && x.index === 1 && x.lfdNr === 2);
    
    if (!partnerAField && partnerBField) {
      fields.push({
        nr: GP_FIELD_NR,
        index: 1,
        lfdNr: 1,
        value: 1
      });
    } else if (!partnerBField && partnerAField) {
      fields.push({
        nr: GP_FIELD_NR,
        index: 1,
        lfdNr: 2,
        value: 1
      });
    }
  }
  return fields;
};