// @flow

import * as AnswerTypes from './answer-types';
import FormatterError from './error/FormatterError';
import type {
  Answer,
  FormattedAnswer,
  TypeProbably,
} from './flowtypes';

// load in all AnswerTypes, but wrap with flow
const types: TypeProbably[] = ((Object.values(AnswerTypes): any): TypeProbably[]);

const typeOf = (type: string): ?TypeProbably => (
  types.find(typeObj => type === typeObj.name)
);

export default (type: string, format: string, answer: Answer): FormattedAnswer => {
  const answerType = typeOf(type);

  // no known type/formatter, so return the answer and let the caller handle is
  if (answerType == null) {
    throw new FormatterError(`unknown type: ${type}`);
  }

  // if no formatters, and it's a simple type, then return its value
  if (
    answerType.formats.length === 0 && (
      typeof answer === 'boolean' ||
      typeof answer === 'number' ||
      typeof answer === 'string'
    )
  ) {
    return answer;
  }

  return answerType.format(answer, format);
};
