'use strict';
module.exports = validate21;
module.exports.default = validate21;
var schema6 = {
  $id: 'http://taxfix.de/schemas/answers/ES_personal_id.json',
  $schema: 'http://json-schema.org/draft-06/schema#',
  type: 'object',
  properties: {
    nif: { type: 'string', maxLength: 9, minLength: 8 },
    supportNumber: { type: ['string', 'null'] },
    validityDate: {
      type: ['string', 'null'],
      pattern: '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$',
    },
    birthDate: {
      type: 'string',
      pattern: '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$',
    },
    hasExpirationDate: { type: 'boolean' },
    hasNie: { type: 'boolean' },
  },
  additionalProperties: false,
  required: ['nif', 'birthDate', 'hasExpirationDate', 'hasNie'],
};
var func3 = require('ajv/dist/compile/ucs2length').default;
var pattern0 = new RegExp('^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$', 'u');

function validate21(data, valCxt) {
  'use strict' /*# sourceURL="http://taxfix.de/schemas/answers/ES_personal_id.json" */;
  if (valCxt) {
    var dataPath = valCxt.dataPath;
    var parentData = valCxt.parentData;
    var parentDataProperty = valCxt.parentDataProperty;
    var rootData = valCxt.rootData;
  } else {
    var dataPath = '';
    var parentData = undefined;
    var parentDataProperty = undefined;
    var rootData = data;
  }
  var vErrors = null;
  var errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.nif === undefined) {
      var err0 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'nif' },
        message: "should have required property '" + 'nif' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.birthDate === undefined) {
      var err1 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'birthDate' },
        message: "should have required property '" + 'birthDate' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.hasExpirationDate === undefined) {
      var err2 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'hasExpirationDate' },
        message: "should have required property '" + 'hasExpirationDate' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.hasNie === undefined) {
      var err3 = {
        keyword: 'required',
        dataPath: dataPath,
        schemaPath: '#/required',
        params: { missingProperty: 'hasNie' },
        message: "should have required property '" + 'hasNie' + "'",
      };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    for (var key0 in data) {
      if (
        !(
          key0 === 'nif' ||
          key0 === 'supportNumber' ||
          key0 === 'validityDate' ||
          key0 === 'birthDate' ||
          key0 === 'hasExpirationDate' ||
          key0 === 'hasNie'
        )
      ) {
        var err4 = {
          keyword: 'additionalProperties',
          dataPath: dataPath,
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: key0 },
          message: 'should NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
    if (data.nif !== undefined) {
      var data0 = data.nif;
      if (typeof data0 === 'string') {
        if (func3(data0) > 9) {
          var err5 = {
            keyword: 'maxLength',
            dataPath: dataPath + '/nif',
            schemaPath: '#/properties/nif/maxLength',
            params: { limit: 9 },
            message: 'should NOT have more than 9 characters',
          };
          if (vErrors === null) {
            vErrors = [err5];
          } else {
            vErrors.push(err5);
          }
          errors++;
        }
        if (func3(data0) < 8) {
          var err6 = {
            keyword: 'minLength',
            dataPath: dataPath + '/nif',
            schemaPath: '#/properties/nif/minLength',
            params: { limit: 8 },
            message: 'should NOT have fewer than 8 characters',
          };
          if (vErrors === null) {
            vErrors = [err6];
          } else {
            vErrors.push(err6);
          }
          errors++;
        }
      } else {
        var err7 = {
          keyword: 'type',
          dataPath: dataPath + '/nif',
          schemaPath: '#/properties/nif/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err7];
        } else {
          vErrors.push(err7);
        }
        errors++;
      }
    }
    if (data.supportNumber !== undefined) {
      var data1 = data.supportNumber;
      if (typeof data1 !== 'string' && data1 !== null) {
        var err8 = {
          keyword: 'type',
          dataPath: dataPath + '/supportNumber',
          schemaPath: '#/properties/supportNumber/type',
          params: { type: schema6.properties.supportNumber.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
    if (data.validityDate !== undefined) {
      var data2 = data.validityDate;
      if (typeof data2 !== 'string' && data2 !== null) {
        var err9 = {
          keyword: 'type',
          dataPath: dataPath + '/validityDate',
          schemaPath: '#/properties/validityDate/type',
          params: { type: schema6.properties.validityDate.type },
          message: 'should be string,null',
        };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
      if (typeof data2 === 'string') {
        if (!pattern0.test(data2)) {
          var err10 = {
            keyword: 'pattern',
            dataPath: dataPath + '/validityDate',
            schemaPath: '#/properties/validityDate/pattern',
            params: { pattern: '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$' },
            message:
              'should match pattern "' +
              '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$' +
              '"',
          };
          if (vErrors === null) {
            vErrors = [err10];
          } else {
            vErrors.push(err10);
          }
          errors++;
        }
      }
    }
    if (data.birthDate !== undefined) {
      var data3 = data.birthDate;
      if (typeof data3 === 'string') {
        if (!pattern0.test(data3)) {
          var err11 = {
            keyword: 'pattern',
            dataPath: dataPath + '/birthDate',
            schemaPath: '#/properties/birthDate/pattern',
            params: { pattern: '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$' },
            message:
              'should match pattern "' +
              '^\\d\\d\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$' +
              '"',
          };
          if (vErrors === null) {
            vErrors = [err11];
          } else {
            vErrors.push(err11);
          }
          errors++;
        }
      } else {
        var err12 = {
          keyword: 'type',
          dataPath: dataPath + '/birthDate',
          schemaPath: '#/properties/birthDate/type',
          params: { type: 'string' },
          message: 'should be string',
        };
        if (vErrors === null) {
          vErrors = [err12];
        } else {
          vErrors.push(err12);
        }
        errors++;
      }
    }
    if (data.hasExpirationDate !== undefined) {
      if (typeof data.hasExpirationDate !== 'boolean') {
        var err13 = {
          keyword: 'type',
          dataPath: dataPath + '/hasExpirationDate',
          schemaPath: '#/properties/hasExpirationDate/type',
          params: { type: 'boolean' },
          message: 'should be boolean',
        };
        if (vErrors === null) {
          vErrors = [err13];
        } else {
          vErrors.push(err13);
        }
        errors++;
      }
    }
    if (data.hasNie !== undefined) {
      if (typeof data.hasNie !== 'boolean') {
        var err14 = {
          keyword: 'type',
          dataPath: dataPath + '/hasNie',
          schemaPath: '#/properties/hasNie/type',
          params: { type: 'boolean' },
          message: 'should be boolean',
        };
        if (vErrors === null) {
          vErrors = [err14];
        } else {
          vErrors.push(err14);
        }
        errors++;
      }
    }
  } else {
    var err15 = {
      keyword: 'type',
      dataPath: dataPath,
      schemaPath: '#/type',
      params: { type: 'object' },
      message: 'should be object',
    };
    if (vErrors === null) {
      vErrors = [err15];
    } else {
      vErrors.push(err15);
    }
    errors++;
  }
  validate21.errors = vErrors;
  return errors === 0;
}
