// @flow
/* eslint-disable import/prefer-default-export */

import { type TreeNode, type Refs } from '../types';
import isQuestion from '../isQuestion';

export type JointAssessmentInfo = {
  isInPersonLoop: boolean,
  isPartner: boolean,
};

const DEFAULT_JA_INFO: JointAssessmentInfo = Object.freeze({
  isInPersonLoop: false,
  isPartner: false,
});

const isPersonLoop = (node: TreeNode) => node.tags instanceof Array && node.tags.includes('person-loop');

const getParentPersonLoop = (node: TreeNode, refs: Refs): ?TreeNode => {
  if (node.parentId) {
    const parent = refs[node.parentId];
    if (parent) {
      return isPersonLoop(parent) ? parent : getParentPersonLoop(parent, refs);
    }
  }
  return undefined;
};

const hasPersonLoopParent = (node: TreeNode, refs: Refs): boolean =>
  getParentPersonLoop(node, refs) != null;

const getPersonLoopIndex = (node: TreeNode, refs: Refs): ?number => {
  const personLoop = getParentPersonLoop(node, refs);
  if (personLoop && node.loopContext) {
    return node.loopContext.indexes[personLoop.id];
  }
  return undefined;
};

/**
 * Provides Joint Assessment information about a question flow node at runtime.
 * @param node: TreeNode
 * @param refs: Refs
 * @returns { isInPersonLoop: boolean, isPartner: boolean }
 */
export const getJointAssessmentInfo = (node: TreeNode, refs: Refs): JointAssessmentInfo => {
  if (isQuestion(node)) {
    const isInPersonLoop = hasPersonLoopParent(node, refs);
    const isPartner = isInPersonLoop && getPersonLoopIndex(node, refs) === 1;
    return { isInPersonLoop, isPartner };
  }
  return DEFAULT_JA_INFO;
};
