'use strict';

const { getBool } = require('./utils_fields');

function isJointAssessment(fields) {
  const newFields = [...fields];

  return getBool(newFields, '0101201', 'X');
}

module.exports = {
  isJointAssessment,
};