module.exports = { 
  'address': {
    'house': require('./assets/address/house.png')
  }
,
  'assisted-tax-filing-svg': {
    'bag-money': require('./assets/assisted-tax-filing-svg/bag-money.svg'),
    'check-mark-circle': require('./assets/assisted-tax-filing-svg/check-mark-circle.svg'),
    'check-mark-cogwheel': require('./assets/assisted-tax-filing-svg/check-mark-cogwheel.svg'),
    'document': require('./assets/assisted-tax-filing-svg/document.svg'),
    'green-prize-no-background': require('./assets/assisted-tax-filing-svg/green-prize-no-background.svg'),
    'green-prize': require('./assets/assisted-tax-filing-svg/green-prize.svg'),
    'report': require('./assets/assisted-tax-filing-svg/report.svg'),
    'shield-plus': require('./assets/assisted-tax-filing-svg/shield-plus.svg'),
    'shirt-tie': require('./assets/assisted-tax-filing-svg/shirt-tie.svg')
  }
,
  'assisted-tax-filing': {
    'badge': require('./assets/assisted-tax-filing/badge.png'),
    'confetti1': require('./assets/assisted-tax-filing/confetti1.png'),
    'confetti2': require('./assets/assisted-tax-filing/confetti2.png'),
    'cozy-chair': require('./assets/assisted-tax-filing/cozy-chair.png'),
    'docs-magnifier': require('./assets/assisted-tax-filing/docs-magnifier.png'),
    'expert-group': require('./assets/assisted-tax-filing/expert-group.png'),
    'expert-report-de': require('./assets/assisted-tax-filing/expert-report-de.png'),
    'expert-report-experts-de': require('./assets/assisted-tax-filing/expert-report-experts-de.png'),
    'expert-report': require('./assets/assisted-tax-filing/expert-report.png'),
    'expert-review-check-mark-circle-white': require('./assets/assisted-tax-filing/expert-review-check-mark-circle-white.png'),
    'expert-review-de': require('./assets/assisted-tax-filing/expert-review-de.png'),
    'expert-review-doc-and-phone-de': require('./assets/assisted-tax-filing/expert-review-doc-and-phone-de.png'),
    'expert-review-doc-and-phone': require('./assets/assisted-tax-filing/expert-review-doc-and-phone.png'),
    'expert-review-opt-in-de': require('./assets/assisted-tax-filing/expert-review-opt-in-de.png'),
    'expert-review-report-de': require('./assets/assisted-tax-filing/expert-review-report-de.png'),
    'expert-review-report-turned-de': require('./assets/assisted-tax-filing/expert-review-report-turned-de.png'),
    'expert-review-report-turned': require('./assets/assisted-tax-filing/expert-review-report-turned.png'),
    'expert-review-report': require('./assets/assisted-tax-filing/expert-review-report.png'),
    'expert-review-responses-de': require('./assets/assisted-tax-filing/expert-review-responses-de.png'),
    'expert-review-responses': require('./assets/assisted-tax-filing/expert-review-responses.png'),
    'expert-review-reviewed-by-advisors-de': require('./assets/assisted-tax-filing/expert-review-reviewed-by-advisors-de.png'),
    'expert-review-reviewed-by-advisors': require('./assets/assisted-tax-filing/expert-review-reviewed-by-advisors.png'),
    'expert-review-toggle-de': require('./assets/assisted-tax-filing/expert-review-toggle-de.png'),
    'expert-review-toggle-test-de-added': require('./assets/assisted-tax-filing/expert-review-toggle-test-de-added.png'),
    'expert-review-toggle-test-de': require('./assets/assisted-tax-filing/expert-review-toggle-test-de.png'),
    'expert-review-toggle': require('./assets/assisted-tax-filing/expert-review-toggle.png'),
    'expert-review': require('./assets/assisted-tax-filing/expert-review.png'),
    'expert1': require('./assets/assisted-tax-filing/expert1.png'),
    'expert2': require('./assets/assisted-tax-filing/expert2.png'),
    'expert3': require('./assets/assisted-tax-filing/expert3.png'),
    'expert4': require('./assets/assisted-tax-filing/expert4.png'),
    'experts-de': require('./assets/assisted-tax-filing/experts-de.png'),
    'letter': require('./assets/assisted-tax-filing/letter.png'),
    'people-working-on-ground': require('./assets/assisted-tax-filing/people-working-on-ground.png'),
    'piggybank': require('./assets/assisted-tax-filing/piggybank.png')
  }
,
  'category-icons-svg': {
    'icon-category-family-default': require('./assets/category-icons-svg/icon-category-family-default.svg'),
    'icon-category-family-inactive': require('./assets/category-icons-svg/icon-category-family-inactive.svg'),
    'icon-category-family-selected': require('./assets/category-icons-svg/icon-category-family-selected.svg'),
    'icon-category-financials-default': require('./assets/category-icons-svg/icon-category-financials-default.svg'),
    'icon-category-financials-inactive': require('./assets/category-icons-svg/icon-category-financials-inactive.svg'),
    'icon-category-financials-selected': require('./assets/category-icons-svg/icon-category-financials-selected.svg'),
    'icon-category-healthcare-default': require('./assets/category-icons-svg/icon-category-healthcare-default.svg'),
    'icon-category-healthcare-inactive': require('./assets/category-icons-svg/icon-category-healthcare-inactive.svg'),
    'icon-category-healthcare-selected': require('./assets/category-icons-svg/icon-category-healthcare-selected.svg'),
    'icon-category-home-default': require('./assets/category-icons-svg/icon-category-home-default.svg'),
    'icon-category-home-inactive': require('./assets/category-icons-svg/icon-category-home-inactive.svg'),
    'icon-category-home-selected': require('./assets/category-icons-svg/icon-category-home-selected.svg'),
    'icon-category-income-default': require('./assets/category-icons-svg/icon-category-income-default.svg'),
    'icon-category-income-inactive': require('./assets/category-icons-svg/icon-category-income-inactive.svg'),
    'icon-category-income-selected': require('./assets/category-icons-svg/icon-category-income-selected.svg'),
    'icon-category-introduction-default': require('./assets/category-icons-svg/icon-category-introduction-default.svg'),
    'icon-category-introduction-inactive': require('./assets/category-icons-svg/icon-category-introduction-inactive.svg'),
    'icon-category-introduction-selected': require('./assets/category-icons-svg/icon-category-introduction-selected.svg'),
    'icon-category-lifesituation-default': require('./assets/category-icons-svg/icon-category-lifesituation-default.svg'),
    'icon-category-lifesituation-inactive': require('./assets/category-icons-svg/icon-category-lifesituation-inactive.svg'),
    'icon-category-lifesituation-selected': require('./assets/category-icons-svg/icon-category-lifesituation-selected.svg'),
    'icon-category-personaldetails-default': require('./assets/category-icons-svg/icon-category-personaldetails-default.svg'),
    'icon-category-personaldetails-inactive': require('./assets/category-icons-svg/icon-category-personaldetails-inactive.svg'),
    'icon-category-personaldetails-selected': require('./assets/category-icons-svg/icon-category-personaldetails-selected.svg'),
    'icon-category-training-default': require('./assets/category-icons-svg/icon-category-training-default.svg'),
    'icon-category-training-inactive': require('./assets/category-icons-svg/icon-category-training-inactive.svg'),
    'icon-category-training-selected': require('./assets/category-icons-svg/icon-category-training-selected.svg'),
    'icon-category-work-default': require('./assets/category-icons-svg/icon-category-work-default.svg'),
    'icon-category-work-inactive': require('./assets/category-icons-svg/icon-category-work-inactive.svg'),
    'icon-category-work-selected': require('./assets/category-icons-svg/icon-category-work-selected.svg')
  }
,
  'category-icons': {
    'icon-category-family-inactive': require('./assets/category-icons/icon-category-family-inactive.png'),
    'icon-category-family': require('./assets/category-icons/icon-category-family.png'),
    'icon-category-financials-inactive': require('./assets/category-icons/icon-category-financials-inactive.png'),
    'icon-category-financials': require('./assets/category-icons/icon-category-financials.png'),
    'icon-category-healthcare-inactive': require('./assets/category-icons/icon-category-healthcare-inactive.png'),
    'icon-category-healthcare': require('./assets/category-icons/icon-category-healthcare.png'),
    'icon-category-home-inactive': require('./assets/category-icons/icon-category-home-inactive.png'),
    'icon-category-home': require('./assets/category-icons/icon-category-home.png'),
    'icon-category-income-inactive': require('./assets/category-icons/icon-category-income-inactive.png'),
    'icon-category-income': require('./assets/category-icons/icon-category-income.png'),
    'icon-category-information': require('./assets/category-icons/icon-category-information.png'),
    'icon-category-introduction-inactive': require('./assets/category-icons/icon-category-introduction-inactive.png'),
    'icon-category-introduction': require('./assets/category-icons/icon-category-introduction.png'),
    'icon-category-optimisation-inactive': require('./assets/category-icons/icon-category-optimisation-inactive.png'),
    'icon-category-optimisation': require('./assets/category-icons/icon-category-optimisation.png'),
    'icon-category-personal-details-inactive': require('./assets/category-icons/icon-category-personal-details-inactive.png'),
    'icon-category-personal-details': require('./assets/category-icons/icon-category-personal-details.png'),
    'icon-category-questions-multiple-select-collapse': require('./assets/category-icons/icon-category-questions-multiple-select-collapse.png'),
    'icon-category-questions-multiple-select-expand': require('./assets/category-icons/icon-category-questions-multiple-select-expand.png'),
    'icon-category-questions-multiple-select': require('./assets/category-icons/icon-category-questions-multiple-select.png'),
    'icon-category-taxes-inactive': require('./assets/category-icons/icon-category-taxes-inactive.png'),
    'icon-category-taxes': require('./assets/category-icons/icon-category-taxes.png'),
    'icon-category-training-inactive': require('./assets/category-icons/icon-category-training-inactive.png'),
    'icon-category-training': require('./assets/category-icons/icon-category-training.png'),
    'icon-category-work-inactive': require('./assets/category-icons/icon-category-work-inactive.png'),
    'icon-category-work': require('./assets/category-icons/icon-category-work.png'),
    'icon-nav-skip': require('./assets/category-icons/icon-nav-skip.png'),
    'icon-nav-skipped': require('./assets/category-icons/icon-nav-skipped.png')
  }
,
  'category-illustrations-svg': {
    'category-family': require('./assets/category-illustrations-svg/category-family.svg'),
    'category-financials': require('./assets/category-illustrations-svg/category-financials.svg'),
    'category-healthcare': require('./assets/category-illustrations-svg/category-healthcare.svg'),
    'category-home': require('./assets/category-illustrations-svg/category-home.svg'),
    'category-income': require('./assets/category-illustrations-svg/category-income.svg'),
    'category-introduction': require('./assets/category-illustrations-svg/category-introduction.svg'),
    'category-training': require('./assets/category-illustrations-svg/category-training.svg'),
    'category-work': require('./assets/category-illustrations-svg/category-work.svg'),
    'fill-documents': require('./assets/category-illustrations-svg/fill-documents.svg'),
    'tax-expert': require('./assets/category-illustrations-svg/tax-expert.svg')
  }
,
  'category-illustrations': {
    'category-family': require('./assets/category-illustrations/category-family.png'),
    'category-financials': require('./assets/category-illustrations/category-financials.png'),
    'category-healthcare': require('./assets/category-illustrations/category-healthcare.png'),
    'category-home': require('./assets/category-illustrations/category-home.png'),
    'category-income': require('./assets/category-illustrations/category-income.png'),
    'category-introduction': require('./assets/category-illustrations/category-introduction.png'),
    'category-personal-details': require('./assets/category-illustrations/category-personal-details.png'),
    'category-training': require('./assets/category-illustrations/category-training.png'),
    'category-work': require('./assets/category-illustrations/category-work.png'),
    'cozy-chair': require('./assets/category-illustrations/cozy-chair.png'),
    'docs-magnifier': require('./assets/category-illustrations/docs-magnifier.png'),
    'from-gov-to-app': require('./assets/category-illustrations/from-gov-to-app.png'),
    'happy-refund': require('./assets/category-illustrations/happy-refund.png')
  }
,
  'commercialista': {
    'commercialista': require('./assets/commercialista/commercialista.png'),
    'commercialistaAlessandra': require('./assets/commercialista/commercialistaAlessandra.png'),
    'commercialistaAlessandra2': require('./assets/commercialista/commercialistaAlessandra2.png'),
    'commercialistaBadge': require('./assets/commercialista/commercialistaBadge.png'),
    'commercialistaBorder': require('./assets/commercialista/commercialistaBorder.png'),
    'commercialistaClaudio': require('./assets/commercialista/commercialistaClaudio.png'),
    'commercialistaDeborah': require('./assets/commercialista/commercialistaDeborah.png'),
    'commercialistaLaura': require('./assets/commercialista/commercialistaLaura.png'),
    'commercialistaLaura2': require('./assets/commercialista/commercialistaLaura2.png'),
    'commercialistaStephan': require('./assets/commercialista/commercialistaStephan.png'),
    'commercialistav2': require('./assets/commercialista/commercialistav2.png')
  }
,
  'continue-from-computer': {
    'continue-from-computer': require('./assets/continue-from-computer/continue-from-computer.png')
  }
,
  'difm-illustrations': {
    'bg': require('./assets/difm-illustrations/bg.png'),
    'bg2': require('./assets/difm-illustrations/bg2.png'),
    'bg3': require('./assets/difm-illustrations/bg3.png'),
    'difm-error-barrier': require('./assets/difm-illustrations/difm-error-barrier.png'),
    'difm-home-illustration-1': require('./assets/difm-illustrations/difm-home-illustration-1.png'),
    'difm-home-illustration-2': require('./assets/difm-illustrations/difm-home-illustration-2.png'),
    'difm-home-illustration-2Bis': require('./assets/difm-illustrations/difm-home-illustration-2Bis.png'),
    'difm-home-illustration-3': require('./assets/difm-illustrations/difm-home-illustration-3.png'),
    'difm-home-illustration-4': require('./assets/difm-illustrations/difm-home-illustration-4.png'),
    'difm-home-illustration-active-step-4': require('./assets/difm-illustrations/difm-home-illustration-active-step-4.png'),
    'difm-id-back': require('./assets/difm-illustrations/difm-id-back.png'),
    'difm-id-front': require('./assets/difm-illustrations/difm-id-front.png'),
    'difm-opt-out-hand-money': require('./assets/difm-illustrations/difm-opt-out-hand-money.png'),
    'difm-optin': require('./assets/difm-illustrations/difm-optin.png'),
    'difm-optin2': require('./assets/difm-illustrations/difm-optin2.png'),
    'difm-payslip': require('./assets/difm-illustrations/difm-payslip.png'),
    'difm-tax-expert': require('./assets/difm-illustrations/difm-tax-expert.png'),
    'difm-trust': require('./assets/difm-illustrations/difm-trust.png'),
    'difm-women': require('./assets/difm-illustrations/difm-women.png'),
    'sign-icon': require('./assets/difm-illustrations/sign-icon.png')
  }
,
  'difm-svg': {
    'arrow-left': require('./assets/difm-svg/arrow-left.svg'),
    'avatar': require('./assets/difm-svg/avatar.svg'),
    'cellphone-hand': require('./assets/difm-svg/cellphone-hand.png'),
    'chat': require('./assets/difm-svg/chat.svg'),
    'icon-document-file-blue': require('./assets/difm-svg/icon-document-file-blue.svg'),
    'loading': require('./assets/difm-svg/loading.svg'),
    'receipt': require('./assets/difm-svg/receipt.svg'),
    'star-blue': require('./assets/difm-svg/star-blue.svg'),
    'star': require('./assets/difm-svg/star.svg'),
    'success': require('./assets/difm-svg/success.svg')
  }
,
  'document-svg': {
    'document-payslip': require('./assets/document-svg/document-payslip.svg'),
    'document-photo': require('./assets/document-svg/document-photo.svg'),
    'payslip-info-left': require('./assets/document-svg/payslip-info-left.svg'),
    'payslip-info-right': require('./assets/document-svg/payslip-info-right.svg'),
    'payslip-manual': require('./assets/document-svg/payslip-manual.svg')
  }
,
  'download-app': {
    'app-store-base-fr': require('./assets/download-app/app-store-base-fr.png'),
    'app-store-base-it': require('./assets/download-app/app-store-base-it.png'),
    'app-store-base': require('./assets/download-app/app-store-base.png'),
    'app-store-it': require('./assets/download-app/app-store-it.png'),
    'coliseum': require('./assets/download-app/coliseum.png'),
    'eiffel': require('./assets/download-app/eiffel.png'),
    'ellipse': require('./assets/download-app/ellipse.png'),
    'phone-in-hand-it': require('./assets/download-app/phone-in-hand-it.svg'),
    'play-store-base-fr': require('./assets/download-app/play-store-base-fr.png'),
    'play-store-base-it': require('./assets/download-app/play-store-base-it.png'),
    'play-store-base': require('./assets/download-app/play-store-base.png'),
    'play-store-it': require('./assets/download-app/play-store-it.png')
  }
,
  'duotone-svg': {
    'icon-referral': require('./assets/duotone-svg/icon-referral.svg')
  }
,
  'duotone': {
    'icon-730-orange': require('./assets/duotone/icon-730-orange.png'),
    'icon-apple-book': require('./assets/duotone/icon-apple-book.png'),
    'icon-baby-mobile': require('./assets/duotone/icon-baby-mobile.png'),
    'icon-bag-house': require('./assets/duotone/icon-bag-house.png'),
    'icon-bag-money': require('./assets/duotone/icon-bag-money.png'),
    'icon-bag-percent': require('./assets/duotone/icon-bag-percent.png'),
    'icon-baggage-storage': require('./assets/duotone/icon-baggage-storage.png'),
    'icon-ball': require('./assets/duotone/icon-ball.png'),
    'icon-banana': require('./assets/duotone/icon-banana.png'),
    'icon-barrier': require('./assets/duotone/icon-barrier.png'),
    'icon-basket-ball': require('./assets/duotone/icon-basket-ball.png'),
    'icon-basketball': require('./assets/duotone/icon-basketball.png'),
    'icon-bed-bunk': require('./assets/duotone/icon-bed-bunk.png'),
    'icon-bed-double': require('./assets/duotone/icon-bed-double.png'),
    'icon-bed-hospital': require('./assets/duotone/icon-bed-hospital.png'),
    'icon-bed-single': require('./assets/duotone/icon-bed-single.png'),
    'icon-bench': require('./assets/duotone/icon-bench.png'),
    'icon-bike': require('./assets/duotone/icon-bike.png'),
    'icon-blood-donation': require('./assets/duotone/icon-blood-donation.png'),
    'icon-boat-orange': require('./assets/duotone/icon-boat-orange.png'),
    'icon-boat': require('./assets/duotone/icon-boat.png'),
    'icon-book': require('./assets/duotone/icon-book.png'),
    'icon-bottle-baby': require('./assets/duotone/icon-bottle-baby.png'),
    'icon-bottle-glass': require('./assets/duotone/icon-bottle-glass.png'),
    'icon-bottle-message': require('./assets/duotone/icon-bottle-message.png'),
    'icon-bottle-plastic': require('./assets/duotone/icon-bottle-plastic.png'),
    'icon-bottle-single2': require('./assets/duotone/icon-bottle-single2.png'),
    'icon-bottle-two-box': require('./assets/duotone/icon-bottle-two-box.png'),
    'icon-bottle-two-different': require('./assets/duotone/icon-bottle-two-different.png'),
    'icon-bottle-two-same': require('./assets/duotone/icon-bottle-two-same.png'),
    'icon-bottle-wine-glass': require('./assets/duotone/icon-bottle-wine-glass.png'),
    'icon-briefcase': require('./assets/duotone/icon-briefcase.png'),
    'icon-broken-foot': require('./assets/duotone/icon-broken-foot.png'),
    'icon-broken-glas': require('./assets/duotone/icon-broken-glas.png'),
    'icon-broken-glass': require('./assets/duotone/icon-broken-glass.png'),
    'icon-broken-screen': require('./assets/duotone/icon-broken-screen.png'),
    'icon-broken-teddybear': require('./assets/duotone/icon-broken-teddybear.png'),
    'icon-broken-trophy': require('./assets/duotone/icon-broken-trophy.png'),
    'icon-broom': require('./assets/duotone/icon-broom.png'),
    'icon-browser': require('./assets/duotone/icon-browser.png'),
    'icon-bundestag': require('./assets/duotone/icon-bundestag.png'),
    'icon-bunk-bed': require('./assets/duotone/icon-bunk-bed.png'),
    'icon-bus': require('./assets/duotone/icon-bus.png'),
    'icon-camera': require('./assets/duotone/icon-camera.png'),
    'icon-candle': require('./assets/duotone/icon-candle.png'),
    'icon-car-company': require('./assets/duotone/icon-car-company.png'),
    'icon-car-key': require('./assets/duotone/icon-car-key.png'),
    'icon-car-share': require('./assets/duotone/icon-car-share.png'),
    'icon-car-taxi': require('./assets/duotone/icon-car-taxi.png'),
    'icon-car-van': require('./assets/duotone/icon-car-van.png'),
    'icon-car': require('./assets/duotone/icon-car.png'),
    'icon-cat': require('./assets/duotone/icon-cat.png'),
    'icon-cctv': require('./assets/duotone/icon-cctv.png'),
    'icon-chair-apprenticeship': require('./assets/duotone/icon-chair-apprenticeship.png'),
    'icon-chair-ball': require('./assets/duotone/icon-chair-ball.png'),
    'icon-chair-child': require('./assets/duotone/icon-chair-child.png'),
    'icon-chair-folding': require('./assets/duotone/icon-chair-folding.png'),
    'icon-chair-freelance': require('./assets/duotone/icon-chair-freelance.png'),
    'icon-chair-freelance2': require('./assets/duotone/icon-chair-freelance2.png'),
    'icon-chair-globe': require('./assets/duotone/icon-chair-globe.png'),
    'icon-chair-kitchen': require('./assets/duotone/icon-chair-kitchen.png'),
    'icon-chair-manager': require('./assets/duotone/icon-chair-manager.png'),
    'icon-chair-office': require('./assets/duotone/icon-chair-office.png'),
    'icon-chair-rocking-lock-closed': require('./assets/duotone/icon-chair-rocking-lock-closed.png'),
    'icon-chair-rocking-lock-open': require('./assets/duotone/icon-chair-rocking-lock-open.png'),
    'icon-chair-rocking-paragraph': require('./assets/duotone/icon-chair-rocking-paragraph.png'),
    'icon-chair-rocking-sign': require('./assets/duotone/icon-chair-rocking-sign.png'),
    'icon-chair-rocking-signs': require('./assets/duotone/icon-chair-rocking-signs.png'),
    'icon-chair-rocking': require('./assets/duotone/icon-chair-rocking.png'),
    'icon-chair-school': require('./assets/duotone/icon-chair-school.png'),
    'icon-chair-study': require('./assets/duotone/icon-chair-study.png'),
    'icon-chair-training': require('./assets/duotone/icon-chair-training.png'),
    'icon-checked-pin': require('./assets/duotone/icon-checked-pin.png'),
    'icon-chimney': require('./assets/duotone/icon-chimney.png'),
    'icon-coffin': require('./assets/duotone/icon-coffin.png'),
    'icon-coin': require('./assets/duotone/icon-coin.png'),
    'icon-computer': require('./assets/duotone/icon-computer.png'),
    'icon-conductor-baton': require('./assets/duotone/icon-conductor-baton.png'),
    'icon-crest': require('./assets/duotone/icon-crest.png'),
    'icon-croissant': require('./assets/duotone/icon-croissant.png'),
    'icon-cup': require('./assets/duotone/icon-cup.png'),
    'icon-cv': require('./assets/duotone/icon-cv.png'),
    'icon-diamond': require('./assets/duotone/icon-diamond.png'),
    'icon-dirigent': require('./assets/duotone/icon-dirigent.png'),
    'icon-dish-washer': require('./assets/duotone/icon-dish-washer.png'),
    'icon-document-file': require('./assets/duotone/icon-document-file.png'),
    'icon-document-folder': require('./assets/duotone/icon-document-folder.png'),
    'icon-document': require('./assets/duotone/icon-document.png'),
    'icon-drill': require('./assets/duotone/icon-drill.png'),
    'icon-driller': require('./assets/duotone/icon-driller.png'),
    'icon-dryer': require('./assets/duotone/icon-dryer.png'),
    'icon-ear': require('./assets/duotone/icon-ear.png'),
    'icon-earrings': require('./assets/duotone/icon-earrings.png'),
    'icon-eye': require('./assets/duotone/icon-eye.png'),
    'icon-first-aid-box': require('./assets/duotone/icon-first-aid-box.png'),
    'icon-folder': require('./assets/duotone/icon-folder.png'),
    'icon-frame-account': require('./assets/duotone/icon-frame-account.png'),
    'icon-fridge': require('./assets/duotone/icon-fridge.png'),
    'icon-gavel': require('./assets/duotone/icon-gavel.png'),
    'icon-gear-wheel': require('./assets/duotone/icon-gear-wheel.png'),
    'icon-glas-lemonade': require('./assets/duotone/icon-glas-lemonade.png'),
    'icon-glas-wine': require('./assets/duotone/icon-glas-wine.png'),
    'icon-glass-lemonade': require('./assets/duotone/icon-glass-lemonade.png'),
    'icon-glass-wine': require('./assets/duotone/icon-glass-wine.png'),
    'icon-glasses': require('./assets/duotone/icon-glasses.png'),
    'icon-graduate-cap': require('./assets/duotone/icon-graduate-cap.png'),
    'icon-green-energy': require('./assets/duotone/icon-green-energy.png'),
    'icon-hammock': require('./assets/duotone/icon-hammock.png'),
    'icon-hand-bar-chart': require('./assets/duotone/icon-hand-bar-chart.png'),
    'icon-hand-coins': require('./assets/duotone/icon-hand-coins.png'),
    'icon-hand-heart': require('./assets/duotone/icon-hand-heart.png'),
    'icon-hand-percent': require('./assets/duotone/icon-hand-percent.png'),
    'icon-hand-shield': require('./assets/duotone/icon-hand-shield.png'),
    'icon-hat-student': require('./assets/duotone/icon-hat-student.png'),
    'icon-heart': require('./assets/duotone/icon-heart.png'),
    'icon-heartbeat': require('./assets/duotone/icon-heartbeat.png'),
    'icon-holy-book': require('./assets/duotone/icon-holy-book.png'),
    'icon-house-euro': require('./assets/duotone/icon-house-euro.png'),
    'icon-house-home': require('./assets/duotone/icon-house-home.png'),
    'icon-house-key': require('./assets/duotone/icon-house-key.png'),
    'icon-house-office': require('./assets/duotone/icon-house-office.png'),
    'icon-house-paragraph': require('./assets/duotone/icon-house-paragraph.png'),
    'icon-house-percent': require('./assets/duotone/icon-house-percent.png'),
    'icon-house-skyscraper': require('./assets/duotone/icon-house-skyscraper.png'),
    'icon-house-walls': require('./assets/duotone/icon-house-walls.png'),
    'icon-house': require('./assets/duotone/icon-house.png'),
    'icon-icon-broom': require('./assets/duotone/icon-icon-broom.png'),
    'icon-injection': require('./assets/duotone/icon-injection.png'),
    'icon-jewellery': require('./assets/duotone/icon-jewellery.png'),
    'icon-judge-hammer': require('./assets/duotone/icon-judge-hammer.png'),
    'icon-kite': require('./assets/duotone/icon-kite.png'),
    'icon-lamp-orange': require('./assets/duotone/icon-lamp-orange.png'),
    'icon-lamp': require('./assets/duotone/icon-lamp.png'),
    'icon-locomotive': require('./assets/duotone/icon-locomotive.png'),
    'icon-lunch': require('./assets/duotone/icon-lunch.png'),
    'icon-mail': require('./assets/duotone/icon-mail.png'),
    'icon-marker': require('./assets/duotone/icon-marker.png'),
    'icon-megaphone': require('./assets/duotone/icon-megaphone.png'),
    'icon-message-bottle': require('./assets/duotone/icon-message-bottle.png'),
    'icon-microphone': require('./assets/duotone/icon-microphone.png'),
    'icon-microwave': require('./assets/duotone/icon-microwave.png'),
    'icon-money-hand': require('./assets/duotone/icon-money-hand.png'),
    'icon-mouse': require('./assets/duotone/icon-mouse.png'),
    'icon-none': require('./assets/duotone/icon-none.png'),
    'icon-noodles': require('./assets/duotone/icon-noodles.png'),
    'icon-nurse-hat': require('./assets/duotone/icon-nurse-hat.png'),
    'icon-organ-keys': require('./assets/duotone/icon-organ-keys.png'),
    'icon-overalls': require('./assets/duotone/icon-overalls.png'),
    'icon-packages': require('./assets/duotone/icon-packages.png'),
    'icon-packets': require('./assets/duotone/icon-packets.png'),
    'icon-paint-roller': require('./assets/duotone/icon-paint-roller.png'),
    'icon-painting': require('./assets/duotone/icon-painting.png'),
    'icon-parachute': require('./assets/duotone/icon-parachute.png'),
    'icon-parking': require('./assets/duotone/icon-parking.png'),
    'icon-phone': require('./assets/duotone/icon-phone.png'),
    'icon-piggy-bank': require('./assets/duotone/icon-piggy-bank.png'),
    'icon-pills': require('./assets/duotone/icon-pills.png'),
    'icon-pin': require('./assets/duotone/icon-pin.png'),
    'icon-plane': require('./assets/duotone/icon-plane.png'),
    'icon-postage-stamp': require('./assets/duotone/icon-postage-stamp.png'),
    'icon-present': require('./assets/duotone/icon-present.png'),
    'icon-punch': require('./assets/duotone/icon-punch.png'),
    'icon-puzzle': require('./assets/duotone/icon-puzzle.png'),
    'icon-red-card': require('./assets/duotone/icon-red-card.png'),
    'icon-rocking-horse': require('./assets/duotone/icon-rocking-horse.png'),
    'icon-rubber-stamp': require('./assets/duotone/icon-rubber-stamp.png'),
    'icon-scale': require('./assets/duotone/icon-scale.png'),
    'icon-scissor-card': require('./assets/duotone/icon-scissor-card.png'),
    'icon-scissors-card': require('./assets/duotone/icon-scissors-card.png'),
    'icon-scissors-money': require('./assets/duotone/icon-scissors-money.png'),
    'icon-scissors-ring': require('./assets/duotone/icon-scissors-ring.png'),
    'icon-settings-orange': require('./assets/duotone/icon-settings-orange.png'),
    'icon-shelf': require('./assets/duotone/icon-shelf.png'),
    'icon-shield-plus': require('./assets/duotone/icon-shield-plus.png'),
    'icon-shield': require('./assets/duotone/icon-shield.png'),
    'icon-shirt-tie': require('./assets/duotone/icon-shirt-tie.png'),
    'icon-shoe': require('./assets/duotone/icon-shoe.png'),
    'icon-shovel-dirt': require('./assets/duotone/icon-shovel-dirt.png'),
    'icon-shredder': require('./assets/duotone/icon-shredder.png'),
    'icon-single-bottle': require('./assets/duotone/icon-single-bottle.png'),
    'icon-snowflake': require('./assets/duotone/icon-snowflake.png'),
    'icon-snowman': require('./assets/duotone/icon-snowman.png'),
    'icon-sofa': require('./assets/duotone/icon-sofa.png'),
    'icon-spectacles': require('./assets/duotone/icon-spectacles.png'),
    'icon-speech-bubble': require('./assets/duotone/icon-speech-bubble.png'),
    'icon-stamp': require('./assets/duotone/icon-stamp.png'),
    'icon-stoppwatch': require('./assets/duotone/icon-stoppwatch.png'),
    'icon-stopwatch': require('./assets/duotone/icon-stopwatch.png'),
    'icon-storage': require('./assets/duotone/icon-storage.png'),
    'icon-table': require('./assets/duotone/icon-table.png'),
    'icon-tag-percent': require('./assets/duotone/icon-tag-percent.png'),
    'icon-tap': require('./assets/duotone/icon-tap.png'),
    'icon-teddybear': require('./assets/duotone/icon-teddybear.png'),
    'icon-ticket': require('./assets/duotone/icon-ticket.png'),
    'icon-till': require('./assets/duotone/icon-till.png'),
    'icon-tools': require('./assets/duotone/icon-tools.png'),
    'icon-tooth': require('./assets/duotone/icon-tooth.png'),
    'icon-train': require('./assets/duotone/icon-train.png'),
    'icon-treasure-chest': require('./assets/duotone/icon-treasure-chest.png'),
    'icon-trouser': require('./assets/duotone/icon-trouser.png'),
    'icon-tv': require('./assets/duotone/icon-tv.png'),
    'icon-umbrella-plus': require('./assets/duotone/icon-umbrella-plus.png'),
    'icon-umbrella': require('./assets/duotone/icon-umbrella.png'),
    'icon-vespa': require('./assets/duotone/icon-vespa.png'),
    'icon-wardrobe': require('./assets/duotone/icon-wardrobe.png'),
    'icon-washing-machine': require('./assets/duotone/icon-washing-machine.png'),
    'icon-watering-can': require('./assets/duotone/icon-watering-can.png'),
    'icon-wheelchair': require('./assets/duotone/icon-wheelchair.png'),
    'icon-whistle': require('./assets/duotone/icon-whistle.png'),
    'icon-window': require('./assets/duotone/icon-window.png'),
    'icon-wood': require('./assets/duotone/icon-wood.png'),
    'icon-wrecking-ball': require('./assets/duotone/icon-wrecking-ball.png'),
    'lightBulb': require('./assets/duotone/lightBulb.png')
  }
,
  'emoji': {
    'bike': require('./assets/emoji/bike.png'),
    'car': require('./assets/emoji/car.png'),
    'computer': require('./assets/emoji/computer.png'),
    'concert': require('./assets/emoji/concert.png'),
    'home-cinema': require('./assets/emoji/home-cinema.png'),
    'partying-face': require('./assets/emoji/partying-face.png'),
    'plane': require('./assets/emoji/plane.png'),
    'restaurant': require('./assets/emoji/restaurant.png'),
    'tada': require('./assets/emoji/tada.png'),
    'ticket': require('./assets/emoji/ticket.png'),
    'train': require('./assets/emoji/train.png')
  }
,
  'error-svg': {
    'error-404': require('./assets/error-svg/error-404.svg'),
    'error-failed': require('./assets/error-svg/error-failed.svg'),
    'error-no-connection': require('./assets/error-svg/error-no-connection.svg'),
    'error-something-wrong': require('./assets/error-svg/error-something-wrong.svg')
  }
,
  'error': {
    'error-no-connection': require('./assets/error/error-no-connection.png'),
    'error-something-wrong': require('./assets/error/error-something-wrong.png')
  }
,
  'feedback-modal': {
    'nps-header-new': require('./assets/feedback-modal/nps-header-new.png'),
    'nps-header': require('./assets/feedback-modal/nps-header.png')
  }
,
  'files-svg': {
    'pdf': require('./assets/files-svg/pdf.svg')
  }
,
  'files': {
    'pdf': require('./assets/files/pdf.png')
  }
,
  'gif': {
    'question-loading': require('./assets/gif/question-loading.gif')
  }
,
  'icons-svg': {
    'asterisk': require('./assets/icons-svg/asterisk.svg'),
    'check': require('./assets/icons-svg/check.svg'),
    'circle-cross': require('./assets/icons-svg/circle-cross.svg'),
    'coins': require('./assets/icons-svg/coins.svg'),
    'grayCircle': require('./assets/icons-svg/grayCircle.svg'),
    'happy': require('./assets/icons-svg/happy.svg'),
    'home': require('./assets/icons-svg/home.svg'),
    'icon-warning-message': require('./assets/icons-svg/icon-warning-message.svg'),
    'loading': require('./assets/icons-svg/loading.svg'),
    'location-marker': require('./assets/icons-svg/location-marker.svg'),
    'lock': require('./assets/icons-svg/lock.svg'),
    'radio-button-active': require('./assets/icons-svg/radio-button-active.svg'),
    'radio-button-inactive': require('./assets/icons-svg/radio-button-inactive.svg'),
    'star-icon': require('./assets/icons-svg/star-icon.svg'),
    'statusStartPrimary': require('./assets/icons-svg/statusStartPrimary.svg'),
    'video-identification': require('./assets/icons-svg/video-identification.svg'),
    'warning': require('./assets/icons-svg/warning.svg')
  }
,
  'icons': {
    'action-success': require('./assets/icons/action-success.png'),
    'bulb-emoji': require('./assets/icons/bulb-emoji.png'),
    'checkmark-green-dark': require('./assets/icons/checkmark-green-dark.png'),
    'checkmark-green': require('./assets/icons/checkmark-green.png'),
    'circle-cross': require('./assets/icons/circle-cross.png'),
    'coins': require('./assets/icons/coins.png'),
    'error-red-cross': require('./assets/icons/error-red-cross.png'),
    'error-red': require('./assets/icons/error-red.png'),
    'filled-questionmark': require('./assets/icons/filled-questionmark.png'),
    'fiscal-code': require('./assets/icons/fiscal-code.png'),
    'hand-bar': require('./assets/icons/hand-bar.png'),
    'hand-percent': require('./assets/icons/hand-percent.png'),
    'house-home': require('./assets/icons/house-home.png'),
    'icon-anydocs': require('./assets/icons/icon-anydocs.png'),
    'icon-briefcase-2': require('./assets/icons/icon-briefcase-2.png'),
    'icon-briefcase': require('./assets/icons/icon-briefcase.png'),
    'icon-buggy': require('./assets/icons/icon-buggy.png'),
    'icon-chat': require('./assets/icons/icon-chat.png'),
    'icon-check-mark': require('./assets/icons/icon-check-mark.png'),
    'icon-checkmark-green': require('./assets/icons/icon-checkmark-green.png'),
    'icon-cleaning-2': require('./assets/icons/icon-cleaning-2.png'),
    'icon-clock': require('./assets/icons/icon-clock.png'),
    'icon-contact-support-call': require('./assets/icons/icon-contact-support-call.png'),
    'icon-contact-support-chat': require('./assets/icons/icon-contact-support-chat.png'),
    'icon-contact-support-cross': require('./assets/icons/icon-contact-support-cross.png'),
    'icon-contact-support': require('./assets/icons/icon-contact-support.png'),
    'icon-contact': require('./assets/icons/icon-contact.png'),
    'icon-document': require('./assets/icons/icon-document.png'),
    'icon-eye-big': require('./assets/icons/icon-eye-big.png'),
    'icon-eye': require('./assets/icons/icon-eye.png'),
    'icon-file': require('./assets/icons/icon-file.png'),
    'icon-full-list': require('./assets/icons/icon-full-list.png'),
    'icon-gear': require('./assets/icons/icon-gear.png'),
    'icon-group-2': require('./assets/icons/icon-group-2.png'),
    'icon-group-5': require('./assets/icons/icon-group-5.png'),
    'icon-hand-flag': require('./assets/icons/icon-hand-flag.png'),
    'icon-info-preseason': require('./assets/icons/icon-info-preseason.png'),
    'icon-laptop': require('./assets/icons/icon-laptop.png'),
    'icon-logout': require('./assets/icons/icon-logout.png'),
    'icon-mail': require('./assets/icons/icon-mail.png'),
    'icon-onboarding-interview': require('./assets/icons/icon-onboarding-interview.png'),
    'icon-phone-call': require('./assets/icons/icon-phone-call.png'),
    'icon-phone': require('./assets/icons/icon-phone.png'),
    'icon-pig': require('./assets/icons/icon-pig.png'),
    'icon-protection': require('./assets/icons/icon-protection.png'),
    'icon-share': require('./assets/icons/icon-share.png'),
    'icon-signal': require('./assets/icons/icon-signal.png'),
    'icon-spark': require('./assets/icons/icon-spark.png'),
    'icon-speech-bubble': require('./assets/icons/icon-speech-bubble.png'),
    'icon-student': require('./assets/icons/icon-student.png'),
    'icon-supported-loans': require('./assets/icons/icon-supported-loans.png'),
    'icon-thumbs-up': require('./assets/icons/icon-thumbs-up.png'),
    'icon-trash-darker': require('./assets/icons/icon-trash-darker.png'),
    'icon-trash': require('./assets/icons/icon-trash.png'),
    'icon-unsupported-athlete': require('./assets/icons/icon-unsupported-athlete.png'),
    'icon-unsupported-business': require('./assets/icons/icon-unsupported-business.png'),
    'icon-unsupported-foreignbank': require('./assets/icons/icon-unsupported-foreignbank.png'),
    'icon-unsupported-letting': require('./assets/icons/icon-unsupported-letting.png'),
    'icon-unsupported-parents': require('./assets/icons/icon-unsupported-parents.png'),
    'icon-unsupported-pension': require('./assets/icons/icon-unsupported-pension.png'),
    'icon-unsupported-silentpartnerships': require('./assets/icons/icon-unsupported-silentpartnerships.png'),
    'icon-unsupported-taxdeclaration': require('./assets/icons/icon-unsupported-taxdeclaration.png'),
    'icon-unsupported-unemployed': require('./assets/icons/icon-unsupported-unemployed.png'),
    'icon-unsupported-winnings': require('./assets/icons/icon-unsupported-winnings.png'),
    'icon-video-call': require('./assets/icons/icon-video-call.png'),
    'icon-warning-message': require('./assets/icons/icon-warning-message.png'),
    'info-green-outline': require('./assets/icons/info-green-outline.png'),
    'info-green': require('./assets/icons/info-green.png'),
    'info-orange': require('./assets/icons/info-orange.png'),
    'Lock': require('./assets/icons/Lock.png'),
    'piggy-bank': require('./assets/icons/piggy-bank.png'),
    'question-outlined': require('./assets/icons/question-outlined.png'),
    'reviewed-document': require('./assets/icons/reviewed-document.png'),
    'scissors-ring': require('./assets/icons/scissors-ring.png'),
    'shield-outlined': require('./assets/icons/shield-outlined.png'),
    'shield': require('./assets/icons/shield.png'),
    'star-icon': require('./assets/icons/star-icon.png'),
    'teddy-bear': require('./assets/icons/teddy-bear.png'),
    'video-identification': require('./assets/icons/video-identification.png'),
    'warning-emoji': require('./assets/icons/warning-emoji.png'),
    'year-2021': require('./assets/icons/year-2021.png')
  }
,
  'instant-refund-svg': {
    'beach': require('./assets/instant-refund-svg/beach.svg'),
    'bounty': require('./assets/instant-refund-svg/bounty.svg'),
    'clock': require('./assets/instant-refund-svg/clock.svg'),
    'coins': require('./assets/instant-refund-svg/coins.svg'),
    'green-clock': require('./assets/instant-refund-svg/green-clock.svg'),
    'icon-money-bill': require('./assets/instant-refund-svg/icon-money-bill.svg'),
    'Info': require('./assets/instant-refund-svg/Info.svg'),
    'instant-refund-alert': require('./assets/instant-refund-svg/instant-refund-alert.svg'),
    'lightning': require('./assets/instant-refund-svg/lightning.svg'),
    'loading': require('./assets/instant-refund-svg/loading.svg'),
    'money-back': require('./assets/instant-refund-svg/money-back.svg'),
    'single-filer': require('./assets/instant-refund-svg/single-filer.svg'),
    'sparks': require('./assets/instant-refund-svg/sparks.svg'),
    'vacation': require('./assets/instant-refund-svg/vacation.svg'),
    'white-question-mark': require('./assets/instant-refund-svg/white-question-mark.svg'),
    'wifi': require('./assets/instant-refund-svg/wifi.svg')
  }
,
  'instant-refund': {
    'beach': require('./assets/instant-refund/beach.png'),
    'calculating': require('./assets/instant-refund/calculating.png'),
    'done-check': require('./assets/instant-refund/done-check.png'),
    'done': require('./assets/instant-refund/done.png'),
    'earth-globe': require('./assets/instant-refund/earth-globe.png'),
    'icon-bag-money-deliver': require('./assets/instant-refund/icon-bag-money-deliver.png'),
    'icon-bag-money-fast-deliver': require('./assets/instant-refund/icon-bag-money-fast-deliver.png'),
    'icon-money-bill': require('./assets/instant-refund/icon-money-bill.png'),
    'instant-refund-alert': require('./assets/instant-refund/instant-refund-alert.png'),
    'loading': require('./assets/instant-refund/loading.png'),
    'money-back': require('./assets/instant-refund/money-back.png'),
    'result': require('./assets/instant-refund/result.png')
  }
,
  'intercom-svg': {
    'colored-bubble': require('./assets/intercom-svg/colored-bubble.svg')
  }
,
  'interface-icons-svg': {
    'icon-account-circle-small': require('./assets/interface-icons-svg/icon-account-circle-small.svg'),
    'icon-account-circle': require('./assets/interface-icons-svg/icon-account-circle.svg'),
    'icon-account-web': require('./assets/interface-icons-svg/icon-account-web.svg'),
    'icon-arrow-left': require('./assets/interface-icons-svg/icon-arrow-left.svg'),
    'icon-arrow-right-green': require('./assets/interface-icons-svg/icon-arrow-right-green.svg'),
    'icon-arrow-right': require('./assets/interface-icons-svg/icon-arrow-right.svg'),
    'icon-arrow-web': require('./assets/interface-icons-svg/icon-arrow-web.svg'),
    'icon-badge': require('./assets/interface-icons-svg/icon-badge.svg'),
    'icon-chat': require('./assets/interface-icons-svg/icon-chat.svg'),
    'icon-checkbox-checked': require('./assets/interface-icons-svg/icon-checkbox-checked.svg'),
    'icon-checkbox-unchecked': require('./assets/interface-icons-svg/icon-checkbox-unchecked.svg'),
    'icon-checkmark-black': require('./assets/interface-icons-svg/icon-checkmark-black.svg'),
    'icon-checkmark-green': require('./assets/interface-icons-svg/icon-checkmark-green.svg'),
    'icon-checkmark-white': require('./assets/interface-icons-svg/icon-checkmark-white.svg'),
    'icon-checkmark': require('./assets/interface-icons-svg/icon-checkmark.svg'),
    'icon-chevron-down-thin-sm': require('./assets/interface-icons-svg/icon-chevron-down-thin-sm.svg'),
    'icon-chevron-down-thin': require('./assets/interface-icons-svg/icon-chevron-down-thin.svg'),
    'icon-chevron-down': require('./assets/interface-icons-svg/icon-chevron-down.svg'),
    'icon-chevron-left-thin-sm': require('./assets/interface-icons-svg/icon-chevron-left-thin-sm.svg'),
    'icon-chevron-left-thin': require('./assets/interface-icons-svg/icon-chevron-left-thin.svg'),
    'icon-chevron-right-thin-sm': require('./assets/interface-icons-svg/icon-chevron-right-thin-sm.svg'),
    'icon-chevron-right-thin': require('./assets/interface-icons-svg/icon-chevron-right-thin.svg'),
    'icon-chevron-right': require('./assets/interface-icons-svg/icon-chevron-right.svg'),
    'icon-chevron-up-thin-sm': require('./assets/interface-icons-svg/icon-chevron-up-thin-sm.svg'),
    'icon-chevron-up-thin': require('./assets/interface-icons-svg/icon-chevron-up-thin.svg'),
    'icon-chevron-up': require('./assets/interface-icons-svg/icon-chevron-up.svg'),
    'icon-cross-gray': require('./assets/interface-icons-svg/icon-cross-gray.svg'),
    'icon-cross': require('./assets/interface-icons-svg/icon-cross.svg'),
    'icon-document': require('./assets/interface-icons-svg/icon-document.svg'),
    'icon-done': require('./assets/interface-icons-svg/icon-done.svg'),
    'icon-home': require('./assets/interface-icons-svg/icon-home.svg'),
    'icon-info-questionmark-small': require('./assets/interface-icons-svg/icon-info-questionmark-small.svg'),
    'icon-info-questionmark': require('./assets/interface-icons-svg/icon-info-questionmark.svg'),
    'icon-lock': require('./assets/interface-icons-svg/icon-lock.svg'),
    'icon-menu': require('./assets/interface-icons-svg/icon-menu.svg'),
    'icon-phone-call': require('./assets/interface-icons-svg/icon-phone-call.svg'),
    'icon-pin-a': require('./assets/interface-icons-svg/icon-pin-a.svg'),
    'icon-pin-b': require('./assets/interface-icons-svg/icon-pin-b.svg'),
    'icon-pin-outline': require('./assets/interface-icons-svg/icon-pin-outline.svg'),
    'icon-previous-saved': require('./assets/interface-icons-svg/icon-previous-saved.svg'),
    'icon-spinner': require('./assets/interface-icons-svg/icon-spinner.svg'),
    'icon-trophy': require('./assets/interface-icons-svg/icon-trophy.svg'),
    'icon-video-call': require('./assets/interface-icons-svg/icon-video-call.svg')
  }
,
  'it-commercialista-svg': {
    'alessandra': require('./assets/it-commercialista-svg/alessandra.svg'),
    'piergiorgio': require('./assets/it-commercialista-svg/piergiorgio.svg')
  }
,
  'kyc-illustrations-svg': {
    'kyc': require('./assets/kyc-illustrations-svg/kyc.svg')
  }
,
  'kyc-illustrations': {
    'kyc': require('./assets/kyc-illustrations/kyc.png'),
    'paul': require('./assets/kyc-illustrations/paul.png')
  }
,
  'logo-svg': {
    'taxfix': require('./assets/logo-svg/taxfix.svg')
  }
,
  'maintenance-svg': {
    'maintenance': require('./assets/maintenance-svg/maintenance.svg')
  }
,
  'mono': {
    'chat-bubble': require('./assets/mono/chat-bubble.png'),
    'icon-bell': require('./assets/mono/icon-bell.png'),
    'icon-calender-feb': require('./assets/mono/icon-calender-feb.png'),
    'icon-calender': require('./assets/mono/icon-calender.png'),
    'icon-category-finished': require('./assets/mono/icon-category-finished.png'),
    'icon-clock': require('./assets/mono/icon-clock.png'),
    'icon-form-arrow': require('./assets/mono/icon-form-arrow.png'),
    'icon-menu-account': require('./assets/mono/icon-menu-account.png'),
    'icon-menu-tax': require('./assets/mono/icon-menu-tax.png'),
    'icon-menu-todo': require('./assets/mono/icon-menu-todo.png'),
    'icon-nav-arrow-down': require('./assets/mono/icon-nav-arrow-down.png'),
    'icon-nav-arrow-right': require('./assets/mono/icon-nav-arrow-right.png'),
    'icon-nav-arrow-up': require('./assets/mono/icon-nav-arrow-up.png'),
    'icon-nav-documents': require('./assets/mono/icon-nav-documents.png'),
    'icon-nav-location-home': require('./assets/mono/icon-nav-location-home.png'),
    'icon-nav-location-other': require('./assets/mono/icon-nav-location-other.png'),
    'icon-nav-location-previous': require('./assets/mono/icon-nav-location-previous.png'),
    'icon-nav-location-work': require('./assets/mono/icon-nav-location-work.png'),
    'icon-nav-skip': require('./assets/mono/icon-nav-skip.png'),
    'icon-not-page': require('./assets/mono/icon-not-page.png'),
    'icon-ok': require('./assets/mono/icon-ok.png'),
    'icon-pages-one': require('./assets/mono/icon-pages-one.png'),
    'icon-paid': require('./assets/mono/icon-paid.png'),
    'icon-search-file': require('./assets/mono/icon-search-file.png'),
    'icon-signup-chat': require('./assets/mono/icon-signup-chat.png'),
    'icon-signup-successful': require('./assets/mono/icon-signup-successful.png'),
    'icon-signup-taxdeclaration': require('./assets/mono/icon-signup-taxdeclaration.png'),
    'icon-signup-till': require('./assets/mono/icon-signup-till.png'),
    'icon-stamp': require('./assets/mono/icon-stamp.png'),
    'icon-suit': require('./assets/mono/icon-suit.png'),
    'icon-taxes-notsubmitted': require('./assets/mono/icon-taxes-notsubmitted.png'),
    'icon-taxes-pending': require('./assets/mono/icon-taxes-pending.png'),
    'icon-taxes-review': require('./assets/mono/icon-taxes-review.png'),
    'icon-taxes-submitted': require('./assets/mono/icon-taxes-submitted.png'),
    'icon-trophy': require('./assets/mono/icon-trophy.png')
  }
,
  'multiselect-icons-svg': {
    'icon-apple-book': require('./assets/multiselect-icons-svg/icon-apple-book.svg'),
    'icon-baby-mobile': require('./assets/multiselect-icons-svg/icon-baby-mobile.svg'),
    'icon-bag-house': require('./assets/multiselect-icons-svg/icon-bag-house.svg'),
    'icon-bag-money': require('./assets/multiselect-icons-svg/icon-bag-money.svg'),
    'icon-bag-percent': require('./assets/multiselect-icons-svg/icon-bag-percent.svg'),
    'icon-baggage-storage': require('./assets/multiselect-icons-svg/icon-baggage-storage.svg'),
    'icon-ball': require('./assets/multiselect-icons-svg/icon-ball.svg'),
    'icon-banana': require('./assets/multiselect-icons-svg/icon-banana.svg'),
    'icon-barrier': require('./assets/multiselect-icons-svg/icon-barrier.svg'),
    'icon-basket-ball': require('./assets/multiselect-icons-svg/icon-basket-ball.svg'),
    'icon-basketball': require('./assets/multiselect-icons-svg/icon-basketball.svg'),
    'icon-bed-bunk': require('./assets/multiselect-icons-svg/icon-bed-bunk.svg'),
    'icon-bed-double': require('./assets/multiselect-icons-svg/icon-bed-double.svg'),
    'icon-bed-hospital': require('./assets/multiselect-icons-svg/icon-bed-hospital.svg'),
    'icon-bed-single': require('./assets/multiselect-icons-svg/icon-bed-single.svg'),
    'icon-bench': require('./assets/multiselect-icons-svg/icon-bench.svg'),
    'icon-bike': require('./assets/multiselect-icons-svg/icon-bike.svg'),
    'icon-blood-donation': require('./assets/multiselect-icons-svg/icon-blood-donation.svg'),
    'icon-boat': require('./assets/multiselect-icons-svg/icon-boat.svg'),
    'icon-book': require('./assets/multiselect-icons-svg/icon-book.svg'),
    'icon-bottle-baby': require('./assets/multiselect-icons-svg/icon-bottle-baby.svg'),
    'icon-bottle-glass': require('./assets/multiselect-icons-svg/icon-bottle-glass.svg'),
    'icon-bottle-message': require('./assets/multiselect-icons-svg/icon-bottle-message.svg'),
    'icon-bottle-plastic': require('./assets/multiselect-icons-svg/icon-bottle-plastic.svg'),
    'icon-bottle-single2': require('./assets/multiselect-icons-svg/icon-bottle-single2.svg'),
    'icon-bottle-two-box': require('./assets/multiselect-icons-svg/icon-bottle-two-box.svg'),
    'icon-bottle-two-different': require('./assets/multiselect-icons-svg/icon-bottle-two-different.svg'),
    'icon-bottle-two-same': require('./assets/multiselect-icons-svg/icon-bottle-two-same.svg'),
    'icon-bottle-wine-glass': require('./assets/multiselect-icons-svg/icon-bottle-wine-glass.svg'),
    'icon-briefcase': require('./assets/multiselect-icons-svg/icon-briefcase.svg'),
    'icon-broken-foot': require('./assets/multiselect-icons-svg/icon-broken-foot.svg'),
    'icon-broken-glas': require('./assets/multiselect-icons-svg/icon-broken-glas.svg'),
    'icon-broken-glass': require('./assets/multiselect-icons-svg/icon-broken-glass.svg'),
    'icon-broken-screen': require('./assets/multiselect-icons-svg/icon-broken-screen.svg'),
    'icon-broken-teddybear': require('./assets/multiselect-icons-svg/icon-broken-teddybear.svg'),
    'icon-broken-trophy': require('./assets/multiselect-icons-svg/icon-broken-trophy.svg'),
    'icon-broom': require('./assets/multiselect-icons-svg/icon-broom.svg'),
    'icon-browser': require('./assets/multiselect-icons-svg/icon-browser.svg'),
    'icon-bundestag': require('./assets/multiselect-icons-svg/icon-bundestag.svg'),
    'icon-bunk-bed': require('./assets/multiselect-icons-svg/icon-bunk-bed.svg'),
    'icon-bus': require('./assets/multiselect-icons-svg/icon-bus.svg'),
    'icon-camera': require('./assets/multiselect-icons-svg/icon-camera.svg'),
    'icon-candle': require('./assets/multiselect-icons-svg/icon-candle.svg'),
    'icon-car-company': require('./assets/multiselect-icons-svg/icon-car-company.svg'),
    'icon-car-key': require('./assets/multiselect-icons-svg/icon-car-key.svg'),
    'icon-car-share': require('./assets/multiselect-icons-svg/icon-car-share.svg'),
    'icon-car-taxi': require('./assets/multiselect-icons-svg/icon-car-taxi.svg'),
    'icon-car-van': require('./assets/multiselect-icons-svg/icon-car-van.svg'),
    'icon-car': require('./assets/multiselect-icons-svg/icon-car.svg'),
    'icon-cat': require('./assets/multiselect-icons-svg/icon-cat.svg'),
    'icon-cctv': require('./assets/multiselect-icons-svg/icon-cctv.svg'),
    'icon-chair-apprenticeship': require('./assets/multiselect-icons-svg/icon-chair-apprenticeship.svg'),
    'icon-chair-ball': require('./assets/multiselect-icons-svg/icon-chair-ball.svg'),
    'icon-chair-child': require('./assets/multiselect-icons-svg/icon-chair-child.svg'),
    'icon-chair-folding': require('./assets/multiselect-icons-svg/icon-chair-folding.svg'),
    'icon-chair-freelance': require('./assets/multiselect-icons-svg/icon-chair-freelance.svg'),
    'icon-chair-freelance2': require('./assets/multiselect-icons-svg/icon-chair-freelance2.svg'),
    'icon-chair-globe': require('./assets/multiselect-icons-svg/icon-chair-globe.svg'),
    'icon-chair-kitchen': require('./assets/multiselect-icons-svg/icon-chair-kitchen.svg'),
    'icon-chair-manager': require('./assets/multiselect-icons-svg/icon-chair-manager.svg'),
    'icon-chair-office': require('./assets/multiselect-icons-svg/icon-chair-office.svg'),
    'icon-chair-rocking-lock-closed': require('./assets/multiselect-icons-svg/icon-chair-rocking-lock-closed.svg'),
    'icon-chair-rocking-lock-open': require('./assets/multiselect-icons-svg/icon-chair-rocking-lock-open.svg'),
    'icon-chair-rocking-paragraph': require('./assets/multiselect-icons-svg/icon-chair-rocking-paragraph.svg'),
    'icon-chair-rocking-sign': require('./assets/multiselect-icons-svg/icon-chair-rocking-sign.svg'),
    'icon-chair-rocking-signs': require('./assets/multiselect-icons-svg/icon-chair-rocking-signs.svg'),
    'icon-chair-rocking': require('./assets/multiselect-icons-svg/icon-chair-rocking.svg'),
    'icon-chair-school': require('./assets/multiselect-icons-svg/icon-chair-school.svg'),
    'icon-chair-study': require('./assets/multiselect-icons-svg/icon-chair-study.svg'),
    'icon-chair-training': require('./assets/multiselect-icons-svg/icon-chair-training.svg'),
    'icon-chimney': require('./assets/multiselect-icons-svg/icon-chimney.svg'),
    'icon-coffin': require('./assets/multiselect-icons-svg/icon-coffin.svg'),
    'icon-coin': require('./assets/multiselect-icons-svg/icon-coin.svg'),
    'icon-computer': require('./assets/multiselect-icons-svg/icon-computer.svg'),
    'icon-conductor-baton': require('./assets/multiselect-icons-svg/icon-conductor-baton.svg'),
    'icon-crest': require('./assets/multiselect-icons-svg/icon-crest.svg'),
    'icon-croissant': require('./assets/multiselect-icons-svg/icon-croissant.svg'),
    'icon-cup': require('./assets/multiselect-icons-svg/icon-cup.svg'),
    'icon-cv': require('./assets/multiselect-icons-svg/icon-cv.svg'),
    'icon-diamond': require('./assets/multiselect-icons-svg/icon-diamond.svg'),
    'icon-dirigent': require('./assets/multiselect-icons-svg/icon-dirigent.svg'),
    'icon-dish-washer': require('./assets/multiselect-icons-svg/icon-dish-washer.svg'),
    'icon-document-file': require('./assets/multiselect-icons-svg/icon-document-file.svg'),
    'icon-document': require('./assets/multiselect-icons-svg/icon-document.svg'),
    'icon-drill': require('./assets/multiselect-icons-svg/icon-drill.svg'),
    'icon-driller': require('./assets/multiselect-icons-svg/icon-driller.svg'),
    'icon-dryer': require('./assets/multiselect-icons-svg/icon-dryer.svg'),
    'icon-ear': require('./assets/multiselect-icons-svg/icon-ear.svg'),
    'icon-earrings': require('./assets/multiselect-icons-svg/icon-earrings.svg'),
    'icon-eye': require('./assets/multiselect-icons-svg/icon-eye.svg'),
    'icon-first-aid-box': require('./assets/multiselect-icons-svg/icon-first-aid-box.svg'),
    'icon-folder': require('./assets/multiselect-icons-svg/icon-folder.svg'),
    'icon-fridge': require('./assets/multiselect-icons-svg/icon-fridge.svg'),
    'icon-gavel': require('./assets/multiselect-icons-svg/icon-gavel.svg'),
    'icon-gear-wheel': require('./assets/multiselect-icons-svg/icon-gear-wheel.svg'),
    'icon-glas-lemonade': require('./assets/multiselect-icons-svg/icon-glas-lemonade.svg'),
    'icon-glas-wine': require('./assets/multiselect-icons-svg/icon-glas-wine.svg'),
    'icon-glass-lemonade': require('./assets/multiselect-icons-svg/icon-glass-lemonade.svg'),
    'icon-glass-wine': require('./assets/multiselect-icons-svg/icon-glass-wine.svg'),
    'icon-glasses': require('./assets/multiselect-icons-svg/icon-glasses.svg'),
    'icon-graduate-cap': require('./assets/multiselect-icons-svg/icon-graduate-cap.svg'),
    'icon-green-energy': require('./assets/multiselect-icons-svg/icon-green-energy.svg'),
    'icon-gym-ball': require('./assets/multiselect-icons-svg/icon-gym-ball.svg'),
    'icon-hammock': require('./assets/multiselect-icons-svg/icon-hammock.svg'),
    'icon-hand-bar-chart': require('./assets/multiselect-icons-svg/icon-hand-bar-chart.svg'),
    'icon-hand-coins': require('./assets/multiselect-icons-svg/icon-hand-coins.svg'),
    'icon-hand-heart': require('./assets/multiselect-icons-svg/icon-hand-heart.svg'),
    'icon-hand-percent': require('./assets/multiselect-icons-svg/icon-hand-percent.svg'),
    'icon-hand-shield': require('./assets/multiselect-icons-svg/icon-hand-shield.svg'),
    'icon-hat-student': require('./assets/multiselect-icons-svg/icon-hat-student.svg'),
    'icon-heart': require('./assets/multiselect-icons-svg/icon-heart.svg'),
    'icon-heartbeat': require('./assets/multiselect-icons-svg/icon-heartbeat.svg'),
    'icon-holy-book': require('./assets/multiselect-icons-svg/icon-holy-book.svg'),
    'icon-house-euro': require('./assets/multiselect-icons-svg/icon-house-euro.svg'),
    'icon-house-home': require('./assets/multiselect-icons-svg/icon-house-home.svg'),
    'icon-house-key': require('./assets/multiselect-icons-svg/icon-house-key.svg'),
    'icon-house-office': require('./assets/multiselect-icons-svg/icon-house-office.svg'),
    'icon-house-paragraph': require('./assets/multiselect-icons-svg/icon-house-paragraph.svg'),
    'icon-house-percent': require('./assets/multiselect-icons-svg/icon-house-percent.svg'),
    'icon-house-skyscraper': require('./assets/multiselect-icons-svg/icon-house-skyscraper.svg'),
    'icon-house-walls': require('./assets/multiselect-icons-svg/icon-house-walls.svg'),
    'icon-house': require('./assets/multiselect-icons-svg/icon-house.svg'),
    'icon-icon-broom': require('./assets/multiselect-icons-svg/icon-icon-broom.svg'),
    'icon-injection': require('./assets/multiselect-icons-svg/icon-injection.svg'),
    'icon-jewellery': require('./assets/multiselect-icons-svg/icon-jewellery.svg'),
    'icon-judge-hammer': require('./assets/multiselect-icons-svg/icon-judge-hammer.svg'),
    'icon-kite': require('./assets/multiselect-icons-svg/icon-kite.svg'),
    'icon-lamp': require('./assets/multiselect-icons-svg/icon-lamp.svg'),
    'icon-locomotive': require('./assets/multiselect-icons-svg/icon-locomotive.svg'),
    'icon-lunch': require('./assets/multiselect-icons-svg/icon-lunch.svg'),
    'icon-mail': require('./assets/multiselect-icons-svg/icon-mail.svg'),
    'icon-marker': require('./assets/multiselect-icons-svg/icon-marker.svg'),
    'icon-megaphone': require('./assets/multiselect-icons-svg/icon-megaphone.svg'),
    'icon-message-bottle': require('./assets/multiselect-icons-svg/icon-message-bottle.svg'),
    'icon-microphone': require('./assets/multiselect-icons-svg/icon-microphone.svg'),
    'icon-microwave': require('./assets/multiselect-icons-svg/icon-microwave.svg'),
    'icon-money-hand': require('./assets/multiselect-icons-svg/icon-money-hand.svg'),
    'icon-mouse': require('./assets/multiselect-icons-svg/icon-mouse.svg'),
    'icon-noodles': require('./assets/multiselect-icons-svg/icon-noodles.svg'),
    'icon-nurse-hat': require('./assets/multiselect-icons-svg/icon-nurse-hat.svg'),
    'icon-organ-keys': require('./assets/multiselect-icons-svg/icon-organ-keys.svg'),
    'icon-overalls': require('./assets/multiselect-icons-svg/icon-overalls.svg'),
    'icon-packages': require('./assets/multiselect-icons-svg/icon-packages.svg'),
    'icon-packets': require('./assets/multiselect-icons-svg/icon-packets.svg'),
    'icon-paint-roller': require('./assets/multiselect-icons-svg/icon-paint-roller.svg'),
    'icon-painting': require('./assets/multiselect-icons-svg/icon-painting.svg'),
    'icon-parachute': require('./assets/multiselect-icons-svg/icon-parachute.svg'),
    'icon-parking': require('./assets/multiselect-icons-svg/icon-parking.svg'),
    'icon-phone': require('./assets/multiselect-icons-svg/icon-phone.svg'),
    'icon-piggy-bank': require('./assets/multiselect-icons-svg/icon-piggy-bank.svg'),
    'icon-pills': require('./assets/multiselect-icons-svg/icon-pills.svg'),
    'icon-pin': require('./assets/multiselect-icons-svg/icon-pin.svg'),
    'icon-plane': require('./assets/multiselect-icons-svg/icon-plane.svg'),
    'icon-postage-stamp': require('./assets/multiselect-icons-svg/icon-postage-stamp.svg'),
    'icon-present': require('./assets/multiselect-icons-svg/icon-present.svg'),
    'icon-punch': require('./assets/multiselect-icons-svg/icon-punch.svg'),
    'icon-puzzle': require('./assets/multiselect-icons-svg/icon-puzzle.svg'),
    'icon-red-card': require('./assets/multiselect-icons-svg/icon-red-card.svg'),
    'icon-rocking-horse': require('./assets/multiselect-icons-svg/icon-rocking-horse.svg'),
    'icon-rubber-stamp': require('./assets/multiselect-icons-svg/icon-rubber-stamp.svg'),
    'icon-scale': require('./assets/multiselect-icons-svg/icon-scale.svg'),
    'icon-scissor-card': require('./assets/multiselect-icons-svg/icon-scissor-card.svg'),
    'icon-scissors-card': require('./assets/multiselect-icons-svg/icon-scissors-card.svg'),
    'icon-scissors-money': require('./assets/multiselect-icons-svg/icon-scissors-money.svg'),
    'icon-scissors-ring': require('./assets/multiselect-icons-svg/icon-scissors-ring.svg'),
    'icon-shelf': require('./assets/multiselect-icons-svg/icon-shelf.svg'),
    'icon-shield-plus': require('./assets/multiselect-icons-svg/icon-shield-plus.svg'),
    'icon-shield': require('./assets/multiselect-icons-svg/icon-shield.svg'),
    'icon-shirt-tie': require('./assets/multiselect-icons-svg/icon-shirt-tie.svg'),
    'icon-shoe': require('./assets/multiselect-icons-svg/icon-shoe.svg'),
    'icon-shovel-dirt': require('./assets/multiselect-icons-svg/icon-shovel-dirt.svg'),
    'icon-shredder': require('./assets/multiselect-icons-svg/icon-shredder.svg'),
    'icon-single-bottle': require('./assets/multiselect-icons-svg/icon-single-bottle.svg'),
    'icon-snowman': require('./assets/multiselect-icons-svg/icon-snowman.svg'),
    'icon-sofa': require('./assets/multiselect-icons-svg/icon-sofa.svg'),
    'icon-spectacles': require('./assets/multiselect-icons-svg/icon-spectacles.svg'),
    'icon-speech-bubble': require('./assets/multiselect-icons-svg/icon-speech-bubble.svg'),
    'icon-stamp': require('./assets/multiselect-icons-svg/icon-stamp.svg'),
    'icon-stoppwatch': require('./assets/multiselect-icons-svg/icon-stoppwatch.svg'),
    'icon-stopwatch': require('./assets/multiselect-icons-svg/icon-stopwatch.svg'),
    'icon-storage': require('./assets/multiselect-icons-svg/icon-storage.svg'),
    'icon-table': require('./assets/multiselect-icons-svg/icon-table.svg'),
    'icon-tag-percent': require('./assets/multiselect-icons-svg/icon-tag-percent.svg'),
    'icon-tap': require('./assets/multiselect-icons-svg/icon-tap.svg'),
    'icon-teddybear': require('./assets/multiselect-icons-svg/icon-teddybear.svg'),
    'icon-ticket': require('./assets/multiselect-icons-svg/icon-ticket.svg'),
    'icon-till': require('./assets/multiselect-icons-svg/icon-till.svg'),
    'icon-tools': require('./assets/multiselect-icons-svg/icon-tools.svg'),
    'icon-tooth': require('./assets/multiselect-icons-svg/icon-tooth.svg'),
    'icon-train': require('./assets/multiselect-icons-svg/icon-train.svg'),
    'icon-treasure-chest': require('./assets/multiselect-icons-svg/icon-treasure-chest.svg'),
    'icon-trouser': require('./assets/multiselect-icons-svg/icon-trouser.svg'),
    'icon-tv': require('./assets/multiselect-icons-svg/icon-tv.svg'),
    'icon-umbrella-plus': require('./assets/multiselect-icons-svg/icon-umbrella-plus.svg'),
    'icon-umbrella': require('./assets/multiselect-icons-svg/icon-umbrella.svg'),
    'icon-vespa': require('./assets/multiselect-icons-svg/icon-vespa.svg'),
    'icon-wardrobe': require('./assets/multiselect-icons-svg/icon-wardrobe.svg'),
    'icon-washing-machine': require('./assets/multiselect-icons-svg/icon-washing-machine.svg'),
    'icon-watering-can': require('./assets/multiselect-icons-svg/icon-watering-can.svg'),
    'icon-wheelchair': require('./assets/multiselect-icons-svg/icon-wheelchair.svg'),
    'icon-whistle': require('./assets/multiselect-icons-svg/icon-whistle.svg'),
    'icon-window': require('./assets/multiselect-icons-svg/icon-window.svg'),
    'icon-wood': require('./assets/multiselect-icons-svg/icon-wood.svg'),
    'icon-wrecking-ball': require('./assets/multiselect-icons-svg/icon-wrecking-ball.svg')
  }
,
  'onboarding-it-svg': {
    'web-bg-shape': require('./assets/onboarding-it-svg/web-bg-shape.svg'),
    'web-step-1': require('./assets/onboarding-it-svg/web-step-1.svg'),
    'web-step-2': require('./assets/onboarding-it-svg/web-step-2.svg'),
    'web-step-3': require('./assets/onboarding-it-svg/web-step-3.svg')
  }
,
  'onboarding-svg': {
    'illustration-key-money': require('./assets/onboarding-svg/illustration-key-money.svg'),
    'illustration-letter-envelope': require('./assets/onboarding-svg/illustration-letter-envelope.svg'),
    'illustration-two-tickets': require('./assets/onboarding-svg/illustration-two-tickets.svg'),
    'personalised-diamond': require('./assets/onboarding-svg/personalised-diamond.svg'),
    'personalised-dirigent': require('./assets/onboarding-svg/personalised-dirigent.svg'),
    'personalised-hand-heart': require('./assets/onboarding-svg/personalised-hand-heart.svg'),
    'personalised-stopwatch': require('./assets/onboarding-svg/personalised-stopwatch.svg'),
    'popup-piggy-bank': require('./assets/onboarding-svg/popup-piggy-bank.svg'),
    'rollover': require('./assets/onboarding-svg/rollover.svg'),
    'stars-green': require('./assets/onboarding-svg/stars-green.svg'),
    'stars-grey': require('./assets/onboarding-svg/stars-grey.svg'),
    'tax-type-card-children': require('./assets/onboarding-svg/tax-type-card-children.svg'),
    'tax-type-card-joint-assessment': require('./assets/onboarding-svg/tax-type-card-joint-assessment.svg'),
    'tax-type-card-single-employee': require('./assets/onboarding-svg/tax-type-card-single-employee.svg'),
    'tax-type-card-student': require('./assets/onboarding-svg/tax-type-card-student.svg')
  }
,
  'onboarding': {
    'cool': require('./assets/onboarding/cool.png'),
    'data-protection': require('./assets/onboarding/data-protection.png'),
    'declaration': require('./assets/onboarding/declaration.png'),
    'emoji-group': require('./assets/onboarding/emoji-group.png'),
    'find-out': require('./assets/onboarding/find-out.png'),
    'folder': require('./assets/onboarding/folder.png'),
    'free-estimation': require('./assets/onboarding/free-estimation.png'),
    'guy-bg': require('./assets/onboarding/guy-bg.png'),
    'guy': require('./assets/onboarding/guy.png'),
    'illustration-envelope': require('./assets/onboarding/illustration-envelope.png'),
    'illustration-money': require('./assets/onboarding/illustration-money.png'),
    'illustration-ticket': require('./assets/onboarding/illustration-ticket.png'),
    'money-back': require('./assets/onboarding/money-back.png'),
    'onboarding-4-push-notifications': require('./assets/onboarding/onboarding-4-push-notifications.png'),
    'onboarding-generic-push-notifications': require('./assets/onboarding/onboarding-generic-push-notifications.png'),
    'onboarding': require('./assets/onboarding/onboarding.png'),
    'phone-hand': require('./assets/onboarding/phone-hand.png'),
    'pig': require('./assets/onboarding/pig.png'),
    'pointing': require('./assets/onboarding/pointing.png'),
    'popup-piggy-bank': require('./assets/onboarding/popup-piggy-bank.png'),
    'shapes': require('./assets/onboarding/shapes.png'),
    'stars-green': require('./assets/onboarding/stars-green.png'),
    'stars-grey': require('./assets/onboarding/stars-grey.png'),
    'tax-type-card-children': require('./assets/onboarding/tax-type-card-children.png'),
    'tax-type-card-joint-assessment': require('./assets/onboarding/tax-type-card-joint-assessment.png'),
    'tax-type-card-single-employee': require('./assets/onboarding/tax-type-card-single-employee.png'),
    'tax-type-card-student': require('./assets/onboarding/tax-type-card-student.png'),
    'wave': require('./assets/onboarding/wave.png'),
    'wink': require('./assets/onboarding/wink.png')
  }
,
  'payment-illustrations-svg': {
    'iap-payment': require('./assets/payment-illustrations-svg/iap-payment.svg')
  }
,
  'payment-illustrations': {
    'iap-payment': require('./assets/payment-illustrations/iap-payment.png'),
    'special-free': require('./assets/payment-illustrations/special-free.png')
  }
,
  'payslip': {
    'error': require('./assets/payslip/error.png'),
    'good-lighting': require('./assets/payslip/good-lighting.png'),
    'illu-payslip-manual-partner': require('./assets/payslip/illu-payslip-manual-partner.png'),
    'illu-payslip-manual': require('./assets/payslip/illu-payslip-manual.png'),
    'illuDocumentPayslip': require('./assets/payslip/illuDocumentPayslip.png'),
    'imageLightConditions': require('./assets/payslip/imageLightConditions.png'),
    'imagePayslipCornerAlignment': require('./assets/payslip/imagePayslipCornerAlignment.png'),
    'imagePayslipNotFound': require('./assets/payslip/imagePayslipNotFound.png'),
    'imageSharpText': require('./assets/payslip/imageSharpText.png'),
    'payslip-small': require('./assets/payslip/payslip-small.png'),
    'payslip': require('./assets/payslip/payslip.png'),
    'taxfix-loading': require('./assets/payslip/taxfix-loading.png'),
    'upload-documents': require('./assets/payslip/upload-documents.png')
  }
,
  'prefill': {
    'capture-tip1': require('./assets/prefill/capture-tip1.png'),
    'capture-tip2': require('./assets/prefill/capture-tip2.png'),
    'example-letter-1': require('./assets/prefill/example-letter-1.png'),
    'example-letter-2': require('./assets/prefill/example-letter-2.png'),
    'example-letter-3': require('./assets/prefill/example-letter-3.png'),
    'example-letter-4': require('./assets/prefill/example-letter-4.png'),
    'find-your-nif': require('./assets/prefill/find-your-nif.png'),
    'icon-badge': require('./assets/prefill/icon-badge.png'),
    'icon-gov-prefilled-dark': require('./assets/prefill/icon-gov-prefilled-dark.png'),
    'icon-gov-prefilled': require('./assets/prefill/icon-gov-prefilled.png'),
    'icon-id-new-front-green': require('./assets/prefill/icon-id-new-front-green.png'),
    'icon-id-old-inside-green': require('./assets/prefill/icon-id-old-inside-green.png'),
    'icon-prefilled': require('./assets/prefill/icon-prefilled.png'),
    'illu-730-3-with-bubble': require('./assets/prefill/illu-730-3-with-bubble.png'),
    'illu-730-3': require('./assets/prefill/illu-730-3.png'),
    'illu-730-error': require('./assets/prefill/illu-730-error.png'),
    'illu-id-backside': require('./assets/prefill/illu-id-backside.png'),
    'illu-id-error': require('./assets/prefill/illu-id-error.png'),
    'illu-id-new': require('./assets/prefill/illu-id-new.png'),
    'illu-id-old': require('./assets/prefill/illu-id-old.png'),
    'illu-passport': require('./assets/prefill/illu-passport.png'),
    'magic-blue': require('./assets/prefill/magic-blue.png'),
    'prefill-activation-code-document-last-page': require('./assets/prefill/prefill-activation-code-document-last-page.png'),
    'prefill-activation-code-document': require('./assets/prefill/prefill-activation-code-document.png'),
    'prefill-activation-code-processing': require('./assets/prefill/prefill-activation-code-processing.png'),
    'prefill-activation-started': require('./assets/prefill/prefill-activation-started.png'),
    'prefill-code-expiry-info': require('./assets/prefill/prefill-code-expiry-info.png'),
    'prefill-info': require('./assets/prefill/prefill-info.png'),
    'prefill-landing-v2-banner': require('./assets/prefill/prefill-landing-v2-banner.png'),
    'prefill-logo': require('./assets/prefill/prefill-logo.png'),
    'prefill-onboarding': require('./assets/prefill/prefill-onboarding.png'),
    'upload-730': require('./assets/prefill/upload-730.png'),
    'yoga': require('./assets/prefill/yoga.png')
  }
,
  'referral': {
    'purpose-oriented-screen': require('./assets/referral/purpose-oriented-screen.png')
  }
,
  'sidebar-svg': {
    'external-link': require('./assets/sidebar-svg/external-link.svg'),
    'item-completed': require('./assets/sidebar-svg/item-completed.svg'),
    'item-current': require('./assets/sidebar-svg/item-current.svg')
  }
,
  'submission-svg': {
    'submission-id': require('./assets/submission-svg/submission-id.svg'),
    'submission-result-losscarryforward': require('./assets/submission-svg/submission-result-losscarryforward.svg'),
    'submission-result-negative': require('./assets/submission-svg/submission-result-negative.svg'),
    'submission-result-positive': require('./assets/submission-svg/submission-result-positive.svg')
  }
,
  'submission': {
    'confetti': require('./assets/submission/confetti.png'),
    'submission-failed': require('./assets/submission/submission-failed.png'),
    'submission-fee': require('./assets/submission/submission-fee.png'),
    'submission-id-document-de': require('./assets/submission/submission-id-document-de.png'),
    'submission-id-document-en': require('./assets/submission/submission-id-document-en.png'),
    'submission-id': require('./assets/submission/submission-id.png'),
    'submission-mandatory': require('./assets/submission/submission-mandatory.png'),
    'submission-pending': require('./assets/submission/submission-pending.png'),
    'submission-result-debt': require('./assets/submission/submission-result-debt.png'),
    'submission-result-loss': require('./assets/submission/submission-result-loss.png'),
    'submission-result-refund': require('./assets/submission/submission-result-refund.png'),
    'submission-successful': require('./assets/submission/submission-successful.png')
  }
,
  'supported-svg': {
    'icon-supported-badge-web': require('./assets/supported-svg/icon-supported-badge-web.svg')
  }
,
  'taxadvisors': {
    'timk-old': require('./assets/taxadvisors/timk-old.png'),
    'timk': require('./assets/taxadvisors/timk.png')
  }
,
  'todos-svg': {
    'icon-account-app': require('./assets/todos-svg/icon-account-app.svg'),
    'icon-account-web': require('./assets/todos-svg/icon-account-web.svg'),
    'icon-number': require('./assets/todos-svg/icon-number.svg'),
    'icon-todo-app': require('./assets/todos-svg/icon-todo-app.svg'),
    'icon-todo-check-grey': require('./assets/todos-svg/icon-todo-check-grey.svg'),
    'icon-todo-grey': require('./assets/todos-svg/icon-todo-grey.svg'),
    'icon-todo-web': require('./assets/todos-svg/icon-todo-web.svg'),
    'todos-empty': require('./assets/todos-svg/todos-empty.svg')
  }
,
  'todos': {
    'todos-no-todos': require('./assets/todos/todos-no-todos.png')
  }
,
  'under-construction': {
    'under-construction': require('./assets/under-construction/under-construction.svg')
  }
,
  'unsupported-svg': {
    'icon-supported-loans': require('./assets/unsupported-svg/icon-supported-loans.svg'),
    'icon-unsupported-business': require('./assets/unsupported-svg/icon-unsupported-business.svg'),
    'icon-unsupported-farmer': require('./assets/unsupported-svg/icon-unsupported-farmer.svg'),
    'icon-unsupported-foreign': require('./assets/unsupported-svg/icon-unsupported-foreign.svg'),
    'icon-unsupported-foreignbank': require('./assets/unsupported-svg/icon-unsupported-foreignbank.svg'),
    'icon-unsupported-jointassessment': require('./assets/unsupported-svg/icon-unsupported-jointassessment.svg'),
    'icon-unsupported-letting': require('./assets/unsupported-svg/icon-unsupported-letting.svg'),
    'icon-unsupported-parents': require('./assets/unsupported-svg/icon-unsupported-parents.svg'),
    'icon-unsupported-pension': require('./assets/unsupported-svg/icon-unsupported-pension.svg'),
    'icon-unsupported-silentpartnerships': require('./assets/unsupported-svg/icon-unsupported-silentpartnerships.svg'),
    'icon-unsupported-survivorpension': require('./assets/unsupported-svg/icon-unsupported-survivorpension.svg')
  }
,
  'unsupported': {
    'icon-unsupported-alimonies': require('./assets/unsupported/icon-unsupported-alimonies.png'),
    'icon-unsupported-annexehouse': require('./assets/unsupported/icon-unsupported-annexehouse.png'),
    'icon-unsupported-athlete': require('./assets/unsupported/icon-unsupported-athlete.png'),
    'icon-unsupported-expenses': require('./assets/unsupported/icon-unsupported-expenses.png'),
    'icon-unsupported-irregularwork': require('./assets/unsupported/icon-unsupported-irregularwork.png'),
    'icon-unsupported-movedtoitaly': require('./assets/unsupported/icon-unsupported-movedtoitaly.png'),
    'icon-unsupported-taxdeclaration': require('./assets/unsupported/icon-unsupported-taxdeclaration.png'),
    'icon-unsupported-unemployed': require('./assets/unsupported/icon-unsupported-unemployed.png'),
    'icon-unsupported-winnings': require('./assets/unsupported/icon-unsupported-winnings.png')
  }
,
  'upload-first-doc': {
    'upload-first-doc': require('./assets/upload-first-doc/upload-first-doc.svg')
  }
 }