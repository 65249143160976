// @flow

import BaseAnswer from './Base';
import { FormatterError } from '../error';
import type { ValidationFunction, ValidationSchema } from '../flowtypes';

export type ForeignAddressValue = {
  street: string,
  houseNumber: string,
  postalCode: string,
  city: string,
  country: string,
  state: string,
  additionalInfo?: string,
};

class ForeignAddress extends BaseAnswer {
  constructor() {
    const formats = [
      { name: 'STREET', type: 'string' },
      { name: 'HOUSE_NUMBER', type: 'string' },
      { name: 'POSTAL_CODE', type: 'string' },
      { name: 'CITY', type: 'string' },
      { name: 'COUNTRY', type: 'string' },
      { name: 'STATE', type: 'string' },
      { name: 'ADDITIONAL_INFO', type: 'string' },
    ];

    super('foreign_address', formats);
  }

  get validationSchema(): ?ValidationSchema {
    /* eslint-disable global-require */
    return {
      schema: require('../schema/foreign-address.json'),
    };
    /* eslint-enable global-require */
  }

  get validationFunction(): ?ValidationFunction {
    /* eslint-disable global-require */
    return require('./generated-validation/foreign_address');
    /* eslint-enable global-require */
  }

  format(value: any, formatKey: string): string {
    super.format(value); // make sure default validations running

    const incomingValue: ForeignAddressValue = value;
    const requestedFormat = this.getFormat(formatKey).name;

    switch (requestedFormat) {
      case this.formats.STREET:
        return incomingValue.street ? incomingValue.street : '';

      case this.formats.HOUSE_NUMBER:
        return incomingValue.houseNumber ? incomingValue.houseNumber : '';

      case this.formats.POSTAL_CODE:
        return incomingValue.postalCode ? incomingValue.postalCode : '';

      case this.formats.CITY:
        return incomingValue.city ? incomingValue.city : '';

      case this.formats.COUNTRY:
        return incomingValue.country ? incomingValue.country : '';

      case this.formats.STATE:
        return incomingValue.state ? incomingValue.state : '';

      case this.formats.ADDITIONAL_INFO:
        return incomingValue.additionalInfo ? incomingValue.additionalInfo : '';

      default:
        break;
    }

    throw new FormatterError(`Unknown format: ${formatKey}`);
  }
}

export default new ForeignAddress();
