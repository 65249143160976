'use strict';

const Decimal = require('decimal.js');

const logger = require('../logger');
const { toString } = require('../utils/utils_logs');
const {
  getDecimal,
  getLfdNrGroupsByNrs,
  iterateLfNr
} = require('../utils/utils_fields');

function applyLumpSumToBenefit(sum, personEarning, werbungskostenpauschbetrag) {
  const ZERO = new Decimal(0);
  // don't consider if there is no IRB
  if (!personEarning || sum.eq(ZERO)) return sum;

  let OUTSTANDING_LUMP_SUM = new Decimal(0);
  const LUMP_SUM = new Decimal(werbungskostenpauschbetrag);

  const {
    revenue,
    revenueAfterProfessionalExpenses,
    professionalExpenses
  } = personEarning;

  /**
   * The lump-sum werbungskostenpauschbetrag is the same lump-sum applied to
   * professionalExpenses. As a result, we cannot just deduct it from
   * the income replacement benefit. What we do is check if there is
   * an outstanding amount after applying it to professional expenses.
   * The outstanding, is what we now apply to the income replace benefit.
   *
   * Example 1 - We only have an outstanding 300 that can be used
   * revenue 700,00
   * actual expenses 300,00
   * professionalExpenses 700,00
   * revenueAfterProfessionalExpenses 0,00
   *
   * Example 2 - We only have an outstanding 700 that can be used
   * revenue 0,00
   * actual expenses 300,00
   * professionalExpenses 300,00
   * revenueAfterProfessionalExpenses -300,00
   *
   * Example 3 - In this case we already used up the lump-sum
   * so, we have nothing left to apply to IRB
   * revenue 2.000,00
   * actual expenses 900,00
   * professionalExpenses 1.000,00
   * revenueAfterProfessionalExpenses 1.000,00
   *
   * The formular below is how we calculate the outstanding lum-sum
   */
  if (revenueAfterProfessionalExpenses.lte(ZERO)) {
    if (professionalExpenses.lt(LUMP_SUM) || (revenue.gt(ZERO) && revenue.lt(LUMP_SUM))) {
      OUTSTANDING_LUMP_SUM = LUMP_SUM.minus(professionalExpenses);
    }
  }

  const result = sum.sub(OUTSTANDING_LUMP_SUM);

  return result.lt(ZERO) ? ZERO : result;
}

function incomeSubjectToProgressionTax2016(fields, personEarnings, werbungskostenpauschbetrag) {
  let personA = new Decimal(0);
  let personB = new Decimal(0);
  let total = new Decimal(0);

  logger.debug('calculate subject to progression');
  // Steuerpflichtige Person: Einkommensersatzleistungen Summe
  personA = personA.add(getDecimal(fields, '0104801'));
  logger.debug('sum A', toString(personA));

  // Steuerpflichtige Person B: Einkommensersatzleistungen Summe
  personB = personB.add(getDecimal(fields, '0104802'));
  logger.debug('sum B', toString(personB));

  // sum Entgeltersatzleistungen (im Arbeitsverhältnis): Summe
  iterateLfNr(getLfdNrGroupsByNrs(fields, ['0202001']), (lfdNrFields, lfdNr) => {
    const replacementNumber = getDecimal(lfdNrFields, '0202001');
    if (lfdNr === 0) personA = personA.add(replacementNumber);
    if (lfdNr === 1) personB = personB.add(replacementNumber);
  });

  /**
   * Foreign income that was received outside the periods mentioned
   * in lines 4 and / or 5 and was not subject to German income tax.
   */
  const foreignIncome = getDecimal(fields, '0105101');
  logger.debug('Foreign income', toString(foreignIncome));

  const incomeReplacementBenefits = personA.add(personB);
  logger.debug('sum after replacment - A', toString(personA));
  logger.debug('sum after replacment - B', toString(personB));

  // Take werbungskostenpauschbetrag into account
  personA = applyLumpSumToBenefit(personA, personEarnings[0], werbungskostenpauschbetrag);
  personB = applyLumpSumToBenefit(personB, personEarnings[1], werbungskostenpauschbetrag);

  logger.debug('sum after Werbungskostenpauschbetrag - A', toString(personA));
  logger.debug('sum after Werbungskostenpauschbetrag - B', toString(personB));

  total = total.add(personA);
  total = total.add(personB);
  total = total.add(foreignIncome);

  logger.debug('Total "incomeSubjectToProgression2016"', toString(total));

  return {
    total,
    foreignIncome,
    incomeReplacementBenefits,
  };
}

module.exports = {
  applyLumpSumToBenefit,
  incomeSubjectToProgressionTax2016
};
